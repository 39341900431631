@charset "UTF-8";
@import url(~antd/dist/antd.css);
:root {
  --color-primary-brand: #de202a;
  --color-secondary-brand: #32cccc;
  --color-tertiary-brand: #4b4ded;
  --color-dark: #010b13;
  --color-success: #32cccc;
  --color-text: #333333;
  --color-subtle-text: #888888;
  --color-text-light: #666668;
  --color-stone: #e7e8ec;
  --color-accent: #ecf1f4;
  --color-light: #d9d9d9;
  --acqua-gradient: linear-gradient(275.91deg, #adbac0 33.02%, #e7e8ec 100.85%);
  --grey-gradient: linear-gradient(103.94deg, #e4e5e9 14.13%, #c2cbd0 41.55%, #adbac0 91.56%);
  --font-size-h1-sm: 4rem;
  --font-size-h1: 6rem;
  --font-size-h2: 2.375rem;
  --font-size-h3: 1.25rem;
  --font-size-body-large: 1.5rem;
  --font-size-body: 1rem;
  --font-size-sm: 0.875rem;
  --font-size-xs: 0.6875rem;
  --border-light: 1px solid var(--color-text);
  --box-shadow-1: 12px 12px 8px 8px rgba(51, 51, 51, 0.05);
  --font-gotham: "Gotham", sans-serif;
  --font-nimbus: nimbus-sans, sans-serif; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #bbe5e6;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #33cccc;
  --secondary: #78868d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #f33;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #f2f4f3; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #00b3ef;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #007aa3;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f2f4f3;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c6f1f1; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #95e4e4; }

.table-hover .table-primary:hover {
  background-color: #b2ecec; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b2ecec; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d9dddf; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b9c0c4; }

.table-hover .table-secondary:hover {
  background-color: #cbd1d3; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cbd1d3; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ffc6c6; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ff9595; }

.table-hover .table-danger:hover {
  background-color: #ffadad; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ffadad; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #b9b6ba;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #99e6e6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 204, 0.25); }
  .form-control::placeholder {
    color: #c5c3c6;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f33; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(255, 51, 51, 0.9);
  border-radius: 0; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f33;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f33' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f33' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #f33;
    box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f33;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f33' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f33' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #f33;
    box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f33; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f33; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #f33; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff6666;
  background-color: #ff6666; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f33; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f33; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f33;
  box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn, .custom-file-label {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .custom-file-label {
      transition: none; } }
  .btn:hover, .custom-file-label:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .custom-file-label:focus, .btn.focus, .focus.custom-file-label {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 204, 0.25); }
  .btn.disabled, .disabled.custom-file-label, .btn:disabled, .custom-file-label:disabled, .btn.bold-button.disabled, .bold-button.disabled.custom-file-label, .btn.bold-button[disabled], .bold-button.custom-file-label[disabled] {
    opacity: 0.65; }

a.btn.disabled, a.disabled.custom-file-label,
fieldset:disabled a.btn,
fieldset:disabled a.custom-file-label {
  pointer-events: none; }

.btn-primary, .custom-file-label {
  color: #212529;
  background-color: #33cccc;
  border-color: #33cccc; }
  .btn-primary:hover, .custom-file-label:hover, .custom-file-input:hover + .custom-file-label {
    color: #fff;
    background-color: #2badad;
    border-color: #29a3a3; }
  .btn-primary:focus, .custom-file-label:focus, .btn-primary.focus, .focus.custom-file-label {
    color: #fff;
    background-color: #2badad;
    border-color: #29a3a3;
    box-shadow: 0 0 0 0.2rem rgba(48, 179, 180, 0.5); }
  .btn-primary.disabled, .disabled.custom-file-label, .btn-primary:disabled, .custom-file-label:disabled {
    color: #212529;
    background-color: #33cccc;
    border-color: #33cccc; }
  .btn-primary:not(:disabled):not(.disabled):active, .custom-file-label:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .custom-file-label:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle,
  .show > .dropdown-toggle.custom-file-label {
    color: #fff;
    background-color: #29a3a3;
    border-color: #269999; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .custom-file-label:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .custom-file-label:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus,
    .show > .dropdown-toggle.custom-file-label:focus {
      box-shadow: 0 0 0 0.2rem rgba(48, 179, 180, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #78868d;
  border-color: #78868d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #667379;
    border-color: #606c72; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #667379;
    border-color: #606c72;
    box-shadow: 0 0 0 0.2rem rgba(140, 152, 158, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #78868d;
    border-color: #78868d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #606c72;
    border-color: #5a656b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(140, 152, 158, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #f33;
  border-color: #f33; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ff0d0d;
    border-color: red; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #ff0d0d;
    border-color: red;
    box-shadow: 0 0 0 0.2rem rgba(255, 82, 82, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f33;
    border-color: #f33; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: red;
    border-color: #f20000; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 82, 82, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #33cccc;
  border-color: #33cccc; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #33cccc;
    border-color: #33cccc; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 204, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #33cccc;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #33cccc;
    border-color: #33cccc; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(51, 204, 204, 0.5); }

.btn-outline-secondary {
  color: #78868d;
  border-color: #78868d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #78868d;
    border-color: #78868d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(120, 134, 141, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #78868d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #78868d;
    border-color: #78868d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(120, 134, 141, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #f33;
  border-color: #f33; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f33;
    border-color: #f33; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f33;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f33;
    border-color: #f33; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #00b3ef;
  text-decoration: none; }
  .btn-link:hover {
    color: #007aa3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .custom-file-label {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .custom-file-label {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #33cccc; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn, .btn-group > .custom-file-label,
  .btn-group-vertical > .btn,
  .btn-group-vertical > .custom-file-label {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover, .btn-group > .custom-file-label:hover,
    .btn-group-vertical > .btn:hover,
    .btn-group-vertical > .custom-file-label:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .custom-file-label:focus, .btn-group > .btn:active, .btn-group > .custom-file-label:active, .btn-group > .btn.active, .btn-group > .active.custom-file-label,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .custom-file-label:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .custom-file-label:active,
    .btn-group-vertical > .btn.active,
    .btn-group-vertical > .active.custom-file-label {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child), .btn-group > .custom-file-label:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .custom-file-label:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .custom-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child), .btn-group > .custom-file-label:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .custom-file-label + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .custom-file-label + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  
  .btn-group-vertical > .btn,
  .btn-group-vertical > .custom-file-label,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .custom-file-label:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .custom-file-label:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn,
  .btn-group-vertical > .btn-group:not(:last-child) > .custom-file-label {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .custom-file-label:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .custom-file-label {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn, .btn-group-toggle > .custom-file-label,
.btn-group-toggle > .btn-group > .btn,
.btn-group-toggle > .btn-group > .custom-file-label {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .custom-file-label input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .custom-file-label input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .custom-file-label input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .custom-file-label input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn, .input-group-prepend .custom-file-label,
  .input-group-append .btn,
  .input-group-append .custom-file-label {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus, .input-group-prepend .custom-file-label:focus,
    .input-group-append .btn:focus,
    .input-group-append .custom-file-label:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn, .input-group-prepend .custom-file-label + .btn, .input-group-prepend .btn + .custom-file-label, .input-group-prepend .custom-file-label + .custom-file-label,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .custom-file-label + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-prepend .input-group-text + .custom-file-label,
  .input-group-append .btn + .btn,
  .input-group-append .custom-file-label + .btn,
  .input-group-append .btn + .custom-file-label,
  .input-group-append .custom-file-label + .custom-file-label,
  .input-group-append .btn + .input-group-text,
  .input-group-append .custom-file-label + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn,
  .input-group-append .input-group-text + .custom-file-label {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #b9b6ba;
  text-align: center;
  white-space: nowrap;
  background-color: white;
  border: 1px solid #b9b6ba;
  border-radius: 0; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .custom-file-label,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .custom-file-label {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .custom-file-label,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .custom-file-label {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .custom-file-label,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .custom-file-label,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .custom-file-label:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .custom-file-label,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .custom-file-label,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .custom-file-label:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #33cccc;
    background-color: #33cccc; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 204, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #99e6e6; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #c2f0f0;
    border-color: #c2f0f0; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #33cccc;
  background-color: #33cccc; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(51, 204, 204, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(51, 204, 204, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(51, 204, 204, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(51, 204, 204, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #b9b6ba;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #99e6e6;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 204, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #99e6e6;
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 204, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  background-color: #33cccc;
  border: 1px solid #33cccc;
  border-radius: 0; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: white;
    content: "Browse";
    background-color: white;
    border-left: inherit;
    border-radius: 0 0 0 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #f2f4f3, 0 0 0 0.2rem rgba(51, 204, 204, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #f2f4f3, 0 0 0 0.2rem rgba(51, 204, 204, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #f2f4f3, 0 0 0 0.2rem rgba(51, 204, 204, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #33cccc;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #c2f0f0; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #33cccc;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #c2f0f0; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #33cccc;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #c2f0f0; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #f2f4f3;
    border-color: #dee2e6 #dee2e6 #f2f4f3; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #33cccc; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: -1px -1px 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 -1px -1px; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: -1px;
  border-top-right-radius: -1px; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: -1px;
  border-bottom-left-radius: -1px; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #00b3ef;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #007aa3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 204, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #33cccc;
  border-color: #33cccc; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge, .custom-file-label .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #212529;
  background-color: #33cccc; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #212529;
    background-color: #29a3a3; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 204, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #78868d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #606c72; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(120, 134, 141, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #f33; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: red; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #1b6a6a;
  background-color: #d6f5f5;
  border-color: #c6f1f1; }
  .alert-primary hr {
    border-top-color: #b2ecec; }
  .alert-primary .alert-link {
    color: #114141; }

.alert-secondary {
  color: #3e4649;
  background-color: #e4e7e8;
  border-color: #d9dddf; }
  .alert-secondary hr {
    border-top-color: #cbd1d3; }
  .alert-secondary .alert-link {
    color: #272c2d; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #851b1b;
  background-color: #ffd6d6;
  border-color: #ffc6c6; }
  .alert-danger hr {
    border-top-color: #ffadad; }
  .alert-danger .alert-link {
    color: #5b1212; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #33cccc;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #33cccc;
    border-color: #33cccc; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #1b6a6a;
  background-color: #c6f1f1; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #1b6a6a;
    background-color: #b2ecec; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #1b6a6a;
    border-color: #1b6a6a; }

.list-group-item-secondary {
  color: #3e4649;
  background-color: #d9dddf; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #3e4649;
    background-color: #cbd1d3; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3e4649;
    border-color: #3e4649; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #851b1b;
  background-color: #ffc6c6; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #851b1b;
    background-color: #ffadad; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #851b1b;
    border-color: #851b1b; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #33cccc !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #29a3a3 !important; }

.bg-secondary {
  background-color: #78868d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #606c72 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #f33 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: red !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #33cccc !important; }

.border-secondary {
  border-color: #78868d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #f33 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #33cccc !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #248f8f !important; }

.text-secondary {
  color: #78868d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545f64 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger, .newCreditCard .errorMessage, .billing-info .new-credit-card-v2 .error-message {
  color: #f33 !important; }

a.text-danger:hover, .newCreditCard a.errorMessage:hover, .billing-info .new-credit-card-v2 a.error-message:hover, a.text-danger:focus, .newCreditCard a.errorMessage:focus, .billing-info .new-credit-card-v2 a.error-message:focus {
  color: #e60000 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn):not(.custom-file-label) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.grecaptcha-badge {
  visibility: hidden !important; }

.app {
  min-height: calc(100vh - 180px); }
  .app .set-width {
    min-height: calc(100vh - (30px + 78px)); }
  .app .gotham-regular-font {
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .app .gotham-bold-font {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .app .gotham-medium-font {
    font-family: Gotham-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .app .nimbus-regular-font {
    font-family: "nimbus-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .app.login {
    background-color: #363d43; }
    .app.login .route-content {
      max-width: 1000px;
      width: 100%;
      padding: 0;
      min-height: calc(100vh - 180px); }
  .app.v2-login {
    background-color: white; }
    .app.v2-login .route-content {
      max-width: 1000px;
      width: 100%;
      padding: 0; }
  .app .mobileNavBar {
    display: none; }
  .app .mobileNavBarV2 {
    display: none; }
  .app .navbars {
    height: auto; }
  .app .mobileFooter {
    display: none; }
  .app .v2footers {
    height: auto;
    width: 100%;
    position: absolute;
    z-index: 999; }
  .app .footers {
    height: auto;
    width: 100%; }
  .app .route-content {
    width: 100%;
    margin: 0 auto;
    padding: 20px 0;
    min-height: calc(100vh - 30px);
    overflow: hidden; }
  .app .set-padding {
    padding: 45px 0; }

@media (max-width: 1100px) {
  .app .set-width {
    min-height: calc(100vh - 30px); }
  .app.container-fluid, .app.container-sm, .app.container-md, .app.container-lg, .app.container-xl {
    padding: 0; }
    .app.container-fluid > .row, .app.container-sm > .row, .app.container-md > .row, .app.container-lg > .row, .app.container-xl > .row {
      margin: 0; }
  .app .mobileNavBar {
    display: flex; }
  .app .mobileNavBarV2 {
    display: flex; }
  .app .navBar {
    display: none; }
  .app .navBarV2 {
    display: none; }
  .app .mobileFooter {
    display: flex; }
  .app .footer {
    display: none; }
  .app .route-content {
    padding-top: 25px; } }

.v2 {
  background-color: white; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.navBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 78px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: #454e56; }
  .navBar .logo {
    width: 45px; }
  .navBar .links {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: italic;
    height: 100%; }
    .navBar .links li {
      padding-right: 20px; }
      .navBar .links li:last-of-type {
        padding-right: 0; }
        .navBar .links li:last-of-type.simple {
          padding-left: 40px;
          margin-right: auto;
          font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-style: normal;
          white-space: nowrap; }
          .navBar .links li:last-of-type.simple > a {
            text-transform: uppercase; }
  .navBar .unauthed-links {
    flex-grow: 1; }
    .navBar .unauthed-links:last-of-type {
      padding-right: 0; }
      .navBar .unauthed-links:last-of-type.simple {
        padding-left: 40px;
        margin-right: auto;
        width: 110%;
        font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-style: normal;
        text-transform: uppercase; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.navLink {
  display: flex;
  color: #fff; }
  .navLink.active > a {
    color: #33cccc;
    z-index: 0; }
    .navLink.active > a:after {
      height: 14px;
      background: #33cccc;
      position: absolute;
      bottom: -6.5px;
      left: 0;
      content: '';
      width: 100%;
      z-index: 2; }
  .navLink .thumbnail {
    height: 40px;
    width: 40px;
    margin-left: 7px;
    border-radius: 40px;
    background-size: cover;
    background-position: center; }
  .navLink > a {
    display: flex;
    position: relative;
    align-items: center;
    color: #fff;
    font-size: 14px;
    line-height: 17px;
    padding: 13px 5px;
    text-decoration: none;
    letter-spacing: .7px;
    text-transform: lowercase; }
    .navLink > a:hover {
      color: #33cccc; }
    .navLink > a > .fa-angle-down {
      display: inline-block;
      padding-left: 6px; }
    .navLink > a > div {
      flex-wrap: nowrap; }
  .navLink > .dropdown-menu {
    padding-top: 5px;
    padding-bottom: 0;
    margin: 0;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    right: 0;
    left: auto; }
    .navLink > .dropdown-menu:before {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      display: block;
      top: -15px;
      right: 25%;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid #fff; }
    .navLink > .dropdown-menu.show {
      display: block; }
    .navLink > .dropdown-menu a {
      background-color: #fff;
      color: #78868d;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-style: normal;
      padding: .8rem 1.75rem .8rem 2rem;
      border-top: 1px solid #ECEFF5;
      font-size: 14px; }
      .navLink > .dropdown-menu a:hover {
        background-color: #f2f4f3; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.dashboard {
  width: 80%;
  margin: 0 auto; }
  .dashboard .header-rating-flag {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-family: Evogria, sans-serif; }
  .dashboard > .header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-family: Evogria, sans-serif; }
    .dashboard > .header h1 {
      margin: 0;
      line-height: 40px;
      text-transform: uppercase;
      font-weight: normal; }
  .dashboard > h2 {
    font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 20px;
    font-style: italic;
    line-height: 25px;
    margin: 0;
    margin-bottom: 8px;
    color: #677379;
    display: flex; }
    .dashboard > h2 img {
      margin-right: 5px;
      top: 2px;
      position: relative;
      height: 1.2em; }
  .dashboard > div > p {
    color: #78868d;
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    color: #677379; }
  .dashboard .rating-section {
    background-color: #e4e4e4;
    border-style: dashed;
    border-width: 1px;
    border-radius: 5px; }
    .dashboard .rating-section > div, .dashboard .rating-section p {
      color: #677379; }
    .dashboard .rating-section .review-ats {
      width: 40%;
      border-radius: 5px;
      font-family: Evogria, sans-serif;
      border-style: solid;
      border-width: 1px;
      font-size: 15px; }

.rating-modal > div, .rating-modal p {
  color: #677379; }

.rating-modal .button {
  border: none;
  color: white;
  font-weight: bold; }

.rating-modal .light-teal-button {
  background-color: #33cccc; }

.rating-modal .grey-button {
  background-color: grey; }

.rating-modal .skip-button {
  outline: none;
  border: none;
  background-color: white;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.rating-modal .modal-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; }

.rating-modal .footer-rating-modal {
  font-family: Evogria, sans-serif; }

.empty-events {
  height: calc(80vh - 180px); }
  .empty-events h3 {
    color: #33cccc;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 18px;
    text-transform: none; }
    .empty-events h3 i {
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-transform: lowercase; }

@media (min-width: 1100px) {
  .empty-events .display-mobile {
    display: none; } }

@media (max-width: 1100px) {
  .dashboard {
    width: 100%; }
    .dashboard .rating-section {
      padding-left: 5px;
      padding-right: 5px; }
    .dashboard .empty-events {
      padding-left: 5px;
      padding-right: 5px; }
      .dashboard .empty-events > * {
        margin-top: 25px; }
    .dashboard > h2 {
      padding-left: 5px;
      padding-right: 5px; }
    .dashboard > div > p {
      padding-left: 5px;
      padding-right: 5px; }
    .dashboard .header-rating-flag {
      padding-left: 5px;
      padding-right: 5px; }
      .dashboard .header-rating-flag .btn-danger {
        display: none; }
    .dashboard .header {
      padding-left: 5px;
      padding-right: 5px; }
      .dashboard .header .btn-danger {
        display: none; }
      .dashboard .header > h1 {
        margin-bottom: 10px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.parentDashboard {
  width: 80%;
  margin: 0 auto; }
  .parentDashboard > .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
    font-family: Evogria, sans-serif; }
    .parentDashboard > .header h1 {
      margin: 0;
      line-height: 40px;
      text-transform: uppercase;
      font-weight: normal; }
  .parentDashboard > h2 {
    font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 20px;
    font-style: italic;
    line-height: 25px;
    margin: 0;
    margin-bottom: 8px;
    color: #677379;
    display: flex; }
    .parentDashboard > h2 img {
      margin-right: 5px;
      top: 2px;
      position: relative;
      height: 1.2em; }
  .parentDashboard > div > p {
    color: #78868d;
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    color: #677379; }
  .parentDashboard > div > p.go4blue {
    color: #33cccc; }

.empty-events {
  height: calc(80vh - 180px); }
  .empty-events h3 {
    color: #33cccc;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 18px;
    text-transform: none; }
    .empty-events h3 i {
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-transform: lowercase; }

@media (max-width: 1100px) {
  .parentDashboard {
    text-align: center;
    width: 100%; }
    .parentDashboard > div > p {
      padding: 8px 16px;
      text-align: left; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.medicalRecord {
  width: 80%;
  margin: 0 auto; }
  .medicalRecord table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    word-wrap: break-word; }
  .medicalRecord tr.header {
    font-family: "Evogria", Sans-serif, serif;
    vertical-align: bottom;
    text-align: left; }
  .medicalRecord .centered {
    text-align: center; }
  .medicalRecord table, .medicalRecord td, .medicalRecord th {
    border: 1px solid black;
    padding: 10px; }
  .medicalRecord td.subheader {
    font-family: "Evogria", Sans-serif, serif;
    background-color: lightgrey; }
  .medicalRecord .bg-gray {
    background-color: lightgrey; }

.emptyMedicalRecord {
  height: calc(80vh - 180px); }
  .emptyMedicalRecord h3 {
    color: #33cccc;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 18px;
    text-transform: none; }
    .emptyMedicalRecord h3 i {
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-transform: lowercase; }

@media (max-width: 1100px) {
  .medicalRecord {
    width: 100%;
    padding: 8px 16px; }
    .medicalRecord > .header {
      flex-direction: column;
      text-align: center; }
    .medicalRecord .actions {
      display: flex;
      align-self: center;
      justify-content: center; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

@media (max-width: 1100px) {
  .medicalRecordWaiverList .xs-btn-primary-xs {
    padding: 0.125rem 0.25rem;
    margin-top: 0.125rem;
    font-size: 0.75rem;
    line-height: 1.25;
    border-radius: 0.15rem; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventList .list-table > .head > div {
  flex-grow: 1; }
  .eventList .list-table > .head > div:nth-of-type(1) {
    width: 7%; }
  .eventList .list-table > .head > div:nth-child(2) {
    width: 28%;
    flex-grow: 2;
    padding-right: 10px; }
  .eventList .list-table > .head > div:nth-child(3) {
    width: 14%; }
  .eventList .list-table > .head > div:nth-child(4) {
    width: calc((9%) + 1px);
    text-align: center; }
  .eventList .list-table > .head > div:nth-child(5) {
    width: calc(9%);
    text-align: center; }
  .eventList .list-table > .head > div:nth-child(6) {
    width: calc((9%) + 1px);
    text-align: center; }
  .eventList .list-table > .head > div:nth-child(7) {
    width: 14%;
    text-align: center; }
  .eventList .list-table > .head > div:nth-child(8) {
    width: 9%;
    text-align: center; }

.eventList .errors {
  margin: 10px;
  color: red; }

.eventList .head.draft div:nth-of-type(n+4):not(:last-of-type) {
  color: #aab8be; }

@media (max-width: 1100px) {
  .eventList .list-table .head {
    display: none; }
  .eventList .list-table > li > div:nth-of-type(n+4) {
    display: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventListItem small.feature-v2 {
  position: absolute;
  margin-top: 10px;
  margin-left: 13px;
  z-index: 1;
  font-weight: bolder;
  font-size: 9px;
  color: orange; }

.eventListItem.expanded {
  background-color: #eefafa; }
  .eventListItem.expanded > .summary > div:nth-of-type(1) .no-alert-bar {
    background-color: #eefafa; }
  .eventListItem.expanded > .summary .item-controls .edit-link {
    display: block; }

.eventListItem.draft > .summary > div:first-of-type {
  justify-content: flex-start;
  align-items: center; }
  .eventListItem.draft > .summary > div:first-of-type > div {
    min-height: 71px;
    color: #f33;
    text-transform: uppercase;
    font-size: 12px; }

.eventListItem.draft > .summary > div:nth-of-type(4), .eventListItem.draft > .summary > div:nth-of-type(5), .eventListItem.draft > .summary > div:nth-of-type(6), .eventListItem.draft > .summary > div:nth-of-type(7) {
  content: none; }
  .eventListItem.draft > .summary > div:nth-of-type(4).bold, .eventListItem.draft > .summary > div:nth-of-type(5).bold, .eventListItem.draft > .summary > div:nth-of-type(6).bold, .eventListItem.draft > .summary > div:nth-of-type(7).bold {
    color: #b9b9bc; }

.eventListItem.draft > .summary .item-controls .confirmationModal {
  display: block; }

.eventListItem.draft > .summary .item-controls .edit-link {
  display: block; }

.eventListItem > .summary {
  display: flex;
  width: 100% !important; }
  .eventListItem > .summary > .pending-request-text {
    color: red !important; }
  .eventListItem > .summary > div {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px 0; }
    .eventListItem > .summary > div:nth-of-type(1) {
      width: 7%; }
    .eventListItem > .summary > div:nth-child(2) {
      width: 28%;
      flex-grow: 2;
      padding-right: 10px; }
    .eventListItem > .summary > div:nth-child(3) {
      width: 14%; }
    .eventListItem > .summary > div:nth-child(4) {
      width: calc((9%) + 1px);
      text-align: center; }
    .eventListItem > .summary > div:nth-child(5) {
      width: calc(9%);
      text-align: center; }
    .eventListItem > .summary > div:nth-child(6) {
      width: calc((9%) + 1px);
      text-align: center; }
    .eventListItem > .summary > div:nth-child(7) {
      width: 14%;
      text-align: center; }
    .eventListItem > .summary > div:nth-child(8) {
      width: 9%;
      text-align: center; }
    .eventListItem > .summary > div b {
      font-size: 15px;
      line-height: 17px;
      font-weight: bold;
      color: #677379;
      margin: 0; }
    .eventListItem > .summary > div p {
      font-size: 12px;
      line-height: 17px;
      color: #78868d;
      margin: 0; }
    .eventListItem > .summary > div:nth-of-type(4), .eventListItem > .summary > div:nth-of-type(5), .eventListItem > .summary > div:nth-of-type(6) {
      color: #b9b9bc;
      font-weight: bold;
      font-size: 24px;
      line-height: 31px; }
      .eventListItem > .summary > div:nth-of-type(4).bold, .eventListItem > .summary > div:nth-of-type(5).bold, .eventListItem > .summary > div:nth-of-type(6).bold {
        color: #000; }
    .eventListItem > .summary > div:nth-of-type(1) {
      padding: 0; }
      .eventListItem > .summary > div:nth-of-type(1) > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: #c4c5c7;
        font-size: 18px;
        padding: 0;
        height: 100%; }
        .eventListItem > .summary > div:nth-of-type(1) > div .fa-angle-down,
        .eventListItem > .summary > div:nth-of-type(1) > div .fa-angle-up {
          flex-grow: 1;
          text-align: center; }
    .eventListItem > .summary > div:nth-of-type(4), .eventListItem > .summary > div:nth-of-type(8) {
      border-left: 1px solid #c4c5c7;
      margin: 10px 0; }
    .eventListItem > .summary > div:nth-of-type(4) {
      padding: 0; }
    .eventListItem > .summary > div:nth-of-type(6) {
      border-right: 1px solid #c4c5c7;
      margin: 10px 0;
      padding: 0; }
    .eventListItem > .summary > div:nth-of-type(7) {
      color: #c4c5c7;
      font-size: 12px;
      line-height: 17px; }
      .eventListItem > .summary > div:nth-of-type(7) p {
        color: #e03738;
        margin: 4px 0; }
  .eventListItem > .summary .item-controls {
    display: flex;
    padding: 0 10px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center; }
    .eventListItem > .summary .item-controls .confirmationModal {
      display: none; }
    .eventListItem > .summary .item-controls .delete-link {
      color: #e03738; }

.eventListItem .alert-bar,
.eventListItem .no-alert-bar {
  width: 12px;
  min-height: 71px;
  background-color: #e03738; }

.eventListItem .alert-bar {
  border: 2px solid #e03738;
  border-right: none; }

.eventListItem .event-operator {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 6%;
  padding-right: 2%;
  background-color: #f3f4f5;
  border-top: 1px solid #dcdcdd;
  color: #79968d; }
  .eventListItem .event-operator > div {
    width: 22%;
    padding: 7px 0;
    font-size: 12px;
    line-height: 15px; }
    .eventListItem .event-operator > div img {
      width: 33px;
      height: 33px;
      margin-right: 9px;
      border-radius: 33px; }
    .eventListItem .event-operator > div:nth-of-type(2) {
      width: 43%; }
    .eventListItem .event-operator > div:last-of-type {
      flex-grow: 1;
      width: 17%;
      padding-left: 15%; }

.eventListItem .no-alert-bar {
  background-color: #fff; }

.eventListItem .mobile-controls {
  display: none; }

@media (max-width: 1100px) {
  .eventListItem.expanded .mobile-controls .no-alert-bar {
    background-color: #eefafa; }
  .eventListItem .draft-alert-bar {
    display: none !important; }
  .eventListItem .mobile-controls {
    display: flex;
    height: 30px;
    align-items: center;
    padding: 4px;
    padding-left: 0;
    padding-top: 0;
    padding-right: 8px; }
    .eventListItem .mobile-controls p {
      color: #e03738;
      font-size: 12px;
      padding: 0;
      margin: 0;
      text-align: center; }
    .eventListItem .mobile-controls .alert {
      flex-grow: 1;
      text-align: left; }
    .eventListItem .mobile-controls .alert-bar,
    .eventListItem .mobile-controls .no-alert-bar {
      min-height: 30px;
      margin-top: 0; }
    .eventListItem .mobile-controls i {
      padding: 4px; }
    .eventListItem .mobile-controls .fas.fa-pencil-alt {
      margin-right: 25px; }
  .eventListItem .summary > div:nth-of-type(n + 4) {
    display: none !important; }
  .eventListItem .summary > div:nth-of-type(1) {
    width: 12px !important;
    flex-grow: unset; }
    .eventListItem .summary > div:nth-of-type(1) i {
      display: none; }
  .eventListItem .summary > div:nth-of-type(2) {
    padding-bottom: 0;
    align-self: flex-start;
    flex-grow: 1; }
  .eventListItem .summary > div:nth-of-type(3) {
    width: auto;
    padding-bottom: 0;
    align-self: flex-start;
    align-items: flex-end;
    padding-right: 12px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.shiftListItem {
  border-top: none;
  background-color: #f3f4f5; }
  .shiftListItem > .header, .shiftListItem > .details {
    display: flex;
    flex-direction: row; }
  .shiftListItem > .header {
    background-color: #dcdcdd;
    color: #677379;
    font-size: 12px;
    line-height: 15px;
    padding-left: 59px;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    height: 30px;
    display: flex;
    align-items: center; }
    .shiftListItem > .header div {
      padding: 0 40px;
      border-left: 1px solid #677379;
      font-weight: bold; }
      .shiftListItem > .header div:first-of-type {
        padding-left: 0;
        width: 20%;
        min-width: 200px;
        border: none; }
      .shiftListItem > .header div:nth-of-type(2) {
        min-width: 177px;
        width: 18%; }
      .shiftListItem > .header div:nth-of-type(3) {
        font-weight: normal;
        width: 41.5%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden; }
      .shiftListItem > .header div:last-of-type {
        font-weight: normal;
        text-align: left;
        width: 21.5%;
        border-right: 1px solid #677379;
        margin-left: auto; }
        .shiftListItem > .header div:last-of-type.assign-shift {
          border: none;
          padding: 0; }
          .shiftListItem > .header div:last-of-type.assign-shift .plain-button {
            text-transform: uppercase;
            font-weight: bold; }
            .shiftListItem > .header div:last-of-type.assign-shift .plain-button:hover {
              cursor: pointer; }
        .shiftListItem > .header div:last-of-type.eap-link {
          width: auto;
          margin: 0;
          padding: 0; }
          .shiftListItem > .header div:last-of-type.eap-link a {
            padding: 0.75rem 1.25rem; }
            .shiftListItem > .header div:last-of-type.eap-link a small.eap-feature-v2 {
              z-index: 1;
              font-weight: bolder;
              font-size: 9px;
              color: #ff8800; }
        .shiftListItem > .header div:last-of-type .alert {
          color: #e03738; }
  .shiftListItem > .details {
    flex-direction: column;
    padding-left: 2%;
    background-color: #f3f4f5; }
  .shiftListItem .waitlist {
    padding-left: 2%; }
  .shiftListItem .waitlist-toggle {
    margin-left: calc(6% + 41px);
    font-size: 12px;
    line-height: 15px; }
    .shiftListItem .waitlist-toggle:hover {
      cursor: pointer; }

@media (max-width: 1100px) {
  .shiftListItem > .header {
    padding: 0 10px; }
    .shiftListItem > .header > div {
      border: none;
      padding: 0; }
    .shiftListItem > .header > div:nth-of-type(1) {
      min-width: auto;
      flex-grow: 1; }
    .shiftListItem > .header > div:nth-of-type(2) {
      width: auto;
      min-width: auto; }
    .shiftListItem > .header > div:nth-of-type(3) {
      display: none; }
    .shiftListItem > .header .assign-shift, .shiftListItem > .header .eap-link {
      display: none; }
  .shiftListItem > .details {
    padding: 0 16px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.shiftJobItem {
  display: flex;
  flex-direction: row;
  padding-left: 4%;
  padding-right: 10px;
  border-bottom: 1px solid #dcdcdd; }
  .shiftJobItem > div {
    display: flex;
    flex: 2;
    align-items: center;
    padding: 7px 0;
    font-size: 12px;
    line-height: 15px;
    color: #79968d; }
    .shiftJobItem > div a {
      text-decoration: none; }
    .shiftJobItem > div img {
      width: 33px;
      height: 33px;
      margin-right: 9px;
      border-radius: 33px;
      object-fit: cover; }
    .shiftJobItem > div.applied {
      flex: 3;
      flex-grow: 3; }
    .shiftJobItem > div.payRate {
      flex: 1;
      flex-grow: 1;
      justify-content: center; }
    .shiftJobItem > div.jobTimes {
      flex: 4;
      flex-grow: 4; }
    .shiftJobItem > div.shiftInfo {
      flex: 2;
      justify-content: center; }
      .shiftJobItem > div.shiftInfo .plain-button {
        color: #f33;
        font-weight: bold; }
        .shiftJobItem > div.shiftInfo .plain-button:hover {
          cursor: pointer; }
    .shiftJobItem > div.actions {
      justify-content: center; }
    .shiftJobItem > div.shiftStateRow {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 20px; }
      .shiftJobItem > div.shiftStateRow .decline {
        font-weight: bold;
        color: #363d43;
        text-transform: uppercase; }
    .shiftJobItem > div .shiftState {
      display: flex;
      flex-direction: column;
      align-items: flex-center;
      justify-content: center; }
      .shiftJobItem > div .shiftState p {
        color: #ff3333;
        margin-top: 0.3em;
        margin-bottom: 0; }
      .shiftJobItem > div .shiftState a {
        padding: 0; }
      .shiftJobItem > div .shiftState .pending {
        font-weight: bold;
        color: #f33; }
      .shiftJobItem > div .shiftState .pending,
      .shiftJobItem > div .shiftState .decommitted,
      .shiftJobItem > div .shiftState .callout {
        text-transform: uppercase; }
      .shiftJobItem > div .shiftState .eo_accepted_at {
        color: #454e56;
        font-weight: bold; }
      .shiftJobItem > div .shiftState .confirmed,
      .shiftJobItem > div .shiftState .checked_in,
      .shiftJobItem > div .shiftState .paid {
        text-transform: uppercase;
        color: #33cccc;
        font-weight: bolder; }
      .shiftJobItem > div .shiftState .waitlist,
      .shiftJobItem > div .shiftState .decommitted .callout {
        color: #98a1a5; }
        .shiftJobItem > div .shiftState .waitlist:hover,
        .shiftJobItem > div .shiftState .decommitted .callout:hover {
          color: #98a1a5; }
      .shiftJobItem > div .shiftState .eo_accepted_at:hover,
      .shiftJobItem > div .shiftState .confirmed:hover,
      .shiftJobItem > div .shiftState .waitlist:hover,
      .shiftJobItem > div .shiftState .decommitted .callout:hover {
        cursor: default; }
      .shiftJobItem > div .shiftState .extension-request {
        font-size: 12px;
        text-transform: uppercase;
        padding: 6px 0 0 0; }

@media (max-width: 1100px) {
  .shiftJobItem {
    padding: 0;
    flex-direction: column;
    justify-content: flex-start; }
    .shiftJobItem > div {
      padding: 8px 0; }
      .shiftJobItem > div:nth-of-type(1) {
        flex-grow: 1; }
      .shiftJobItem > div:last-of-type {
        width: auto; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.createEventScene, .editEventScene, .editEAPScene {
  width: 90%;
  margin: 0 auto; }
  .createEventScene h1, .editEventScene h1, .editEAPScene h1 {
    margin-bottom: 0; }
  .createEventScene .step-number, .editEventScene .step-number, .editEAPScene .step-number {
    color: #78868d;
    font-size: 18px;
    line-height: 22px; }
  .createEventScene .step-description, .editEventScene .step-description, .editEAPScene .step-description {
    height: 26px;
    width: 100%;
    color: #78868d;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px; }
  .createEventScene label:not(.custom-file-label), .editEventScene label:not(.custom-file-label), .editEAPScene label:not(.custom-file-label) {
    height: 17px;
    color: #78868d;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    display: inline-table; }
  .createEventScene .link-button.secondary, .editEventScene .link-button.secondary, .editEAPScene .link-button.secondary {
    color: #78868d;
    text-transform: uppercase; }
  .createEventScene .button-row, .editEventScene .button-row, .editEAPScene .button-row {
    margin-top: 34px; }
  .createEventScene .bottom-border-only, .editEventScene .bottom-border-only, .editEAPScene .bottom-border-only {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0; }
    .createEventScene .bottom-border-only:focus, .editEventScene .bottom-border-only:focus, .editEAPScene .bottom-border-only:focus {
      box-shadow: none; }
  .createEventScene .image-preview, .editEventScene .image-preview, .editEAPScene .image-preview {
    max-height: 140px;
    max-width: 140px; }
  .createEventScene #detailsForm, .editEventScene #detailsForm, .editEAPScene #detailsForm {
    border-bottom: 1px solid #dcdcdd; }
  .createEventScene .shift-form-label, .editEventScene .shift-form-label, .editEAPScene .shift-form-label {
    margin-top: 24px;
    color: #b9b6ba;
    font-style: italic; }
  .createEventScene .shift-form-label + .link-button, .editEventScene .shift-form-label + .link-button, .editEAPScene .shift-form-label + .link-button {
    text-transform: uppercase;
    padding-left: 30px;
    font-size: 14px; }
  @media (max-width: 1100px) {
    .createEventScene, .editEventScene, .editEAPScene {
      width: 100%; }
      .createEventScene .progress-dots, .editEventScene .progress-dots, .editEAPScene .progress-dots {
        font-size: small; }
      .createEventScene .title, .editEventScene .title, .editEAPScene .title {
        text-align: center;
        width: 100%;
        padding-left: 5px; }
      .createEventScene .shift-form-label, .editEventScene .shift-form-label, .editEAPScene .shift-form-label {
        padding-left: 5px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.createEventScene, .editEventScene, .editEAPScene {
  width: 90%;
  margin: 0 auto; }
  .createEventScene h1, .editEventScene h1, .editEAPScene h1 {
    margin-bottom: 0; }
  .createEventScene .step-number, .editEventScene .step-number, .editEAPScene .step-number {
    color: #78868d;
    font-size: 18px;
    line-height: 22px; }
  .createEventScene .step-description, .editEventScene .step-description, .editEAPScene .step-description {
    height: 26px;
    width: 100%;
    color: #78868d;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px; }
  .createEventScene label:not(.custom-file-label), .editEventScene label:not(.custom-file-label), .editEAPScene label:not(.custom-file-label) {
    height: 17px;
    color: #78868d;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    display: inline-table; }
  .createEventScene .link-button.secondary, .editEventScene .link-button.secondary, .editEAPScene .link-button.secondary {
    color: #78868d;
    text-transform: uppercase; }
  .createEventScene .button-row, .editEventScene .button-row, .editEAPScene .button-row {
    margin-top: 34px; }
  .createEventScene .bottom-border-only, .editEventScene .bottom-border-only, .editEAPScene .bottom-border-only {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0; }
    .createEventScene .bottom-border-only:focus, .editEventScene .bottom-border-only:focus, .editEAPScene .bottom-border-only:focus {
      box-shadow: none; }
  .createEventScene .image-preview, .editEventScene .image-preview, .editEAPScene .image-preview {
    max-height: 140px;
    max-width: 140px; }
  .createEventScene #detailsForm, .editEventScene #detailsForm, .editEAPScene #detailsForm {
    border-bottom: 1px solid #dcdcdd; }
  .createEventScene .shift-form-label, .editEventScene .shift-form-label, .editEAPScene .shift-form-label {
    margin-top: 24px;
    color: #b9b6ba;
    font-style: italic; }
  .createEventScene .shift-form-label + .link-button, .editEventScene .shift-form-label + .link-button, .editEAPScene .shift-form-label + .link-button {
    text-transform: uppercase;
    padding-left: 30px;
    font-size: 14px; }
  @media (max-width: 1100px) {
    .createEventScene, .editEventScene, .editEAPScene {
      width: 100%; }
      .createEventScene .progress-dots, .editEventScene .progress-dots, .editEAPScene .progress-dots {
        font-size: small; }
      .createEventScene .title, .editEventScene .title, .editEAPScene .title {
        text-align: center;
        width: 100%;
        padding-left: 5px; }
      .createEventScene .shift-form-label, .editEventScene .shift-form-label, .editEAPScene .shift-form-label {
        padding-left: 5px; } }

.createEventStep1 {
  width: 90%;
  margin: 0 auto; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

@media (max-width: 1100px) {
  .createEventStep3 .header {
    padding: 5px; }
    .createEventStep3 .header .step-description {
      font-size: clamp(13px, 4vw, 17px);
      height: auto; }
  .createEventStep3 .contactList {
    padding: 10px; } }

.createEventStep3 .navigation-arrows {
  display: flex;
  flex-shrink: 0;
  align-items: center; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.event-summary {
  background: white;
  border: 1px solid #dcdcdd;
  padding: 24px 76px; }
  .event-summary .payment-details-box {
    border: 1px solid #dcdcdd;
    margin-left: -25px;
    margin-right: -25px;
    margin-top: 52px;
    margin-bottom: 48px;
    padding: 12px 26px; }
    .event-summary .payment-details-box p {
      margin-bottom: 8px;
      margin-top: 14px;
      font-size: 14px;
      color: #677379;
      line-height: 21px; }
  .event-summary h5 {
    letter-spacing: 0.7px; }
    .event-summary h5 .btn-link {
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .event-summary .location-cell {
    font-size: 14px;
    text-align: left; }
  .event-summary .details-box {
    margin-bottom: 8px;
    margin-top: 14px;
    font-size: 14px;
    color: #677379;
    line-height: 21px; }
  .event-summary table {
    width: 100%;
    color: #677379; }
  .event-summary td {
    padding: 5px;
    text-align: center;
    border: 1px solid #dcdcdd; }
  .event-summary td:first-child {
    border-left-width: 0; }
  .event-summary td:last-child {
    border-right-width: 0; }
  .event-summary th {
    text-align: center; }
  .event-summary .custom-checkbox label {
    font-size: 14px;
    font-weight: normal;
    color: #b9b6ba; }
  .event-summary .custom-checkbox + .custom-checkbox {
    margin-top: 18px; }
  .event-summary label.check-both-boxes {
    margin: 14px 0;
    font-weight: bold;
    color: #373636; }
  .event-summary .amex-notice {
    font-size: 14px;
    color: #677379;
    line-height: 21px; }
  @media (max-width: 1100px) {
    .event-summary {
      padding: 24px 24px; }
      .event-summary .payment-details-box {
        border: none; }
      .event-summary .custom-control-label {
        height: auto;
        display: inline; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.progressDots {
  display: flex;
  flex-direction: row;
  z-index: 1000; }
  .progressDots .dot-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1; }
    .progressDots .dot-col:hover {
      text-decoration: none; }
  .progressDots .progress-dot {
    color: white;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    border: 3px solid #f2f4f3;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    display: block;
    text-align: center;
    background-color: #33cccc; }
    .progressDots .progress-dot:hover {
      text-decoration: none; }
  .progressDots .dot-col[disabled] .progress-dot {
    background: #c5c3c6; }
  .progressDots .active .progress-dot {
    color: white;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    border: 3px solid #006e6d;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    display: block;
    text-align: center;
    background: linear-gradient(90deg, #33cccc 0%, #006e6d 100%); }
  .progressDots .active .dot-description {
    color: #33cccc; }
  .progressDots .completed .progress-dot {
    padding-top: 1px; }
  .progressDots .dot-description {
    color: #c5c3c6;
    text-align: center;
    width: 102px; }
  .progressDots .progress-arrow {
    margin-left: -25px;
    margin-right: -30px;
    margin-top: -48px;
    flex: 1;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center; }
  @media (max-width: 600px) {
    .progressDots .progress-arrow {
      display: none; }
    .progressDots .mobile-hidden {
      display: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.shiftForm {
  border: 1px solid #c5c3c6;
  background-color: white;
  box-shadow: 1px 1px 2px 0 rgba(102, 99, 99, 0.5);
  padding-top: 16px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 40px;
  margin-top: 8px; }
  .shiftForm .location-check-col {
    display: flex;
    flex-direction: column-reverse; }
  .shiftForm .onsite-contacts-check-col {
    display: flex;
    flex-direction: column-reverse; }
  .shiftForm .button-row {
    justify-content: space-between;
    flex-direction: row; }
    @media (max-width: 1100px) {
      .shiftForm .button-row {
        flex-direction: column;
        align-items: center;
        gap: 16px; } }
  .shiftForm .flatpickr-time,
  .shiftForm .flatpickr-weekdays,
  .shiftForm span.flatpickr-weekday {
    color: white;
    background-color: #4ac2c4; }
  .shiftForm .flatpickr-current-month select.flatpickr-monthDropdown-months {
    color: white;
    background: #4ac2c4; }
  .shiftForm .flatpickr-months div.flatpickr-month,
  .shiftForm .dayContainer .flatpickr-day.selected,
  .shiftForm .dayContainer .flatpickr-day.selected:hover {
    color: white;
    background: #4ac2c4;
    border-color: #4ac2c4; }
  .shiftForm .flatpickr-months .flatpickr-prev-month,
  .shiftForm .flatpickr-months .flatpickr-next-month {
    color: white;
    fill: white; }
  .shiftForm .flatpickr-current-month div.numInputWrapper span.arrowUp:after,
  .shiftForm .flatpickr-current-month div.numInputWrapper span.arrowDown:after,
  .shiftForm .flatpickr-time div.numInputWrapper span.arrowUp:after,
  .shiftForm .flatpickr-time div.numInputWrapper span.arrowDown:after {
    border-bottom-color: white;
    border-top-color: white; }
  .shiftForm .flatpickr-months .flatpickr-prev-month:hover svg,
  .shiftForm .flatpickr-months .flatpickr-next-month:hover svg {
    fill: black; }
  .shiftForm .flatpickr-time input,
  .shiftForm .flatpickr-time span.flatpickr-time-separator,
  .shiftForm .flatpickr-time span.flatpickr-am-pm {
    color: white; }
  .shiftForm .flatpickr-time input:hover,
  .shiftForm .flatpickr-time .flatpickr-am-pm:hover,
  .shiftForm .flatpickr-time input:focus,
  .shiftForm .flatpickr-time .flatpickr-am-pm:focus {
    color: white;
    background: rgba(0, 0, 0, 0.05); }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.shiftPanelList .jobs-list {
  list-style: none;
  border: 1px solid #dcdcdd;
  padding-left: 70px;
  padding-right: 98px;
  margin-bottom: 15px;
  margin-top: 5px; }
  .shiftPanelList .jobs-list img {
    height: 35px;
    width: 35px; }
  .shiftPanelList .jobs-list .jobs-list-item {
    border-bottom: 1px solid #dcdcdd;
    padding-top: 8px;
    padding-bottom: 15px; }
  .shiftPanelList .jobs-list .text {
    font-size: 14px;
    font-weight: 600;
    color: #78868d; }
  .shiftPanelList .jobs-list .btn-link {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px; }

.shiftPanelList .add-shift {
  margin-left: 25px; }

@media (max-width: 1100px) {
  .shiftPanelList .add-shift {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .shiftPanelList .jobs-list {
    padding: 0; }
    .shiftPanelList .jobs-list .col-md-1,
    .shiftPanelList .jobs-list .col-md-3 {
      width: 100%;
      text-align: center; }
    .shiftPanelList .jobs-list .col-md-4 {
      width: 50%; }
      .shiftPanelList .jobs-list .col-md-4 button {
        font-size: 12px; }
  .shiftPanelList .jobs-list-item {
    padding-left: 0;
    padding-right: 0; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.reportToContactList .red-star {
  color: #f33 !important; }

.reportToContactList .invalid-feedback-displayed {
  color: #f33 !important;
  font-size: 80%;
  padding-top: 4px; }

.reportToContactList .remove-contact-btn {
  color: #78868d;
  font-size: 28px; }
  @media (max-width: 1100px) {
    .reportToContactList .remove-contact-btn {
      margin-top: 0px; } }

@media (max-width: 1100px) {
  .reportToContactList .add-report-contact-btn {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    align-self: center; }
  .reportToContactList .merge-phone {
    flex: 5; }
  .reportToContactList .merge-close {
    flex: 1; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventDetailsPanel {
  width: 100%; }
  .eventDetailsPanel .event-title {
    color: #373636;
    font-family: Lato;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px; }
  .eventDetailsPanel .pay-rate, .eventDetailsPanel .event-dates {
    color: #373636;
    font-family: Lato;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px; }
  .eventDetailsPanel .event-dates {
    text-transform: uppercase; }
  .eventDetailsPanel .event-particulars {
    color: #677379;
    font-family: Lato;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px; }
  .eventDetailsPanel .event-logo-preview {
    margin: 16px; }
    .eventDetailsPanel .event-logo-preview img {
      max-height: 140px;
      max-width: 140px; }
    .eventDetailsPanel .event-logo-preview .image-preview-text {
      font-size: 16px;
      color: #00b3ef;
      position: absolute;
      top: 50%;
      left: calc(50% + 16px);
      transform: translate(-50%, -50%);
      text-transform: uppercase;
      text-align: center;
      width: 80%; }
    .eventDetailsPanel .event-logo-preview .event-logo-input {
      position: absolute;
      top: 0;
      left: 15;
      height: 100%;
      width: 100%; }
    .eventDetailsPanel .event-logo-preview .v2-event-logo-input {
      width: 140px; }
  @media (max-width: 1100px) {
    .eventDetailsPanel .event-logo-preview {
      margin: 10px; }
    .eventDetailsPanel .event-title {
      font-size: 18px; }
    .eventDetailsPanel .pay-rate, .eventDetailsPanel .event-dates {
      font-size: 14px; }
    .eventDetailsPanel .event-particulars {
      font-size: 14px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eAPForm {
  background: white;
  border: 1px solid #dcdcdd;
  padding-bottom: 34px;
  padding-top: 30px;
  padding-left: 8%;
  padding-right: 8%; }
  .eAPForm p {
    color: #78868d;
    font-size: 14px; }
  .eAPForm .eap-form-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    color: #677379;
    text-transform: uppercase;
    text-align: center; }
  .eAPForm .eap-explainer {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500; }
  .eAPForm .eap-form-subheader {
    padding-bottom: 16px;
    margin-bottom: 12px; }
    .eAPForm .eap-form-subheader .eventDetailsPanel, .eAPForm .eap-form-subheader .generate-or-upload-col {
      border-bottom: 1px solid #dcdcdd; }
  .eAPForm .section-header {
    min-height: 25px;
    color: #373636;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 20px;
    line-height: 20px;
    border-bottom: 1px solid #373636;
    margin-bottom: 15px;
    text-transform: lowercase;
    letter-spacing: .7px; }
    .eAPForm .section-header button {
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 14px; }
  .eAPForm .generate-or-upload {
    border-left: 1px solid #373636;
    padding-left: 24px;
    padding-top: 4px;
    padding-bottom: 4px; }
    .eAPForm .generate-or-upload label {
      color: #677379;
      font-weight: bold;
      font-size: 14px; }
    .eAPForm .generate-or-upload input[type="radio"] ~ label {
      font-size: 18px; }
    .eAPForm .generate-or-upload .custom-radio {
      margin-top: 12px; }
  .eAPForm input[type="radio"]:not(:checked) ~ label {
    font-weight: normal; }
  .eAPForm section {
    padding: 28px 48px; }
  .eAPForm .location-name {
    font-size: 20px; }
  .eAPForm .location-address, .eAPForm .location-details, .eAPForm .location-coordinates {
    font-size: 14px; }
  .eAPForm .location-phone {
    font-size: 18px; }
  .eAPForm .eap-exists-message {
    background-color: #33cccc;
    padding: 15px 25px;
    margin-bottom: 30px;
    color: inherit;
    width: 100%; }
    .eAPForm .eap-exists-message button {
      color: white; }
    .eAPForm .eap-exists-message div:first-of-type {
      color: white;
      font-size: 20px;
      font-weight: bold; }
    .eAPForm .eap-exists-message div:nth-of-type(2) {
      font-size: 16px;
      font-weight: bold; }
    .eAPForm .eap-exists-message div:nth-of-type(3) {
      font-size: 16px; }
  @media (max-width: 1100px) {
    .eAPForm {
      padding: 10px; }
      .eAPForm .eap-form-title {
        font-size: 18px; }
      .eAPForm .location-name {
        font-size: 16px; }
      .eAPForm .location-phone {
        font-size: 14px; }
      .eAPForm .eap-explainer {
        text-align: center; }
      .eAPForm .generate-or-upload {
        border: 0; }
      .eAPForm section {
        padding: 10px 15px; } }

.location-name.gps {
  margin-top: 28px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.emergencyServices .mobile-services-section {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media (min-width: 768px) {
    .emergencyServices .mobile-services-section {
      display: none; } }

.emergencyServices .services-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  @media (max-width: 768px) {
    .emergencyServices .services-section {
      display: none; } }

.emergencyServices .cya-text {
  font-size: 16px;
  color: #55556d;
  margin-inline: 0;
  padding-left: 5px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.venueMap .venue-map-preview {
  max-width: 200px;
  max-height: 200px; }

.venueMap .custom-file-label {
  top: inherit; }

.venueMap .upload-vmap-label {
  color: #78868d;
  font-size: 14px;
  line-height: 17px; }

.venue-map-modal .modal-dialog {
  max-width: 80vw; }

.venue-map-modal .modal-body {
  text-align: center; }
  .venue-map-modal .modal-body img {
    max-width: calc(80vw - 2rem); }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.contactList .remove-contact-btn {
  color: #78868d;
  font-size: 28px; }
  @media (max-width: 1100px) {
    .contactList .remove-contact-btn {
      margin-top: 0px; } }

@media (max-width: 1100px) {
  .contactList .add-emergency-contact-btn {
    margin-top: 15px; }
  .contactList .merge-phone {
    flex: 5; }
  .contactList .merge-close {
    flex: 1; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.emergencyEquipment .remove-contact-btn {
  color: #78868d;
  font-size: 28px;
  margin-top: 6px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.buttonRow {
  padding-left: 24px; }
  .buttonRow .button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .buttonRow .link-button.secondary {
    color: #78868d;
    text-transform: uppercase;
    max-width: 100%;
    width: 150px;
    flex-basis: auto; }

@media (max-width: 1100px) {
  .buttonRow {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 20px; }
    .buttonRow .button-row {
      margin: 0;
      flex-direction: column-reverse; }
      .buttonRow .button-row .btn-info {
        width: 80%;
        margin-right: 0 !important;
        margin-top: 10px; }
      .buttonRow .button-row .btn-danger {
        width: 80%; }
      .buttonRow .button-row .link-button {
        margin-top: 20px;
        text-align: center; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.loginScene h2 {
  color: #33cccc;
  font-size: 35px;
  line-height: 44px;
  margin-bottom: 40px; }

.loginScene > img {
  display: none; }

.loginScene .alert {
  margin-top: -66px;
  margin-bottom: 16px; }

@media (max-width: 1100px) {
  .loginScene {
    position: relative;
    height: 650px; }
    .loginScene h2 {
      font-size: 30px;
      margin-bottom: 40px;
      text-align: center; }
    .loginScene > img {
      display: block;
      height: 50px;
      margin-bottom: 40px;
      margin-left: auto;
      margin-right: auto; }
    .loginScene .alert {
      width: 100%;
      position: absolute;
      margin: 0;
      transform: translateY(-115%); } }

.serviceProvider {
  flex: 1; }
  .serviceProvider:first-child {
    padding-left: 5px; }
  .serviceProvider .italic-placeholder::placeholder {
    font-style: italic;
    color: #65647f; }
  .serviceProvider input {
    border-color: #65647f; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.registrationScene .parent h2 {
  font-size: 2rem; }

.registrationScene .parent button {
  text-transform: uppercase;
  font-style: normal; }

.registrationScene .checkbox {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .registrationScene .checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .registrationScene .checkbox input[type="checkbox"]:checked ~ span:after {
      display: block; }
  .registrationScene .checkbox span {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #979797; }
    .registrationScene .checkbox span:after {
      content: "";
      position: absolute;
      display: none;
      left: 5px;
      top: 1px;
      width: 8px;
      height: 13px;
      border: solid #00b3ef;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }

.registrationScene .controls {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .registrationScene .controls a {
    color: #78868d;
    text-transform: uppercase;
    text-decoration: none; }
    .registrationScene .controls a:hover {
      cursor: pointer;
      text-decoration: underline; }

.registrationScene__left, .registrationScene__right {
  flex: 1; }

.registrationScene__left {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 3rem; }

.registrationScene__right {
  max-width: 32rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
  background-color: white;
  padding: 3rem 1rem;
  border: 4px solid var(--color-light);
  box-shadow: var(--box-shadow-1);
  border-radius: 40px;
  overflow: hidden; }

.registrationScene h1 {
  margin-bottom: 1rem;
  max-width: 20rem; }

@media only screen and (min-width: 1100px) {
  .registrationScene h1 {
    max-width: 26rem; }
  .registrationScene__right {
    padding: 2.25rem 4.5rem 1rem; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.role {
  margin: 0 auto;
  margin: 50px 0; }
  .role > .content {
    display: flex; }
    .role > .content h2 {
      color: #33cccc;
      font-size: 35px;
      line-height: 44px;
      margin-bottom: 65px; }
    .role > .content > .logo {
      height: 350px;
      border-right: 1px solid #78868d;
      width: 40%;
      display: flex;
      align-self: center;
      align-items: center;
      justify-content: flex-end;
      padding: 0 20px; }
      .role > .content > .logo img {
        width: 100%;
        max-height: 100%; }
    .role > .content > .options {
      width: 60%;
      padding: 0 20px; }
      .role > .content > .options hr {
        border-bottom: 1px solid #f2f4f3; }
      .role > .content > .options p {
        color: #aab8be;
        border-bottom: 1px solid #f2f4f3;
        font-size: 24px;
        padding-bottom: 5px;
        margin-bottom: 45px; }
      .role > .content > .options button {
        width: 100%;
        margin-bottom: 35px; }
      .role > .content > .options a {
        width: 100%;
        margin-bottom: 35px; }

.at-notice h2 {
  margin-bottom: 20px;
  color: #33cccc;
  font-size: 35px;
  line-height: 44px;
  margin-bottom: 65px; }

.at-notice p {
  color: #aab8be;
  border-bottom: 1px solid #f2f4f3;
  font-size: 24px;
  padding-bottom: 5px;
  margin-bottom: 45px; }

.at-notice .badge {
  width: 50%; }
  .at-notice .badge.apple img {
    height: 70px; }
  .at-notice .badge.google img {
    height: 100px; }
  .at-notice .badge img {
    max-width: 100%; }

@media (max-width: 768px) {
  .role > .content {
    flex-direction: column;
    justify-content: center;
    padding: 0 50px; }
    .role > .content > .logo {
      display: none; }
    .role > .content > .options {
      width: 100%;
      padding: 30px 0 0; }
      .role > .content > .options > .select-role {
        text-align: center; }
        .role > .content > .options > .select-role h2 {
          margin-bottom: 30px; }
    .role > .content .at-notice {
      text-align: center; }
      .role > .content .at-notice h2 {
        margin-bottom: 30px;
        line-height: 1; }
      .role > .content .at-notice p {
        text-align: justify;
        padding-bottom: 30px; } }

@media (max-width: 575px) {
  .role > .content .at-notice .badge {
    width: 100%;
    text-align: center;
    display: block; }
    .role > .content .at-notice .badge.google img {
      height: 94px; } }

@media (max-width: 420px) {
  .role {
    margin: 20px 0; }
    .role > .content {
      padding: 0 20px; } }

@media (max-width: 600px) {
  .shiftDetailsTable {
    display: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

table.billingEstimateTable .service-fee-row {
  background: #f2f4f3; }
  table.billingEstimateTable .service-fee-row td {
    border-width: 0; }

table.billingEstimateTable .money-cell {
  text-align: right;
  width: 18%;
  padding-right: 24px; }

table.billingEstimateTable .grand-total-row td {
  border-width: 0; }

@media (max-width: 600px) {
  .billingEstimateTable {
    display: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.athleticTrainerModal .toggle {
  color: #00b3ef !important;
  text-decoration: underline;
  font-weight: bold; }
  .athleticTrainerModal .toggle:hover {
    cursor: pointer; }

.athletic-trainer-modal .modal-dialog {
  max-width: 50%;
  min-width: 650px; }

.athletic-trainer-modal .modal-header h5 {
  margin-left: auto;
  padding-left: 8%; }

.athletic-trainer-modal .modal-body {
  padding: 0; }
  .athletic-trainer-modal .modal-body .credentials {
    display: flex;
    flex-direction: column; }
    .athletic-trainer-modal .modal-body .credentials h3 {
      text-align: center;
      font-size: 18px;
      line-height: 23px;
      color: #33cccc;
      background-color: #bbe5e6;
      font-style: italic;
      padding: 10px; }
    .athletic-trainer-modal .modal-body .credentials ul {
      margin: 0 auto;
      padding: 0;
      list-style: none;
      display: inline-block;
      padding-bottom: 10px; }
      .athletic-trainer-modal .modal-body .credentials ul li {
        color: #979797;
        margin: 5px;
        position: relative; }
        .athletic-trainer-modal .modal-body .credentials ul li i {
          margin-right: 10px;
          color: #33cccc; }
  .athletic-trainer-modal .modal-body .user-info {
    display: flex;
    padding: 20px 40px;
    background-color: #fff;
    overflow-x: auto; }
    .athletic-trainer-modal .modal-body .user-info > div {
      flex-grow: 1; }
    .athletic-trainer-modal .modal-body .user-info img {
      height: 82.5px;
      width: 82.5px;
      margin-right: 16px;
      border-radius: 41.25px;
      object-fit: cover; }
    .athletic-trainer-modal .modal-body .user-info b {
      font-size: 20px;
      margin-bottom: 12px;
      line-height: 20px;
      display: block; }
    .athletic-trainer-modal .modal-body .user-info .info-grid {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .athletic-trainer-modal .modal-body .user-info .info-grid > div {
        display: flex;
        flex-direction: column;
        font-size: 15px; }
        .athletic-trainer-modal .modal-body .user-info .info-grid > div:last-of-type {
          min-width: 180px; }
      .athletic-trainer-modal .modal-body .user-info .info-grid i {
        font-style: normal;
        color: #979797;
        margin-right: 3px; }
  .athletic-trainer-modal .modal-body .download-all-link {
    text-align: center; }
    .athletic-trainer-modal .modal-body .download-all-link button {
      padding: 20px 0;
      text-transform: uppercase; }
  .athletic-trainer-modal .modal-body .reset-password-link {
    background-color: #fff;
    text-align: center; }
    .athletic-trainer-modal .modal-body .reset-password-link button {
      border-top: 1px solid #979797;
      padding: 20px 0;
      text-transform: uppercase; }
      .athletic-trainer-modal .modal-body .reset-password-link button:hover {
        cursor: pointer; }
  .athletic-trainer-modal .modal-body .error, .athletic-trainer-modal .modal-body .success {
    text-align: center;
    padding: 10px;
    background-color: #33cccc;
    color: #fff; }
  .athletic-trainer-modal .modal-body .error {
    background-color: #f33; }

@media (max-width: 1100px) {
  .athletic-trainer-modal .modal-dialog {
    width: calc(100% - 1rem);
    max-width: calc(100% - 1rem);
    min-width: auto; }
  .athletic-trainer-modal .modal-body .user-info .info-grid {
    display: block; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.paymentDetailsPanel {
  border-top: 1px solid #dcdcdd;
  border-bottom: 1px solid #dcdcdd;
  padding-top: 14px;
  padding-bottom: 14px; }
  .paymentDetailsPanel .cc-icon {
    color: #33cccc; }
  .paymentDetailsPanel .custom-control-label:before,
  .paymentDetailsPanel .custom-control-label:after {
    top: 1.4rem; }
  .paymentDetailsPanel .card-element {
    border: 1px solid #78868d;
    min-height: 55px;
    padding: 20px 8px; }
  .paymentDetailsPanel .add-card-btn {
    text-transform: uppercase;
    font-size: 14px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.newCreditCard {
  display: flex;
  flex-direction: column; }
  .newCreditCard .stripeCard {
    max-width: 496px;
    display: block;
    margin: 10px 0 20px 0;
    min-width: 200px;
    padding: 10px 14px;
    font-size: 1em;
    box-shadow: rgba(50, 50, 93, 0.15) 0px 1px 3px, rgba(0, 0, 0, 0.02) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px; }
  .newCreditCard .actions {
    margin: 0.5rem; }
  .newCreditCard .v2-button {
    border-radius: 3px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 18px;
    line-height: 25px;
    align-items: center;
    justify-content: center;
    font-style: italic;
    letter-spacing: 0.7;
    text-transform: "lowercase"; }
  .newCreditCard .center {
    align-self: center; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.termsConditionsModal {
  display: inline; }
  .termsConditionsModal .toggle {
    color: #00b3ef !important; }
    .termsConditionsModal .toggle:hover {
      cursor: pointer; }

.terms-conditions-modal .modal-dialog {
  width: 87%;
  max-width: 87%; }
  .terms-conditions-modal .modal-dialog .modal-content .modal-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1; }
  .terms-conditions-modal .modal-dialog .modal-content ol li {
    margin-bottom: 1rem; }
  .terms-conditions-modal .modal-dialog object {
    position: absolute;
    width: 97%;
    height: 92%; }

@media (max-width: 1100px) {
  .terms-conditions-modal .modal-content * > ol {
    padding-inline-start: 15px; }
  .terms-conditions-modal .modal-content .modal-body {
    padding: 15px 20px; }
  .terms-conditions-modal .modal-dialog {
    margin: 0 !important;
    width: 100%;
    max-width: 100%;
    height: 100%; }
    .terms-conditions-modal .modal-dialog object {
      width: 300;
      height: 200; } }

.parent {
  width: 100%;
  max-width: 720px;
  margin: 30px auto;
  padding: 0 30px;
  color: white; }
  .parent > p,
  .parent form {
    display: flex;
    flex-direction: column;
    color: white; }
    .parent > p button,
    .parent form button {
      align-self: center; }
  .parent .checkbox {
    width: 100%; }

.react-tel-input {
  color: grey; }

.registrationScene {
  min-height: 100vh;
  position: relative;
  padding-bottom: 2rem; }
  .registrationScene h1, .registrationScene h2, .registrationScene h3 {
    font-family: 'Gotham'; }
  .registrationScene h1 {
    font-style: normal;
    font-weight: 700;
    text-transform: none;
    line-height: 105%;
    letter-spacing: -0.05em;
    color: var(--color-dark);
    font-size: var(--font-size-h1-sm);
    margin: 0; }
  .registrationScene h2 {
    font-size: var(--font-size-h2);
    margin: 0; }
  .registrationScene h3 {
    font-size: var(--font-size-h3);
    color: var(--color-text-light);
    font-family: 'Gotham';
    font-style: normal;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.05em;
    margin: 0; }
  .registrationScene p,
  .registrationScene a,
  .registrationScene ul,
  .registrationScene li,
  .registrationScene label,
  .registrationScene span,
  .registrationScene a {
    font-family: var(--font-nimbus);
    font-weight: 400;
    font-style: normal; }
  .registrationScene p,
  .registrationScene a,
  .registrationScene li,
  .registrationScene label {
    font-size: var(--font-size-body);
    line-height: 120%;
    letter-spacing: 0.01em;
    color: var(--color-text-light); }
  .registrationScene span {
    font-size: var(--font-size-sm);
    line-height: 120%; }
  .registrationScene a {
    color: var(--color-secondary-brand);
    font-size: var(--font-size-sm); }
  .registrationScene ul {
    list-style: none; }
  .registrationScene__wrapper, .registrationScene__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%; }
  .registrationScene__wrapper {
    padding-top: 3.5rem;
    width: 95%;
    margin: 0 auto; }
  .registrationScene__content {
    padding-bottom: 2.5rem; }
  .registrationScene__logo {
    height: 3rem;
    margin-bottom: 2rem;
    margin-left: 3%;
    cursor: pointer; }
  .registrationScene__acquaBg {
    background: var(--acqua-gradient);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(50% - 2.25rem);
    z-index: -1; }
  .registrationScene__greyBg {
    background: var(--color-stone);
    position: absolute;
    top: calc(50% - 2.25rem);
    left: 0;
    width: 100%;
    height: calc(50% + 2.25rem);
    z-index: -1; }
  .registrationScene input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--color-light);
    border-radius: 0.25rem;
    font-size: var(--font-size-sm);
    height: 2.25rem;
    border: 1px solid #CCCCCC;
    border-radius: 6px; }
    .registrationScene input:focus {
      outline: none;
      border: 1px solid var(--color-secondary-brand); }
  .registrationScene select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid var(--color-light);
    border-radius: 0.25rem;
    font-size: var(--font-size-sm);
    height: 2.25rem;
    border: 1px solid #CCCCCC;
    border-radius: 6px; }
    .registrationScene select:focus {
      outline: none;
      border: 1px solid var(--color-secondary-brand); }
  .registrationScene > p,
  .registrationScene label {
    color: #78868d;
    color: var(--color-text);
    font-size: 1rem;
    margin-bottom: 0.75rem; }
  @media screen and (min-width: 1100px) {
    .registrationScene h1 {
      font-size: var(--font-size-h1); } }
  @media screen and (min-width: 795px) {
    .registrationScene__wrapper {
      max-width: 66rem; }
    .registrationScene__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 3.5rem; }
    .registrationScene__logo {
      height: 3rem;
      margin-bottom: 3.75rem;
      margin-left: .5%; } }

.registrationScene__footer {
  max-width: 28rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  .registrationScene__footer a,
  .registrationScene__footer span,
  .registrationScene__footer p {
    font-style: normal;
    font-weight: 400;
    font-size: var(--font-size-sm);
    line-height: 120%;
    letter-spacing: 0.02em;
    margin: 0;
    color: var(--color-subtle-text);
    margin-bottom: .5rem; }
  @media screen and (min-width: 795px) {
    .registrationScene__footer {
      flex-direction: row; }
      .registrationScene__footer a,
      .registrationScene__footer span,
      .registrationScene__footer p {
        margin-bottom: 0; }
      .registrationScene__footer a {
        position: relative; }
        .registrationScene__footer a::after {
          content: "|";
          position: absolute;
          right: -1rem; } }

.registrationSteps {
  margin: 0;
  padding: 0;
  padding-top: 2.875rem;
  margin-left: .5rem; }
  .registrationSteps__item {
    position: relative;
    width: 19rem;
    padding: 1rem 0 1.25rem 2rem;
    border-bottom: 2px dashed var(--color-text-light); }
    .registrationSteps__item:last-child {
      border-bottom: none; }
    .registrationSteps__item:first-child {
      padding-bottom: 1rem; }
      .registrationSteps__item:first-child h3 {
        color: var(--color-primary-brand);
        height: 100%;
        margin-bottom: 0; }
    .registrationSteps__item:first-of-type i {
      color: var(--color-primary-brand); }
    .registrationSteps__item i {
      font-size: 1.1rem;
      color: var(--color-text-light);
      position: absolute;
      top: 1.2rem;
      left: .1rem; }
  .registrationSteps__itemText {
    color: var(--color-text-light); }
  .registrationSteps__itemInner {
    display: flex;
    align-items: center; }
    .registrationSteps__itemInner h3 {
      margin-bottom: .5rem; }

.account {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  padding: 0 30px; }
  .account form {
    display: flex;
    flex-direction: column; }
    .account form button {
      align-self: center; }
  .account .checkbox {
    width: 100%; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.postEventAccount {
  max-width: 100% !important; }
  .postEventAccount .user-form {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media only screen and (max-width: 620px) {
      .postEventAccount .user-form {
        flew-wrap: wrap;
        flex-direction: column-reverse; } }
  .postEventAccount .long-term-btn {
    padding: 6px 20px;
    border-radius: 3px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 16px;
    line-height: 25px;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .postEventAccount .presentation-details {
    align-self: center;
    padding: 1rem;
    border: 1px solid #54595f;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex: 0 0 25%;
    align-self: start;
    margin-top: 20px; }
  .postEventAccount .steps {
    display: flex;
    align-items: flex-end; }
    .postEventAccount .steps img {
      width: 50px; }
  .postEventAccount .solid-line {
    border: 1px solid #54595f; }
  .postEventAccount .dashed-line {
    border: 1px dashed #54595f; }
  .postEventAccount .password-disclaimer {
    display: inline-block;
    color: var(--color-subtle-text);
    font-size: var(--font-size-xs);
    line-height: 120%;
    letter-spacing: 0.02em;
    padding-top: 0.5rem;
    margin: 0; }
  .postEventAccount .privacy-policy {
    margin: 0;
    max-width: 20rem;
    line-height: 120%;
    letter-spacing: 0.01em; }
    .postEventAccount .privacy-policy p, .postEventAccount .privacy-policy a {
      font-size: 1rem; }
  .postEventAccount .homePostEventForm {
    width: 100%;
    max-width: 100%; }
  .postEventAccount .w-100 {
    width: 100%; }
  .postEventAccount > h2 {
    text-align: center;
    color: var(--color-text); }
  .postEventAccount > p,
  .postEventAccount label {
    font-size: 1rem;
    margin-bottom: 0.75rem; }
  .postEventAccount label.required:after {
    content: " *";
    color: red; }
  .postEventAccount form {
    display: flex;
    flex-direction: column;
    max-width: 550px; }
    @media only screen and (max-width: 620px) {
      .postEventAccount form {
        width: 100%;
        margin: 0; } }
    .postEventAccount form .contract-details {
      color: #54595f; }
      @media only screen and (max-width: 620px) {
        .postEventAccount form .contract-details {
          text-align: center; } }
    .postEventAccount form .actions {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 1.5rem; }
      .postEventAccount form .actions button {
        border-radius: 6px;
        flex: 0.45;
        padding: 0.5rem;
        border: none;
        font-size: 1rem;
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        box-shadow: none;
        color: #FFFFFF; }
      .postEventAccount form .actions .checkbox {
        width: 100%; }
  .postEventAccount .login-account {
    display: inline-block;
    text-align: center;
    margin-top: 20px; }
    .postEventAccount .login-account span, .postEventAccount .login-account a {
      font-size: 1rem; }
    .postEventAccount .login-account .login-link {
      margin-left: 0.5rem; }

/* CSS variables. */
:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: inherit;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1); }

.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center; }

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0; }

.PhoneInputCountryIcon {
  width: calc( var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
  height: var(--PhoneInputCountryFlag-height); }

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height); }

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor); }

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
  margin-top: 0; }

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity); }

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity); }

/* Styling native country `<select/>`. */
.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight); }

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer; }

.PhoneInputCountrySelect[disabled] {
  cursor: default; }

.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity); }

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus); }

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus); }

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus); }

.postEventContact {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  padding: 0 30px; }
  .postEventContact .homePostEventForm {
    width: 100%;
    max-width: 100%; }
  .postEventContact .w-100 {
    width: 100%; }
  .postEventContact > h2 {
    text-align: center;
    color: #54595f; }
  .postEventContact label.required:after {
    content: " *";
    color: red; }
  .postEventContact form {
    display: flex;
    flex-direction: column; }
    .postEventContact form .actions {
      display: flex;
      justify-content: space-evenly; }
      .postEventContact form .actions button {
        margin: 0.5rem;
        flex: 1;
        align-self: center; }
  .postEventContact .checkbox {
    width: 100%; }

/* CSS variables. */
:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: inherit;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0,0,0,0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0,0,0,0.1); }

.PhoneInput {
  /* This is done to stretch the contents of this component. */
  display: flex;
  align-items: center; }

.PhoneInputInput {
  /* The phone number input stretches to fill all empty space */
  flex: 1;
  /* The phone number input should shrink
	   to make room for the extension input */
  min-width: 0; }

.PhoneInputCountryIcon {
  width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
  height: var(--PhoneInputCountryFlag-height); }

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height); }

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor); }

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
  margin-top: 0; }

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity); }

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity); }

/* Styling native country `<select/>`. */
.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight); }

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer; }

.PhoneInputCountrySelect[disabled] {
  cursor: default; }

.PhoneInputCountrySelectArrow {
  display: block;
  content: '';
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity); }

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus); }

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus); }

.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
  opacity: 1;
  color: var(--PhoneInputCountrySelectArrow-color--focus); }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.postEvent {
  /* Hidden placeholder */ }
  .postEvent label.required:after {
    content: " *";
    color: red; }
  .postEvent option {
    color: var(--color-text); }
  .postEvent select.empty {
    color: #c5c3c6; }
  .postEvent select option[disabled]:first-child {
    display: none; }
  .postEvent form {
    display: flex;
    flex-direction: column; }
    .postEvent form .actions {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      justify-content: space-between;
      margin: 1rem 0 2rem; }
      .postEvent form .actions button {
        border-radius: 6px;
        flex: 0.45;
        padding: 0.5rem;
        border: none;
        font-size: 1rem;
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        box-shadow: none;
        color: #FFFFFF; }
  .postEvent .checkbox {
    width: 100%; }
  .postEvent hr {
    background-image: linear-gradient(to right, #b9b6ba 50%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 1.5rem 0.1rem;
    background-repeat: repeat-x;
    border-top: 0.2rem solid transparent;
    height: 0.1rem;
    width: 100%;
    margin-top: 2rem; }
  .postEvent .row {
    margin: 0; }
    .postEvent .row .form-group {
      flex: 1; }
    .postEvent .row .form-group:not(:last-child) {
      margin-right: 1rem; }
    .postEvent .row .form-group:last-child {
      max-width: 10rem; }
      @media (max-width: 1100px) {
        .postEvent .row .form-group:last-child {
          max-width: 8rem; } }

.postEvent > h2 {
  text-align: center;
  color: #54595f; }

.postEvent label.required:after {
  content: " *";
  color: red; }

.postEvent form {
  display: flex;
  flex-direction: column; }
  .postEvent form .actions {
    display: flex;
    justify-content: space-evenly; }
    .postEvent form .actions button {
      margin: 0.5rem;
      flex: 1;
      align-self: center; }

.postEvent .checkbox {
  width: 100%; }

.postEvent > h2 {
  text-align: center;
  color: #54595f; }

.postEvent label.required:after {
  content: " *";
  color: red; }

.postEvent form {
  display: flex;
  flex-direction: column; }
  .postEvent form .actions {
    display: flex;
    justify-content: space-evenly; }
    .postEvent form .actions button {
      margin: 0.5rem;
      flex: 1;
      align-self: center; }

.postEvent .checkbox {
  width: 100%; }

.contractConfirmation {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  padding: 0 30px; }
  .contractConfirmation > h2 {
    text-align: center;
    color: #54595f; }
  .contractConfirmation form {
    display: flex;
    flex-direction: column; }
    .contractConfirmation form .actions {
      display: flex;
      justify-content: space-evenly; }
      .contractConfirmation form .actions button {
        margin: 0.5rem;
        flex: 1;
        align-self: center; }
  .contractConfirmation .checkbox {
    width: 100%; }

.postEvent label.required:after {
  content: " *";
  color: red; }

.postEvent form {
  display: flex;
  flex-direction: column; }
  .postEvent form .actions {
    display: flex;
    justify-content: space-evenly; }
    .postEvent form .actions button {
      margin: 0.5rem;
      flex: 1;
      align-self: center; }

.postEvent .checkbox {
  width: 100%; }

.form-control.date-picker[readonly] {
  background-color: white; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.profile {
  width: 50%;
  min-width: 500px;
  margin: 0 auto; }
  .profile > p,
  .profile label {
    color: #78868d; }
  .profile section {
    width: 100px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: reverse; }
  .profile .custom-file-input {
    height: 100px; }
  .profile .custom-file-label {
    top: inherit;
    display: inline-block;
    width: auto;
    background-color: transparent !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    color: #00b3ef !important;
    padding: 0; }
  .profile .profile-pic {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: block;
    border-radius: 50px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.editPaymentScene {
  width: 80%;
  margin: 0 auto; }
  @media (max-width: 1100px) {
    .editPaymentScene {
      width: 90%; } }
  .editPaymentScene > p {
    color: #78868d; }
  .editPaymentScene form {
    margin-top: 30px; }
  .editPaymentScene label {
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #78868d;
    margin-bottom: 0; }
  .editPaymentScene h2 {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1.5px solid black; }
  .editPaymentScene h3 {
    color: #65647f;
    font-size: 1.3rem; }
  .editPaymentScene .checkbox {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #78868d; }
    .editPaymentScene .checkbox input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer; }
      .editPaymentScene .checkbox input[type="checkbox"]:checked ~ span:after {
        display: block; }
    .editPaymentScene .checkbox span {
      position: absolute;
      top: 3px;
      left: 0;
      height: 20px;
      width: 20px;
      border: 1px solid #979797; }
      .editPaymentScene .checkbox span:after {
        content: "";
        position: absolute;
        display: none;
        width: 8px;
        height: 13px;
        border: solid #00b3ef;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        color: #006e6d; }
  .editPaymentScene .credit-cards {
    display: block; }
    .editPaymentScene .credit-cards img {
      margin-left: 10px; }
  .editPaymentScene .controls {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .editPaymentScene .controls .cancel-container {
      margin-bottom: 10px;
      color: #33cccc;
      padding-left: 0; }
      @media (max-width: 1100px) {
        .editPaymentScene .controls .cancel-container {
          display: flex;
          justify-content: center;
          align-items: center; } }
      .editPaymentScene .controls .cancel-container .cancel-button {
        background-color: transparent;
        border: none;
        outline: none;
        color: #30827b;
        text-transform: uppercase;
        text-decoration: none; }
        .editPaymentScene .controls .cancel-container .cancel-button:focus {
          outline: none; }
    .editPaymentScene .controls a {
      font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      color: #006e6d;
      text-transform: uppercase;
      text-decoration: none; }
      .editPaymentScene .controls a:hover {
        cursor: pointer;
        text-decoration: underline; }
    .editPaymentScene .controls button {
      background-color: #55556d;
      border-radius: 5px;
      font-size: 15px;
      line-height: 14px;
      padding: 10px 45px;
      width: auto;
      border: none; }
      .editPaymentScene .controls button:active, .editPaymentScene .controls button:focus {
        background-color: #55556d;
        border-color: #55556d;
        box-shadow: none;
        outline: none; }
    .editPaymentScene .controls .btn.bold-button[disabled], .editPaymentScene .controls .bold-button.custom-file-label[disabled] {
      background-color: #55556d;
      color: #fff; }
      .editPaymentScene .controls .btn.bold-button[disabled]:active, .editPaymentScene .controls .bold-button.custom-file-label[disabled]:active, .editPaymentScene .controls .btn.bold-button[disabled]:focus, .editPaymentScene .controls .bold-button.custom-file-label[disabled]:focus {
        background-color: #55556d;
        border-color: #55556d;
        box-shadow: none;
        outline: none; }
  .editPaymentScene .payment-billing-wrapper {
    border-bottom-style: dashed;
    border-image: repeating-linear-gradient(to right, #bbb, #bbb 10px, transparent 10px, transparent 20px) 3; }
  .editPaymentScene .payment-types {
    margin: 0;
    padding: 0;
    padding-bottom: 20px;
    border-bottom-style: dashed;
    border-image: repeating-linear-gradient(to right, #bbb, #bbb 10px, transparent 10px, transparent 20px) 3; }
    .editPaymentScene .payment-types > div {
      padding: 15px 0 15px 0;
      position: relative;
      display: flex; }
  .editPaymentScene .radio {
    display: flex;
    position: relative;
    padding-left: 35px;
    margin: 0;
    cursor: pointer;
    font-size: 18px;
    line-height: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .editPaymentScene .radio input[type="radio"] {
      position: absolute;
      opacity: 0;
      cursor: pointer; }
      .editPaymentScene .radio input[type="radio"]:checked ~ span {
        background-color: #fff; }
        .editPaymentScene .radio input[type="radio"]:checked ~ span:after {
          display: block; }
    .editPaymentScene .radio span {
      position: absolute;
      top: 2px;
      left: 0;
      height: 15px;
      width: 15px;
      background-color: #eee;
      border-radius: 50%;
      border: 1px solid #000;
      box-sizing: border-box; }
      .editPaymentScene .radio span:after {
        content: "";
        position: absolute;
        display: none;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #006e6d; }
  .editPaymentScene .source-info {
    margin-top: 30px;
    margin-bottom: 20px; }
    .editPaymentScene .source-info i {
      margin-right: 5px;
      color: #78868d;
      font-style: normal; }
    .editPaymentScene .source-info p {
      color: #65647f;
      margin-bottom: 5px;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .editPaymentScene .separator {
    width: 100%;
    height: 1.5px;
    background-color: black;
    margin-top: 15px;
    margin-bottom: 40px; }
  .editPaymentScene .toggle-button {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background-color: #55556d;
    color: #fff;
    border-radius: 5px;
    font-size: 15px;
    line-height: 14px;
    padding: 10px 45px;
    width: auto;
    border: none; }
  .editPaymentScene .toggle-button-credit-card {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background-color: #55556d;
    color: #fff;
    border-radius: 5px;
    font-size: 15px;
    line-height: 14px;
    padding: 10px 45px;
    width: auto;
    border: none; }
  .editPaymentScene .toggle-button-white {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #55556d;
    background-color: white;
    border-radius: 5px;
    font-size: 14px;
    line-height: 14px;
    padding: 10px 45px;
    border: 2px solid #55556d; }
    .editPaymentScene .toggle-button-white:active, .editPaymentScene .toggle-button-white:focus {
      color: #55556d;
      background-color: white;
      border-color: #55556d;
      box-shadow: none;
      outline: none; }
    .editPaymentScene .toggle-button-white:hover {
      border-color: #55556d; }
  .editPaymentScene .update-payment-button button {
    font-style: normal; }
    .editPaymentScene .update-payment-button button:active, .editPaymentScene .update-payment-button button:focus {
      background-color: #55556d;
      border-color: #55556d;
      box-shadow: none;
      outline: none; }
    .editPaymentScene .update-payment-button button:hover {
      background-color: black;
      border-color: #55556d; }

@media (max-width: 1100px) {
  .editPaymentScene .payment-types > div {
    padding: 15px 4% 15px 0; }
  .editPaymentScene .update-payment-button {
    display: flex;
    justify-content: center; }
  .editPaymentScene .controls .cancel-container {
    padding-left: 15px; } }

.creditCard input {
  border-color: #65647f; }

.creditCard .form-control {
  border-color: #65647f;
  font-family: "nimbus-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  width: 100%; }

.creditCard b {
  padding: 0 8px;
  font-size: 20px; }

.creditCard i {
  font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.creditCard span {
  font-family: "nimbus-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.creditCard p {
  color: #979797;
  font-family: "nimbus-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.creditCard a {
  color: #006e6d; }
  .creditCard a:hover {
    cursor: pointer;
    text-decoration: underline; }

.creditCard select {
  flex: 1;
  border-radius: 0; }

.creditCard .section-no-border {
  padding-top: 10px;
  padding-bottom: 20px; }
  .creditCard .section-no-border .col-md-3 {
    padding-right: 5px; }
  .creditCard .section-no-border .state {
    padding-right: 17px; }
  .creditCard .section-no-border .zip {
    padding-right: 18px; }

.creditCard .section {
  padding-top: 30px; }
  .creditCard .section .pr-4 {
    margin-right: 3px; }
  .creditCard .section .form-row {
    margin-bottom: 1rem; }
  .creditCard .section .dashed-line {
    border-bottom: 1px dashed #bbb;
    margin: 20px 0; }
  .creditCard .section .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; }
  .creditCard .section .form-group {
    margin-bottom: 0.5rem; }
  .creditCard .section .show-error {
    display: block;
    color: red;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 80%; }
  .creditCard .section .hide-error {
    display: none; }

@media (max-width: 768px) {
  .creditCard .section {
    padding-bottom: 0; }
  .creditCard .section-no-border {
    padding-top: 0;
    padding-bottom: 0; } }

@media (min-width: 768px) {
  .text-nowrap {
    margin-right: 25px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.checking p {
  color: #979797;
  font-family: "nimbus-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.checking a {
  color: #006e6d; }
  .checking a:hover {
    cursor: pointer;
    text-decoration: underline; }

.checking span {
  font-family: "nimbus-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.checking .section {
  padding-top: 30px;
  padding-bottom: 20px; }
  .checking .section.no-dashed-line {
    border-bottom: none; }
  .checking .section .btn-wrapper .btn-micro {
    color: white;
    background-color: #55556d;
    border-color: #55556d; }
    .checking .section .btn-wrapper .btn-micro:focus {
      outline: none;
      box-shadow: none; }
    .checking .section .btn-wrapper .btn-micro:hover {
      background-color: black;
      border-color: #55556d; }
    .checking .section .btn-wrapper .btn-micro:active {
      background-color: #55556d;
      border-color: #55556d;
      box-shadow: none; }
  .checking .section .toggle-button:hover {
    background-color: black; }

.checking .show-error {
  display: block;
  color: red;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 80%; }

.checking .hide-error {
  display: none; }

.checking .responsive-text {
  white-space: nowrap; }
  @media (max-width: 768px) {
    .checking .responsive-text {
      white-space: normal; } }

.editPaymentScene .toggle-button {
  outline: none; }

@media (max-width: 768px) {
  .toggle-button {
    margin: 30px 0;
    outline: none; }
  .checking .section .btn-wrapper .btn-micro {
    margin-bottom: 30px; }
  .checking .section .toggle-button {
    margin-top: 0;
    margin-bottom: 0; } }

.generateEAPForm .facility {
  padding-top: 8px;
  padding-bottom: 8px; }

.uploadEAPForm .pdf-preview {
  width: 500px;
  min-height: 300px; }

.uploadEAPForm .custom-file-label {
  top: inherit; }

.uploadEAPForm .upload-section {
  height: 100%; }

@media (max-width: 1100px) {
  .uploadEAPForm .pdf-preview {
    width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.athleticTrainers {
  padding-top: 78px; }
  .athleticTrainers .whoUses {
    background-color: #f2f4f3; }
    .athleticTrainers .whoUses .red-text {
      color: #ec1c24; }
  .athleticTrainers > .jobsButtonWrapper {
    background-color: #e4e4e4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0; }
    .athleticTrainers > .jobsButtonWrapper > .buttonWrapper {
      width: 60%; }

@media (max-width: 768px) {
  .athleticTrainers > .jobsButtonWrapper > .buttonWrapper {
    width: 80%; } }

@media (max-width: 420px) {
  .athleticTrainers > .jobsButtonWrapper > .buttonWrapper {
    width: 95%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.athleticTrainers {
  padding-top: 78px; }
  .athleticTrainers .whoUses {
    background-color: #f2f4f3; }
    .athleticTrainers .whoUses .red-text {
      color: #ec1c24; }
  .athleticTrainers > .jobsButtonWrapper {
    background-color: #e4e4e4;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0; }
    .athleticTrainers > .jobsButtonWrapper > .buttonWrapper {
      width: 60%; }

@media (max-width: 768px) {
  .athleticTrainers > .jobsButtonWrapper > .buttonWrapper {
    width: 80%; } }

@media (max-width: 420px) {
  .athleticTrainers > .jobsButtonWrapper > .buttonWrapper {
    width: 95%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.rNTopSection {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../images/rn-bg.png");
  background-position: right;
  height: 525px; }
  .rNTopSection > .top-sub-section {
    text-transform: uppercase;
    color: #ffffff; }
  .rNTopSection > .left-top {
    width: 100%; }
    .rNTopSection > .left-top > .text-container {
      background: rgba(208, 2, 27, 0.65);
      padding: 42px 50px 42px 0;
      width: 100%;
      height: 227px;
      display: flex;
      align-items: center;
      font-family: Helvetica;
      font-size: 34px;
      letter-spacing: 1.14px;
      text-align: left; }
      .rNTopSection > .left-top > .text-container h1 {
        color: #33cccc; }
        .rNTopSection > .left-top > .text-container h1 .white-text {
          color: #ffffff; }
  .rNTopSection > .right-bottom {
    display: flex;
    align-items: center;
    margin: 30px 0 20px 0;
    width: 35%; }
    .rNTopSection > .right-bottom .red-bar {
      height: 227px;
      width: 100%;
      background: rgba(208, 2, 27, 0.65);
      width: 20%; }

@media (max-width: 1024px) {
  .rNTopSection {
    flex-direction: column;
    height: unset; }
    .rNTopSection > .top-sub-section {
      width: 100%; }
    .rNTopSection > .right-bottom {
      padding: 0 10px; }
      .rNTopSection > .right-bottom .red-bar {
        display: none; } }

@media (max-width: 600px) {
  .rNTopSection {
    height: unset; }
    .rNTopSection > .left-top > .text-container {
      align-items: start;
      flex-direction: column;
      height: auto; }
      .rNTopSection > .left-top > .text-container h1 {
        margin-top: 24px; } }

@media (max-width: 575px) {
  .rNTopSection {
    height: unset; }
    .rNTopSection > .left-top > .text-container {
      padding: 10px; }
      .rNTopSection > .left-top > .text-container > .go4ellis-img {
        width: 100%;
        max-width: 100%; } }

form.resetPasswordForm {
  color: #979797;
  display: flex;
  flex-direction: column; }
  form.resetPasswordForm label {
    color: #fff;
    font-size: 18px;
    font-weight: normal; }
  form.resetPasswordForm .forgot-password-icon {
    width: 110px;
    margin-top: -24px; }
  form.resetPasswordForm .alert {
    margin-top: -58px;
    margin-bottom: 16px; }
  form.resetPasswordForm h4 {
    color: white;
    margin-top: 16px;
    margin-bottom: 67px; }
  form.resetPasswordForm input[type=email], form.resetPasswordForm input[type=password] {
    border: 1px solid #979797;
    display: block;
    width: 100%;
    background-color: #363d43;
    color: #979797;
    font-size: 18px;
    padding: .6em 1em;
    margin-bottom: 1.3em; }
    form.resetPasswordForm input[type=email]:focus, form.resetPasswordForm input[type=password]:focus {
      border: 1px solid #fff;
      outline: none;
      color: #fff; }
  form.resetPasswordForm a {
    float: right;
    text-transform: uppercase; }
  form.resetPasswordForm > .btn-danger {
    width: 50%;
    align-self: flex-end;
    margin-top: 1.3em; }
  form.resetPasswordForm .checkbox {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #AAB8BE; }
    form.resetPasswordForm .checkbox:hover input ~ span {
      border: 1px solid #fff; }
    form.resetPasswordForm .checkbox input[type=checkbox] {
      position: absolute;
      opacity: 0;
      cursor: pointer; }
      form.resetPasswordForm .checkbox input[type=checkbox]:checked ~ span:after {
        display: block; }
    form.resetPasswordForm .checkbox span {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border: 1px solid #979797; }
      form.resetPasswordForm .checkbox span:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 8px;
        height: 13px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg); }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

form.loginForm {
  color: #979797;
  display: flex;
  flex-direction: column; }
  form.loginForm > label {
    color: #fff;
    font-size: 24px;
    line-height: 29px; }
  form.loginForm label {
    text-transform: uppercase; }
  form.loginForm input[type=email], form.loginForm input[type=password] {
    border: 1px solid #979797;
    display: block;
    width: 100%;
    background-color: #363d43;
    color: #979797;
    font-size: 18px;
    padding: .6em 1em;
    margin-bottom: 1.3em; }
    form.loginForm input[type=email]:focus, form.loginForm input[type=password]:focus {
      border: 1px solid #fff;
      outline: none;
      color: #fff; }
  form.loginForm a {
    float: right;
    text-transform: uppercase; }
  form.loginForm > .btn-danger, form.loginForm > .forgot-password {
    width: 100%;
    align-self: flex-end;
    text-align: center;
    margin-top: 1.3em; }
  form.loginForm .checkbox {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #AAB8BE; }
    form.loginForm .checkbox:hover input ~ span {
      border: 1px solid #fff; }
    form.loginForm .checkbox input[type=checkbox] {
      position: absolute;
      opacity: 0;
      cursor: pointer; }
      form.loginForm .checkbox input[type=checkbox]:checked ~ span:after {
        display: block; }
    form.loginForm .checkbox span {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border: 1px solid #979797; }
      form.loginForm .checkbox span:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 8px;
        height: 13px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg); }

@media (max-width: 420px) {
  form.loginForm .forgot-password {
    width: 100%; }
  form.loginForm > .btn-danger {
    width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.withLogoCol {
  margin: 150px 0;
  display: flex;
  flex-direction: row; }
  .withLogoCol .logo {
    border-right: 1px solid #78868d;
    height: 329px; }

@media (max-width: 1100px) {
  .withLogoCol {
    margin-top: calc(10px + 78px);
    display: flex;
    flex-direction: row; } }

form.forgotPasswordScene {
  color: #979797;
  display: flex;
  flex-direction: column; }
  form.forgotPasswordScene label {
    color: #fff;
    font-size: 18px;
    font-weight: normal; }
  form.forgotPasswordScene .forgot-password-icon {
    width: 110px; }
  form.forgotPasswordScene .reset-failure-message {
    color: red; }
  form.forgotPasswordScene .reset-success-message {
    color: #33cccc;
    font-size: 16px; }
    form.forgotPasswordScene .reset-success-message div:first-child {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 20px; }
    form.forgotPasswordScene .reset-success-message div:last-child {
      padding-left: 28px; }
  form.forgotPasswordScene h4 {
    color: white;
    margin-top: 16px;
    margin-bottom: 67px; }
  form.forgotPasswordScene input[type=email], form.forgotPasswordScene input[type=password] {
    border: 1px solid #979797;
    display: block;
    width: 100%;
    background-color: #363d43;
    color: #979797;
    font-size: 18px;
    padding: .6em 1em;
    margin-bottom: 1.3em; }
    form.forgotPasswordScene input[type=email]:focus, form.forgotPasswordScene input[type=password]:focus {
      border: 1px solid #fff;
      outline: none;
      color: #fff; }
  form.forgotPasswordScene a {
    float: right;
    text-transform: uppercase; }
  form.forgotPasswordScene > .btn-danger {
    text-align: center;
    width: 100%;
    align-self: flex-end;
    margin-top: 1.3em; }
  form.forgotPasswordScene .checkbox {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #AAB8BE; }
    form.forgotPasswordScene .checkbox:hover input ~ span {
      border: 1px solid #fff; }
    form.forgotPasswordScene .checkbox input[type=checkbox] {
      position: absolute;
      opacity: 0;
      cursor: pointer; }
      form.forgotPasswordScene .checkbox input[type=checkbox]:checked ~ span:after {
        display: block; }
    form.forgotPasswordScene .checkbox span {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border: 1px solid #979797; }
      form.forgotPasswordScene .checkbox span:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 8px;
        height: 13px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg); }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.resetPasswordSuccess p {
  color: #fff;
  font-size: 18px;
  font-weight: normal;
  margin-top: 50px;
  margin-bottom: 140px; }

.resetPasswordSuccess img {
  height: 75px; }

.resetPasswordSuccess .login-link {
  font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #33cccc;
  float: right;
  font-style: italic;
  font-size: 18px; }

.resetPasswordSuccess .badge {
  width: 50%; }
  .resetPasswordSuccess .badge.apple img {
    height: 70px; }
  .resetPasswordSuccess .badge.google img {
    height: 100px; }
  .resetPasswordSuccess .badge img {
    max-width: 100%; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.notFound {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  text-align: center;
  font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .notFound h1 {
    margin-top: 12vh;
    text-transform: none; }
  .notFound a {
    margin-top: 3vh; }

body.not-found .v2footer {
  display: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.verificationFailed {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-align: center; }
  .verificationFailed h1 {
    margin-top: 25vh;
    text-transform: none; }
  .verificationFailed a {
    margin-top: 3vh; }

body.verification-failed .navBarV2 {
  display: none; }

body.verification-failed .v2footer {
  display: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.verificationSuccess {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  text-align: center;
  font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .verificationSuccess h1 {
    margin-top: 25vh;
    text-transform: none; }
  .verificationSuccess a {
    margin-top: 3vh; }

body.verification-success .navBarV2 {
  display: none; }

body.verification-success .v2footer {
  display: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.confirmationScene {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  text-align: center;
  font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .confirmationScene h1 {
    margin-top: 25vh;
    text-transform: none; }
  .confirmationScene a {
    margin-top: 3vh; }

body.confirmation-scene .navBarV2 {
  display: none; }

body.confirmation-scene .v2footer {
  display: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.changePassword {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  text-align: center;
  font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .changePassword h1 {
    margin-top: 25vh;
    text-transform: none; }
  .changePassword a {
    margin-top: 3vh; }

body.change-password .navBarV2 {
  display: none; }

body.change-password .v2footer {
  display: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.btn, .custom-file-label {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn, .custom-file-label {
      transition: none; } }
  .btn:hover, .custom-file-label:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .custom-file-label:focus, .btn.focus, .focus.custom-file-label {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 204, 0.25); }
  .btn.disabled, .disabled.custom-file-label, .btn:disabled, .custom-file-label:disabled, .btn.bold-button.disabled, .bold-button.disabled.custom-file-label, .btn.bold-button[disabled], .bold-button.custom-file-label[disabled] {
    opacity: 0.65; }

a.btn.disabled, a.disabled.custom-file-label,
fieldset:disabled a.btn,
fieldset:disabled a.custom-file-label {
  pointer-events: none; }

.btn-primary, .custom-file-label {
  color: #212529;
  background-color: #33cccc;
  border-color: #33cccc; }
  .btn-primary:hover, .custom-file-label:hover, .custom-file-input:hover + .custom-file-label {
    color: #fff;
    background-color: #2badad;
    border-color: #29a3a3; }
  .btn-primary:focus, .custom-file-label:focus, .btn-primary.focus, .focus.custom-file-label {
    color: #fff;
    background-color: #2badad;
    border-color: #29a3a3;
    box-shadow: 0 0 0 0.2rem rgba(48, 179, 180, 0.5); }
  .btn-primary.disabled, .disabled.custom-file-label, .btn-primary:disabled, .custom-file-label:disabled {
    color: #212529;
    background-color: #33cccc;
    border-color: #33cccc; }
  .btn-primary:not(:disabled):not(.disabled):active, .custom-file-label:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .custom-file-label:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle,
  .show > .dropdown-toggle.custom-file-label {
    color: #fff;
    background-color: #29a3a3;
    border-color: #269999; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .custom-file-label:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .custom-file-label:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus,
    .show > .dropdown-toggle.custom-file-label:focus {
      box-shadow: 0 0 0 0.2rem rgba(48, 179, 180, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #78868d;
  border-color: #78868d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #667379;
    border-color: #606c72; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #667379;
    border-color: #606c72;
    box-shadow: 0 0 0 0.2rem rgba(140, 152, 158, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #78868d;
    border-color: #78868d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #606c72;
    border-color: #5a656b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(140, 152, 158, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #f33;
  border-color: #f33; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ff0d0d;
    border-color: red; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #ff0d0d;
    border-color: red;
    box-shadow: 0 0 0 0.2rem rgba(255, 82, 82, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f33;
    border-color: #f33; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: red;
    border-color: #f20000; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 82, 82, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #33cccc;
  border-color: #33cccc; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #33cccc;
    border-color: #33cccc; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(51, 204, 204, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #33cccc;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #33cccc;
    border-color: #33cccc; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(51, 204, 204, 0.5); }

.btn-outline-secondary {
  color: #78868d;
  border-color: #78868d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #78868d;
    border-color: #78868d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(120, 134, 141, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #78868d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #78868d;
    border-color: #78868d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(120, 134, 141, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #f33;
  border-color: #f33; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f33;
    border-color: #f33; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f33;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f33;
    border-color: #f33; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 51, 51, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #00b3ef;
  text-decoration: none; }
  .btn-link:hover {
    color: #007aa3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .custom-file-label {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .custom-file-label {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.deeplinkScene {
  min-height: calc(100vh - 180px);
  padding-top: 78px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .deeplinkScene .container {
    max-width: 756px; }
  .deeplinkScene .dashed-line {
    border-top: 2px dashed #b6b6b6; }
  .deeplinkScene .corners {
    width: 100%;
    background: linear-gradient(to right, #b6b6b6 2px, transparent 2px) 0 0, linear-gradient(to right, #b6b6b6 2px, transparent 2px) 0 100%, linear-gradient(to left, #b6b6b6 2px, transparent 2px) 100% 0, linear-gradient(to left, #b6b6b6 2px, transparent 2px) 100% 100%, linear-gradient(to bottom, #b6b6b6 2px, transparent 2px) 0 0, linear-gradient(to bottom, #b6b6b6 2px, transparent 2px) 100% 0, linear-gradient(to top, #b6b6b6 2px, transparent 2px) 0 100%, linear-gradient(to top, #b6b6b6 2px, transparent 2px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px; }
  .deeplinkScene h2 {
    margin-top: 78px; }
  .deeplinkScene a {
    margin-top: 3vh; }

@media screen and (max-width: 575px) {
  .deeplink .route-content {
    min-height: calc(100vh + 180px); }
  .deeplinkScene h2 {
    margin-top: 0; }
  .deeplinkScene a {
    margin-top: 0; }
  .deeplinkScene .sm-btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.editProfileScene {
  margin: 0 auto; }
  .editProfileScene .profile-pic {
    width: 100px;
    height: 100px;
    margin: 27px auto 5px auto;
    display: block;
    border-radius: 50px;
    background-size: cover;
    background-position: center; }
  .editProfileScene .profile-stats {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 15px;
    margin-block: 15px; }
    .editProfileScene .profile-stats tr {
      border: 1px #b9b6ba solid; }
      .editProfileScene .profile-stats tr th,
      .editProfileScene .profile-stats tr td {
        padding: 5px 10px; }
      .editProfileScene .profile-stats tr th {
        border-right: 1px #b9b6ba solid;
        font-weight: normal; }
      .editProfileScene .profile-stats tr td {
        text-align: center; }
    .editProfileScene .profile-stats p {
      font-size: 10px;
      color: #979797;
      margin: 0; }
  .editProfileScene .controls {
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .editProfileScene .controls a {
      color: #78868d;
      text-transform: uppercase;
      text-decoration: none; }
      .editProfileScene .controls a:hover {
        cursor: pointer;
        text-decoration: underline; }
  .editProfileScene .financeEmailInfo {
    font-size: 0.8rem; }
  .editProfileScene .invalid-feedback-displayed {
    color: #f33; }
  .editProfileScene .custom-file-label {
    top: inherit;
    display: inline-block;
    width: auto;
    background-color: transparent !important;
    border: none !important;
    font-style: normal;
    font-weight: normal;
    color: #00b3ef !important;
    padding: 0; }
  .editProfileScene .divider {
    background-color: #979797;
    height: 2px;
    width: 100%;
    margin: 25px 0; }
  .editProfileScene section {
    width: 100px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: reverse; }

@media (max-width: 1100px) {
  .container {
    padding-left: 0px;
    padding-right: 0px; }
  .profile-stats {
    width: 100%; }
  .controls {
    flex-direction: column-reverse; }
    .controls .btn-info {
      width: 80%;
      margin-right: 0 !important;
      margin-top: 10px; }
    .controls .btn-danger {
      width: 80%; }
    .controls .link-button {
      margin-top: 20px;
      text-align: center; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

#detailsForm .event-logo-preview {
  margin-bottom: 10px;
  text-align: center; }

#detailsForm .image-preview-row {
  padding-top: 18px; }

#detailsForm input[type="radio"] ~ label {
  margin-top: 3px; }

#detailsForm input[type="radio"]:checked ~ label {
  font-weight: bold; }

#detailsForm .select__menu {
  background-color: grey;
  color: white; }

#detailsForm .select__option {
  font-size: 1rem;
  line-height: 0.5; }

#detailsForm .css-26l3qy-menu {
  z-index: 9999 !important; }

#detailsForm .select__option--is-focused {
  background-color: #5499ff; }

@media (max-width: 1100px) {
  #detailsForm .custom-file {
    height: auto; }
  #detailsForm .event-logo-preview {
    margin: 10px 0; }
  #detailsForm .form-group {
    margin-bottom: 0; }
  #detailsForm .image-preview-row {
    padding-top: 10px; }
    #detailsForm .image-preview-row > div:nth-of-type(1) {
      margin-bottom: 5px; }
      #detailsForm .image-preview-row > div:nth-of-type(1) label {
        margin: 0; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.editEventScene {
  width: 90%; }
  .editEventScene .step-description {
    color: #78868d;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px; }
  .editEventScene .description {
    height: 40px;
    width: 752px;
    color: #677379;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    margin-left: 20px; }
  .editEventScene .cancel-panel {
    border: 1px solid #f33;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 24px;
    margin-bottom: 32px; }
    .editEventScene .cancel-panel button {
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-style: italic; }
  .editEventScene .duplicate-panel {
    border: 1px solid #33cccc;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 24px;
    margin-bottom: 32px; }
    .editEventScene .duplicate-panel button {
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-style: italic; }

@media (max-width: 1100px) {
  .editEventScene {
    width: 85%; }
    .editEventScene h1 {
      margin-bottom: 40px; }
    .editEventScene > p {
      display: none; }
    .editEventScene .cancel-panel {
      border: none;
      display: block;
      padding: 0; }
      .editEventScene .cancel-panel button {
        margin: 0 auto 20px auto; }
      .editEventScene .cancel-panel .cancel-description {
        width: auto;
        height: auto;
        margin: 0;
        font-size: 12px; }
    .editEventScene .duplicate-panel {
      border: none;
      display: block;
      padding: 0; }
      .editEventScene .duplicate-panel button {
        margin: 0 auto 20px auto; }
      .editEventScene .duplicate-panel .duplicate-description {
        width: auto;
        height: auto;
        margin: 0;
        font-size: 12px; } }

@media (max-width: 1100px) {
  .editEventScene {
    margin: 5px auto !important;
    width: 95% !important; }
    .editEventScene .cancel-panel {
      width: 95%;
      margin: 10px auto; }
    .editEventScene .description {
      width: 100%;
      display: inline;
      height: auto; }
    .editEventScene .shiftForm {
      margin-left: -5px;
      margin-right: -5px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.flashMessage {
  width: 80%;
  margin: 0 auto; }
  .flashMessage.withFixedHeader {
    position: relative;
    top: 100px;
    z-index: 2; }
  .flashMessage .scroll-to {
    position: absolute;
    top: -100px; }
    .flashMessage .scroll-to.noNavBar {
      top: 0; }
  .flashMessage .collapsing .message {
    visibility: hidden; }
  .flashMessage .error {
    background-color: #f33; }
  .flashMessage .message {
    margin-bottom: 30px;
    margin-top: -10px;
    padding: 10px 3%;
    min-height: 43px;
    color: #fff;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .flashMessage .success {
    background-color: #33cccc; }
  .flashMessage button {
    background: transparent;
    border: none;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px; }
    .flashMessage button:focus {
      outline: none; }
    .flashMessage button:hover {
      cursor: pointer; }

@media (max-width: 1100px) {
  .flashMessage {
    width: 100%;
    margin: 0; }
    .flashMessage .message-text {
      margin-right: 30px; }
    .flashMessage .flash-close {
      display: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.info-banner {
  width: 80%;
  margin: 0 auto; }
  .info-banner button.message {
    min-height: 43px;
    color: #fff;
    font-weight: bold;
    align-items: center;
    margin-top: -13px;
    border-radius: 0;
    background: #33cccc;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    border: none;
    width: 100%; }
    .info-banner button.message:focus {
      outline: none; }
    .info-banner button.message:hover {
      cursor: pointer; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.confirmation-banner {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 43px;
  padding: 15px;
  color: #fff;
  font-weight: bold;
  align-items: center;
  margin-top: -13px;
  border-radius: 0;
  background: #33cccc;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  border: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventDetailsScene {
  display: flex;
  justify-content: space-between;
  margin-top: -45px; }
  .eventDetailsScene .events {
    flex-grow: 1;
    padding-left: 10%;
    padding-right: calc(10% - 52px);
    padding-top: 45px; }
    .eventDetailsScene .events > .header {
      display: flex;
      margin-bottom: 20px; }
      .eventDetailsScene .events > .header h1 {
        margin: 0;
        font-size: 38px;
        line-height: 40px;
        text-transform: uppercase;
        font-weight: normal;
        font-family: Evogria, sans-serif; }
      .eventDetailsScene .events > .header .actions {
        margin-left: 20px; }
        .eventDetailsScene .events > .header .actions a {
          height: 100%;
          margin: 0 8px 0 0;
          font-style: italic;
          font-family: Evogria, sans-serif; }
    .eventDetailsScene .events > h2 {
      font-size: 20px;
      font-style: italic;
      color: #677379;
      margin-bottom: 5px;
      letter-spacing: 0.7px; }
    .eventDetailsScene .events > div > p {
      font-size: 14px;
      color: #677379; }
    .eventDetailsScene .events .clear-filters-link {
      color: #33cccc; }
    .eventDetailsScene .events .results-pagination {
      font-family: "Lato", sans-serif;
      font-weight: bold;
      overflow: hidden; }

@media (min-width: 1100px) {
  .eventDetailsScene .events .empty-events .display-mobile {
    display: none; } }

@media (max-width: 1100px) {
  .eventDetailsScene .events .empty-events {
    padding-left: 5px;
    padding-right: 5px; }
    .eventDetailsScene .events .empty-events > * {
      margin-top: 25px; } }

@media (max-width: 1100px) {
  .eventDetailsScene {
    margin: 0 auto;
    width: 100%; }
    .eventDetailsScene .events {
      flex-grow: unset;
      width: 100%;
      padding: 0; }
      .eventDetailsScene .events > h2, .eventDetailsScene .events > div > p {
        padding-left: 5px;
        padding-right: 5px; }
      .eventDetailsScene .events > .header {
        flex-flow: column; }
        .eventDetailsScene .events > .header .actions {
          margin-top: 16px;
          margin-left: 0; }
    .eventDetailsScene .header {
      padding-left: 5px; }
      .eventDetailsScene .header .btn-danger {
        display: none; }
    .eventDetailsScene .eventList ul.list-table {
      padding-right: 20px; }
    .eventDetailsScene .eventList .pagination {
      padding-right: 20px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.datePanel {
  margin-bottom: 20px; }
  .datePanel > .header {
    background-color: #aab8be;
    color: #fff;
    font-size: 15px;
    line-height: 18px;
    padding: 6px 3%; }

.collapsibleFilterPanel {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  min-height: 100vh;
  height: 100%;
  overflow: none;
  z-index: 999; }
  .collapsibleFilterPanel .content {
    display: flex;
    flex-direction: column;
    transition: width 0.3s ease, padding 0.3s linear;
    -webkit-transition: width 0.3s ease, padding 0.3s linear;
    width: 0.1px;
    padding: 45px 0px 45px 20px;
    overflow: hidden;
    background-color: #e4e4e4;
    white-space: nowrap; }
    .collapsibleFilterPanel .content.show {
      width: 320px;
      max-width: 290px;
      padding-right: 20px;
      overflow: scroll; }
    .collapsibleFilterPanel .content h2 {
      font-size: 20px;
      line-height: 24px;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      color: #677379;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0;
      margin-bottom: 25px; }
    .collapsibleFilterPanel .content label {
      color: #78868d;
      display: block;
      margin-bottom: 0; }
    .collapsibleFilterPanel .content select {
      color: #b9b6ba; }
      .collapsibleFilterPanel .content select:focus {
        color: #000; }
      .collapsibleFilterPanel .content select[value="1"] {
        color: #000; }
    .collapsibleFilterPanel .content .picker[readonly] {
      background-color: white;
      cursor: pointer; }
    .collapsibleFilterPanel .content .form-controls {
      padding: 0 20px;
      margin-top: 30px;
      display: flex;
      flex-direction: column; }
      .collapsibleFilterPanel .content .form-controls button {
        width: 100%;
        box-shadow: none;
        padding: 8px 32px;
        overflow: hidden;
        display: block; }
      .collapsibleFilterPanel .content .form-controls .btn-outline-secondary {
        margin-bottom: 20px;
        color: #858b9a;
        border: 1px solid #858b9a; }
        .collapsibleFilterPanel .content .form-controls .btn-outline-secondary:hover {
          color: #fff; }
    .collapsibleFilterPanel .content .form-group {
      margin-bottom: 5px; }
      .collapsibleFilterPanel .content .form-group:first-of-type {
        margin-bottom: 30px; }
    .collapsibleFilterPanel .content .search-group {
      position: relative; }
      .collapsibleFilterPanel .content .search-group i {
        position: absolute;
        color: #b9b6ba;
        top: 8px;
        left: 10px; }
      .collapsibleFilterPanel .content .search-group input {
        border: none;
        border-radius: 50px;
        padding-left: 35px;
        font-size: 14px; }
  .collapsibleFilterPanel .toggle {
    height: 25px;
    width: 25px;
    margin-top: 78px;
    background-color: #e4e4e4;
    color: #979797; }
    .collapsibleFilterPanel .toggle:hover {
      cursor: pointer; }
    .collapsibleFilterPanel .toggle i {
      font-size: 28px;
      margin-left: -1px;
      margin-top: -1px; }

.filter-btn {
  display: flex;
  align-items: center; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventFiltersPanel select.selected, .eventFiltersPanel input.selected {
  color: #495057; }

.eventFiltersPanel input.blank {
  color: #b9b6ba; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventDraftsScene {
  width: 70%;
  margin: 0 auto; }
  .eventDraftsScene > h1 {
    margin-bottom: 35px; }
  .eventDraftsScene > h2 {
    font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 20px;
    font-style: italic;
    line-height: 25px;
    margin: 0;
    margin-bottom: 8px;
    color: #677379;
    display: flex; }
  .eventDraftsScene > p {
    color: #677379;
    font-size: 14px; }

@media (max-width: 1100px) {
  .eventDraftsScene {
    margin: 0 auto;
    width: 100%; }
    .eventDraftsScene > p, .eventDraftsScene .headers {
      padding-left: 5px;
      padding-right: 5px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.financesScene {
  margin: 0 auto;
  width: 80%; }
  .financesScene .events > .header {
    display: flex;
    margin-bottom: 20px; }
    .financesScene .events > .header h1 {
      margin: 0;
      font-size: 38px;
      line-height: 40px;
      text-transform: uppercase;
      font-weight: normal;
      font-family: Evogria, sans-serif; }
    .financesScene .events > .header .actions {
      margin-left: 20px; }
      .financesScene .events > .header .actions a {
        height: 100%;
        margin: 0 8px 0 0;
        font-style: italic;
        font-family: Evogria, sans-serif; }
    .financesScene .events > .header .clear-filters-link {
      color: #33cccc; }
    .financesScene .events > .header .results-pagination {
      font-family: "Lato", sans-serif;
      font-weight: bold;
      overflow: hidden; }
  .financesScene .events > h2 {
    font-size: 20px;
    font-style: italic;
    color: #677379;
    margin-bottom: 5px;
    letter-spacing: 0.7px; }
  .financesScene .events > div > p {
    font-size: 14px;
    color: #677379; }
  .financesScene .edit-profile-link {
    color: #f33; }
    .financesScene .edit-profile-link:hover {
      color: #e03738; }

@media (max-width: 1100px) {
  .financesScene {
    width: 100%;
    margin-top: auto; }
    .financesScene .btn-danger {
      margin-top: 15px;
      margin-right: 45px;
      max-width: 225px; }
    .financesScene .events {
      margin-right: 20px; }
      .financesScene .events .empty-events {
        padding-left: 5px;
        padding-right: 5px; }
        .financesScene .events .empty-events > * {
          margin-top: 25px; }
      .financesScene .events > .header {
        padding-left: 5px;
        margin-right: 45px;
        flex-flow: column; }
        .financesScene .events > .header .actions {
          margin-top: 16px;
          margin-left: 0; }
      .financesScene .events > h2 {
        padding-left: 5px; }
      .financesScene .events > div > p {
        padding-left: 5px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventFinancesList .list-table li.head {
  text-align: center; }
  .eventFinancesList .list-table li.head > div {
    flex-grow: 1; }
    .eventFinancesList .list-table li.head > div:nth-of-type(1) {
      width: 7%; }
    .eventFinancesList .list-table li.head > div:nth-of-type(2) {
      width: 30%; }
    .eventFinancesList .list-table li.head > div:nth-of-type(3) {
      width: 14%; }
    .eventFinancesList .list-table li.head > div:nth-of-type(4) {
      width: 14%; }
    .eventFinancesList .list-table li.head > div:nth-of-type(5) {
      width: 14%; }
    .eventFinancesList .list-table li.head > div:nth-of-type(6) {
      width: 14%; }
    .eventFinancesList .list-table li.head > div:nth-of-type(7) {
      width: 7%; }
    .eventFinancesList .list-table li.head > div:nth-of-type(2) {
      text-align: left; }

@media (max-width: 1100px) {
  .eventFinancesList .list-table .head {
    display: none; }
  .eventFinancesList .list-table > li > div:nth-of-type(n+4) {
    display: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventFinancesListItem.expanded {
  background-color: #eefafa; }

.eventFinancesListItem > .summary {
  display: flex;
  width: 100% !important; }
  .eventFinancesListItem > .summary > div {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px 0; }
    .eventFinancesListItem > .summary > div:nth-of-type(1) {
      width: 7%; }
    .eventFinancesListItem > .summary > div:nth-of-type(2) {
      width: 30%; }
    .eventFinancesListItem > .summary > div:nth-of-type(3) {
      width: 14%; }
    .eventFinancesListItem > .summary > div:nth-of-type(4) {
      width: 14%; }
    .eventFinancesListItem > .summary > div:nth-of-type(5) {
      width: 14%; }
    .eventFinancesListItem > .summary > div:nth-of-type(6) {
      width: 14%; }
    .eventFinancesListItem > .summary > div:nth-of-type(7) {
      width: 7%; }
    .eventFinancesListItem > .summary > div b {
      font-size: 15px;
      line-height: 17px;
      font-weight: bold;
      color: #677379;
      margin: 0; }
    .eventFinancesListItem > .summary > div p {
      font-size: 12px;
      line-height: 17px;
      color: #78868d;
      margin: 0; }
    .eventFinancesListItem > .summary > div:nth-of-type(1) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      color: #c4c5c7;
      font-size: 18px;
      padding: 0; }
      .eventFinancesListItem > .summary > div:nth-of-type(1) .fa-angle-down, .eventFinancesListItem > .summary > div:nth-of-type(1) .fa-angle-up {
        flex-grow: 1;
        text-align: center; }
    .eventFinancesListItem > .summary > div:nth-of-type(3) {
      align-items: center; }
    .eventFinancesListItem > .summary > div:nth-of-type(5) {
      font-size: 12px;
      line-height: 15px; }
    .eventFinancesListItem > .summary > div:nth-of-type(4), .eventFinancesListItem > .summary > div:nth-of-type(5), .eventFinancesListItem > .summary > div:nth-of-type(6), .eventFinancesListItem > .summary > div:nth-of-type(7) {
      text-align: center;
      border-left: 1px solid #c4c5c7;
      margin: 10px 0;
      padding: 0;
      color: #78868d; }

.eventFinancesListItem .service-charge {
  padding-left: 8%;
  padding-right: 11%;
  background-color: #DCDFE1;
  color: #78868d;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  border-bottom: 1px solid #979797; }

.eventFinancesListItem .mobile-controls {
  display: none; }

@media (max-width: 1100px) {
  .eventFinancesListItem.expanded .mobile-controls .no-alert-bar {
    background-color: #eefafa; }
  .eventFinancesListItem .draft-alert-bar {
    display: none !important; }
  .eventFinancesListItem .mobile-controls {
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: space-between; }
    .eventFinancesListItem .mobile-controls i {
      padding-left: 12px;
      padding-right: 12px; }
  .eventFinancesListItem .summary > div:nth-of-type(n+4) {
    display: none !important; }
  .eventFinancesListItem .summary > div:nth-of-type(1) {
    width: 12px !important;
    flex-grow: unset; }
    .eventFinancesListItem .summary > div:nth-of-type(1) i {
      display: none; }
  .eventFinancesListItem .summary > div:nth-of-type(2) {
    padding-bottom: 0;
    align-self: flex-start;
    flex-grow: 1; }
  .eventFinancesListItem .summary > div:nth-of-type(3) {
    width: auto;
    padding-bottom: 0;
    align-self: flex-start;
    align-items: flex-end;
    padding-right: 12px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.shiftFinancesListItem > .header {
  background-color: #dcdcdd;
  color: #677379;
  font-size: 12px;
  line-height: 15px;
  padding-left: 59px;
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
  height: 30px;
  display: flex;
  align-items: center; }
  .shiftFinancesListItem > .header div {
    padding: 0 40px;
    border-right: 1px solid #677379; }
    .shiftFinancesListItem > .header div:first-of-type {
      padding-left: 0;
      font-weight: bold;
      width: 20%;
      min-width: 200px; }
    .shiftFinancesListItem > .header div:last-of-type {
      border: none; }

.shiftFinancesListItem > .details {
  flex-direction: column;
  padding: 0 2%;
  background-color: #f3f4f5; }

@media (max-width: 1100px) {
  .shiftFinancesListItem > .header {
    padding: 0 10px; }
    .shiftFinancesListItem > .header > div {
      border: none;
      padding: 0; }
    .shiftFinancesListItem > .header > div:nth-of-type(1) {
      min-width: auto;
      flex-grow: 1; }
    .shiftFinancesListItem > .header > div:nth-of-type(2) {
      width: auto;
      min-width: auto; }
    .shiftFinancesListItem > .header > div:nth-of-type(3) {
      display: none; }
    .shiftFinancesListItem > .header .assign-shift, .shiftFinancesListItem > .header .eap-link {
      display: none; }
  .shiftFinancesListItem > .details {
    padding: 0 16px; } }

.shiftFinancesJobListItem {
  display: flex;
  flex-direction: row;
  padding-left: 4%;
  border-bottom: 1px solid #dcdcdd; }
  .shiftFinancesJobListItem:last-of-type {
    border: none; }
  .shiftFinancesJobListItem > div {
    display: flex;
    width: 15%;
    align-items: center;
    padding: 7px 0;
    font-size: 12px;
    line-height: 15px;
    color: #464e55; }
    .shiftFinancesJobListItem > div a {
      text-decoration: none;
      color: #78868d; }
    .shiftFinancesJobListItem > div img {
      width: 33px;
      height: 33px;
      margin-right: 9px;
      border-radius: 33px;
      object-fit: cover; }
    .shiftFinancesJobListItem > div b {
      margin-right: 5px;
      font-weight: normal;
      color: #78868d; }
    .shiftFinancesJobListItem > div:first-of-type {
      width: 24%; }
    .shiftFinancesJobListItem > div:nth-of-type(2) {
      width: 35%; }
    .shiftFinancesJobListItem > div:nth-of-type(3) {
      width: 25%; }

@media (max-width: 1100px) {
  .shiftFinancesJobListItem {
    padding: 0; }
    .shiftFinancesJobListItem > div {
      padding: 8px 0; }
      .shiftFinancesJobListItem > div:nth-of-type(1) {
        flex-grow: 1; }
      .shiftFinancesJobListItem > div:nth-of-type(2) {
        display: none; }
      .shiftFinancesJobListItem > div:nth-of-type(3) {
        flex-grow: 1;
        align-self: center;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center; }
      .shiftFinancesJobListItem > div:last-of-type {
        width: auto;
        margin: auto;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.financeFiltersPanel select.selected, .financeFiltersPanel input.selected {
  color: #495057; }

.financeFiltersPanel input.blank {
  color: #b9b6ba; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.confirmationModal .modal-button:hover {
  cursor: pointer; }

.confirmation-modal-content .modal-footer {
  display: flex;
  justify-content: flex-end; }
  .confirmation-modal-content .modal-footer .bold-button {
    font-size: 14px;
    padding: 8px 32px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.editEAPScene .button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.editEAPScene .title {
  margin-bottom: 20px; }

.editEAPScene .back-link {
  color: #78868d; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.aboutUsScene {
  text-align: center; }
  .aboutUsScene img {
    max-width: 1000px;
    width: 100%; }
  .aboutUsScene .content-mobile {
    display: none; }

.about {
  background: #000; }
  .about .route-content {
    padding: 0;
    max-width: 1348px; }
  .about .navBar {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    z-index: 100; }

body.dark {
  background-color: #000; }

@media (min-width: 800px) and (max-width: 1100px) {
  .about .navBar {
    display: flex; }
    .about .navBar .logo {
      width: 100px !important; } }

@media (max-width: 800px) {
  .aboutUsScene .content {
    display: none; }
  .aboutUsScene .content-mobile {
    display: block; }
  .about .footer {
    max-width: 1318px;
    margin: 0 auto; }
  .about .navBar {
    display: flex;
    max-width: 800px; }
    .about .navBar .logo {
      width: 100px !important; }
    .about .navBar .navLink:last-of-type {
      display: none; }
  .about .route-content {
    width: 100%;
    max-width: 800px; }
  .sign-up {
    display: none !important; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.homeScene {
  padding-top: 78px; }
  .homeScene .faqs {
    display: flex;
    background-color: #f2f2f2;
    padding: 70px 40px; }
    .homeScene .faqs h2 {
      position: relative;
      margin-bottom: 20px;
      font-size: 125px;
      text-transform: uppercase;
      line-height: 125px; }
      .homeScene .faqs h2:after {
        position: absolute;
        top: 165px;
        left: 0px;
        background-color: #000;
        height: 15px;
        width: 130px;
        content: ''; }
    .homeScene .faqs .list {
      width: 70%;
      padding-right: 30px; }
  .homeScene .hero {
    height: 785px;
    width: 100%;
    background-image: url("../scenes/HomeScene/Hero.jpg");
    padding-top: 200px; }
    .homeScene .hero .content {
      margin: 0 auto;
      color: #fff; }
      .homeScene .hero .content h1 {
        font-size: 118px;
        text-align: center; }
      .homeScene .hero .content .parallelogram {
        width: 500px;
        -webkit-transform: skew(-20deg);
        -moz-transform: skew(-20deg);
        -o-transform: skew(-20deg);
        background: #f8cd55;
        color: #000;
        font-size: 33px;
        font-weight: bolder;
        font-style: normal;
        padding: 4px 74px;
        line-height: 33px;
        margin: 0 auto;
        position: relative;
        left: -10%; }
        .homeScene .hero .content .parallelogram p {
          margin: 0;
          -webkit-transform: skew(20deg);
          -moz-transform: skew(20deg);
          -o-transform: skew(20deg); }
      .homeScene .hero .content .step-text {
        font-size: 24px;
        padding-right: 70px;
        margin-left: -45px; }
        .homeScene .hero .content .step-text p:nth-of-type(1) {
          text-indent: 150px; }
        .homeScene .hero .content .step-text p:nth-of-type(2) {
          text-indent: 120px; }
        .homeScene .hero .content .step-text p:nth-of-type(3) {
          text-indent: 90px; }
        .homeScene .hero .content .step-text p:nth-of-type(4) {
          text-indent: 60px; }
        .homeScene .hero .content .step-text p:nth-of-type(5) {
          text-indent: 30px; }
        .homeScene .hero .content .step-text p {
          margin-bottom: 0px; }
        .homeScene .hero .content .step-text .yellow {
          color: #f8cd55; }
      .homeScene .hero .content .two-column .actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end; }
        .homeScene .hero .content .two-column .actions .btn, .homeScene .hero .content .two-column .actions .custom-file-label {
          margin-bottom: 10px;
          font-size: 44px;
          padding: 8px 110px;
          text-transform: lowercase;
          letter-spacing: .7px; }
        .homeScene .hero .content .two-column .actions .ask-link {
          color: #fff;
          font-size: 22px;
          border-bottom: 1px solid #fff;
          padding-bottom: 3px; }
          .homeScene .hero .content .two-column .actions .ask-link:hover {
            text-decoration: none;
            font-weight: bold; }
  .homeScene .two-column {
    display: flex;
    justify-content: center; }
  .homeScene .center-content-images, .homeScene .mission-ellis-images {
    background: #fff; }
    .homeScene .center-content-images img, .homeScene .mission-ellis-images img {
      width: 100%;
      max-width: 1350px;
      margin-top: -30px;
      margin-bottom: -60px; }
  .homeScene .mission-ellis-images {
    margin-top: -50px;
    background: transparent; }
    .homeScene .mission-ellis-images img {
      margin-bottom: -50px; }
  .homeScene .center-content-mobile, .homeScene .mission-ellis-mobile {
    display: none; }
  .homeScene .footer-actions {
    width: 100%;
    max-width: 1350px;
    display: flex;
    justify-content: space-around;
    padding: 30px 40px;
    background-color: #969696;
    z-index: 100;
    position: relative;
    align-items: flex-end; }
    .homeScene .footer-actions .btn, .homeScene .footer-actions .custom-file-label {
      display: block;
      font-size: 34px;
      text-transform: lowercase;
      letter-spacing: .7px;
      padding-left: 40px;
      padding-right: 60px; }

.home {
  background: #000; }
  .home .route-content {
    padding: 0;
    max-width: 1348px; }
  .home .navBar {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    z-index: 100; }

body.dark {
  background-color: #000; }

@media (min-width: 575px) and (max-width: 1200px) {
  .homeScene .hero .content .two-column .step-text {
    text-align: left;
    margin-left: 0;
    margin-bottom: 20px; }
    .homeScene .hero .content .two-column .step-text p {
      text-indent: 0; } }

@media (max-width: 575px) {
  .homeScene .center-content {
    display: none; }
  .homeScene .center-content-mobile {
    display: block; }
  .homeScene .faqs {
    flex-direction: column-reverse;
    padding: 70px 20px; }
    .homeScene .faqs h2 {
      font-size: 28px;
      line-height: 28px; }
      .homeScene .faqs h2:after {
        height: 3px;
        width: 40px;
        top: 38px; }
    .homeScene .faqs .list {
      width: 100%;
      padding: 0; }
  .homeScene .footer-actions {
    display: block; }
    .homeScene .footer-actions img {
      display: none; }
    .homeScene .footer-actions .btn, .homeScene .footer-actions .custom-file-label {
      font-size: 24px;
      width: 70%;
      padding: 4px 0;
      margin: 0 auto; }
  .homeScene .hero {
    padding-top: 40px;
    padding-bottom: 60px;
    height: auto; }
    .homeScene .hero .content {
      margin: 0; }
      .homeScene .hero .content .parallelogram {
        width: 300px;
        padding: 4px 20px;
        font-size: 24px;
        left: 0; }
      .homeScene .hero .content h1 {
        margin-top: 20px;
        font-size: 60px; }
      .homeScene .hero .content .two-column {
        display: block; }
        .homeScene .hero .content .two-column .actions .btn, .homeScene .hero .content .two-column .actions .custom-file-label {
          width: 80%;
          font-size: 36px;
          padding: 4px; }
        .homeScene .hero .content .two-column .step-text {
          padding: 0;
          font-size: 16px;
          text-align: center;
          margin-left: 0;
          margin-bottom: 20px; }
          .homeScene .hero .content .two-column .step-text p {
            text-indent: 0; }
  .home .footer {
    max-width: 1318px;
    margin: 0 auto; }
  .home .navBar {
    display: flex;
    max-width: 575px; }
    .home .navBar .logo {
      width: 100px !important; }
    .home .navBar .navLink:last-of-type {
      display: none; }
  .home .route-content {
    width: 100%;
    max-width: 575px; }
  .sign-up {
    display: none !important; } }

@media (min-width: 575px) and (max-width: 1100px) {
  .homeScene .center-content-images img {
    margin-bottom: -35px; }
  .homeScene .faqs {
    flex-direction: column-reverse;
    padding: 70px 20px; }
    .homeScene .faqs h2 {
      font-size: 46px;
      line-height: 46px; }
      .homeScene .faqs h2:after {
        height: 5px;
        width: 40px;
        top: 50px; }
    .homeScene .faqs .list {
      width: 100%;
      padding: 0; }
  .homeScene .footer-actions {
    display: block; }
    .homeScene .footer-actions img {
      display: none; }
    .homeScene .footer-actions .btn, .homeScene .footer-actions .custom-file-label {
      width: 60%;
      padding: 4px 0;
      margin: 0 auto; }
  .homeScene .hero {
    padding-top: 40px;
    padding-bottom: 70px;
    height: auto; }
    .homeScene .hero .content h1 {
      margin-top: 20px; }
    .homeScene .hero .content .parallelogram {
      left: 0; }
    .homeScene .hero .content .two-column {
      display: block; }
      .homeScene .hero .content .two-column .actions .btn, .homeScene .hero .content .two-column .actions .custom-file-label {
        width: 80%;
        font-size: 36px;
        padding: 4px; }
      .homeScene .hero .content .two-column .step-text {
        padding: 0;
        text-align: center;
        margin-left: 0;
        margin-bottom: 20px; }
        .homeScene .hero .content .two-column .step-text p {
          text-indent: 0; }
  .homeScene .sign-up {
    display: none !important; }
  .home .navBar {
    display: flex; }
    .home .navBar .logo {
      width: 100px !important; }
    .home .navBar .navLink:last-of-type {
      display: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.homeScene2 {
  padding-top: 78px; }
  .homeScene2 .top-section {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../field-grass-green-night-SMALLER-crop-4.jpg"); }
  .homeScene2 .go4ellis-img {
    max-width: 72%;
    margin-bottom: 20px;
    opacity: 0.8; }
  .homeScene2 .go4ellis-logo-img {
    max-width: 72%;
    margin-bottom: 20px; }
  .homeScene2 p.top-section-text {
    text-transform: uppercase;
    color: white;
    font-size: 24px;
    font-weight: bold;
    word-spacing: 1.5px; }
  .homeScene2 > .at-for {
    background-color: white;
    padding: 48px 0 20px; }
    .homeScene2 > .at-for > .grouping {
      color: #666666;
      text-align: center;
      width: 25%;
      display: inline-block; }
      .homeScene2 > .at-for > .grouping > div {
        font-family: "Lato", Sans-serif;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 20px;
        margin-bottom: 10px; }
    .homeScene2 > .at-for > .at-link {
      text-align: center;
      width: 100%;
      margin: 10px 0; }
      .homeScene2 > .at-for > .at-link > button {
        background-color: #33cccc;
        padding: 10px 14px;
        border-radius: 3px;
        border: none;
        margin: 18px; }
        .homeScene2 > .at-for > .at-link > button:focus, .homeScene2 > .at-for > .at-link > button:hover {
          background-color: #54595f; }
          .homeScene2 > .at-for > .at-link > button:focus > a, .homeScene2 > .at-for > .at-link > button:hover > a {
            color: #ffffff; }
        .homeScene2 > .at-for > .at-link > button > a {
          text-decoration: none;
          color: #677379;
          font-size: 16px;
          font-weight: 500; }
          .homeScene2 > .at-for > .at-link > button > a:focus {
            outline: none;
            color: #ffffff; }
  .homeScene2 > .eap {
    background-color: #54595f;
    padding: 2px 38px 0; }
    .homeScene2 > .eap > .inner {
      display: flex;
      border-left: dashed #ffffff 5px; }
      .homeScene2 > .eap > .inner > .eap-top {
        padding: 24px 0px 24px 24px;
        align-self: center; }
        .homeScene2 > .eap > .inner > .eap-top > img {
          height: 100px; }
      .homeScene2 > .eap > .inner > .eap-bottom {
        padding: 10px 10px 20px;
        align-self: center; }
        .homeScene2 > .eap > .inner > .eap-bottom > h1 {
          color: #33cccc;
          font-size: 28px;
          line-height: 28px;
          display: inline;
          margin-right: 5px; }
        .homeScene2 > .eap > .inner > .eap-bottom > p {
          display: inline;
          color: white;
          font-family: "Lato", Sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 18px; }
  .homeScene2 > .at-sports:before {
    content: "";
    background-color: #ffffff;
    height: 50px;
    width: 100%;
    display: block; }
  .homeScene2 > .at-skills-services,
  .homeScene2 .at-sports {
    background-color: #e6e6e6;
    padding: 48px 0px 23px 0px; }
    .homeScene2 > .at-skills-services > .top-banner,
    .homeScene2 .at-sports > .top-banner {
      background-color: #ec1c24;
      color: white;
      padding: 18px 78px; }
      .homeScene2 > .at-skills-services > .top-banner > h5,
      .homeScene2 .at-sports > .top-banner > h5 {
        font-family: "Evogria", Sans-serif;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        font-style: italic;
        letter-spacing: 1.5px; }
      .homeScene2 > .at-skills-services > .top-banner > p,
      .homeScene2 .at-sports > .top-banner > p {
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 28px;
        align-self: flex-start;
        margin-bottom: 0; }
  .homeScene2 > .who-uses {
    background-color: #54595f;
    padding: 48px 0 23px; }
  .homeScene2 > .locations {
    background-color: #e6e6e6;
    padding: 48px 0px 23px 0px; }

.home {
  background: #ffffff; }
  .home .route-content {
    padding: 0;
    max-width: 1348px; }
  .home .navBar {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    z-index: 100; }

@media (max-width: 1024px) {
  .homeScene2 > .at-for > .grouping {
    width: 50%; }
  .homeScene2 > .eap > .inner {
    display: block; }
    .homeScene2 > .eap > .inner > .eap-bottom {
      padding: 0 0 10px 30px; }
      .homeScene2 > .eap > .inner > .eap-bottom > h1 {
        display: inline; }
      .homeScene2 > .eap > .inner > .eap-bottom > p {
        display: inline; }
  .homeScene2 > .at-skills-services > .top-banner,
  .homeScene2 .at-sports > .top-banner {
    padding: 18px; }
    .homeScene2 > .at-skills-services > .top-banner > p,
    .homeScene2 .at-sports > .top-banner > p {
      width: 100%;
      max-width: 100%; }
  .homeScene2 > .who-uses > .title {
    padding: 8px 20px; } }

@media (max-width: 403px) {
  .homeScene2 p.top-section-text {
    font-size: 20px; } }

@media (max-width: 590px) {
  .homeScene2 > .at-for > .grouping {
    width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.fAQListItem p {
  margin: 0; }

.fAQListItem .answer {
  color: #5A5B5F;
  padding: 10px 30px; }

.fAQListItem .question-line {
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  border-bottom: 3px solid #ccc;
  padding: 4px 8px; }
  .fAQListItem .question-line:hover {
    cursor: pointer; }
  .fAQListItem .question-line .material-icons {
    color: #33cccc;
    font-size: 40px; }

@media (max-width: 575px) {
  .fAQListItem .answer {
    font-size: 14px; }
  .fAQListItem .question-line {
    font-size: 16px; }
    .fAQListItem .question-line .material-icons {
      font-size: 32px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.faq {
  padding: 50px 38px;
  background-color: white; }
  .faq > .inner-faq {
    display: flex; }
    .faq > .inner-faq .faq-banner {
      border-radius: 3px;
      width: 100%;
      background-color: #33cccc;
      padding: 10px;
      color: #54595f;
      font-size: 56px;
      font-weight: 600;
      font-family: "Evogria", Sans-serif;
      line-height: 1;
      width: 22.7%;
      display: flex;
      align-items: center;
      justify-content: center; }
    .faq > .inner-faq .accordion-container {
      width: 77.3%;
      padding-left: 20px; }

@media (max-width: 1024px) {
  .faq > .inner-faq {
    flex-direction: column; }
    .faq > .inner-faq > .accordion-container {
      width: 100%;
      padding-left: 0; }
    .faq > .inner-faq > .faq-banner {
      width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.footer {
  position: relative;
  width: auto; }
  .footer .slogan {
    font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    display: block;
    margin-bottom: 10px; }
  .footer a {
    text-decoration: none;
    color: inherit; }
  .footer .upper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 25px 145px;
    background-color: #78868d;
    color: #d9dddf;
    margin-right: 0; }
    .footer .upper .footer-4-logo {
      height: 65px; }
    .footer .upper .social-links img {
      margin-left: 5px;
      margin-right: 5px;
      height: 45px;
      display: inline-block; }
    .footer .upper .info {
      display: flex;
      flex-direction: row;
      padding-right: 40px;
      font-size: 0.8em; }
      .footer .upper .info h5 {
        color: #e5e7e8; }
      .footer .upper .info .links {
        padding-left: 40px;
        padding-right: 30px; }
        .footer .upper .info .links:nth-child(1) {
          flex-grow: 1;
          border-right: 1px solid #8e9294; }
        .footer .upper .info .links:nth-child(2) {
          flex-grow: 2;
          padding-left: 35px; }
  .footer .lower {
    height: 30px;
    background-color: #68747c;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 145px;
    padding-right: 145px;
    font-size: 0.8em;
    align-items: center;
    color: #97a4ab;
    margin-right: 0; }
    .footer .lower div:nth-child(1) {
      flex-grow: 7; }
    .footer .lower div:nth-child(2) {
      flex-grow: 5; }
    .footer .lower div:nth-child(3) {
      flex-grow: 5; }
    .footer .lower div:nth-child(4) {
      flex-grow: 5;
      text-align: right; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.mobileNavBarPlaceholder {
  height: 0px; }
  @media (max-width: 1100px) {
    .mobileNavBarPlaceholder {
      height: 56px; } }

.mobileNavBar {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 8px 16px;
  background-color: #464e55;
  color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999; }
  .mobileNavBar .thumbnail {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    margin: 0 16px;
    background-size: cover;
    background-position: center; }
  .mobileNavBar .fa-bars {
    font-size: 20px; }
  .mobileNavBar .links {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; }
    .mobileNavBar .links.show {
      visibility: visible; }
      .mobileNavBar .links.show ul {
        left: 0; }
      .mobileNavBar .links.show .clear {
        opacity: 1; }
      .mobileNavBar .links.show .overlay {
        opacity: 0.5; }
    .mobileNavBar .links.hide {
      visibility: hidden;
      transition: visibility 0.3s; }
      .mobileNavBar .links.hide ul {
        left: -100%; }
      .mobileNavBar .links.hide .clear .overlay {
        opacity: 0; }
    .mobileNavBar .links ul {
      top: 0;
      left: -100%;
      position: absolute;
      width: 70%;
      height: 100%;
      background-color: #f33;
      z-index: 200;
      -webkit-transition: left 0.2s;
      transition: left 0.2s;
      list-style: none;
      padding: 0 20px; }
      .mobileNavBar .links ul li {
        padding: 5px 0;
        border-bottom: 1px solid #fff; }
        .mobileNavBar .links ul li:first-of-type {
          padding-top: 15px;
          border: none;
          margin-bottom: 40px; }
        .mobileNavBar .links ul li:last-of-type {
          border: none; }
        .mobileNavBar .links ul li a {
          padding: 15px 0;
          color: #fff;
          text-transform: uppercase;
          display: block; }
    .mobileNavBar .links .clear {
      background-color: #fff;
      position: absolute;
      right: 10%;
      top: 20px;
      z-index: 200;
      color: #000;
      padding: 8px;
      border-radius: 50%;
      font-size: 36px;
      opacity: 0;
      transition: opacity 0.2s; }
    .mobileNavBar .links .overlay {
      background-color: #000;
      opacity: 0;
      height: 100%;
      width: 100%;
      z-index: 100;
      transition: opacity 0.2s; }
  .mobileNavBar .user-name {
    text-transform: uppercase;
    font-size: 14px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.mobileFooter {
  display: flex;
  flex-direction: column; }
  .mobileFooter .slogan {
    font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    display: block;
    margin-bottom: 10px; }
  .mobileFooter a {
    text-decoration: none;
    color: inherit; }
  .mobileFooter .upper {
    background-color: #78868d;
    color: #d9dddf;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px; }
    .mobileFooter .upper .logo {
      margin-bottom: 20px; }
      .mobileFooter .upper .logo img {
        height: 60px; }
    .mobileFooter .upper .links-section {
      display: flex;
      justify-content: space-evenly;
      align-items: center; }
      .mobileFooter .upper .links-section .social-links {
        margin-bottom: 20px; }
        .mobileFooter .upper .links-section .social-links img {
          margin-left: 5px;
          margin-right: 5px;
          width: 42px;
          height: 42px;
          display: inline-block; }
      .mobileFooter .upper .links-section .slogan-container {
        display: flex;
        justify-content: center;
        flex-direction: column; }
        .mobileFooter .upper .links-section .slogan-container .info {
          display: flex;
          flex-direction: row;
          font-size: 16px; }
          .mobileFooter .upper .links-section .slogan-container .info .links {
            margin: 20px 0; }
            .mobileFooter .upper .links-section .slogan-container .info .links:nth-child(1) {
              width: 50%;
              padding-right: 15%;
              border-right: 1px solid #8e9294; }
            .mobileFooter .upper .links-section .slogan-container .info .links:nth-child(2) {
              flex-grow: 4;
              text-align: left;
              padding-left: 15%; }
  .mobileFooter .lower-top {
    height: 30px;
    background-color: #68747c;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: .8em;
    text-align: center;
    color: #97a4ab;
    padding-top: 10px; }
    .mobileFooter .lower-top:nth-child(1) {
      padding-left: 40px; }
  .mobileFooter .lower-bottom {
    height: 30px;
    background-color: #68747c;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: .8em;
    text-align: center;
    color: #97a4ab;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px; }

@media (max-width: 575px) {
  .mobileFooter .upper .links-section {
    flex-direction: column; } }

.loading-modal .modal-dialog {
  min-height: calc(100% - (1.75rem * 2));
  display: flex;
  align-items: center;
  justify-content: center; }

.loading-modal .modal-content {
  background: transparent;
  border: 0; }

.loading-modal .spinner-container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center; }

.loading-modal .spin-clockwise {
  animation: trigonometric-spin 3s linear infinite; }

.loading-modal .spin-trigonometric {
  animation: spin 3s linear infinite; }

.loading-modal .four {
  position: absolute;
  height: 120px;
  width: 120px; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes trigonometric-spin {
  from {
    transform: rotate(360deg); }
  to {
    transform: rotate(0deg); } }

.credentialModal {
  display: inline-block; }
  .credentialModal .addon {
    margin-left: 26px; }
  .credentialModal strong {
    font-weight: bold;
    color: black; }

.credential-modal .modal-dialog {
  max-width: 80%;
  min-width: 600px; }

.credential-modal .modal-body img {
  width: 100%;
  margin-bottom: 20px; }

.credential-modal .modal-body .react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important; }

@media (max-width: 1100px) {
  .credential-modal .modal-dialog {
    width: calc(100% - 1rem);
    max-width: calc(100% - 1rem);
    min-width: auto; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.userList .user-list {
  border-bottom: 1px solid #c4c5c7; }
  .userList .user-list .head > div {
    width: 23.75%; }
    .userList .user-list .head > div:nth-of-type(1) {
      padding-left: 5%;
      width: 33.75%; }
  .userList .user-list li {
    padding: 0 15px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.userListItem {
  border-bottom: none !important; }
  .userListItem:last-of-type > div {
    border-bottom: none; }
  .userListItem > div {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #c4c5c7; }
    .userListItem > div > div {
      width: 23.75%;
      font-size: 12px;
      padding: 10px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #979797; }
      .userListItem > div > div:nth-of-type(1) {
        padding-left: 5%;
        width: 33.75%;
        font-weight: bold; }
        .userListItem > div > div:nth-of-type(1) img {
          width: 33px;
          height: 33px;
          margin-right: 9px;
          border-radius: 33px; }
      .userListItem > div > div:nth-of-type(2) {
        text-transform: uppercase; }

.userFiltersPanel select.selected, .userFiltersPanel input.selected {
  color: #495057; }

.userFiltersPanel input.blank {
  color: #b9b6ba; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.expandableSection {
  border-bottom: 1px solid #666666;
  color: #707070;
  font-family: "Lato", Sans-serif;
  font-size: 18px;
  font-weight: 500; }
  .expandableSection > .panel-top {
    padding: 22px 26px;
    transition-property: padding;
    transition-timing-function: ease-in-out;
    transition: 0.5s;
    cursor: pointer;
    font-size: 21px; }
    .expandableSection > .panel-top > .panel-icon-wrapper {
      width: 23px;
      display: inline-block; }
      .expandableSection > .panel-top > .panel-icon-wrapper > i {
        color: #ec1c24;
        transition-property: transform;
        transition: 0.5s; }
  .expandableSection > p {
    border-bottom-color: #666666;
    color: #707070;
    font-family: "Lato", Sans-serif;
    font-size: 17px;
    padding: 0px 0px 0px 50px;
    transform: scaleY(0);
    max-height: 0;
    transition-property: max-height, transform, margin-bottom;
    transform-origin: top;
    transition-timing-function: ease-in-out;
    transition: 0.5s;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 28.9px; }
  .expandableSection.open > .panel-top {
    padding: 22px 26px 5px; }
  .expandableSection.open > p {
    transform: scaleY(1);
    max-height: 100vh;
    margin-bottom: 22px; }
  .expandableSection.open > .panel-top > .panel-icon-wrapper > i {
    transform: rotate(90deg); }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.aTActionButton {
  text-align: center;
  margin-bottom: 25px; }
  .aTActionButton > button {
    color: #ec1c24;
    background-color: rgba(72, 193, 196, 0);
    padding: 6px 14px;
    border-radius: 3px;
    border-style: solid;
    border-width: 3px;
    border-color: #ec1c24;
    margin: 18px; }
    .aTActionButton > button:focus, .aTActionButton > button:hover {
      background-color: #ec1c24;
      outline: none; }
      .aTActionButton > button:focus > a, .aTActionButton > button:hover > a {
        color: #ffffff; }
    .aTActionButton > button > a {
      text-decoration: none;
      font-weight: 500;
      color: #ec1c24;
      font-family: "Lato", Sans-serif;
      font-size: 16px;
      text-transform: uppercase; }
      .aTActionButton > button > a:focus {
        outline: none;
        color: #ffffff; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.forAts {
  background-color: #ffffff;
  padding-top: 50px; }
  .forAts > .body {
    display: flex; }
    .forAts > .body > .phone-background {
      display: flex;
      width: 33%;
      background-color: #33cccc;
      justify-content: center; }
      .forAts > .body > .phone-background > .img-wrapper {
        height: 370px;
        overflow: hidden;
        align-self: flex-end;
        display: flex;
        justify-content: center; }
        .forAts > .body > .phone-background > .img-wrapper > img.phone-img {
          height: 500px;
          position: relative;
          top: 35px;
          max-width: calc(100% - 40px);
          width: 300px; }
    .forAts > .body > .for-at-text {
      width: 67%;
      background-color: #b9b6ba;
      padding: 45px 35px;
      color: #54595f;
      font-family: "Lato", Sans-serif;
      display: inline-block;
      vertical-align: top; }
      .forAts > .body > .for-at-text h3 {
        font-size: 24px;
        font-family: "Lato", Sans-serif;
        text-transform: uppercase;
        font-weight: bold; }
        .forAts > .body > .for-at-text h3 > span {
          color: #ec1c24; }
      .forAts > .body > .for-at-text p {
        margin-top: 8px;
        font-size: 16px; }
      .forAts > .body > .for-at-text .for-at-divider {
        width: 70px;
        height: 4px;
        background-color: #54595f;
        margin: 8px 0 20px; }

@media (max-width: 1024px) {
  .forAts > .body {
    flex-direction: column; }
    .forAts > .body > .phone-background {
      width: 100%; }
    .forAts > .body > .for-at-text {
      width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.downLoadApp {
  display: flex;
  background-color: #e6e6e6;
  padding: 25px 35px;
  color: #54595f; }
  .downLoadApp.tealBackground {
    background-color: #33cccc;
    color: #ffffff;
    text-transform: uppercase; }
  .downLoadApp.darkBackground {
    background-color: #454e56;
    color: #ffffff;
    margin: 30px 0; }
  .downLoadApp > .text-with-arrow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 33%; }
    .downLoadApp > .text-with-arrow > img {
      height: 46px; }
    .downLoadApp > .text-with-arrow > span {
      font-family: "Lato", Sans-serif;
      font-size: 18px;
      line-height: 40px;
      vertical-align: bottom;
      margin-left: 10px; }
    .downLoadApp > .text-with-arrow.emr {
      font-family: Helvetica;
      font-size: 24px;
      color: #ffffff;
      width: 45%; }
      .downLoadApp > .text-with-arrow.emr > span {
        font-size: 24px;
        text-transform: uppercase; }
      .downLoadApp > .text-with-arrow.emr p {
        font-size: 17px;
        text-align: center; }
  .downLoadApp > .download-icons {
    display: inline-flex;
    padding: 0 20px;
    width: 33%;
    border-right: dashed 4px #54595f;
    align-items: center; }
    .downLoadApp > .download-icons > a {
      width: 50%;
      cursor: pointer; }
      .downLoadApp > .download-icons > a > img {
        width: 100%; }
    .downLoadApp > .download-icons > .downloadArrow {
      height: 80%;
      margin-left: 15px; }
    .downLoadApp > .download-icons.emr {
      width: 55%; }
  .downLoadApp.noButton .download-icons {
    border: none; }
  .downLoadApp > .learn-more {
    width: 33%;
    padding: 0 20px;
    display: flex;
    align-items: center; }

@media (max-width: 1024px) {
  .downLoadApp {
    flex-direction: column; }
    .downLoadApp > .text-with-arrow {
      width: 100%;
      align-items: center; }
      .downLoadApp > .text-with-arrow.emr {
        width: 100%;
        align-items: center; }
    .downLoadApp > .download-icons {
      display: flex;
      justify-content: center;
      padding: 0 20px;
      border: none;
      width: 100%; }
      .downLoadApp > .download-icons > a {
        width: auto; }
      .downLoadApp > .download-icons.emr {
        width: 100%; }
      .downLoadApp > .download-icons > .downloadArrow {
        height: 41px;
        margin-right: -61px; }
    .downLoadApp > .learn-more {
      width: 75%;
      align-self: center;
      margin-top: 15px; } }

@media (max-width: 420px) {
  .downLoadApp {
    padding: 15px; }
    .downLoadApp > .text-with-arrow.emr > span {
      font-size: 20px; }
    .downLoadApp > .download-icons > .downloadArrow {
      display: none; }
    .downLoadApp > .learn-more {
      width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.whoIsEllis {
  background-color: #ffffff;
  padding: 50px 0; }
  .whoIsEllis > .body {
    background-color: #454e56;
    padding: 20px 60px;
    display: flex; }
    .whoIsEllis > .body > .heading {
      display: flex;
      align-items: flex-end;
      padding: 10px;
      flex-direction: column;
      width: 35%; }
      .whoIsEllis > .body > .heading > .who-is {
        color: #f2f2f2;
        font-size: 50px;
        font-weight: 600;
        line-height: 76px; }
      .whoIsEllis > .body > .heading img {
        width: 90%;
        max-width: 100%;
        height: auto; }
    .whoIsEllis > .body > .text {
      padding: 10px 0 0 10px;
      width: 65%;
      color: #f2f2f2;
      font-family: "Lato", Sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 33px;
      align-self: flex-end; }

@media (max-width: 1024px) {
  .whoIsEllis > .body {
    flex-direction: column;
    padding: 20px 40px; }
    .whoIsEllis > .body > .heading {
      flex-direction: row;
      width: 100%;
      justify-content: center; }
      .whoIsEllis > .body > .heading > .who-is {
        font-size: 5.5vw;
        margin-right: 12px;
        line-height: 1;
        padding-left: 20px; }
      .whoIsEllis > .body > .heading img {
        width: 60%; }
    .whoIsEllis > .body > .text {
      width: 100%; } }

@media (max-width: 768px) {
  .whoIsEllis > .body {
    padding: 20px 30px; } }

@media (max-width: 575px) {
  .whoIsEllis > .body {
    padding: 20px 20px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.invalidResetTokenScene {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  text-align: center;
  font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .invalidResetTokenScene h1 {
    margin-top: 25vh;
    text-transform: none; }
  .invalidResetTokenScene a {
    margin-top: 3vh; }

body.invalid-reset-token .navBarV2 {
  display: none; }

body.invalid-reset-token .v2footer {
  display: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.aboutUs {
  padding-top: 78px; }
  .aboutUs > .about-us-top {
    background-image: url("../scenes/AboutUs/field-lights.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 438px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 60px; }
    .aboutUs > .about-us-top > p {
      font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 4vw;
      color: #FFFFFF;
      letter-spacing: 0.86px;
      line-height: 1.5;
      text-transform: uppercase;
      display: block;
      margin-bottom: 0; }
  .aboutUs > .sub-top {
    background: #FFFFFF;
    padding: 20px 30px 30px; }
    .aboutUs > .sub-top > .text-container {
      padding: 15px 30px 5px;
      width: 70%; }
      .aboutUs > .sub-top > .text-container p {
        font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        color: #454E56;
        letter-spacing: -0px;
        text-align: justify; }
    .aboutUs > .sub-top > .bottom-border {
      border-bottom: 1px solid #D8D8D8; }

.about {
  background: #ffffff; }
  .about .route-content {
    padding: 0;
    max-width: 1348px; }
  .about .navBar {
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    z-index: 100; }

@media (max-width: 1024px) {
  .aboutUs > .about-us-top {
    height: 400px; }
    .aboutUs > .about-us-top > p {
      font-size: 42px;
      line-height: 1.5; }
  .aboutUs > .sub-top > .text-container {
    width: 80%; } }

@media (max-width: 768px) {
  .aboutUs > .about-us-top {
    height: 300px; }
  .aboutUs > .sub-top > .text-container {
    width: 100%; } }

@media (max-width: 575px) {
  .aboutUs > .about-us-top {
    padding: 30px;
    height: 200px; }
    .aboutUs > .about-us-top > p {
      font-size: 26px; }
  .aboutUs > .sub-top > .text-container {
    padding: 0; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.theNumbers {
  background-color: #e6e6e6;
  padding: 30px 0; }
  .theNumbers > .section {
    width: 33.33%;
    padding: 10px 0;
    text-align: center;
    display: inline-block; }
    .theNumbers > .section:not(:last-child) {
      border-right: dashed 4px #979797; }
    .theNumbers > .section > .top-border {
      display: none; }
    .theNumbers > .section > .number {
      color: #48c1c4;
      font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 44px;
      text-align: center; }
      .theNumbers > .section > .number > i {
        -webkit-animation: spin 4s linear infinite;
        -moz-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite; }
    .theNumbers > .section > .text {
      color: #454e56;
      font-weight: bold;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .theNumbers > .section > .red-dash {
      border-bottom: 4px solid #ec1c24;
      width: 30px;
      margin: 10px auto 0; }

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@media (max-width: 575px) {
  .theNumbers > .section {
    padding: 30px 0 0;
    width: 100%;
    border: none; }
    .theNumbers > .section:not(:last-child) {
      border-right: none; }
      .theNumbers > .section:not(:last-child) > .bottom-border {
        margin: 30px auto 0;
        border-bottom: dashed 4px #979797;
        display: block;
        width: 50%; } }

.teamInfo {
  padding: 30px 0;
  background-color: #ffffff; }
  .teamInfo > .team-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.teamCard {
  width: 325px;
  margin: 15px; }
  .teamCard > .top {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    background-color: #979797; }
    .teamCard > .top > i {
      color: #48c1c4;
      font-size: 18px; }
    .teamCard > .top > .name-divider {
      border-bottom: 2px solid #ec1c24;
      width: 35px;
      margin: 10px 2px 5px; }
    .teamCard > .top > p {
      color: #ec1c24;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 22px;
      letter-spacing: 0.42px;
      text-transform: uppercase;
      margin-bottom: 0; }
      .teamCard > .top > p.last-name {
        font-size: 32px;
        letter-spacing: 0.62px; }
  .teamCard > .bottom {
    background-color: #454e56;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    color: #ffffff;
    font-size: 12px;
    min-height: 270px; }
    .teamCard > .bottom > .title {
      font-size: 18px; }
    .teamCard > .bottom > .title-divider {
      border-bottom: 3px solid #f5d523;
      width: 20px;
      margin: 10px 2px; }
    .teamCard > .bottom > .info-header {
      text-transform: uppercase; }
      .teamCard > .bottom > .info-header.education {
        margin-top: 10px; }
    .teamCard > .bottom > p {
      margin: 0;
      letter-spacing: 0.23px;
      line-height: 18px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.nata-banner {
  background-color: #ffffff;
  padding: 35px 0; }
  .nata-banner > .blue-background {
    background-color: #006198;
    padding: 0 38px; }
    .nata-banner > .blue-background > .banner-inner {
      border-style: dashed;
      border-width: 0px 0px 0px 5px;
      border-color: #ffffff;
      padding: 24px 10px 24px 24px; }
      .nata-banner > .blue-background > .banner-inner > img {
        width: 175px;
        margin-left: -20px; }
      .nata-banner > .blue-background > .banner-inner > p {
        color: #ffffff;
        font-family: "Lato", Sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px; }

.nata-banner.about-us > .blue-background > .banner-inner {
  border: none;
  display: flex;
  align-items: flex-end;
  padding-top: 14px; }
  .nata-banner.about-us > .blue-background > .banner-inner > img {
    width: 140px;
    margin-bottom: -13px; }
  .nata-banner.about-us > .blue-background > .banner-inner > p {
    margin: 0; }

@media (max-width: 768px) {
  .nata-banner > .blue-background > .banner-inner > img {
    width: 48%;
    margin-left: -6%; }
  .nata-banner.about-us > .blue-background > .banner-inner {
    flex-direction: column;
    align-items: flex-start; }
    .nata-banner.about-us > .blue-background > .banner-inner > img {
      margin-left: -17px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.homeContactForm {
  height: 100%;
  width: 80%;
  max-width: 80%;
  background: white;
  text-transform: uppercase; }
  .homeContactForm > .header {
    background-color: #ec1c24;
    padding: 10px 8px;
    word-spacing: 1.5px;
    text-align: center;
    color: white;
    font-size: 24px;
    font-family: "Evogria", Sans-serif; }
  .homeContactForm > .body {
    background-color: #f2f2f2;
    padding: 15px 40px;
    box-shadow: 0px 9px 17px -6px rgba(0, 0, 0, 0.5); }
    .homeContactForm > .body > .date-picker > span {
      color: #454e56;
      font-size: 12px;
      font-weight: 900;
      font-family: "Lato"; }
    .homeContactForm > .body input,
    .homeContactForm > .body textarea {
      border: solid 1px #666666;
      background-color: white;
      border-radius: 3px;
      padding: 10px 15px;
      font-size: 16px;
      font-family: "Lato";
      line-height: 21px;
      font-weight: 400;
      width: 100%;
      margin-bottom: 15px;
      color: #b4b4b4; }
      .homeContactForm > .body input::placeholder,
      .homeContactForm > .body textarea::placeholder {
        text-transform: uppercase !important;
        color: #dedee5; }
      .homeContactForm > .body input:focus,
      .homeContactForm > .body textarea:focus {
        outline: none; }
      .homeContactForm > .body input.error,
      .homeContactForm > .body textarea.error {
        border-color: #e03738; }
    .homeContactForm > .body textarea {
      height: 100px; }
    .homeContactForm > .body button {
      background-color: #ec1c24;
      cursor: pointer;
      font-style: italic;
      text-transform: uppercase;
      font-size: 21px;
      width: 100%;
      padding: 10px 20px;
      font-weight: 500;
      border-radius: 6px;
      border: none;
      font-family: "Evogria", Sans-serif;
      color: #ffffff;
      margin-bottom: 18px; }
      .homeContactForm > .body button:hover {
        color: #aab8be; }
      .homeContactForm > .body button:focus {
        outline: none; }

@media (max-width: 1024px) {
  .homeContactForm {
    width: 100%;
    max-width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.homePostEventForm {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  max-width: 80%;
  background: white;
  text-transform: uppercase; }
  .homePostEventForm > .header {
    background-color: #ec1c24;
    padding: 10px 8px;
    word-spacing: 1.5px;
    text-align: center;
    color: white;
    font-size: 24px;
    font-family: "Lato"; }
  .homePostEventForm > .body {
    background-color: #f2f2f2;
    padding: 15px 40px;
    box-shadow: 0px 9px 17px -6px rgba(0, 0, 0, 0.5);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .homePostEventForm > .body hr {
      width: 100%;
      padding-bottom: 18px; }
    .homePostEventForm > .body .contact-us-btn {
      width: 80%;
      align-self: center; }
    .homePostEventForm > .body > .date-picker > span {
      color: #454e56;
      font-size: 12px;
      font-weight: 900;
      font-family: "Lato"; }
    .homePostEventForm > .body input,
    .homePostEventForm > .body textarea {
      border: solid 1px #666666;
      background-color: white;
      border-radius: 3px;
      padding: 10px 15px;
      font-size: 16px;
      font-family: "Lato";
      line-height: 21px;
      font-weight: 400;
      width: 100%;
      margin-bottom: 15px;
      color: #b4b4b4; }
      .homePostEventForm > .body input::placeholder,
      .homePostEventForm > .body textarea::placeholder {
        text-transform: uppercase !important;
        color: #dedee5; }
      .homePostEventForm > .body input:focus,
      .homePostEventForm > .body textarea:focus {
        outline: none; }
      .homePostEventForm > .body input.error,
      .homePostEventForm > .body textarea.error {
        border-color: #e03738; }
    .homePostEventForm > .body textarea {
      height: 100px; }
    .homePostEventForm > .body button {
      background-color: #ec1c24;
      cursor: pointer;
      font-style: italic;
      text-transform: uppercase;
      font-size: 21px;
      width: 100%;
      padding: 10px 20px;
      font-weight: 500;
      border-radius: 6px;
      border: none;
      font-family: "Evogria", Sans-serif;
      color: #ffffff;
      margin-bottom: 18px; }
      .homePostEventForm > .body button:hover {
        color: #aab8be; }
      .homePostEventForm > .body button:focus {
        outline: none; }

@media (max-width: 1024px) {
  .homePostEventForm {
    width: 100%;
    max-width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.homeCTAForm {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  max-width: 80%;
  background: white;
  color: black;
  padding: 25px; }
  .homeCTAForm .nata-logo-container {
    background-color: #006198;
    padding-top: 15px;
    width: 140px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .homeCTAForm .nata-logo-container > p {
      color: white;
      text-align: center;
      font-size: 10px;
      margin-bottom: -10px; }
    .homeCTAForm .nata-logo-container > .nata-img {
      width: 100%; }
  .homeCTAForm .athletic-trainers {
    color: #666666;
    font-weight: bold;
    font-family: "Lato", sans-serif; }
  .homeCTAForm .content-description {
    color: #666666;
    font-family: "Lato", sans-serif;
    text-align: left; }
  .homeCTAForm .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly; }
    .homeCTAForm .actions .contactus-go4ellis {
      text-transform: capitalize;
      font-weight: bolder;
      color: #666666; }
    .homeCTAForm .actions > * {
      padding: 25px; }
  .homeCTAForm > .header {
    background-color: #ec1c24;
    padding: 10px 8px;
    word-spacing: 1.5px;
    text-align: center;
    color: white;
    font-size: 24px;
    font-family: "Lato"; }
  .homeCTAForm > .body {
    background-color: #f2f2f2;
    padding: 15px 40px;
    box-shadow: 0px 9px 17px -6px rgba(0, 0, 0, 0.5);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .homeCTAForm > .body hr {
      width: 100%;
      padding-bottom: 18px; }
    .homeCTAForm > .body .contact-us-btn {
      width: 80%;
      align-self: center; }
    .homeCTAForm > .body > .date-picker > span {
      color: #454e56;
      font-size: 12px;
      font-weight: 900;
      font-family: "Lato"; }
    .homeCTAForm > .body input,
    .homeCTAForm > .body textarea {
      border: solid 1px #666666;
      background-color: white;
      border-radius: 3px;
      padding: 10px 15px;
      font-size: 16px;
      font-family: "Lato";
      line-height: 21px;
      font-weight: 400;
      width: 100%;
      margin-bottom: 15px;
      color: #b4b4b4; }
      .homeCTAForm > .body input::placeholder,
      .homeCTAForm > .body textarea::placeholder {
        text-transform: uppercase !important;
        color: #dedee5; }
      .homeCTAForm > .body input:focus,
      .homeCTAForm > .body textarea:focus {
        outline: none; }
      .homeCTAForm > .body input.error,
      .homeCTAForm > .body textarea.error {
        border-color: #e03738; }
    .homeCTAForm > .body textarea {
      height: 100px; }
    .homeCTAForm > .body button {
      background-color: #ec1c24;
      cursor: pointer;
      font-style: italic;
      text-transform: uppercase;
      font-size: 21px;
      width: 100%;
      padding: 10px 20px;
      font-weight: 500;
      border-radius: 6px;
      border: none;
      font-family: "Evogria", Sans-serif;
      color: #ffffff;
      margin-bottom: 18px; }
      .homeCTAForm > .body button:hover {
        color: #aab8be; }
      .homeCTAForm > .body button:focus {
        outline: none; }

@media (max-width: 1024px) {
  .homeCTAForm {
    width: 100%;
    max-width: 100%; }
    .homeCTAForm .go4ellis-img,
    .homeCTAForm .go4ellis-logo-img {
      max-width: 100%; }
    .homeCTAForm .actions {
      flex-direction: column; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.navBar2 {
  display: flex;
  z-index: 3;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 78px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #454e56;
  left: 0; }
  .navBar2 .alt-logo {
    width: 35px;
    cursor: pointer; }
  .navBar2 .menu-icon-container {
    background-color: #ec1c24;
    cursor: pointer;
    font-size: 20px;
    padding: 5px 10px;
    transition: 0.4s;
    color: #ffffff;
    display: none; }
    .navBar2 .menu-icon-container:hover {
      color: #aab8be; }
  .navBar2 .links {
    display: flex;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: italic;
    height: 100%; }
    .navBar2 .links li {
      padding-right: 20px; }
      .navBar2 .links li:last-of-type {
        padding-right: 0; }
        .navBar2 .links li:last-of-type.simple {
          padding-left: 40px;
          margin-right: auto;
          font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-style: normal;
          white-space: nowrap; }
          .navBar2 .links li:last-of-type.simple > a {
            text-transform: uppercase; }

.unauthed-links {
  flex-grow: 1; }
  .unauthed-links:last-of-type {
    padding-right: 0; }
    .unauthed-links:last-of-type.simple {
      padding-left: 40px;
      margin-right: auto;
      width: 110%;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-style: normal;
      text-transform: uppercase; }

@media (max-width: 1024px) {
  .navBar2 .links {
    display: none; }
  .navBar2 .menu-icon-container {
    display: block; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.navLink2 {
  display: flex;
  color: #fff; }
  .navLink2.active > a {
    color: #f33;
    z-index: 0; }
    .navLink2.active > a:after {
      height: 9px;
      background: #f33;
      position: absolute;
      bottom: -6.5px;
      left: 0;
      content: '';
      width: 100%;
      z-index: 2; }
  .navLink2 .thumbnail {
    height: 40px;
    width: 40px;
    margin-left: 7px;
    border-radius: 40px;
    background-size: cover;
    background-position: center; }
  .navLink2 > a {
    display: flex;
    position: relative;
    align-items: center;
    color: #fff;
    font-size: 13px;
    line-height: 17px;
    padding: 13px 5px;
    text-decoration: none;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-style: normal;
    cursor: pointer;
    transition: .4s;
    font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .navLink2 > a:hover {
      color: #aab8be;
      text-decoration: none; }
    .navLink2 > a > .fa-angle-down {
      display: inline-block;
      padding-left: 6px; }
    .navLink2 > a > div {
      flex-wrap: nowrap; }
    .navLink2 > a .with-background {
      padding: 8px 15px;
      background: #ec1c24;
      border-radius: 6px;
      font-style: italic; }
  .navLink2 > .dropdown-menu {
    padding-top: 5px;
    padding-bottom: 0;
    margin: 0;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    right: 0;
    left: auto; }
    .navLink2 > .dropdown-menu:before {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      display: block;
      top: -15px;
      right: 25%;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 15px solid #fff; }
    .navLink2 > .dropdown-menu.show {
      display: block; }
    .navLink2 > .dropdown-menu a {
      background-color: #fff;
      color: #78868d;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-style: normal;
      padding: .8rem 1.75rem .8rem 2rem;
      border-top: 1px solid #ECEFF5;
      font-size: 14px; }
      .navLink2 > .dropdown-menu a:hover {
        background-color: #f2f4f3; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.redHomeButton {
  display: block;
  text-decoration: none;
  margin-top: 20px;
  background-color: #ec1c24;
  cursor: pointer;
  font-style: italic;
  text-align: center;
  text-transform: uppercase;
  font-size: 21px;
  width: 100%;
  padding: 18px 20px;
  font-weight: 500;
  border-radius: 6px;
  border: none;
  font-family: "Evogria", Sans-serif;
  color: #ffffff;
  transition: all 0.3s; }
  .redHomeButton:hover {
    background-color: #454e56;
    text-decoration: none;
    color: #ffffff; }
  .redHomeButton:focus {
    outline: none; }
  .redHomeButton.statePage, .redHomeButton.emr-banner {
    font-style: normal;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 15px;
    font-weight: 300;
    width: 40%;
    padding: 10px 58px;
    margin: 0 0 0 14px; }
  .redHomeButton.emr-banner {
    margin: 0; }
  .redHomeButton.jobsButton {
    margin: 0;
    letter-spacing: 1px; }
  .redHomeButton.careers {
    padding: 10px 58px; }

.redHomeButton.skinner {
  padding: 10px 20px;
  height: 51px;
  margin-top: 0; }

@media (max-width: 1024px) {
  .redHomeButton.skinner {
    height: auto; } }

@media (max-width: 768px) {
  .redHomeButton.statePage, .redHomeButton.emr-banner {
    margin: 0;
    width: 70%;
    min-width: 250px;
    align-self: center; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.sectionBanner {
  width: 100%;
  background-color: #ec1c24;
  padding: 8px 68px;
  color: white;
  font-family: "Evogria", Sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  font-style: italic;
  letter-spacing: 1.5px;
  margin-bottom: 35px; }

@media (max-width: 1024px) {
  .sectionBanner {
    padding: 8px 20px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.navDropDown {
  width: 100vw;
  transition: 0.5s;
  transform: scaleY(0);
  max-height: 0;
  position: absolute;
  left: 0;
  top: 78px;
  padding: 0;
  opacity: 0;
  transition-property: max-height, transform, opacity;
  transform-origin: top;
  transition-timing-function: ease-in-out;
  box-shadow: 0px 9px 17px -6px rgba(0, 0, 0, 0.5); }
  .navDropDown.open {
    transform: scaleY(1);
    max-height: 100vh;
    opacity: 1; }
  .navDropDown li {
    list-style: none;
    background-color: #ec1c24;
    transition: 0.2s background-color;
    padding: 12px 40px; }
    .navDropDown li.italic {
      font-style: italic; }
    .navDropDown li > a {
      text-decoration: none;
      color: #fff; }
      .navDropDown li > a:hover {
        color: #f2f4f3; }
    .navDropDown li:hover {
      background-color: #464e55; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.homeList {
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 0px 68px;
  font-family: "Lato", Sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  display: flex;
  flex-wrap: wrap; }
  .homeList > p {
    padding: 10px;
    display: inline-block;
    vertical-align: top;
    width: 25%;
    margin-bottom: 0; }
  .homeList.uppercase > p {
    text-transform: uppercase; }
  .homeList.maxThree > p {
    width: 33.3%;
    font-size: 16px; }
  .homeList.center {
    padding: 0; }
    .homeList.center > p {
      text-align: center; }
  .homeList.largerText > p {
    font-family: Helvetica;
    font-size: 20px;
    color: #454e56;
    letter-spacing: 0.38px;
    line-height: 32px;
    font-weight: normal; }
  .homeList.withBorderTop {
    border-top: 1px dashed #454e56; }
  .homeList.withLeftBorder p {
    padding-left: 30px; }
    .homeList.withLeftBorder p:nth-child(3n + 2), .homeList.withLeftBorder p:nth-child(3n + 3) {
      border: none; }
    .homeList.withLeftBorder p:nth-child(4n + 2), .homeList.withLeftBorder p:nth-child(4n + 3), .homeList.withLeftBorder p:nth-child(4n + 4) {
      border-left: 1px solid #b9b6ba; }

@media (max-width: 1024px) {
  .homeList > p {
    width: 50%; }
  .homeList.maxThree > p {
    width: 50%; }
  .homeList.withLeftBorder > p {
    padding-left: 30px; }
    .homeList.withLeftBorder > p:nth-child(4n + 2), .homeList.withLeftBorder > p:nth-child(4n + 3), .homeList.withLeftBorder > p:nth-child(4n + 4) {
      border: none; }
    .homeList.withLeftBorder > p:nth-child(2n) {
      border-left: 1px solid #b9b6ba; } }

@media (max-width: 575px) {
  .homeList,
  .homeList.maxThree {
    padding: 0 10px; }
  .homeList.mobileSingleColumn > p {
    width: 100%; }
    .homeList.mobileSingleColumn > p:nth-child(2n) {
      border: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.whoUsesList {
  color: white; }

.whoUsesList.darkText {
  color: #454e56; }

.whoUsesList.redText {
  color: #ec1c24; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.careersScene {
  padding-top: 78px; }
  .careersScene > .careersScene-top {
    background-image: url("../scenes/AboutUs/field-lights.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 438px;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .careersScene > .careersScene-top > .red-stripe {
      background-color: #ec1c24;
      height: 50%;
      display: flex;
      align-items: flex-end;
      padding: 50px 40px 50px 50px; }
      .careersScene > .careersScene-top > .red-stripe > img {
        height: 100%; }
      .careersScene > .careersScene-top > .red-stripe > p {
        color: #ffffff;
        text-transform: uppercase;
        font-size: 85px;
        font-weight: 400;
        font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        line-height: 1;
        margin-bottom: -5px;
        margin-left: 15px;
        letter-spacing: 3px; }
  .careersScene .body {
    padding: 30px; }
    .careersScene .body > ul {
      padding: 0;
      list-style: none; }
      .careersScene .body > ul > li {
        border-bottom: 1px solid #c5c3c6;
        margin-bottom: 30px; }
        .careersScene .body > ul > li > .buttonWrapper {
          width: 240px;
          margin-bottom: 30px; }

@media (max-width: 1024px) {
  .careersScene > .careersScene-top {
    height: 400px; }
    .careersScene > .careersScene-top > .red-stripe > p {
      font-size: 70px; } }

@media (max-width: 768px) {
  .careersScene > .careersScene-top {
    height: 300px; }
    .careersScene > .careersScene-top > .red-stripe {
      padding: 30px 25px 30px 30px; }
      .careersScene > .careersScene-top > .red-stripe > p {
        font-size: 65px; } }

@media (max-width: 575px) {
  .careersScene > .careersScene-top {
    height: 200px; }
    .careersScene > .careersScene-top > .red-stripe {
      padding: 15px 10px 15px 15px; }
      .careersScene > .careersScene-top > .red-stripe > p {
        font-size: 50px; } }

@media (max-width: 420px) {
  .careersScene > .careersScene-top > .red-stripe > p {
    font-size: 38px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.homeContactConfirmation {
  height: 593px;
  width: 80%;
  max-width: 80%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  position: relative; }
  .homeContactConfirmation > #home-contact-scroll-to {
    position: absolute;
    top: -78px; }
  .homeContactConfirmation > .confirmation-body {
    background-color: #ec1c24;
    height: 100%;
    width: 100%;
    color: #ffffff;
    padding: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px; }
  .homeContactConfirmation.withShadow {
    box-shadow: 0px 2px 5px #00000050; }

@media (max-width: 1024px) {
  .homeContactConfirmation {
    width: 100%;
    max-width: 100%;
    height: 450px; } }

@media (max-width: 768px) {
  .homeContactConfirmation {
    height: 400px; } }

@media (max-width: 575px) {
  .homeContactConfirmation {
    height: auto;
    padding: 15px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.aTSceneTop {
  background-image: url("../field-grass-green-night-SMALLER-crop-4.jpg");
  background-repeat: no-repeat;
  background-size: cover; }
  .aTSceneTop .text-panel {
    width: 55%;
    background-color: #ec1c24;
    padding: 75px 30px;
    display: flex;
    flex-wrap: wrap; }
    .aTSceneTop .text-panel *:not(last-child) {
      margin-bottom: 20px; }
    .aTSceneTop .text-panel .text {
      font-size: 70px;
      text-transform: uppercase;
      font-weight: 600;
      color: #ffffff;
      font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 1;
      width: 100%;
      letter-spacing: 2px; }
    .aTSceneTop .text-panel .text:first-child {
      color: #33cccc; }
    .aTSceneTop .text-panel .divider {
      width: 100%;
      display: flex;
      padding: 15px 0;
      align-items: center; }
    .aTSceneTop .text-panel .divider::before {
      content: "";
      background-color: #33cccc;
      height: 8px;
      width: 70px; }
    .aTSceneTop .text-panel img {
      max-width: 46%;
      width: 100%; }

@media (max-width: 1024px) {
  .aTSceneTop {
    padding-top: 30px; }
    .aTSceneTop .text-panel {
      width: 100%; }
      .aTSceneTop .text-panel img {
        max-width: 80%; } }

@media (max-width: 768px) {
  .aTSceneTop .text-panel img {
    max-width: 80%; } }

@media (max-width: 575px) {
  .aTSceneTop .text-panel .text {
    font-size: 47px; }
  .aTSceneTop .text-panel img {
    max-width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.aTSceneSubTopSection {
  background-color: #ffffff; }
  .aTSceneSubTopSection .top-left {
    width: 100%;
    color: #454e56;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    line-height: 27px;
    padding: 25px 25px 0px;
    font-weight: 400;
    text-align: justify; }
    .aTSceneSubTopSection .top-left span {
      font-weight: 700;
      text-transform: uppercase;
      color: #ec1c24; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.aTSceneSubTopSection {
  background-color: #ffffff; }
  .aTSceneSubTopSection .top-left {
    width: 100%;
    color: #454e56;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    line-height: 27px;
    padding: 25px 25px 0px;
    font-weight: 400;
    text-align: justify; }
    .aTSceneSubTopSection .top-left span {
      font-weight: 700;
      text-transform: uppercase;
      color: #ec1c24; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.aTReviews {
  background-color: #e4e4e4;
  display: flex; }
  .aTReviews > .phone-background {
    width: 33%;
    text-align: center;
    align-self: flex-end;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    height: 400px; }
    .aTReviews > .phone-background > img.phone-img {
      height: auto;
      position: relative;
      top: 35px;
      max-width: calc(100% - 40px);
      width: 300px; }
  .aTReviews > .reviews {
    width: 67%;
    padding: 30px;
    color: #54595f;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    display: inline-block;
    vertical-align: top; }
    .aTReviews > .reviews p {
      margin-top: 8px;
      font-size: 21px;
      text-align: end;
      color: #454e56;
      font-weight: 500; }
    .aTReviews > .reviews p.reviewer {
      color: #33cccc; }
      .aTReviews > .reviews p.reviewer span {
        color: #ec1c24; }
    .aTReviews > .reviews .reviews-source {
      color: #ffffff;
      font-size: 15px; }

@media (max-width: 856px) {
  .aTReviews > .phone-background {
    height: 460px; } }

@media (max-width: 768px) {
  .aTReviews {
    flex-direction: column; }
    .aTReviews > .phone-background {
      width: 100%;
      height: 400px; }
    .aTReviews > .reviews {
      width: 100%;
      display: block;
      height: auto;
      padding-bottom: 0; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.aTBullits {
  background-color: #f2f4f3;
  display: flex; }
  .aTBullits .bullit {
    width: 33%;
    padding: 30px; }
    .aTBullits .bullit p {
      color: #454e56;
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .aTBullits .bullit .header {
      font-size: 40px;
      font-weight: 900;
      line-height: 1; }
    .aTBullits .bullit .divider {
      width: 40px;
      height: 5px;
      background-color: #454e56;
      margin-bottom: 16px; }

@media (max-width: 575px) {
  .aTBullits {
    flex-direction: column; }
    .aTBullits .bullit {
      width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.rNBullits {
  background-color: #f2f4f3;
  display: flex; }
  .rNBullits .bullit {
    width: 50%;
    padding: 30px; }
    .rNBullits .bullit p {
      color: #454e56;
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .rNBullits .bullit .header {
      font-size: 40px;
      font-weight: 900;
      line-height: 1; }
    .rNBullits .bullit .divider {
      width: 40px;
      height: 5px;
      background-color: #454e56;
      margin-bottom: 16px; }

@media (max-width: 575px) {
  .aTBullits {
    flex-direction: column; }
    .aTBullits .bullit {
      width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.howItWorks {
  background-color: #e6e6e6;
  padding: 48px 0 20px;
  position: relative; }
  .howItWorks > #how-it-works {
    position: absolute;
    top: -60px; }
  .howItWorks > .grouping-container {
    padding: 22px 38px 0px 38px; }
    .howItWorks > .grouping-container > .grouping {
      width: 25%;
      text-align: center;
      color: #707070;
      font-family: "Lato", Sans-serif;
      padding: 30px;
      display: inline-flex;
      vertical-align: top;
      flex-direction: column;
      align-items: center; }
      .howItWorks > .grouping-container > .grouping .img-wrapper {
        width: 60%;
        max-width: 100%;
        height: 170px; }
        .howItWorks > .grouping-container > .grouping .img-wrapper img {
          width: 100%;
          height: 75%; }
      .howItWorks > .grouping-container > .grouping.png img {
        width: 50%;
        max-width: 100%;
        height: auto;
        margin-bottom: 30px; }
      .howItWorks > .grouping-container > .grouping span {
        display: block;
        border-top: 3px solid #fc363b;
        width: 15px;
        margin: 0 auto 15px; }
      .howItWorks > .grouping-container > .grouping h3 {
        font-size: 21px;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 30px;
        font-family: "Lato", Sans-serif; }
      .howItWorks > .grouping-container > .grouping p {
        font-size: 17px;
        line-height: 25px; }

@media (max-width: 1024px) {
  .howItWorks > .grouping-container {
    padding: 10px; }
    .howItWorks > .grouping-container > .grouping {
      width: 50%;
      padding: 20px; }
      .howItWorks > .grouping-container > .grouping .img-wrapper {
        width: 40%; } }

@media (max-width: 590px) {
  .howItWorks > .grouping-container > .grouping {
    width: 100%;
    padding: 10px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.howItWorks {
  background-color: #e6e6e6;
  padding: 48px 0 20px;
  position: relative; }
  .howItWorks > #how-it-works {
    position: absolute;
    top: -60px; }
  .howItWorks > .grouping-container {
    padding: 22px 38px 0px 38px; }
    .howItWorks > .grouping-container > .grouping {
      width: 25%;
      text-align: center;
      color: #707070;
      font-family: "Lato", Sans-serif;
      padding: 30px;
      display: inline-flex;
      vertical-align: top;
      flex-direction: column;
      align-items: center; }
      .howItWorks > .grouping-container > .grouping .img-wrapper {
        width: 60%;
        max-width: 100%;
        height: 170px; }
        .howItWorks > .grouping-container > .grouping .img-wrapper img {
          width: 100%;
          height: 75%; }
      .howItWorks > .grouping-container > .grouping.png img {
        width: 50%;
        max-width: 100%;
        height: auto;
        margin-bottom: 30px; }
      .howItWorks > .grouping-container > .grouping span {
        display: block;
        border-top: 3px solid #fc363b;
        width: 15px;
        margin: 0 auto 15px; }
      .howItWorks > .grouping-container > .grouping h3 {
        font-size: 21px;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 30px;
        font-family: "Lato", Sans-serif; }
      .howItWorks > .grouping-container > .grouping p {
        font-size: 17px;
        line-height: 25px; }

@media (max-width: 1024px) {
  .howItWorks > .grouping-container {
    padding: 10px; }
    .howItWorks > .grouping-container > .grouping {
      width: 50%;
      padding: 20px; }
      .howItWorks > .grouping-container > .grouping .img-wrapper {
        width: 40%; } }

@media (max-width: 590px) {
  .howItWorks > .grouping-container > .grouping {
    width: 100%;
    padding: 10px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.stateScene {
  padding-top: 78px; }
  .stateScene .topSection {
    background-image: url(../scenes/State/phone-header-bg.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px;
    height: 466px;
    overflow: visible; }
    .stateScene .topSection .innerContainer {
      display: flex; }
      .stateScene .topSection .innerContainer .topLeft {
        padding: 75px 30px 14px 30px;
        background-color: #ec1c24;
        color: #ffffff;
        font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        text-transform: uppercase;
        line-height: 75px;
        width: 55%;
        position: relative; }
        .stateScene .topSection .innerContainer .topLeft p.heading {
          font-size: 65px;
          margin-bottom: 20px;
          line-height: 75px;
          font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
        .stateScene .topSection .innerContainer .topLeft .divider {
          background-color: #ffffff;
          width: 44px;
          height: 6px;
          margin-bottom: 20px; }
        .stateScene .topSection .innerContainer .topLeft p {
          font-size: 50px;
          line-height: 65px;
          letter-spacing: 3px; }
        .stateScene .topSection .innerContainer .topLeft .logo {
          position: absolute;
          top: -50px;
          left: 30px;
          background-color: #ffffff;
          height: 100px;
          width: 200px;
          padding-top: 24px; }
          .stateScene .topSection .innerContainer .topLeft .logo img {
            width: 100%; }
        .stateScene .topSection .innerContainer .topLeft.largeLogo {
          padding-top: 110px; }
          .stateScene .topSection .innerContainer .topLeft.largeLogo .logo {
            height: 150px; }
      .stateScene .topSection .innerContainer .bottomRight {
        width: 45%;
        margin-left: 40px;
        position: relative; }
  .stateScene .bottomSection {
    background-color: #ffffff;
    padding: 50px 50px 0; }
    .stateScene .bottomSection .innerContainer {
      width: 53%; }
      .stateScene .bottomSection .innerContainer .banner {
        background-color: #c7c7c7;
        display: flex; }
        .stateScene .bottomSection .innerContainer .banner .orgText {
          text-align: center;
          width: 154px;
          align-self: center;
          font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 14px;
          font-weight: 300;
          color: #5c5c5c;
          margin: 0;
          line-height: 1; }
        .stateScene .bottomSection .innerContainer .banner .logoBox {
          padding: 0 30px;
          background-color: #ec1c24; }
      .stateScene .bottomSection .innerContainer .description {
        text-align: justify;
        color: #6d7680;
        padding: 20px 0;
        font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 15px;
        font-weight: 300;
        margin: 0; }
        .stateScene .bottomSection .innerContainer .description strong {
          color: #414141;
          font-weight: 700; }
          .stateScene .bottomSection .innerContainer .description strong .red {
            color: #ec1c24; }
      .stateScene .bottomSection .innerContainer .divider {
        width: 13px;
        height: 2px;
        background-color: #6d7680;
        margin-bottom: 12px; }
      .stateScene .bottomSection .innerContainer .bullit {
        color: #525252;
        padding: 6px 0;
        font-size: 16px;
        font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
        .stateScene .bottomSection .innerContainer .bullit .red {
          color: #ec1c24;
          margin-right: 5px; }
  .stateScene .contactUsBar {
    background-color: #33cccc;
    display: flex;
    align-items: center;
    padding: 14px 45px; }
    .stateScene .contactUsBar p {
      color: #ffffff;
      width: 50%;
      text-align: right;
      margin: 0;
      padding-right: 14px; }

@media (max-width: 768px) {
  .stateScene .topSection {
    height: auto;
    padding: 30px; }
    .stateScene .topSection .innerContainer {
      flex-direction: column; }
      .stateScene .topSection .innerContainer .topLeft {
        width: 100%; }
      .stateScene .topSection .innerContainer .bottomRight {
        margin: 45px 0 0 0;
        width: 100%; }
  .stateScene .bottomSection {
    padding: 30px 30px 0; }
    .stateScene .bottomSection .innerContainer {
      width: 100%; }
      .stateScene .bottomSection .innerContainer .banner {
        flex-direction: column; }
        .stateScene .bottomSection .innerContainer .banner .orgText {
          width: auto;
          margin: 10px 0; }
        .stateScene .bottomSection .innerContainer .banner .logoBox {
          text-align: center;
          padding: 0; }
  .stateScene .contactUsBar {
    flex-direction: column;
    padding: 14px 0px; }
    .stateScene .contactUsBar p {
      width: 100%;
      text-align: center;
      margin: 0 0 14px;
      padding: 0; } }

@media (max-width: 420px) {
  .stateScene .topSection {
    padding: 30px 10px; }
    .stateScene .topSection .innerContainer .topLeft {
      width: 100%; }
      .stateScene .topSection .innerContainer .topLeft p.heading {
        font-size: 43px;
        line-height: 50px; }
      .stateScene .topSection .innerContainer .topLeft p {
        font-size: 37px;
        letter-spacing: 2px; } }

@media (max-width: 300px) {
  .stateScene .topSection .innerContainer .topLeft {
    padding: 75px 15px 14px; }
    .stateScene .topSection .innerContainer .topLeft .logo {
      left: calc(50% - 100px); } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.stateContactForm {
  position: absolute; }
  .stateContactForm > .body {
    background-color: #c7c7c7;
    padding: 26px 14px 0px;
    text-transform: uppercase;
    box-shadow: 0px 9px 17px -6px rgba(0, 0, 0, 0.5); }
    .stateContactForm > .body > .date-picker > span {
      color: #454e56;
      font-size: 12px;
      font-weight: 900;
      font-family: "Lato"; }
    .stateContactForm > .body input,
    .stateContactForm > .body textarea {
      margin-bottom: 18px;
      background-color: #fff;
      border: 2px solid #525252;
      border-radius: 3px;
      padding: 10px 15px;
      font-size: 16px;
      font-family: "Lato";
      line-height: 21px;
      font-weight: 400;
      width: 100%;
      margin-bottom: 15px;
      color: #b4b4b4; }
      .stateContactForm > .body input::placeholder,
      .stateContactForm > .body textarea::placeholder {
        text-transform: uppercase !important;
        color: #dedee5; }
      .stateContactForm > .body input:focus,
      .stateContactForm > .body textarea:focus {
        outline: none; }
      .stateContactForm > .body input.error,
      .stateContactForm > .body textarea.error {
        border-color: #e03738; }
    .stateContactForm > .body textarea {
      height: 225px; }
    .stateContactForm > .body button {
      background-color: #ec1c24;
      cursor: pointer;
      font-style: italic;
      text-transform: uppercase;
      font-size: 21px;
      width: 100%;
      padding: 10px 20px;
      font-weight: 500;
      border-radius: 6px;
      border: none;
      font-family: "Evogria", Sans-serif;
      color: #ffffff;
      margin-bottom: 18px; }
      .stateContactForm > .body button:hover {
        color: #aab8be; }
      .stateContactForm > .body button:focus {
        outline: none; }
    .stateContactForm > .body.withPaddingBottom {
      padding-bottom: 26px; }

@media (max-width: 768px) {
  .stateContactForm {
    position: relative; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.indentRowWithIcon .redTab {
  background-color: #ec1c24;
  width: 100px;
  padding: 10px;
  text-align: right; }
  .indentRowWithIcon .redTab img {
    height: 40px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.jobsStateScene {
  padding-top: 78px;
  background-color: #ffffff; }
  .jobsStateScene .body {
    padding: 10px 30px 30px; }
    .jobsStateScene .body p.details {
      margin: 0;
      font-weight: 600;
      font-size: 16px; }
      .jobsStateScene .body p.details span {
        text-transform: uppercase; }
    .jobsStateScene .body .detailsDivider {
      margin: 20px 0;
      background-color: #212529;
      height: 4px;
      width: 28px; }
    .jobsStateScene .body p.description {
      margin: 0;
      font-size: 15px;
      color: #373636; }
    .jobsStateScene .body h4 {
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 20px;
      text-transform: uppercase; }
    .jobsStateScene .body .lists .list strong {
      font-size: 16px; }
    .jobsStateScene .body .lists .list ul {
      padding-left: 18px; }
      .jobsStateScene .body .lists .list ul li {
        font-size: 14px; }

@media (max-width: 575px) {
  .jobsStateScene .body {
    padding: 10px 15px 30px; } }

.downloadIcons {
  display: inline-flex;
  padding: 0 20px;
  width: 330px;
  max-width: 100%;
  align-items: center;
  margin-left: -9px;
  margin-top: -8px;
  margin-bottom: 12px; }
  .downloadIcons > a {
    width: 50%;
    cursor: pointer; }
    .downloadIcons > a > img {
      width: 100%; }
  .downloadIcons.jobState {
    margin-left: -34px;
    margin-top: 10px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

h4.currentJobsHeader {
  padding-top: 1rem;
  border-top: 1px dashed #212529;
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 20px;
  text-transform: uppercase; }

.jobs {
  padding: 20px 0;
  border-bottom: 1px dashed #212529; }
  .jobs li {
    list-style: none; }
    .jobs li p {
      color: #212529;
      margin: 0;
      line-height: 1.5; }
      .jobs li p span {
        text-transform: uppercase; }
    .jobs li .divider {
      margin: 20px 0;
      background-color: #33cccc;
      height: 4px;
      width: 28px; }
    .jobs li:last-child .divider {
      display: none; }

.downloadInstructions {
  font-size: 14px;
  margin-bottom: -10px; }
  .downloadInstructions span {
    text-transform: uppercase; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.stateJobLinkList {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid #dcdcdd; }
  .stateJobLinkList .states {
    margin-top: 35px; }
    .stateJobLinkList .states .stateName {
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-transform: uppercase;
      font-size: 1.2rem; }
    .stateJobLinkList .states > ul {
      list-style: none;
      padding: 0 20px 20px;
      display: flex;
      flex-wrap: wrap; }
      .stateJobLinkList .states > ul > li {
        width: 25%; }
        .stateJobLinkList .states > ul > li > a {
          text-decoration: underline;
          color: #33cccc; }

@media (max-width: 768px) {
  .stateJobLinkList {
    border-color: #454e56; }
    .stateJobLinkList .states {
      display: none; }
      .stateJobLinkList .states > ul > li {
        width: 33%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

strong.go4EllisText {
  color: #414141;
  font-weight: 700;
  font-size: 20px; }
  strong.go4EllisText .red {
    color: #ec1c24; }
  strong.go4EllisText.larger {
    font-size: 1.75rem; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

h3.headerText {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #33cccc;
  text-transform: uppercase;
  font-weight: 600; }
  h3.headerText.withBorderTop {
    border-top: 1px solid #dcdcdd; }
  h3.headerText > span {
    color: #414141; }
  h3.headerText > span.teal {
    color: #33cccc; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.singleJob {
  position: relative;
  padding: 50px 30px 30px; }
  .singleJob > ul.bullits {
    padding-left: 20px;
    padding-bottom: 20px;
    list-style: none; }
    .singleJob > ul.bullits > .heading {
      margin-top: 20px;
      margin-bottom: 0.5rem; }
      .singleJob > ul.bullits > .heading b {
        text-decoration: underline;
        font-size: 16px; }
    .singleJob > ul.bullits > li {
      color: #373636;
      font-size: 16px;
      margin-bottom: 0.5rem; }
  .singleJob > .buttonWrapper {
    width: 240px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.jobDetails > h2 {
  color: #33cccc;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 0.3rem; }

.jobDetails > p {
  font-size: 16px;
  margin-bottom: 1.5rem;
  color: #373636; }
  .jobDetails > p > strong > span {
    padding: 0 5px;
    border-right: 2px solid #373636; }
    .jobDetails > p > strong > span:first-child {
      padding-left: 0; }
    .jobDetails > p > strong > span:last-child {
      border-right: none; }
  .jobDetails > p.details {
    font-size: 18px;
    line-height: 20px;
    margin: 0; }
    .jobDetails > p.details.single > strong > span {
      display: block;
      padding: 0;
      border: none;
      line-height: 1.4; }

.jobDetails > .divider {
  width: 35px;
  height: 4px;
  background-color: #373636;
  margin: 1.8rem 0 1.75rem; }

@media (max-width: 660px) {
  .jobDetails {
    margin-top: 35px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.applyForm {
  display: flex;
  flex-direction: column; }
  .applyForm .applySubmitButton {
    background-color: #ec1c24;
    margin-bottom: 20px;
    font-style: italic;
    text-transform: uppercase;
    font-size: 21px;
    padding: 5px 25px;
    font-weight: 500;
    width: 200px;
    border-radius: 6px;
    border: none;
    font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #ffffff;
    transition: all 0.3s; }
    .applyForm .applySubmitButton:hover {
      background-color: #454e56;
      text-decoration: none;
      color: #ffffff; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.applyFormInput {
  border: 0.75px solid #373636;
  width: 80%;
  max-width: 450px;
  height: 35px;
  padding: 5px 10px; }
  .applyFormInput.error {
    border-color: #ec1c24; }

@media (max-width: 575px) {
  .applyFormInput {
    width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.uploadResume {
  display: flex;
  align-items: center; }
  .uploadResume button {
    border: none;
    font-size: 15px;
    padding: 5px 25px;
    border-radius: 5px;
    margin-right: 15px;
    color: white;
    font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    letter-spacing: 1px;
    background-color: #454e56;
    font-style: italic;
    text-transform: uppercase;
    font-weight: 900;
    transition: 0.5s background-color; }
    .uploadResume button:hover {
      background-color: #ec1c24;
      box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.5); }
  .uploadResume span {
    color: #aab8be; }
    .uploadResume span.withFile {
      color: #373636; }
  .uploadResume input {
    display: none; }
  .uploadResume.error button {
    border: 1px solid #ec1c24; }
  .uploadResume.error span {
    color: #ec1c24; }

.inputLabel {
  font-size: 14px;
  font-weight: 700; }

.inputWrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.applyTextarea {
  border: 0.75px solid #373636;
  width: 80%;
  max-width: 450px;
  height: 350px;
  padding: 5px 10px; }
  .applyTextarea.error {
    border-color: #ec1c24; }

@media (max-width: 575px) {
  .applyTextarea {
    width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.formErrorMessage {
  color: #e03738;
  text-transform: none;
  margin-bottom: 18px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.backToListButton {
  position: absolute;
  right: 30px; }
  .backToListButton a {
    color: #ec1c24;
    font-size: 20px;
    line-height: 23px;
    text-decoration: underline; }
    .backToListButton a:hover {
      color: #373636; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.mobileStateCityList {
  display: none;
  margin-top: 30px;
  border-top: 1px solid #dcdcdd; }
  .mobileStateCityList .stateExpansionPanel {
    margin-top: 15px;
    border-bottom: 1px solid #dcdcdd; }
    .mobileStateCityList .stateExpansionPanel .panelTop {
      display: flex;
      justify-content: space-between;
      cursor: pointer; }
      .mobileStateCityList .stateExpansionPanel .panelTop .stateName {
        font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        text-transform: uppercase;
        color: #454e56;
        font-size: 1.2rem; }
    .mobileStateCityList .stateExpansionPanel > ul {
      max-height: 0;
      transform: scaleY(0);
      transition-property: max-height, transform, height;
      transform-origin: top;
      transition: 0.1s;
      list-style: none;
      padding: 0 20px 20px; }
      .mobileStateCityList .stateExpansionPanel > ul.open {
        max-height: none;
        transform: scaleY(1);
        padding-bottom: 0; }
      .mobileStateCityList .stateExpansionPanel > ul > li {
        width: 100%;
        margin-bottom: 10px; }
        .mobileStateCityList .stateExpansionPanel > ul > li > a {
          text-decoration: underline;
          color: #33cccc; }

@media (max-width: 768px) {
  .mobileStateCityList {
    display: block; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.industrial {
  padding-top: 78px; }
  .industrial .top-section {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../industrial-landing-page-image.png"); }
    .industrial .top-section .top-section-text {
      color: #33cccc;
      font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 42px;
      font-weight: normal;
      word-spacing: 1.5px;
      letter-spacing: 2px;
      line-height: 1.5;
      margin-bottom: 0; }
      .industrial .top-section .top-section-text.highlighted {
        color: #ffffff; }
  .industrial > .settings {
    background-color: #ffffff;
    padding: 48px 0 23px; }
  .industrial > .who-uses {
    background-color: #454e56;
    padding: 48px 0 23px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.commonTopSection {
  display: flex; }
  .commonTopSection > .top-sub-section {
    width: 50%;
    color: #ffffff;
    align-self: center; }
  .commonTopSection > .left-top > .nata-logo-container {
    background-color: #006198;
    padding-top: 15px;
    width: 165px;
    margin: 50px 0; }
    .commonTopSection > .left-top > .nata-logo-container > p {
      font-size: 12px;
      margin-bottom: -10px;
      margin-left: 20px; }
    .commonTopSection > .left-top > .nata-logo-container > .nata-img {
      height: 85px; }
  .commonTopSection > .left-top > .text-container {
    background-color: #fc363b90;
    padding: 42px 50px;
    width: 100%;
    display: flex;
    justify-content: center; }
    .commonTopSection > .left-top > .text-container.darker {
      background-color: rgba(252, 54, 59, 0.75); }
    .commonTopSection > .left-top > .text-container.transparent {
      background-color: transparent; }
  .commonTopSection > .right-bottom {
    margin: 0;
    padding-right: 45px; }

@media (max-width: 1024px) {
  .commonTopSection {
    flex-direction: column; }
    .commonTopSection > .top-sub-section {
      width: 100%; }
    .commonTopSection > .right-bottom {
      padding: 0 10px; }
    .commonTopSection > .left-top > .text-container {
      display: none; }
      .commonTopSection > .left-top > .text-container > .go4ellis-img {
        display: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.industrialContactForm {
  height: 100%;
  width: 80%;
  max-width: 80%;
  background: white;
  text-transform: uppercase; }
  .industrialContactForm > .header {
    background-color: #ec1c24;
    padding: 10px 8px;
    word-spacing: 1.5px;
    text-align: center;
    color: white;
    font-size: 24px;
    font-family: "Evogria", Sans-serif; }
  .industrialContactForm > .body {
    background-color: #f2f2f2;
    padding: 15px 40px;
    box-shadow: 0px 9px 17px -6px rgba(0, 0, 0, 0.5); }
    .industrialContactForm > .body > .date-picker > span {
      color: #454e56;
      font-size: 12px;
      font-weight: 900;
      font-family: "Lato"; }
    .industrialContactForm > .body input,
    .industrialContactForm > .body textarea {
      border: solid 1px #666666;
      background-color: white;
      border-radius: 3px;
      padding: 10px 15px;
      font-size: 16px;
      font-family: "Lato";
      line-height: 21px;
      font-weight: 400;
      width: 100%;
      margin-bottom: 15px;
      color: #b4b4b4; }
      .industrialContactForm > .body input::placeholder,
      .industrialContactForm > .body textarea::placeholder {
        text-transform: uppercase !important;
        color: #dedee5; }
      .industrialContactForm > .body input:focus,
      .industrialContactForm > .body textarea:focus {
        outline: none; }
      .industrialContactForm > .body input.error,
      .industrialContactForm > .body textarea.error {
        border-color: #e03738; }
    .industrialContactForm > .body textarea {
      height: 100px; }
    .industrialContactForm > .body button {
      background-color: #ec1c24;
      cursor: pointer;
      font-style: italic;
      text-transform: uppercase;
      font-size: 21px;
      width: 100%;
      padding: 10px 20px;
      font-weight: 500;
      border-radius: 6px;
      border: none;
      font-family: "Evogria", Sans-serif;
      color: #ffffff;
      margin-bottom: 18px; }
      .industrialContactForm > .body button:hover {
        color: #aab8be; }
      .industrialContactForm > .body button:focus {
        outline: none; }
    .industrialContactForm > .body .error-message {
      color: #e03738;
      text-transform: none;
      margin-bottom: 18px; }

@media (max-width: 1024px) {
  .industrialContactForm {
    width: 100%;
    max-width: 100%; } }

.landingUnderTop {
  font-family: "Lato", Sans-serif;
  line-height: 25px;
  color: #454e56;
  font-weight: 400;
  font-size: 16px;
  background-color: #e6e6e6;
  padding: 28px 40px 25px;
  text-align: justify; }
  .landingUnderTop .highlight-text {
    font-weight: 700;
    text-transform: uppercase;
    color: #ec1c24; }
  .landingUnderTop strong {
    font-weight: 700; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.HPBanner {
  background-color: #e6e6e6;
  padding: 40px;
  font-size: 24px;
  color: #454e56;
  letter-spacing: 0.46px;
  text-align: center; }
  .HPBanner .Stressed {
    color: #f33;
    display: block; }

@media (max-width: 768px) {
  .HPBanner .Stressed {
    display: inline-block; }
    .HPBanner .Stressed span {
      display: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.nata-definition {
  background-color: white;
  padding: 50px 0; }
  .nata-definition > .nata-definition-inner {
    background-color: #33cccc;
    padding: 40px 20px;
    font-family: "Lato", Sans-serif;
    color: #454e56;
    font-size: 18px;
    line-height: 27px;
    font-weight: 400; }
    .nata-definition > .nata-definition-inner > .text-wrapper {
      width: 100%;
      text-align: justify; }
      .nata-definition > .nata-definition-inner > .text-wrapper > span {
        color: #ec1c24;
        text-transform: uppercase;
        font-size: 24px;
        font-weight: bold; }

@media (max-width: 1024px) {
  .nata-definition > .nata-definition-inner > .text-wrapper {
    width: 66.6%; } }

@media (max-width: 800px) {
  .nata-definition > .nata-definition-inner > .text-wrapper {
    width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.scheduleADemo {
  padding: 25px 10px 50px;
  background-color: white; }
  .scheduleADemo > .inner {
    background-color: #33cccc;
    color: #454e56;
    font-size: 20px;
    line-height: 23px;
    padding: 25px;
    text-align: center;
    font-weight: 900;
    font-family: "Lato", Sans-serif;
    box-shadow: 0px 5px 13px -7px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center; }
    .scheduleADemo > .inner > a {
      width: 300px !important; }
  .scheduleADemo.altStyle {
    padding: 0; }
    .scheduleADemo.altStyle > .inner {
      background-color: #ffffff;
      box-shadow: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.reasonsToHire {
  background-color: #e6e6e6;
  padding: 48px 0 23px; }
  .reasonsToHire > .inner {
    display: flex;
    padding: 0 20px;
    flex-wrap: wrap; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.reason {
  padding: 10px 25px;
  width: 20%; }
  .reason .top {
    border-bottom: 2px solid #677379;
    padding-bottom: 12px; }
    .reason .top p {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: bottom;
      color: #677379;
      font-size: 30px;
      line-height: 0.8;
      margin-left: 6px; }
  .reason .bottom {
    padding-top: 10px; }
    .reason .bottom strong {
      color: #ec1c24; }

@media (max-width: 1024px) {
  .reason {
    width: 33%; } }

@media (max-width: 575px) {
  .reason {
    width: 50%; } }

@media (max-width: 420px) {
  .reason {
    width: 100%; } }

.aTSkillset {
  background-color: #ffffff;
  padding: 30px 0; }
  .aTSkillset .header {
    text-align: center;
    font-size: 26px;
    font-weight: 600; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.hippaStatement {
  padding: 25px 0;
  margin: 0 25px;
  border-top: dashed 1px #aab8be;
  border-bottom: dashed 1px #aab8be;
  display: flex; }
  .hippaStatement .top-left {
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #ec1c24;
    color: #33cccc;
    font-size: 48px;
    margin-left: -25px;
    margin-right: 20px;
    min-width: 250px;
    font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 900;
    text-transform: uppercase; }
  .hippaStatement.alt {
    border-width: 2px;
    margin: 0; }
    .hippaStatement.alt .bottom-right {
      color: #454e56; }

@media (max-width: 768px) {
  .hippaStatement {
    flex-direction: column; }
    .hippaStatement .top-left {
      align-self: center;
      padding-right: 0px;
      justify-content: center;
      font-size: 36px;
      margin: 0 0 20px;
      min-width: none;
      max-width: 250px;
      width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.go4Tab {
  background-color: #ffffff;
  width: 100%;
  max-width: 220px;
  height: 64px;
  margin: 50px 0;
  display: flex; }
  .go4Tab img {
    margin: 0 auto; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.emrScene {
  padding-top: 78px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eMRBanner {
  display: flex; }
  .eMRBanner .top-left {
    min-height: 193px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #33cccc;
    min-width: 221px; }
    .eMRBanner .top-left img {
      height: 137px;
      width: 153px; }
  .eMRBanner .bottom-right {
    background-color: #454e56;
    flex: 1;
    padding: 18px 24px; }
    .eMRBanner .bottom-right h4 {
      font-size: 24px;
      font-weight: normal;
      color: #33cccc;
      display: inline;
      margin-right: 10px;
      text-transform: capitalize; }
      .eMRBanner .bottom-right h4 span {
        text-transform: uppercase; }
    .eMRBanner .bottom-right p {
      color: #aab8be;
      font-size: 17px; }
      .eMRBanner .bottom-right p.home-text {
        display: inline; }
      .eMRBanner .bottom-right p span {
        text-transform: uppercase; }
  .eMRBanner.home .top-left {
    min-height: 147px; }
  .eMRBanner.home .bottom-right {
    background-color: #454e56;
    flex: 1;
    padding: 18px 24px; }
    .eMRBanner.home .bottom-right h4 {
      font-size: 20px; }

@media (max-width: 768px) {
  .eMRBanner {
    flex-direction: column; }
    .eMRBanner .top-left {
      padding: 15px 0; }
    .eMRBanner .bottom-right {
      display: flex;
      flex-direction: column; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.emrSubHeader {
  padding: 28px 40px 25px; }
  .emrSubHeader p {
    color: #677379;
    font-size: 17px;
    padding-bottom: 1rem;
    border-bottom: 1px dashed #677379; }
    .emrSubHeader p b .red {
      color: #ec1c24; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eMRInAction {
  display: flex;
  background-color: #cfcfcf;
  justify-content: center; }
  .eMRInAction .top-left {
    padding: 28px 28px 25px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    text-align: right; }
    .eMRInAction .top-left img {
      margin-bottom: 10px; }
    .eMRInAction .top-left h3 {
      font-size: 36px;
      color: #ec1c24; }
    .eMRInAction .top-left p {
      color: #677379;
      margin-bottom: 0;
      max-width: 300px; }
  .eMRInAction .bottom-right {
    padding: 25px 0;
    display: flex;
    flex: 1;
    justify-content: center;
    max-width: 550px; }

@media (max-width: 768px) {
  .eMRInAction {
    flex-direction: column; }
    .eMRInAction .top-left {
      align-items: flex-start;
      text-align: left; }
    .eMRInAction .bottom-right {
      padding: 25px;
      max-width: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.evaluationsToGo {
  border-top: 30px solid #33cccc;
  border-bottom: 30px solid #33cccc;
  padding: 28px;
  text-align: right;
  display: flex; }
  .evaluationsToGo .text h4 {
    font-size: 40px;
    color: #d0021b;
    letter-spacing: 0.77px;
    text-align: right; }
  .evaluationsToGo .text p {
    font-family: Helvetica;
    font-size: 17px;
    color: #454e56;
    letter-spacing: 0.33px;
    text-align: right;
    line-height: 23px;
    margin: 0; }
  .evaluationsToGo img {
    height: 120px;
    width: 141px; }

@media (max-width: 575px) {
  .evaluationsToGo {
    flex-direction: column-reverse; }
    .evaluationsToGo img {
      align-self: flex-end; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.hippaCompliant {
  margin: 30px 25px;
  border-bottom: 1px dashed #454e56;
  border-top: 1px dashed #454e56;
  display: flex;
  padding: 15px 0;
  align-items: center; }
  .hippaCompliant .left {
    background-color: #454e56;
    height: 65px;
    display: flex;
    align-items: center; }
    .hippaCompliant .left img {
      height: 35px;
      width: 67px; }
    .hippaCompliant .left h2 {
      color: #33cccc;
      font-size: 36px;
      letter-spacing: 0.69px;
      text-align: justify;
      padding: 0 0 0 10px;
      white-space: nowrap;
      margin-bottom: 0; }
  .hippaCompliant .right {
    font-size: 17px;
    color: #454e56;
    letter-spacing: 0.33px;
    margin-left: 20px; }

@media (max-width: 768px) {
  .hippaCompliant {
    flex-direction: column; }
    .hippaCompliant .left {
      margin-bottom: 15px; }
    .hippaCompliant .left,
    .hippaCompliant .left h2 {
      text-align: center;
      width: 100%; } }

@media (max-width: 575px) {
  .hippaCompliant .left img {
    display: none; } }

@media (max-width: 420px) {
  .hippaCompliant .left h2 {
    font-size: 26px;
    padding: 0 10px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.aTSOnly {
  background-color: #d8d8d8;
  padding: 15px 0; }
  .aTSOnly .top {
    background-color: #ec1c24;
    color: #ffffff;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .aTSOnly .top .white-rect {
      height: 40px;
      flex: 1;
      background-color: #ffffff; }
    .aTSOnly .top h2 {
      font-size: 48px;
      color: #ffffff;
      letter-spacing: 1.42px;
      text-align: center;
      margin: 0 15px;
      white-space: nowrap; }
  .aTSOnly .bottom {
    font-size: 17px;
    color: #454e56;
    letter-spacing: 0.33px;
    text-align: justify;
    padding: 30px;
    border-bottom: 20px solid #ec1c24; }

@media (max-width: 768px) {
  .aTSOnly .top .white-rect {
    display: none; } }

@media (max-width: 575px) {
  .aTSOnly .top h2 {
    font-size: 2rem; } }

@media (max-width: 420px) {
  .aTSOnly .top h2 {
    font-size: 1.5rem; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.freeToUse {
  margin: 30px 15px;
  border-bottom: 1px dashed #454e56;
  border-top: 1px dashed #454e56;
  display: flex;
  align-items: center;
  min-height: 160px;
  padding: 15px; }
  .freeToUse .free-box {
    background-color: #48c1c4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 117px;
    min-width: 149px; }
    .freeToUse .free-box img {
      height: 77px;
      width: 96px; }
  .freeToUse .text {
    background-color: #d8d8d8;
    padding: 28px 25px;
    margin: 0 15px; }
    .freeToUse .text p {
      font-size: 16px;
      color: #454e56;
      text-align: justify;
      margin: 0; }

@media (max-width: 768px) {
  .freeToUse {
    flex-direction: column; }
    .freeToUse .free-box {
      width: 100%; }
      .freeToUse .free-box img {
        height: 85px;
        width: 108px; }
    .freeToUse .non-mobile {
      display: none; }
    .freeToUse .text {
      margin: 0; } }

.fourReasons {
  background-color: #ebebeb;
  padding: 30px 0; }
  .fourReasons .body {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; }

.reason {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  min-width: 250px;
  width: 25%;
  max-width: 350px;
  padding-bottom: 15px; }
  .reason .top {
    flex-direction: row;
    border-bottom: 1px solid #54595f;
    padding-bottom: 15px; }
    .reason .top img {
      height: 24px;
      width: 24px;
      margin-top: -12px; }
    .reason .top span {
      padding-left: 15px;
      font-size: 36px;
      color: #54595f;
      letter-spacing: 0.69px;
      text-align: center;
      line-height: 28px; }
  .reason .title {
    font-size: 28px;
    color: #54595f;
    letter-spacing: 0.54px;
    text-align: left;
    line-height: 28px;
    border-bottom: 1px solid #54595f;
    padding: 15px 0; }
  .reason .copy {
    padding-top: 10px;
    font-size: 15px;
    color: #54595f;
    letter-spacing: 0.29px; }

@media (max-width: 999px) {
  .reason {
    width: 50%; } }

@media (max-width: 498px) {
  .reason {
    width: auto;
    padding-bottom: 30px; } }

.haveAQuestion {
  margin: 30px 15px;
  padding: 30px 0;
  border-bottom: 1px dashed #454e56;
  border-top: 1px dashed #454e56;
  display: flex;
  align-items: center; }
  .haveAQuestion p {
    font-size: 16px;
    color: #54595f;
    letter-spacing: 0.31px;
    text-align: right;
    margin-bottom: 0;
    width: 60%; }
  .haveAQuestion .button-wrapper {
    width: 40%;
    text-align: left;
    padding-left: 10px;
    padding-right: 40px; }

@media (max-width: 768px) {
  .haveAQuestion {
    flex-direction: column; }
    .haveAQuestion p {
      width: auto;
      text-align: left; }
    .haveAQuestion .button-wrapper {
      width: 100%;
      max-width: 350px;
      text-align: center;
      padding: 15px 0 0; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eMRHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 515px;
  background-color: #48c1c4;
  position: relative;
  padding: 0 35px; }
  .eMRHeader .gradient,
  .eMRHeader .center-bar {
    position: absolute;
    left: 0;
    width: 100%; }
  .eMRHeader .gradient {
    top: 0;
    height: 100%;
    opacity: 0.9;
    background-image: linear-gradient(#ffffff, #48c1c4); }
  .eMRHeader .center-bar {
    background-color: #fdfdfd;
    height: 245px;
    overflow-y: visible;
    opacity: 0.6;
    top: calc(50% - 122.5px); }
  .eMRHeader .logo {
    width: 220px;
    height: 195px;
    z-index: 1; }
  .eMRHeader .phone {
    width: 204px;
    height: 409px;
    z-index: 1;
    margin: 0 25px; }
  .eMRHeader > h2.main-text {
    font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 42px;
    color: #454e56;
    letter-spacing: 1.41px;
    line-height: 52px;
    text-transform: uppercase;
    max-width: 485px;
    z-index: 1; }
    .eMRHeader > h2.main-text .desktop-text {
      display: block;
      margin-top: 15px;
      margin-bottom: -90px;
      padding: 10px 15px;
      background-color: #454e56;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 18px;
      color: #48c1c4;
      letter-spacing: 0.55px;
      line-height: 21px;
      width: 308px; }

@media (max-width: 1024px) {
  .eMRHeader {
    padding: 0 20px; }
    .eMRHeader > h2.main-text {
      font-size: 34px;
      line-height: 48px;
      max-width: 410px; } }

@media (max-width: 837px) {
  .eMRHeader > h2.main-text .desktop-text {
    display: none; } }

@media (max-width: 665px) {
  .eMRHeader > h2.main-text {
    font-size: 30px;
    line-height: 38px; } }

@media (max-width: 575px) {
  .eMRHeader {
    flex-direction: column;
    padding-left: 150px;
    align-items: flex-start; }
    .eMRHeader .center-bar {
      height: 320px;
      top: calc(50% - 160px); }
    .eMRHeader > .logo {
      width: 83px;
      height: 74px;
      margin-bottom: 8px; }
    .eMRHeader .phone {
      position: absolute;
      height: 338px;
      left: -85px;
      top: 90px; } }

@media (max-width: 420px) {
  .eMRHeader > h2.main-text {
    font-size: 20px;
    line-height: 26px;
    max-width: 173px;
    margin-bottom: 0; }
    .eMRHeader > h2.main-text em {
      font-size: 30px;
      line-height: 38px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.hippaBox {
  background-color: #006098;
  height: 56px;
  width: 110px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  top: 55px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .hippaBox h4 {
    font-family: Helvetica;
    font-size: 26px;
    letter-spacing: 0;
    color: #ffffff;
    margin: 0; }
  .hippaBox p {
    font-family: Helvetica;
    font-size: 14px;
    letter-spacing: 0.09px;
    color: #ffffff;
    margin: 0; }

@media (max-width: 575px) {
  .hippaBox {
    display: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.covidScene {
  padding-top: 78px; }
  .covidScene .top-section {
    display: flex;
    background: #50e3c2;
    background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0.5) 0%, #48c1c4 100%);
    padding: 40px 0; }
    .covidScene .top-section .heading {
      font-family: Helvetica;
      font-size: 34px;
      color: #4a4a4a;
      letter-spacing: 0.65px;
      text-align: left;
      text-transform: uppercase; }
      .covidScene .top-section .heading .stressed {
        display: block;
        color: #ec1c24;
        font-size: 48px;
        padding: 6px 0; }
  .covidScene > .who-uses {
    background-color: #454e56;
    padding: 48px 0 23px; }
  .covidScene .bottom-gradient {
    background: #50e3c2;
    background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0.5) 0%, #48c1c4 100%);
    height: 34px;
    margin-bottom: 15px; }

@media screen and (min-width: 1025px) and (max-width: 1235px), (max-width: 642px) {
  .covidScene > .top-section .heading {
    font-size: 29px; }
    .covidScene > .top-section .heading .stressed {
      font-size: 35px;
      padding: 5px 0; } }

@media screen and (max-width: 420px) {
  .covidScene .top-section {
    padding: 40px 0; } }

.SectionWithTitleAndBody {
  display: flex;
  flex-direction: column;
  padding: 40px 20px 50px;
  text-align: center; }
  .SectionWithTitleAndBody h3 {
    font-size: 24px;
    color: #454e56;
    margin-bottom: 40px;
    font-family: "Helvetica";
    text-transform: uppercase; }
  .SectionWithTitleAndBody .body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.BodySubSection {
  padding: 10px;
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .BodySubSection p {
    letter-spacing: 0.31px;
    color: #454e56;
    font-size: 16px;
    max-width: 290px; }
  .BodySubSection .Title {
    text-transform: uppercase;
    font-weight: bold; }
  .BodySubSection .Divider {
    height: 2px;
    width: 20px;
    background-color: #f33;
    margin: 12px 0 10px; }

.BodySubSection:not(:first-child) {
  border-left: 1px solid #818991; }

@media (max-width: 768px) {
  .BodySubSection {
    width: 50%;
    border-left: none; }
  .BodySubSection:not(:first-child) {
    border-left: none; }
  .BodySubSection:first-child {
    border-right: 1px solid #818991; } }

@media (max-width: 575px) {
  .BodySubSection {
    width: 100%;
    border: none; }
  .BodySubSection:first-child {
    border-right: none; } }

.covid-scheduleADemo {
  margin: 30px 15px;
  padding: 30px 0;
  display: flex;
  align-items: center; }
  .covid-scheduleADemo p {
    font-size: 16px;
    color: #54595f;
    letter-spacing: 0.31px;
    text-align: right;
    margin-bottom: 0;
    width: 60%; }
  .covid-scheduleADemo .button-wrapper {
    width: 40%;
    text-align: left;
    padding-left: 10px;
    padding-right: 40px; }

@media (max-width: 768px) {
  .scheduleADemo {
    flex-direction: column; }
    .scheduleADemo p {
      width: auto;
      text-align: left; }
    .scheduleADemo .button-wrapper {
      width: 100%;
      max-width: 350px;
      text-align: center;
      padding: 15px 0 0; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.ATCRecognition {
  display: flex;
  padding: 30px 0;
  background-color: #48c1c4; }
  .ATCRecognition .Divider {
    display: none; }
  .ATCRecognition .LeftTop,
  .ATCRecognition .RightBottom {
    width: 50%;
    padding: 0 30px; }
    .ATCRecognition .LeftTop p,
    .ATCRecognition .RightBottom p {
      text-transform: uppercase;
      font-size: 12px;
      color: #4a4a4a;
      margin-bottom: 10px; }
  .ATCRecognition .LeftTop {
    font-size: 14px;
    color: #4a4a4a;
    letter-spacing: 0.27px;
    border-right: 1px solid #818991; }

@media (max-width: 768px) {
  .ATCRecognition {
    flex-direction: column; }
    .ATCRecognition .LeftTop,
    .ATCRecognition .RightBottom {
      width: 100%; }
    .ATCRecognition .LeftTop {
      border-right: none; }
    .ATCRecognition .Divider {
      display: block;
      width: calc(100% - 60px);
      height: 1px;
      background-color: #81899175;
      margin: 30px auto; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.RecognitionItem .Head {
  color: #ec1c24;
  font-size: 20px; }

.RecognitionItem .Body {
  font-size: 16px;
  color: #4a4a4a;
  letter-spacing: 0.19px;
  line-height: 1.5;
  text-transform: uppercase; }
  .RecognitionItem .Body span {
    color: #f33; }

.RecognitionItem .Divider {
  height: 2px;
  width: 10px;
  background-color: #818991;
  margin-bottom: 5px; }

.RecognitionItem:last-child .Divider {
  display: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.ATSettings {
  background-color: #e6e6e6; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.blog {
  padding-top: 78px;
  position: relative; }
  .blog #blog-scroll-to {
    position: absolute;
    top: -78px; }
  .blog .search-input {
    max-width: 300px;
    width: 80%; }
  .blog .top-banner {
    display: flex;
    background: #50e3c2;
    background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0.5) 0%, #48c1c4 100%);
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .blog .top-banner h1 {
      font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 30px;
      color: #fff;
      letter-spacing: 0.58px;
      text-align: left; }
  .blog .blog-snippet h2 {
    font-size: 24px;
    color: #454e56; }
  .blog .blog-snippet img {
    max-width: 100%;
    width: 100%;
    height: auto; }
  .blog .blog-snippet:hover {
    text-decoration: none; }
  .blog .blog-snippet:not(:last-child) article {
    border-bottom: 2px dashed #c5c3c6; }
  .blog .pagination {
    justify-content: center; }
  .blog .bottom-separator {
    margin: 30px 0;
    height: 40px;
    background: #50e3c2;
    background-image: linear-gradient(-90deg, rgba(255, 255, 255, 0.5) 0%, #48c1c4 100%); }

@media screen and (min-width: 768px) {
  .blog .blog-snippet:nth-last-child(n + 2) article {
    border: none; }
  .blog .blog-snippet:nth-last-child(n + 3) article {
    border-bottom: 2px dashed #c5c3c6; } }

.single-blog .separator img {
  max-width: calc(100% - 2em);
  height: auto; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.input {
  border: solid 1px #c5c3c6;
  background-color: white;
  border-radius: 3px;
  padding: 10px 15px;
  font-size: 16px;
  font-family: "Lato";
  line-height: 21px;
  font-weight: 400;
  width: 100%;
  height: 35px;
  color: #454e56; }
  .input::placeholder {
    color: #c5c3c6; }
  .input:focus {
    outline: none; }
  .input.error {
    border-color: #e03738; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.standardRedButton {
  font-size: 14px;
  border-radius: 4px;
  color: #d8d8d8;
  background-color: #f33;
  border: none;
  min-height: 22px;
  text-decoration: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .standardRedButton.large {
    margin-top: 20px;
    font-style: italic;
    text-align: center;
    text-transform: uppercase;
    font-size: 21px;
    padding: 10px 58px;
    font-weight: 500;
    border-radius: 6px;
    border: none;
    font-family: Evogria, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #ffffff;
    transition: all 0.3s; }
  .standardRedButton:hover {
    color: #d8d8d8; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.ghostButton {
  color: #ec1c24;
  font-size: 20px;
  line-height: 23px;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  background-color: transparent; }
  .ghostButton:hover, .ghostButton:focus {
    color: #373636;
    text-decoration: underline; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.nurses {
  padding-top: 78px;
  position: relative; }
  .nurses .separator {
    height: 1px;
    background-color: #979797;
    flex: 1; }
  .nurses .separator-dashed {
    border-top: 1px dashed #979797;
    flex: 1; }
  .nurses .schedule-demo {
    border-top: 1px solid #c5c3c6;
    border-bottom: 1px solid #c5c3c6;
    align-items: center;
    justify-content: flex-end; }
    .nurses .schedule-demo span {
      font-family: Helvetica;
      font-size: 16px;
      color: #54595f;
      letter-spacing: 0.31px;
      text-align: right; }
    .nurses .schedule-demo a {
      margin-top: 0;
      margin-left: 16px; }

@media (max-width: 1100px) {
  .nurses .schedule-demo {
    flex-direction: column;
    justify-content: center; }
    .nurses .schedule-demo span {
      text-align: center; }
    .nurses .schedule-demo a {
      margin-top: 20px;
      margin-left: 0; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.nurses-who-uses {
  background-color: #e6e6e6; }

.cornered-container .top-left {
  transform: rotate(90deg);
  top: 0;
  left: 0; }

.cornered-container .top-right {
  transform: rotate(180deg);
  top: 0;
  right: 0; }

.cornered-container .bottom-left {
  bottom: 0;
  left: 0; }

.cornered-container .bottom-right {
  transform: rotate(-90deg);
  bottom: 0;
  right: 0; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.nurses-rapid-placement {
  background-color: #33cccc; }
  .nurses-rapid-placement h2 {
    color: #454e56;
    border-bottom: 2px solid #454e56;
    font-weight: normal; }
  .nurses-rapid-placement p {
    color: #454e56; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.nursesContactForm {
  height: 100%;
  width: 80%;
  max-width: 80%;
  background: white;
  text-transform: uppercase; }
  .nursesContactForm > .header {
    background-color: #ec1c24;
    padding: 10px 8px;
    word-spacing: 1.5px;
    text-align: center;
    color: white;
    font-size: 24px;
    font-family: "Evogria", Sans-serif; }
  .nursesContactForm > .body {
    background-color: #f2f2f2;
    padding: 15px 40px;
    box-shadow: 0px 9px 17px -6px rgba(0, 0, 0, 0.5); }
    .nursesContactForm > .body .react-datepicker-wrapper {
      width: 100%; }
    .nursesContactForm > .body > .date-picker > span {
      color: #454e56;
      font-size: 12px;
      font-weight: 900;
      font-family: "Lato"; }
    .nursesContactForm > .body input,
    .nursesContactForm > .body textarea {
      border: solid 1px #666666;
      background-color: white;
      border-radius: 3px;
      padding: 10px 15px;
      font-size: 16px;
      font-family: "Lato";
      line-height: 21px;
      font-weight: 400;
      width: 100%;
      margin-bottom: 15px;
      color: #b4b4b4; }
      .nursesContactForm > .body input::placeholder,
      .nursesContactForm > .body textarea::placeholder {
        text-transform: uppercase !important;
        color: #dedee5; }
      .nursesContactForm > .body input:focus,
      .nursesContactForm > .body textarea:focus {
        outline: none; }
      .nursesContactForm > .body input.error,
      .nursesContactForm > .body textarea.error {
        border-color: #e03738; }
    .nursesContactForm > .body textarea {
      height: 100px; }
    .nursesContactForm > .body .submit-button {
      font-style: normal;
      font-family: Helvetica; }
    .nursesContactForm > .body .error-message {
      color: #e03738;
      text-transform: none;
      margin-bottom: 18px; }

@media (max-width: 1024px) {
  .nursesContactForm {
    width: 100%;
    max-width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.nursesTopSection {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../images/man-using-computer.png"); }
  .nursesTopSection > .top-sub-section {
    text-transform: uppercase;
    color: #ffffff; }
  .nursesTopSection > .left-top {
    width: 65%; }
    .nursesTopSection > .left-top > .text-container {
      background: rgba(208, 2, 27, 0.65);
      padding: 42px 50px 42px 0;
      width: 100%;
      height: 227px;
      display: flex;
      align-items: center;
      font-family: Helvetica;
      font-size: 34px;
      letter-spacing: 1.14px;
      text-align: left; }
      .nursesTopSection > .left-top > .text-container h1 {
        color: #33cccc; }
        .nursesTopSection > .left-top > .text-container h1 .white-text {
          color: #ffffff; }
  .nursesTopSection > .right-bottom {
    display: flex;
    align-items: center;
    margin: 30px 0 20px 0;
    width: 35%; }
    .nursesTopSection > .right-bottom .red-bar {
      height: 227px;
      width: 100%;
      background: rgba(208, 2, 27, 0.65);
      width: 20%; }

@media (max-width: 1024px) {
  .nursesTopSection {
    flex-direction: column; }
    .nursesTopSection > .top-sub-section {
      width: 100%; }
    .nursesTopSection > .right-bottom {
      padding: 0 10px; }
      .nursesTopSection > .right-bottom .red-bar {
        display: none; } }

@media (max-width: 600px) {
  .nursesTopSection > .left-top > .text-container {
    align-items: start;
    flex-direction: column;
    height: auto; }
    .nursesTopSection > .left-top > .text-container h1 {
      margin-top: 24px; } }

@media (max-width: 575px) {
  .nursesTopSection > .left-top > .text-container {
    padding: 10px; }
    .nursesTopSection > .left-top > .text-container > .go4ellis-img {
      width: 100%;
      max-width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.seminarContactForm {
  height: 100%;
  width: 80%;
  max-width: 80%;
  background: white;
  text-transform: uppercase; }
  .seminarContactForm > .header {
    background-color: #ec1c24;
    padding: 10px 8px;
    word-spacing: 1.5px;
    text-align: center;
    color: white;
    font-size: 24px;
    font-family: "Evogria", Sans-serif; }
  .seminarContactForm > .body {
    background-color: #f2f2f2;
    padding: 15px 40px;
    box-shadow: 0px 9px 17px -6px rgba(0, 0, 0, 0.5); }
    .seminarContactForm > .body > .role-radio > strong {
      color: #454e56;
      font-size: 16px;
      font-weight: 900;
      font-family: "Lato"; }
    .seminarContactForm > .body > .role-radio > ul {
      list-style: none;
      padding-left: 0px; }
      .seminarContactForm > .body > .role-radio > ul > li {
        display: flex;
        align-items: center;
        margin: 5px 0; }
        .seminarContactForm > .body > .role-radio > ul > li > label {
          margin: 0 0 0 12px;
          color: #454e56;
          font-weight: 900;
          font-family: "Lato"; }
    .seminarContactForm > .body input[type="text"],
    .seminarContactForm > .body input[type="email"] {
      border: solid 1px #666666;
      background-color: white;
      border-radius: 3px;
      padding: 10px 15px;
      font-size: 16px;
      font-family: "Lato";
      line-height: 21px;
      font-weight: 400;
      width: 100%;
      margin-bottom: 15px;
      color: #b4b4b4; }
      .seminarContactForm > .body input[type="text"]::placeholder,
      .seminarContactForm > .body input[type="email"]::placeholder {
        text-transform: uppercase !important;
        color: #dedee5; }
      .seminarContactForm > .body input[type="text"]:focus,
      .seminarContactForm > .body input[type="email"]:focus {
        outline: none; }
      .seminarContactForm > .body input[type="text"].error,
      .seminarContactForm > .body input[type="email"].error {
        border-color: #e03738; }
    .seminarContactForm > .body button {
      background-color: #ec1c24;
      cursor: pointer;
      font-style: italic;
      text-transform: uppercase;
      font-size: 21px;
      width: 100%;
      padding: 10px 20px;
      font-weight: 500;
      border-radius: 6px;
      border: none;
      font-family: "Evogria", Sans-serif;
      color: #ffffff;
      margin-bottom: 18px; }
      .seminarContactForm > .body button:hover {
        color: #aab8be; }
      .seminarContactForm > .body button:focus {
        outline: none; }

@media (max-width: 1024px) {
  .seminarContactForm {
    width: 100%;
    max-width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.big-money_header {
  position: fixed;
  width: 100vw;
  height: 78px;
  top: 0;
  z-index: 2; }

.bigMoney .banner {
  background: #afd696;
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto; }
  .bigMoney .banner img {
    max-width: 100%; }

.bigMoney .text1 {
  font-family: Lato-Bold;
  font-size: 38px;
  color: #4d545a;
  letter-spacing: 0.73px;
  text-align: center;
  line-height: 1;
  margin-bottom: 0; }

.bigMoney .text2 {
  font-family: Lato-Bold;
  font-size: 19px;
  color: #626a71;
  letter-spacing: 0.37px;
  text-align: center;
  line-height: 1;
  margin-bottom: 0; }

.bigMoney .text3 {
  font-family: Lato-Bold;
  font-size: 48px;
  color: #4d545a;
  letter-spacing: 0.92px;
  text-align: center;
  line-height: 1;
  margin-bottom: 0; }

.bigMoney .text4 {
  font-family: Lato-Bold;
  font-size: 30px;
  color: #4d545a;
  letter-spacing: 0.92px;
  text-align: center;
  line-height: 1;
  margin-bottom: 0; }

.bigMoney .text5 {
  font-family: Lato-Bold;
  font-size: 15.5px;
  color: #626a71;
  letter-spacing: 0.3px;
  text-align: center;
  line-height: 1;
  margin-bottom: 0; }
  .bigMoney .text5.white {
    color: #ffffff; }

.bigMoney .text6 {
  font-family: Lato-Bold;
  font-size: 12px;
  color: #626a71;
  letter-spacing: 0.4px;
  text-align: center;
  line-height: 1;
  margin-bottom: 0; }

.bigMoney .text7 {
  font-family: Lato-Medium;
  font-size: 12px;
  color: #4d545a;
  letter-spacing: 0.23px;
  text-align: justify;
  line-height: 1;
  margin-bottom: 0; }

.bigMoney .text8 {
  font-family: Lato-Semibold;
  font-size: 25px;
  color: #4d545a;
  letter-spacing: 0.48px;
  text-align: center;
  line-height: 1;
  margin-bottom: 0; }

.bigMoney .text9 {
  font-family: Lato-Bold;
  font-size: 22px;
  color: #4d545a;
  letter-spacing: 0.36px;
  text-align: center;
  line-height: 35px;
  line-height: 1;
  margin-bottom: 0; }

.bigMoney .text10 {
  font-family: Lato-Bold;
  font-size: 62px;
  color: #4d545a;
  letter-spacing: 1.19px;
  text-align: center;
  line-height: 1;
  margin-bottom: 0; }

.bigMoney .text11 {
  font-family: Lato-Medium;
  font-size: 9px;
  color: #4d545a;
  letter-spacing: 0.23px;
  text-align: justify;
  line-height: 1;
  margin-bottom: 0; }

.bigMoney .separator1 {
  border-bottom: 2px dashed #979797; }

.bigMoney .separator2 {
  border-bottom: 2px solid #979797; }

.bigMoney .button1 {
  max-width: 800px; }

.bigMoney .lato-medium {
  font-family: Lato-Medium; }

.bigMoney .lato-semi {
  font-family: Lato-Semibold; }

.bigMoney .big-money-coins {
  width: 67%;
  min-width: 200px;
  max-width: 450px; }

@media screen and (min-width: 575px) {
  .bigMoney .text1 {
    font-size: 69px; }
  .bigMoney .text2 {
    font-size: 34px; }
  .bigMoney .text3 {
    font-size: 89px; }
  .bigMoney .text4 {
    font-size: 59px; }
  .bigMoney .text5 {
    font-size: 26.5px; }
  .bigMoney .text6,
  .bigMoney .text7,
  .bigMoney .text11 {
    font-size: 21px; }
  .bigMoney .text8,
  .bigMoney .text9 {
    font-size: 45px; }
  .bigMoney .text10 {
    font-size: 130px; } }

@media screen and (min-width: 768px) {
  .bigMoney .banner {
    height: 768px; }
  .bigMoney .text1 {
    font-size: 95px; }
  .bigMoney .text2 {
    font-size: 42px; }
  .bigMoney .text3 {
    font-size: 124px; }
  .bigMoney .text4 {
    font-size: 81px; }
  .bigMoney .text5 {
    font-size: 36.5px; }
  .bigMoney .text6,
  .bigMoney .text7,
  .bigMoney .text11 {
    font-size: 29px; }
  .bigMoney .text8,
  .bigMoney .text9 {
    font-size: 61px; }
  .bigMoney .text10 {
    font-size: 157px; } }

@media screen and (min-width: 1024px) {
  .bigMoney .text1 {
    font-size: 130px; }
  .bigMoney .text2 {
    font-size: 56px; }
  .bigMoney .text3 {
    font-size: 170px; }
  .bigMoney .text4 {
    font-size: 110px; }
  .bigMoney .text5 {
    font-size: 50px; }
  .bigMoney .text6,
  .bigMoney .text7,
  .bigMoney .text11 {
    font-size: 40px; }
  .bigMoney .text8,
  .bigMoney .text9 {
    font-size: 81px; } }

.leadForm {
  width: 100%; }
  .leadForm .lead-form-input,
  .leadForm .lead-form-submit,
  .leadForm .lead-form-textarea {
    height: auto; }
  .leadForm .lead-form-textarea {
    min-height: 125px; }

.standardGreenButton {
  font-size: 14px;
  border-radius: 4px;
  color: #d8d8d8;
  background-color: #009245;
  border: none;
  min-height: 22px;
  text-decoration: none;
  color: #ffffff;
  display: flex;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .standardGreenButton:hover {
    color: #d8d8d8; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.form_textArea {
  border: solid 1px #c5c3c6;
  background-color: white;
  border-radius: 3px;
  padding: 10px 15px;
  font-size: 16px;
  font-family: "Lato";
  line-height: 21px;
  font-weight: 400;
  width: 100%;
  height: 35px;
  color: #454e56; }
  .form_textArea::placeholder {
    color: #c5c3c6; }
  .form_textArea:focus {
    outline: none; }
  .form_textArea.error {
    border-color: #e03738; }

.contactForm {
  width: 100%; }
  .contactForm .contact-form-input,
  .contactForm .contact-form-submit,
  .contactForm .contact-form-textarea {
    height: auto; }
  .contactForm .contact-form-textarea {
    min-height: 125px; }

.slide-menu_hamburger-icon {
  position: absolute;
  font-size: 20px;
  left: 2rem;
  top: 2rem;
  height: 40px;
  width: 40px;
  color: #ffffff;
  background-color: #02381c;
  border-radius: 50%;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.5); }

.slideMenu {
  background-color: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  max-width: 0px;
  opacity: 0;
  transition: 0.5s;
  transform: scaleX(0);
  transition-property: max-width, transform, opacity;
  transform-origin: left;
  transition-timing-function: ease-in-out;
  box-shadow: 4px 6px 17px 5px rgba(0, 0, 0, 0.5); }
  .slideMenu.open {
    transform: scaleY(1);
    max-width: 400px;
    opacity: 1; }
  .slideMenu .link-list {
    list-style: none;
    border-top: 1px solid #979797; }
    .slideMenu .link-list .link-list-item {
      width: 100%;
      border-bottom: 1px solid #979797; }
      .slideMenu .link-list .link-list-item a {
        font-family: Lato-Semibold;
        font-size: 12px;
        color: #4d545a;
        letter-spacing: 0.38px; }
  .slideMenu .slide-menu_image {
    width: 67%; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.averageRatesModal .average-rates-link {
  color: #33cccc;
  text-transform: uppercase;
  display: inline-block;
  font-size: 1.1rem;
  margin-left: 1rem;
  text-decoration: underline; }

@media (max-width: 1100px) {
  .averageRatesModal .average-rates-link {
    margin-left: 0;
    margin-bottom: 1rem; } }

.rates-modal .modal-dialog {
  max-width: 288px;
  margin: 10vh auto; }

.rates-modal .modal-body {
  padding: 0;
  background-color: white; }

.rates-modal .modal-header {
  padding: 0;
  border-bottom: 0; }
  .rates-modal .modal-header button {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 27px;
    width: 27px;
    background-color: white;
    border-radius: 50%;
    opacity: 1;
    border: 1px solid #677379; }
    .rates-modal .modal-header button:hover {
      opacity: 1 !important; }

.rates-modal table {
  max-height: 500px; }

.rates-modal .ant-table-header {
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #677379; }

.rates-modal table th {
  text-align: center;
  font-weight: 600;
  padding: 0.5rem 0;
  font-size: 0.8rem;
  background-color: #677379; }
  .rates-modal table th:first-child {
    border-right: 1px solid #677379; }

.rates-modal table tr td {
  padding: 0.5rem 0;
  text-align: center;
  border: 1px solid #e6e6e6; }

.rates-modal .choosing-rates-tips {
  padding: 16px 12px 0;
  font-size: 0.9rem; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventProfile {
  display: flex;
  flex-direction: column;
  padding-block: 20px;
  min-height: 25vh; }
  .eventProfile #profileForm label {
    color: #55556d !important; }

.eventProfileWithActions {
  display: flex;
  flex-grow: 1;
  flex-direction: row; }
  .eventProfileWithActions .lbr {
    border-radius: 4px 0 0 4px !important;
    text-align: center; }
  .eventProfileWithActions .rbr {
    border-radius: 0 4px 4px 0 !important; }
  .eventProfileWithActions .fbr {
    border-radius: 4px !important;
    text-align: center; }
  .eventProfileWithActions .remove-border-r {
    border-right: none; }
  .eventProfileWithActions small.sub-text {
    display: block;
    font-weight: 500;
    color: #55556d; }
  .eventProfileWithActions main {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    padding-inline: 40px;
    max-width: 60vw; }
    @media (max-width: 1024px) {
      .eventProfileWithActions main {
        padding-inline: 20px;
        max-width: 80vw; } }
    @media (max-width: 768px) {
      .eventProfileWithActions main {
        max-width: 100vw; } }
    .eventProfileWithActions main .input-group .input-group-append span {
      border-color: #65647f; }
    .eventProfileWithActions main label {
      font-family: Gotham-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .eventProfileWithActions main input,
    .eventProfileWithActions main select {
      border-color: #65647f; }
      .eventProfileWithActions main input:focus,
      .eventProfileWithActions main select:focus {
        box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
        outline: 0;
        border-color: #65647f;
        background-color: white; }
    .eventProfileWithActions main input:-webkit-autofill {
      -webkit-box-shadow: 0 0 1000px 20px white inset; }
    .eventProfileWithActions main input:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 1000px 20px white inset, 0 0 0 0.2rem rgba(101, 100, 127, 0.25); }
    .eventProfileWithActions main .select__control {
      border-color: #65647f; }
    .eventProfileWithActions main .select__indicator-separator {
      background-color: #65647f; }
    .eventProfileWithActions main .select__dropdown-indicator {
      color: #65647f; }
    .eventProfileWithActions main .select__control--is-focused {
      outline: 0;
      box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
      border-color: #65647f; }
    .eventProfileWithActions main header {
      padding-top: 5px;
      width: 100%;
      border-bottom: 1px solid #677379; }
    .eventProfileWithActions main .minus,
    .eventProfileWithActions main .plus {
      color: #55556d; }
    .eventProfileWithActions main .italic-placeholder::placeholder {
      font-style: italic;
      color: #65647f; }
    .eventProfileWithActions main input[name="jobDescription"] {
      position: absolute; }
    .eventProfileWithActions main .event-logo-preview {
      margin-bottom: 10px;
      text-align: center; }
    .eventProfileWithActions main .image-preview-row {
      padding-top: 18px; }
    .eventProfileWithActions main input[type="radio"] ~ label {
      margin-top: 3px; }
    .eventProfileWithActions main input[type="radio"]:checked ~ label {
      font-weight: bold; }
    .eventProfileWithActions main .select__menu {
      background-color: grey;
      color: white; }
    .eventProfileWithActions main .select__option {
      font-size: 1rem;
      line-height: 0.5; }
    .eventProfileWithActions main .css-26l3qy-menu {
      z-index: 9999 !important; }
    .eventProfileWithActions main .select__option--is-focused {
      background-color: #5499ff; }
    @media (max-width: 1100px) {
      .eventProfileWithActions main .custom-file {
        height: auto; }
      .eventProfileWithActions main .event-logo-preview {
        margin: 10px 0; }
      .eventProfileWithActions main .image-preview-row {
        padding-top: 10px; }
        .eventProfileWithActions main .image-preview-row > div:nth-of-type(1) {
          margin-bottom: 5px; }
          .eventProfileWithActions main .image-preview-row > div:nth-of-type(1) label {
            margin: 0; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventLocation {
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  padding-block: 20px; }
  .eventLocation .job-contact-copy {
    color: #55556d !important; }
  .eventLocation small.sub-text {
    display: block;
    color: #b9b6ba; }

.report-contact-btn {
  background-color: transparent;
  border: none;
  outline: none;
  text-decoration: underline;
  color: #30827b; }
  .report-contact-btn:focus {
    outline: none; }

.locations-screen {
  display: flex;
  flex-grow: 1;
  flex-direction: row; }
  .locations-screen main {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    padding-inline: 40px;
    max-width: 60vw; }
    @media (max-width: 1024px) {
      .locations-screen main {
        padding-inline: 20px;
        max-width: 80vw; } }
    @media (max-width: 768px) {
      .locations-screen main {
        max-width: 100vw; } }
    .locations-screen main label:not([type="checkbox"]) {
      font-family: Gotham-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .locations-screen main .input-group .input-group-prepend span,
    .locations-screen main .input-group .input-group-append span {
      border-color: #65647f;
      color: #65647f; }
    .locations-screen main input {
      border-color: #65647f; }
      .locations-screen main input:focus {
        box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
        outline: 0;
        border-color: #65647f; }
    .locations-screen main .remove-border-r {
      border-right: none; }
    .locations-screen main header {
      padding-top: 5px;
      width: 100%;
      border-bottom: 1px solid #677379; }
    .locations-screen main.intro .horizontal-rule {
      margin-top: 0;
      margin-bottom: 0; }
    .locations-screen main.intro .tournament-title {
      padding-block: 10px;
      font-size: 18px;
      color: #55556d;
      font-weight: 800;
      text-transform: capitalize; }
    .locations-screen main.intro .location-item {
      margin-block: 10px; }
      .locations-screen main.intro .location-item .location-label {
        font-size: 14px;
        color: #9d9dad;
        font-weight: 800; }
    .locations-screen main.intro .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      height: 28px; }
      .locations-screen main.intro .actions .delete {
        color: #979797; }
        .locations-screen main.intro .actions .delete:active {
          color: #979797; }
    .locations-screen main .edit-btn {
      font-size: 12px; }
    .locations-screen main .add-location {
      font-size: 14px; }
    .locations-screen main .edit-or-start,
    .locations-screen main .delete {
      text-transform: uppercase;
      border: none;
      background: none;
      text-decoration: underline;
      font-size: 12px;
      cursor: pointer;
      color: #006e6d;
      font-style: normal;
      padding: 0;
      margin: 0; }
      .locations-screen main .edit-or-start:focus,
      .locations-screen main .delete:focus {
        box-shadow: none; }
      .locations-screen main .edit-or-start.selected,
      .locations-screen main .delete.selected {
        box-shadow: none; }
      .locations-screen main .edit-or-start:active,
      .locations-screen main .delete:active {
        color: #33cccc;
        background-color: white;
        border-color: white; }

@media (max-width: 1100px) {
  .report-contact-btn {
    align-self: center; }
  .merge-phone {
    flex: 5; }
  .merge-close {
    flex: 1; } }

.italic-placeholder::placeholder {
  font-style: italic;
  color: #65647f; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.numInputWrapper span {
  width: 25px;
  padding: 6px 8px; }

.eventSchedule {
  display: flex;
  flex-grow: 1;
  flex-direction: row; }
  .eventSchedule main.center {
    position: absolute;
    top: 78px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    min-width: 50vw;
    align-content: center; }
    @media (max-width: 1024px) {
      .eventSchedule main.center {
        padding-inline: 20px;
        max-width: 80vw; } }
    @media (max-width: 768px) {
      .eventSchedule main.center {
        max-width: 100vw;
        padding-bottom: 0; } }
    @media (max-width: 420px) {
      .eventSchedule main.center {
        position: unset; } }
  .eventSchedule main {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    padding-inline: 40px;
    max-width: 60vw; }
    @media (max-width: 1024px) {
      .eventSchedule main {
        padding-inline: 20px;
        max-width: 80vw; } }
    @media (max-width: 768px) {
      .eventSchedule main {
        max-width: 100vw; } }
    .eventSchedule main header {
      padding-top: 5px;
      width: 100%;
      border-bottom: 1px solid #677379; }

.schedule-summary {
  display: flex;
  flex-direction: column;
  padding-block: 10px;
  min-height: 25vh;
  margin-block-end: 10px; }
  .schedule-summary .shift-list {
    display: flex;
    text-align: start;
    flex-direction: column;
    margin-block-end: 20px; }
  .schedule-summary .shift-name {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem; }
  .schedule-summary .shift {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    max-width: 35rem; }
    .schedule-summary .shift .shift-summary-mobile {
      display: none;
      color: #677379;
      margin-block: 5px;
      gap: 10px; }
      .schedule-summary .shift .shift-summary-mobile div {
        display: flex;
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 17px; }
        .schedule-summary .shift .shift-summary-mobile div p {
          margin: 0 5px;
          font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 16px;
          align-self: end; }
      @media (max-width: 420px) {
        .schedule-summary .shift .shift-summary-mobile {
          display: flex;
          flex-direction: column; } }
    .schedule-summary .shift table {
      border-collapse: collapse;
      width: 100%;
      border: 2px solid #677379; }
      @media (max-width: 420px) {
        .schedule-summary .shift table {
          display: none; } }
      .schedule-summary .shift table th {
        border: 2px solid #677379;
        padding: 8px;
        text-align: center;
        color: #677379;
        font-weight: bold; }
      .schedule-summary .shift table td {
        border: 2px solid #677379;
        padding: 8px;
        text-align: center;
        color: #677379; }
      .schedule-summary .shift table th:first-child,
      .schedule-summary .shift table td:first-child {
        max-width: 25%; }
      .schedule-summary .shift table th:nth-child(2),
      .schedule-summary .shift table td:nth-child(2) {
        max-width: 20%; }
      .schedule-summary .shift table th:nth-child(3),
      .schedule-summary .shift table td:nth-child(3) {
        max-width: 15%; }
    .schedule-summary .shift .shift-actions {
      display: flex;
      flex-direction: row;
      margin-top: 5px;
      align-items: center;
      height: 30px;
      gap: 5px; }
      .schedule-summary .shift .shift-actions i.separator {
        color: #677379; }
  .schedule-summary .border-bottom {
    border-bottom: 2px solid #c5c3c6;
    padding-bottom: 10px; }

.schedule-summary-with-actions {
  display: flex;
  flex-grow: 1;
  flex-direction: row; }
  .schedule-summary-with-actions main {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    padding-inline: 40px;
    max-width: 60vw; }
    @media (max-width: 1024px) {
      .schedule-summary-with-actions main {
        padding-inline: 20px;
        max-width: 80vw; } }
    @media (max-width: 768px) {
      .schedule-summary-with-actions main {
        max-width: 100vw; } }
    .schedule-summary-with-actions main header {
      padding-top: 5px;
      width: 100%;
      border-bottom: 1px solid #677379; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventRate {
  display: flex;
  flex-direction: column;
  padding-block: 20px;
  min-height: 25vh; }
  .eventRate .tip {
    margin-inline: 15px 10px;
    margin-block: 10px;
    padding: 0;
    padding-top: 3.5px;
    min-height: 30px;
    height: auto;
    min-width: 350px;
    max-width: 400px; }
    @media (max-width: 420px) {
      .eventRate .tip {
        min-width: auto;
        max-width: none; } }
    .eventRate .tip .tip-description {
      width: 89%; }
  .eventRate .responsive-input .input-group-prepend span,
  .eventRate .responsive-input .input-group-append span {
    border-color: #65647f;
    color: #65647f; }
  @media (max-width: 768px) {
    .eventRate .responsive-input {
      width: 50%; } }
  @media (max-width: 575px) {
    .eventRate .responsive-input {
      width: 100%; } }
  .eventRate label {
    font-family: Gotham-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .eventRate input {
    border-color: #65647f; }
    .eventRate input:focus {
      box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
      outline: 0;
      border-color: #65647f; }
  .eventRate .responsive-alert {
    flex: 1;
    position: relative;
    max-width: 450px;
    padding-right: 15px;
    padding-left: 15px; }
    @media (max-width: 768px) {
      .eventRate .responsive-alert {
        min-width: 400px;
        flex: 0 0 35%;
        display: none; } }
    @media (max-width: 420px) {
      .eventRate .responsive-alert {
        min-width: 100%; } }
    .eventRate .responsive-alert.mobile-screen {
      display: none; }
      @media (max-width: 768px) {
        .eventRate .responsive-alert.mobile-screen {
          display: block; } }
    .eventRate .responsive-alert .late-post-alert {
      background-color: #eef3f5; }
      .eventRate .responsive-alert .late-post-alert .horizontal-rule {
        margin-top: 8px;
        margin-bottom: 8px; }
      .eventRate .responsive-alert .late-post-alert .title {
        font-size: 1.2rem;
        font-weight: 600;
        letter-spacing: 0.5px; }
      .eventRate .responsive-alert .late-post-alert .info {
        padding: 10px;
        font-size: 0.9rem;
        color: #78868d; }
      .eventRate .responsive-alert .late-post-alert .info-label {
        padding: 2px;
        text-align: center;
        background-color: #fee02d;
        font-size: 1rem;
        font-weight: 600;
        color: black; }
  .eventRate .rate-section {
    min-width: 350px;
    max-width: 400px; }
    @media (max-width: 768px) {
      .eventRate .rate-section {
        min-width: auto;
        max-width: none; } }
    .eventRate .rate-section .rate-labels {
      justify-content: space-between; }
      @media (max-width: 768px) {
        .eventRate .rate-section .rate-labels {
          justify-content: flex-start; } }

.eventRateWithActions {
  display: flex;
  flex-grow: 1;
  flex-direction: row; }
  .eventRateWithActions main {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    padding-inline: 40px;
    max-width: 60vw; }
    @media (max-width: 1024px) {
      .eventRateWithActions main {
        padding-inline: 20px;
        max-width: 80vw; } }
    @media (max-width: 768px) {
      .eventRateWithActions main {
        max-width: 100vw; } }
    .eventRateWithActions main header {
      padding-top: 5px;
      width: 100%;
      border-bottom: 1px solid #677379; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.locationDetails {
  display: flex;
  flex-grow: 1;
  flex-direction: row; }
  .locationDetails main {
    display: flex;
    flex-direction: column;
    flex: 1; }
    .locationDetails main header {
      padding-top: 5px;
      width: 100%;
      border-bottom: 1px solid #677379; }
  .locationDetails .doc-upload-list {
    display: flex;
    align-items: center; }
    .locationDetails .doc-upload-list .remove-contact-btn {
      color: #65647f;
      font-size: 28px; }
      @media (max-width: 1100px) {
        .locationDetails .doc-upload-list .remove-contact-btn {
          margin-top: 0px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.locationEAP {
  display: flex;
  flex-grow: 1;
  flex-direction: row; }
  .locationEAP main {
    display: flex;
    flex-direction: column;
    flex: 1; }
    .locationEAP main header {
      padding-top: 5px;
      width: 100%;
      border-bottom: 1px solid #677379; }
  .locationEAP .doc-upload-list {
    display: flex;
    align-items: center; }
  .locationEAP .remove-contact-btn {
    color: #78868d;
    font-size: 28px; }
    @media (max-width: 1100px) {
      .locationEAP .remove-contact-btn {
        margin-top: 0px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.navigation {
  display: flex;
  background-color: #eef3f5;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
  min-width: 130px;
  width: 16vw; }
  @media (max-width: 768px) {
    .navigation {
      min-width: 200px;
      width: 20vw; } }
  .navigation > * {
    padding-block: 5px;
    padding-inline: 4px;
    text-align: center;
    text-transform: uppercase; }
    .navigation > *:hover {
      cursor: pointer;
      text-decoration: none; }
  .navigation i.nav-arrow {
    text-align: center;
    color: #006e6d;
    padding: 0; }
    .navigation i.nav-arrow:hover {
      cursor: initial; }
  .navigation > *:first-child {
    border-bottom: 1px solid #677379;
    width: 100%;
    padding: 10px;
    margin-block: 0; }
  .navigation > *:nth-child(2) {
    margin-top: 10px; }
  .navigation .job-title {
    min-height: 42px;
    word-wrap: break-word; }
  .navigation .selected {
    background-color: #fee02d;
    color: #373636;
    min-width: 100px;
    border-radius: 4px; }
  .navigation a {
    font-size: 14px;
    color: #55556d;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    padding-inline: 3px; }
    .navigation a[disabled] {
      opacity: 0.5; }
  .navigation .missing-alert {
    background-color: #f33;
    color: white;
    font-weight: bold;
    text-align: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    opacity: 1; }
  .navigation .close-sidebar {
    padding-inline: 10px; }

.navigation-toggle {
  position: absolute;
  padding: 12px;
  padding-left: 20px;
  color: #33cccc; }

.location-nav {
  margin: 0;
  padding: 0;
  background-color: #eef3f5;
  border: none; }
  .location-nav .horizontal-rule {
    min-width: 100px;
    width: 75%;
    margin-inline: auto; }
  .location-nav .collapse {
    margin: 0 auto; }
  .location-nav a {
    padding-block: 5px;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    color: #55556d;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem; }
    .location-nav a[disabled] {
      opacity: 0.5; }
    .location-nav a:hover {
      cursor: pointer;
      text-decoration: none; }
  .location-nav .card-header {
    background-color: #eef3f5;
    padding-block: 5px;
    border: none;
    padding-block: 5px;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    color: #9d9dad;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem; }
    .location-nav .card-header.disabled {
      opacity: 0.5; }
  .location-nav .card-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
    min-width: 100px;
    width: 16vw; }
  .location-nav .selected {
    color: #373636; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.actionBar {
  display: flex;
  width: 100%;
  flex: 0.1;
  align-items: flex-start;
  justify-content: space-between;
  padding-block: 1rem;
  border-top: 1px solid #677379; }
  @media (max-width: 420px) {
    .actionBar {
      padding: 0;
      padding-block: 1rem;
      align-items: center; } }
  @media (max-width: 575px) {
    .actionBar {
      justify-content: center; } }
  .actionBar .actionBar__left__typeScreen {
    max-width: none !important;
    align-self: center; }
  .actionBar .actionBar__left {
    max-width: 100px;
    flex-direction: row;
    justify-content: center; }
    @media (max-width: 575px) {
      .actionBar .actionBar__left {
        display: none; } }
    .actionBar .actionBar__left .actionBar__left__back {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-transform: uppercase;
      font-style: normal;
      text-decoration: underline;
      text-underline-offset: 3px;
      color: #30827b;
      font-size: 14px; }
    .actionBar .actionBar__left .actionBar__left__atSignUp {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-style: normal;
      color: #30827b;
      text-decoration: underline;
      text-underline-offset: 3px; }
  .actionBar .actionBar__right {
    display: flex;
    max-width: 300px;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    font-style: normal; }
    @media (max-width: 575px) {
      .actionBar .actionBar__right {
        flex: 1; } }
    .actionBar .actionBar__right .actionBar__continue {
      border-radius: 7px;
      padding: 8px 20px;
      font-size: 14px; }
      .actionBar .actionBar__right .actionBar__continue.btn-success {
        background-color: #cfd3e0;
        border-color: #cfd3e0;
        opacity: 1; }
        .actionBar .actionBar__right .actionBar__continue.btn-success:not(:disabled) {
          background-color: #55556d;
          border-color: #55556d;
          opacity: 1; }
        .actionBar .actionBar__right .actionBar__continue.btn-success:not(:disabled):hover {
          background-color: black;
          border-color: black;
          opacity: 1; }
        .actionBar .actionBar__right .actionBar__continue.btn-success:focus {
          box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5); }
      .actionBar .actionBar__right .actionBar__continue.btn-success.disabled {
        background-color: #cfd3e0;
        border-color: #cfd3e0;
        opacity: 1; }
        .actionBar .actionBar__right .actionBar__continue.btn-success.disabled:hover {
          background-color: #cfd3e0;
          border-color: #cfd3e0;
          opacity: 1; }
        .actionBar .actionBar__right .actionBar__continue.btn-success.disabled:focus {
          box-shadow: none; }
    .actionBar .actionBar__right .actionBar__save__and_continue,
    .actionBar .actionBar__right .actionBar__save__draft {
      margin-top: 0.5rem;
      color: #55556d;
      font-size: 0.8rem;
      font-weight: 500;
      text-decoration: underline; }
      @media (max-width: 420px) {
        .actionBar .actionBar__right .actionBar__save__and_continue,
        .actionBar .actionBar__right .actionBar__save__draft {
          margin-top: 1rem; } }
    .actionBar .actionBar__right .actionBar__save__and_continue {
      padding: 0; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.nav-placeholder {
  width: 16vw;
  background: #eef3f5;
  max-width: 200px;
  min-width: 130px; }
  @media (max-width: 768px) {
    .nav-placeholder {
      display: none; } }

.v2CreatePostScene {
  height: 100%;
  min-height: calc(100vh - 78px);
  width: 100%;
  display: flex;
  background-color: white; }
  .v2CreatePostScene .sidebar-button {
    display: none; }
    @media (max-width: 768px) {
      .v2CreatePostScene .sidebar-button {
        display: block;
        position: absolute;
        top: 84px;
        left: 20px;
        margin: 0;
        padding: 0px;
        z-index: 100;
        background: transparent;
        color: #30827b;
        outline: none;
        border: none;
        font-size: 24px; } }
    .v2CreatePostScene .sidebar-button:hover {
      outline: none; }
  .v2CreatePostScene main.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    z-index: 1; }
    .v2CreatePostScene main.content .rounded-input {
      border-radius: 4px; }
    .v2CreatePostScene main.content .react-tel-input .form-control {
      border-radius: 0;
      height: 38px; }
    .v2CreatePostScene main.content header h4 {
      font-weight: 700;
      font-size: 1.5rem;
      color: #333333;
      padding-inline: 10px; }
    .v2CreatePostScene main.content label:not([for^="file_label_type"]) {
      color: #65647f;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.088px;
      margin-bottom: 2px;
      margin-top: 4px; }
  .v2CreatePostScene .screen-title {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-transform: unset;
    font-weight: 700;
    font-size: 2rem;
    padding-inline: 10px; }
    @media (max-width: 768px) {
      .v2CreatePostScene .screen-title {
        padding-inline: 50px;
        font-size: 24px; } }
    .v2CreatePostScene .screen-title span {
      color: #9c9bac;
      font-size: 1.3rem; }

.printable-eap-image {
  display: none; }

@media print {
  body * {
    visibility: hidden; }
  .printable-section,
  .printable-section * {
    visibility: visible; }
  .printable-section {
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0; }
  @page {
    margin-block: 30px !important;
    margin-left: 0;
    margin-bottom: 30px !important;
    margin-right: 0; }
  .reviewEAP main {
    width: 100% !important;
    max-width: 100vw !important; }
    .reviewEAP main header {
      margin: 0; }
    .reviewEAP main .reviewEAPContainer .export-print-eap,
    .reviewEAP main .reviewEAPContainer .venue-map-file {
      display: none; }
    .reviewEAP main .reviewEAPContainer .title {
      background-color: transparent !important;
      color: #373636 !important; }
      .reviewEAP main .reviewEAPContainer .title .horizontal-rule {
        background-image: repeating-linear-gradient(to right, #78868d, #78868d 8px, transparent 8px, transparent 16px) !important; }
    .reviewEAP main .reviewEAPContainer .section-header {
      background-color: transparent !important; }
    .reviewEAP main .reviewEAPContainer .printable-eap-image {
      display: block;
      max-height: 500px;
      height: 100%;
      margin-top: 10px; }
  .actionBar {
    display: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.publish-or-update-step {
  display: flex;
  flex-grow: 1;
  flex-direction: row; }
  .publish-or-update-step main {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1; }
    .publish-or-update-step main .content {
      min-height: 25vh; }
    .publish-or-update-step main header {
      padding-top: 5px;
      width: 100%; }
    .publish-or-update-step main .locations .location-title {
      padding: 10px;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 16px;
      color: #454e56;
      cursor: pointer; }
      .publish-or-update-step main .locations .location-title .arrow {
        color: #677379;
        margin-right: 10px; }
      .publish-or-update-step main .locations .location-title .location-name {
        font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 16px;
        color: #677379; }
    .publish-or-update-step main .locations .bottom-border {
      border-bottom: 1px solid #677379; }
    .publish-or-update-step main .locations .one-location p {
      display: inline-block;
      margin: 0 0 0 5px;
      color: #677379; }
    .publish-or-update-step main .job-name {
      display: flex;
      border-bottom: 1px solid #979797; }
      .publish-or-update-step main .job-name .screen-title {
        text-transform: uppercase;
        font-size: medium;
        padding-inline-start: 10px;
        padding-inline-end: 0;
        padding-block: 10px;
        color: #454e56; }
    .publish-or-update-step main .post-section {
      color: #454e56;
      border: 2px solid #65647f;
      margin-block: 10px;
      display: flex;
      flex-direction: column;
      padding: 20px; }
      .publish-or-update-step main .post-section .bg-gray {
        background-color: #9d9cad;
        margin-bottom: 10px; }
        .publish-or-update-step main .post-section .bg-gray .title {
          font-size: 16px;
          background-color: white;
          color: #55556d;
          line-height: 14px;
          background-color: white;
          width: min-content;
          text-wrap: nowrap;
          margin: 0 auto;
          padding-inline: 10px; }
      .publish-or-update-step main .post-section .no-listing-text {
        color: #65647f;
        font-family: "nimbus-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 17px;
        text-align: left; }
      .publish-or-update-step main .post-section .estimated-total-title {
        font-size: 16px;
        color: #373636;
        margin-block: 5px;
        background-color: #fee02d;
        padding-inline: 10px;
        padding-block: 2px; }
      .publish-or-update-step main .post-section h6 {
        text-transform: uppercase; }
      .publish-or-update-step main .post-section .event-info {
        display: flex;
        flex-direction: column;
        width: 100%; }
        .publish-or-update-step main .post-section .event-info .info-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px; }
          .publish-or-update-step main .post-section .event-info .info-item p {
            margin: 0; }
      .publish-or-update-step main .post-section .billing-estimate {
        padding-right: 1.5rem; }
        @media (max-width: 768px) {
          .publish-or-update-step main .post-section .billing-estimate {
            padding-right: 0; } }
      .publish-or-update-step main .post-section .summary {
        padding-right: 0.25rem; }
        @media (max-width: 768px) {
          .publish-or-update-step main .post-section .summary {
            padding-right: 15px; } }
      .publish-or-update-step main .post-section .actions {
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
        .publish-or-update-step main .post-section .actions .actionBar__continue {
          border-radius: 7px;
          margin: 10px 0 10px 0; }
          .publish-or-update-step main .post-section .actions .actionBar__continue.btn-success {
            background-color: #55556d;
            border-color: #55556d; }
            .publish-or-update-step main .post-section .actions .actionBar__continue.btn-success:hover {
              background-color: black;
              border-color: black; }
            .publish-or-update-step main .post-section .actions .actionBar__continue.btn-success:focus {
              box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5); }
        .publish-or-update-step main .post-section .actions .actionBar__save__draft {
          margin: 20px 0 10px 0;
          font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          color: #30827b;
          font-size: 0.8rem;
          text-align: center;
          text-decoration: underline; }
  @media (max-width: 768px) {
    .publish-or-update-step .screen-title {
      margin-left: 5px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventSetting {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  align-self: center;
  min-width: 50%; }
  .eventSetting header {
    padding-top: 30px;
    width: 100%;
    text-align: center; }
    .eventSetting header .screen-title {
      line-height: 50px;
      background-color: #fee02d;
      outline: 1px solid #fee02d; }
  .eventSetting main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    min-height: 40vh;
    align-content: center;
    width: 100%;
    min-width: 30vw; }
    .eventSetting main i.setting-button-icon {
      font-size: 18px;
      color: #33cccc;
      padding-inline: 10px; }
    .eventSetting main button.setting-button {
      min-height: 55px;
      width: 100%;
      border: 1px solid #55556d;
      color: #65647f;
      background-color: transparent;
      border-radius: 5px;
      padding: 5px 10px 5px 20px;
      align-items: center;
      font-size: 18px;
      text-align: start; }
      .eventSetting main button.setting-button:hover:not(.selected) {
        box-shadow: 0 2px 10px #c5c3c6; }
      .eventSetting main button.setting-button:focus {
        background-color: #55556d;
        box-shadow: 0 2px 10px #55556d;
        color: white;
        border: none;
        outline: 1px solid #55556d; }
      .eventSetting main button.setting-button .setting-tip {
        font-size: 15px;
        font-weight: normal;
        color: #55556d; }
      .eventSetting main button.setting-button .setting-tip.selected {
        color: white; }
    .eventSetting main button.selected {
      background-color: #55556d;
      color: white;
      box-shadow: 0 2px 10px #55556d; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventSettingDetail {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  align-self: center;
  min-width: 50%; }
  .eventSettingDetail header {
    padding-top: 30px;
    width: 100%;
    text-align: center; }
    .eventSettingDetail header .screen-title {
      line-height: 50px;
      background-color: #fee02d;
      outline: 1px solid #fee02d; }
  .eventSettingDetail main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    min-height: 50vh;
    align-content: center;
    width: 100%;
    min-width: 30vw;
    margin-block: 10px; }
    .eventSettingDetail main i.setting-button-icon {
      font-size: 18px;
      color: #33cccc;
      padding-inline: 10px; }
    .eventSettingDetail main button.setting-detail-button {
      height: 55px;
      width: 100%;
      border: 1px solid #55556d;
      color: #65647f;
      background-color: transparent;
      border-radius: 5px;
      padding: 5px 10px 5px 20px;
      align-items: center;
      font-size: 18px;
      text-align: center; }
      .eventSettingDetail main button.setting-detail-button:hover:not(.selected) {
        box-shadow: 0 2px 10px #c5c3c6; }
      .eventSettingDetail main button.setting-detail-button:focus {
        background-color: #55556d;
        box-shadow: 0 2px 10px #55556d;
        color: white;
        border: none;
        outline: 1px solid #55556d; }
    .eventSettingDetail main button.selected {
      background-color: #55556d;
      color: white;
      box-shadow: 0 2px 10px #55556d; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventType {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  align-self: center;
  min-width: 50%; }
  .eventType header {
    padding-top: 30px;
    width: 100%;
    text-align: center; }
    .eventType header .screen-title {
      line-height: 50px;
      background-color: #fee02d;
      outline: 1px solid #fee02d; }
  .eventType main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    min-height: 40vh;
    align-content: center;
    width: 100%;
    min-width: 30vw; }
    .eventType main div.buttons {
      justify-content: center;
      flex-direction: row; }
    .eventType main div.choice {
      text-align: center;
      margin-inline: 20px;
      margin-block: 10px; }
    .eventType main div.choice a {
      text-decoration: none;
      color: #55556d; }
    .eventType main button.type-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 220px;
      height: 100%;
      border: 1px solid #55556d;
      background-color: transparent;
      border-radius: 10px;
      padding: 5px 10px;
      font-weight: bold;
      margin-bottom: 10px; }
      .eventType main button.type-button:hover:not(.selected) {
        box-shadow: 0 2px 10px #c5c3c6; }
      .eventType main button.type-button:focus {
        background-color: #55556d;
        box-shadow: 0 2px 10px #55556d;
        color: white;
        border: none;
        outline: 1px solid #55556d; }
      .eventType main button.type-button .event-type {
        padding: 30px 20px;
        margin: 0;
        font-size: 20px;
        color: #65647f; }
      .eventType main button.type-button .type-tip {
        margin-inline: 0;
        margin-block: 15px 0;
        text-align: left;
        font-size: 16px;
        color: #55556d;
        font-weight: normal; }
    .eventType main button.selected {
      background-color: #55556d;
      color: white;
      box-shadow: 0 2px 10px #55556d; }
      .eventType main button.selected .event-type {
        color: white; }
      .eventType main button.selected .type-tip {
        color: white; }

.reportToContact .invalid-feedback-displayed {
  color: #f33 !important;
  font-size: 80%;
  padding-top: 4px; }

.reportToContact .react-tel-input input {
  border-color: #65647f; }

.reportToContact .react-tel-input .flag-dropdown {
  border-radius: 0;
  border-color: #65647f; }

.reportToContact .remove-contact-btn {
  font-size: 28px;
  color: #65647f; }
  .reportToContact .remove-contact-btn:hover {
    cursor: pointer; }
  @media (max-width: 1100px) {
    .reportToContact .remove-contact-btn {
      margin-top: 0px; } }

.reportToContact .account-contact {
  display: block;
  margin-block: 10px; }
  .reportToContact .account-contact label {
    color: #55556d !important; }
  @media (max-width: 768px) {
    .reportToContact .account-contact {
      display: none; } }

.reportToContact .account-contact-mobile {
  display: none;
  width: 100%;
  margin-block: 10px; }
  .reportToContact .account-contact-mobile label {
    color: #55556d !important; }
  @media (max-width: 768px) {
    .reportToContact .account-contact-mobile {
      display: block; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.shiftDetails {
  width: 100%;
  margin-block-end: 12px;
  display: block;
  overflow-x: auto;
  white-space: nowrap; }
  @media (max-width: 600px) {
    .shiftDetails {
      display: none; } }
  .shiftDetails table {
    border: 1px solid #e6e6e6;
    color: #677379;
    width: 100%;
    overflow-x: scroll; }
    .shiftDetails table thead th {
      padding-top: 10px;
      border-right: 1px solid #e6e6e6;
      font-size: 11.5px;
      text-align: left;
      padding-inline: 10px;
      font-weight: bold; }
      .shiftDetails table thead th:last-child {
        border-right: none;
        display: flex;
        justify-content: center;
        align-items: center; }
        .shiftDetails table thead th:last-child .add-button {
          font-size: 12px;
          letter-spacing: 1px; }
    .shiftDetails table tbody tr td {
      padding-bottom: 10px;
      border-right: 1px solid #e6e6e6;
      font-size: 12px;
      text-align: left;
      padding-inline: 10px; }
      .shiftDetails table tbody tr td:last-child {
        border-right: none;
        display: flex;
        justify-content: center;
        align-items: center; }
        .shiftDetails table tbody tr td:last-child .custom-delete-btn {
          font-size: 12px;
          letter-spacing: -0.6px; }

.shiftDetailsMobile {
  border-bottom: 1px solid #dcdcdd;
  margin-bottom: 20px;
  padding-block-end: 10px; }
  .shiftDetailsMobile .attribute {
    font-size: 12px;
    color: #677379;
    font-size: 16px; }
  .shiftDetailsMobile .location-title {
    padding: 10px;
    font-weight: bold;
    font-size: 16px;
    color: #454e56; }
  .shiftDetailsMobile .actions {
    display: flex;
    gap: 10px;
    align-items: flex-end;
    margin-top: 10px; }
    .shiftDetailsMobile .actions span.editBtn {
      border: none;
      background: none;
      text-decoration: underline;
      text-transform: uppercase;
      font-size: 13px;
      cursor: pointer;
      color: #33cccc;
      font-style: normal;
      padding: 0;
      margin: 0; }
      .shiftDetailsMobile .actions span.editBtn:focus {
        box-shadow: none; }
      .shiftDetailsMobile .actions span.editBtn.selected {
        box-shadow: none; }
    .shiftDetailsMobile .actions button.deleteBtn {
      text-transform: uppercase;
      border: none;
      background: none;
      text-decoration: underline;
      cursor: pointer;
      font-style: normal;
      padding: 0;
      margin: 0;
      color: #677379;
      font-size: 13px; }
      .shiftDetailsMobile .actions button.deleteBtn:focus {
        outline: none; }
      .shiftDetailsMobile .actions button.deleteBtn.selected {
        outline: none; }
  @media (min-width: 600px) {
    .shiftDetailsMobile {
      display: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.tip {
  min-height: 30px;
  padding: 3.5px 0;
  align-self: flex-start; }
  .tip .tip-description {
    display: flex;
    color: #454e56;
    font-size: 14.5px;
    margin-left: 15px;
    padding-right: 5px;
    padding-left: 25px;
    padding-block: 8px;
    background-color: #fee02d; }
  .tip .tip-title {
    display: flex;
    background-color: #f33;
    height: 20px;
    position: absolute;
    top: 12px;
    justify-content: center;
    padding: 5px; }
    .tip .tip-title p {
      font-weight: bold;
      color: white;
      align-self: center;
      margin-top: 15px; }

@media (max-width: 768px) {
  .tip {
    margin: 10px auto 0 auto;
    max-width: 100%; } }

.alert-long-shift {
  border: 2px solid #fee02d;
  background-color: rgba(254, 224, 45, 0.3);
  font-size: 12px;
  display: flex;
  color: #65647f;
  padding-block: 2px;
  margin-top: 4px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.emergencyActionPlan {
  display: flex;
  flex-grow: 1;
  flex-direction: row; }
  .emergencyActionPlan .bold {
    font-weight: 800; }
  .emergencyActionPlan main {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    padding-inline: 40px;
    max-width: 60vw; }
    @media (max-width: 1024px) {
      .emergencyActionPlan main {
        padding-inline: 20px;
        max-width: 80vw; } }
    @media (max-width: 768px) {
      .emergencyActionPlan main {
        max-width: 100vw; } }
    .emergencyActionPlan main header {
      padding-top: 5px;
      width: 100%;
      border-bottom: 1px solid #677379; }
    .emergencyActionPlan main .remove-btn {
      cursor: pointer; }
    .emergencyActionPlan main div > p {
      margin: 0;
      color: #65647f;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.088px; }
    .emergencyActionPlan main .emergencyActionPlanContainer {
      min-height: 25vh;
      display: flex;
      flex-direction: column;
      padding-block: 20px;
      gap: 30px; }
      .emergencyActionPlan main .emergencyActionPlanContainer .title {
        background-color: #f33;
        color: white;
        font-size: 24px;
        font-weight: 800;
        margin-block-end: 15px;
        padding: 20px;
        border-radius: 5px;
        line-height: 20px;
        width: auto; }
      .emergencyActionPlan main .emergencyActionPlanContainer .eap-button-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px; }
        .emergencyActionPlan main .emergencyActionPlanContainer .eap-button-section button.eap-auto-generate-button {
          background-color: transparent;
          border-radius: 5px;
          height: 50px;
          color: #65647f;
          width: 60%;
          margin: 0 auto;
          border: 2px solid #65647f; }
          .emergencyActionPlan main .emergencyActionPlanContainer .eap-button-section button.eap-auto-generate-button:hover {
            text-decoration: underline; }
          .emergencyActionPlan main .emergencyActionPlanContainer .eap-button-section button.eap-auto-generate-button:focus {
            box-shadow: 0 2px 10px #65647f;
            outline: 1px solid #65647f;
            background-color: #65647f;
            color: white; }
        .emergencyActionPlan main .emergencyActionPlanContainer .eap-button-section button.selected {
          outline: 0;
          background-color: #65647f;
          color: white; }
        .emergencyActionPlan main .emergencyActionPlanContainer .eap-button-section .eap-upload-section {
          width: 60%;
          border: 2px solid #65647f;
          border-radius: 5px;
          margin: 0 auto; }
        .emergencyActionPlan main .emergencyActionPlanContainer .eap-button-section .eap-upload-button {
          width: 100%;
          height: 50px;
          background-color: transparent;
          color: #65647f;
          border: none;
          outline: none; }
          .emergencyActionPlan main .emergencyActionPlanContainer .eap-button-section .eap-upload-button:hover {
            text-decoration: underline; }
      .emergencyActionPlan main .emergencyActionPlanContainer .upload-eap-doc {
        padding-inline: 10px; }
        .emergencyActionPlan main .emergencyActionPlanContainer .upload-eap-doc .upload-eap-doc-button {
          background-color: transparent;
          border: none;
          outline: none;
          color: #30827b;
          position: relative;
          font-weight: 500;
          text-transform: uppercase;
          font-style: normal; }
          .emergencyActionPlan main .emergencyActionPlanContainer .upload-eap-doc .upload-eap-doc-button:hover {
            text-decoration: underline; }

.reviewEAP {
  display: flex;
  flex-grow: 1;
  flex-direction: row; }
  .reviewEAP main {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    padding-inline: 40px;
    max-width: 60vw; }
    @media (max-width: 1024px) {
      .reviewEAP main {
        padding-inline: 20px;
        max-width: 80vw; } }
    @media (max-width: 768px) {
      .reviewEAP main {
        max-width: 100vw; } }
    .reviewEAP main input,
    .reviewEAP main textarea {
      border-color: #4d4d4d; }
      .reviewEAP main input:focus,
      .reviewEAP main textarea:focus {
        box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(77, 77, 77, 0.25);
        outline: 0;
        border-color: #4d4d4d; }
      .reviewEAP main input::placeholder,
      .reviewEAP main textarea::placeholder {
        font-style: italic;
        color: #9d9cad;
        font-family: "nimbus-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .reviewEAP main .label-line {
      background-color: #eceff1;
      height: 10px;
      flex: 1;
      align-self: center;
      margin-left: 8px; }
    .reviewEAP main header {
      padding-top: 5px;
      width: 100%;
      border-bottom: 1px solid #677379; }
    .reviewEAP main .job-details-section-title {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.088px;
      margin-block: 0;
      color: #4d4d4d;
      padding-left: 5px; }
    .reviewEAP main .invalid-feedback-displayed {
      color: #f33;
      font-size: 80%;
      padding-top: 4px; }
    .reviewEAP main .reviewEAPContainer {
      min-height: 25vh;
      display: flex;
      flex-direction: column;
      padding-block: 20px; }
      .reviewEAP main .reviewEAPContainer .export-print-eap {
        margin-top: 10px;
        align-self: flex-start; }
      .reviewEAP main .reviewEAPContainer .title {
        background-color: #f33;
        color: white;
        font-size: 24px;
        font-weight: 800;
        margin-block-end: 15px;
        padding: 10px;
        line-height: 20px;
        width: auto; }
        .reviewEAP main .reviewEAPContainer .title p {
          margin: 0; }
        .reviewEAP main .reviewEAPContainer .title .title-help-copy {
          font-size: 16px;
          font-weight: normal;
          line-height: 20px;
          width: auto; }
      .reviewEAP main .reviewEAPContainer .location-header {
        font-size: 18px;
        color: #4d4d4d; }
      .reviewEAP main .reviewEAPContainer .venue-location-section {
        padding-left: 20px;
        color: #55556d; }
        .reviewEAP main .reviewEAPContainer .venue-location-section .location-name {
          font-size: 15px;
          margin: 0;
          line-height: 21px; }
      .reviewEAP main .reviewEAPContainer .location-section {
        color: #55556d; }
        .reviewEAP main .reviewEAPContainer .location-section .location-name {
          font-size: 15px;
          margin: 0;
          line-height: 21px; }
      .reviewEAP main .reviewEAPContainer .location-coordinates {
        font-size: 14px; }
        @media (max-width: 768px) {
          .reviewEAP main .reviewEAPContainer .location-coordinates {
            margin-bottom: 1rem; } }
      .reviewEAP main .reviewEAPContainer .location-phone {
        font-size: 17px; }
      .reviewEAP main .reviewEAPContainer .section-header {
        min-height: 25px;
        color: #373636;
        font-size: 20px;
        line-height: 20px;
        margin-block-end: 15px;
        text-transform: uppercase;
        letter-spacing: 0;
        padding: 5px;
        background-color: #fee02d; }
        .reviewEAP main .reviewEAPContainer .section-header button {
          font-size: 14px; }
      .reviewEAP main .reviewEAPContainer .edit-uploaded-eap {
        color: #30827b;
        font-size: 16px;
        line-height: normal;
        text-transform: lowercase; }
        .reviewEAP main .reviewEAPContainer .edit-uploaded-eap:hover {
          text-decoration: underline;
          cursor: pointer; }
      .reviewEAP main .reviewEAPContainer .venueMap {
        padding-bottom: 15px; }
        .reviewEAP main .reviewEAPContainer .venueMap .venue-map-preview {
          max-width: 200px;
          max-height: 200px; }
        .reviewEAP main .reviewEAPContainer .venueMap .custom-file-label {
          top: inherit; }
        .reviewEAP main .reviewEAPContainer .venueMap .upload-vmap-label {
          color: #78868d;
          font-size: 14px;
          line-height: 17px; }
        .reviewEAP main .reviewEAPContainer .venueMap .venue-map-modal .modal-dialog {
          max-width: 80vw; }
        .reviewEAP main .reviewEAPContainer .venueMap .venue-map-modal .modal-body {
          text-align: center; }
          .reviewEAP main .reviewEAPContainer .venueMap .venue-map-modal .modal-body img {
            max-width: calc(80vw - 2rem); }
      .reviewEAP main .reviewEAPContainer .uploadedEAP {
        padding-block-end: 15px; }
        .reviewEAP main .reviewEAPContainer .uploadedEAP .pdf-preview {
          width: 500px;
          min-height: 300px; }
        @media (max-width: 1100px) {
          .reviewEAP main .reviewEAPContainer .uploadedEAP .pdf-preview {
            width: 100%; } }
      .reviewEAP main .reviewEAPContainer .emergency-location-type {
        color: #4d4d4d;
        font-size: 16px;
        font-weight: bold; }
      @media (max-width: 1100px) {
        .reviewEAP main .reviewEAPContainer .location-name {
          font-size: 16px; }
        .reviewEAP main .reviewEAPContainer .location-phone {
          font-size: 14px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.requiredCredentials {
  display: flex;
  flex-grow: 1;
  flex-direction: row; }
  .requiredCredentials main {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    padding-inline: 40px;
    max-width: 60vw; }
    @media (max-width: 1024px) {
      .requiredCredentials main {
        padding-inline: 20px;
        max-width: 80vw; } }
    @media (max-width: 768px) {
      .requiredCredentials main {
        max-width: 100vw; } }
    .requiredCredentials main .requiredCredentialsContainer {
      display: flex;
      flex-direction: column;
      padding-block: 20px;
      margin-block: 40px;
      min-height: 25vh;
      gap: 30px; }
      .requiredCredentials main .requiredCredentialsContainer .info-text {
        background-color: #fee02d;
        border-radius: 2px;
        padding: 10px 16px;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        margin: 0 auto;
        width: 90%; }
      .requiredCredentials main .requiredCredentialsContainer .eap-button-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px; }
        .requiredCredentials main .requiredCredentialsContainer .eap-button-section button.yes-button {
          background-color: transparent;
          border-radius: 5px;
          height: 50px;
          color: #65647f;
          width: 90%;
          margin: 0 auto;
          border: 2px solid #65647f; }
          .requiredCredentials main .requiredCredentialsContainer .eap-button-section button.yes-button:hover {
            text-decoration: underline; }
          .requiredCredentials main .requiredCredentialsContainer .eap-button-section button.yes-button:focus {
            box-shadow: 0 2px 10px #65647f;
            outline: 1px solid #65647f;
            background-color: #65647f;
            color: white; }
        .requiredCredentials main .requiredCredentialsContainer .eap-button-section button.selected {
          outline: 1px solid #65647f;
          background-color: #65647f;
          border-color: #65647f;
          color: white; }
        .requiredCredentials main .requiredCredentialsContainer .eap-button-section button.no-button.selected {
          outline: 0;
          border-top-right-radius: 2px;
          border-top-left-radius: 2px;
          background-color: #65647f;
          border-color: #65647f;
          color: white; }
        .requiredCredentials main .requiredCredentialsContainer .eap-button-section .no-section.selected {
          color: white; }
        .requiredCredentials main .requiredCredentialsContainer .eap-button-section .no-section {
          width: 90%;
          border: 2px solid #65647f;
          border-radius: 5px;
          margin: 0 auto; }
        .requiredCredentials main .requiredCredentialsContainer .eap-button-section button.no-button {
          width: 100%;
          height: 50px;
          background-color: transparent;
          color: #65647f;
          border: none;
          outline: none; }
          .requiredCredentials main .requiredCredentialsContainer .eap-button-section button.no-button:hover {
            text-decoration: underline; }
      .requiredCredentials main .requiredCredentialsContainer .section-content {
        padding: 20px; }
        .requiredCredentials main .requiredCredentialsContainer .section-content .required-credentials input:focus {
          box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
          outline: 0;
          border-color: #65647f; }
        .requiredCredentials main .requiredCredentialsContainer .section-content .horizontal-rule {
          margin-top: 0;
          margin-bottom: 10px; }
        .requiredCredentials main .requiredCredentialsContainer .section-content .remove-doc-btn {
          font-size: 28px;
          color: #55556d;
          margin-left: 10px; }
          .requiredCredentials main .requiredCredentialsContainer .section-content .remove-doc-btn:hover {
            cursor: pointer; }
          @media (max-width: 1100px) {
            .requiredCredentials main .requiredCredentialsContainer .section-content .remove-doc-btn {
              margin-top: 0px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.supplies {
  display: flex;
  flex-grow: 1;
  flex-direction: row; }
  .supplies main {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    padding-inline: 40px;
    max-width: 60vw; }
    @media (max-width: 1024px) {
      .supplies main {
        padding-inline: 20px;
        max-width: 80vw; } }
    @media (max-width: 768px) {
      .supplies main {
        max-width: 100vw; } }
    .supplies main small.sub-text {
      display: block;
      color: #b9b6ba; }
    .supplies main header {
      padding-top: 5px;
      width: 100%;
      border-bottom: 1px solid #677379; }
    .supplies main .detailsContainer {
      min-height: 25vh;
      display: flex;
      flex-direction: column;
      padding-block: 10px; }
      .supplies main .detailsContainer .equipment-grid {
        column-gap: 5px;
        margin-block-start: 10px; }
      .supplies main .detailsContainer label.custom-control-label::after {
        cursor: pointer; }
      .supplies main .detailsContainer p.select-supplies-text {
        background-color: #fee02d;
        padding: 0.5rem 0.75rem;
        margin: 0; }
      .supplies main .detailsContainer .equipment-item {
        text-transform: uppercase;
        color: #65647f; }
        .supplies main .detailsContainer .equipment-item label {
          font-weight: bold !important; }
      .supplies main .detailsContainer p.med-kit {
        margin: 0;
        color: #65647f;
        font-size: 15px;
        font-style: italic;
        color: #55556d; }
      .supplies main .detailsContainer .other-supplies {
        color: #65647f;
        margin-bottom: 0; }
        .supplies main .detailsContainer .other-supplies input:focus {
          box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
          outline: 0;
          border-color: #65647f; }
      .supplies main .detailsContainer p.supplies-select {
        width: 100%;
        background-color: #fee02d;
        padding: 0.5rem 0.75rem; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

ul.location-list {
  margin: 0;
  padding: 0; }

li.location-list-item {
  list-style-type: none; }
  @media (max-width: 768px) and (min-width: 600px) {
    li.location-list-item {
      width: 72vw; } }
  li.location-list-item .card {
    border: none;
    border-bottom: 1px solid #979797; }
  li.location-list-item .location-item-title {
    padding: 10px;
    font-size: 1rem;
    color: #454e56;
    font-weight: bold;
    background: none;
    border: none;
    cursor: pointer; }
    li.location-list-item .location-item-title .arrow {
      color: #677379;
      margin-right: 10px; }
    li.location-list-item .location-item-title .location-name {
      font-size: 16px;
      color: #677379; }
  li.location-list-item .location-item-body {
    padding: 0; }

.cursor-pointer {
  cursor: pointer; }

.far.fa-calendar-alt {
  color: #55556d; }

.fas.fa-plus {
  color: #55556d; }

.fas.fa-minus {
  color: #55556d; }

.one-time-shift {
  display: flex;
  flex-direction: column;
  padding-block: 20px; }
  .one-time-shift .input-group .input-group-append span,
  .one-time-shift .input-group .input-group-prepend span {
    border-color: #65647f; }
  .one-time-shift label {
    font-family: Gotham-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .one-time-shift input {
    border-color: #65647f; }
    .one-time-shift input:focus {
      box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
      outline: 0;
      border-color: #65647f; }
  .one-time-shift .flatpickr-input.active {
    box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
    outline: 0;
    border-color: #65647f; }
  .one-time-shift .disabled {
    pointer-events: none;
    opacity: 0.5; }
  .one-time-shift .lbr {
    text-align: center; }
  .one-time-shift .fbr {
    text-align: center; }
  .one-time-shift .remove-border-r {
    border-right: none; }
  .one-time-shift h5 {
    line-height: normal; }
  .one-time-shift .shiftName {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem; }
    .one-time-shift .shiftName .hrs {
      text-transform: lowercase;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 14px; }
  .one-time-shift .multi-day {
    transform: scale(0.9);
    -webkit-transform-origin-x: 16px; }

.recurring-shift,
.shift-generator {
  margin-block-end: 20px; }
  .recurring-shift .lbr,
  .shift-generator .lbr {
    text-align: center; }
  .recurring-shift .fbr,
  .shift-generator .fbr {
    text-align: center; }
  .recurring-shift .remove-border-r,
  .shift-generator .remove-border-r {
    border-right: none; }
  .recurring-shift .left-indent,
  .shift-generator .left-indent {
    padding-left: 0; }
  .recurring-shift .shift-name,
  .shift-generator .shift-name {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1rem; }
  .recurring-shift .mini-helper,
  .shift-generator .mini-helper {
    color: #979797;
    font-weight: 400;
    padding-top: 20px;
    width: 45px; }
  .recurring-shift .input-group .input-group-append span,
  .recurring-shift .input-group .input-group-prepend span,
  .shift-generator .input-group .input-group-append span,
  .shift-generator .input-group .input-group-prepend span {
    border-color: #65647f; }
  .recurring-shift label,
  .shift-generator label {
    font-family: Gotham-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .recurring-shift input,
  .recurring-shift select,
  .shift-generator input,
  .shift-generator select {
    border-color: #65647f; }
    .recurring-shift input:focus,
    .recurring-shift select:focus,
    .shift-generator input:focus,
    .shift-generator select:focus {
      box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
      outline: 0;
      border-color: #65647f; }
  .recurring-shift .flatpickr-input.active,
  .shift-generator .flatpickr-input.active {
    box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
    outline: 0;
    border-color: #65647f; }

.brand-teal {
  color: #30827b; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.oneTimeSchedule {
  display: flex;
  text-align: start;
  flex-direction: column; }
  .oneTimeSchedule .bottom-line {
    border-bottom: 2px solid #c5c3c6;
    padding-bottom: 25px; }
  .oneTimeSchedule .content {
    min-height: 25vh; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.recurringSchedule {
  display: flex;
  text-align: start;
  flex-direction: column;
  margin-block-end: 15px; }
  .recurringSchedule .content {
    min-height: 25vh; }
  .recurringSchedule .shift-generator {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    padding-block: 20px; }
    .recurringSchedule .shift-generator .shiftName {
      display: flex;
      align-items: center;
      justify-content: space-between; }
  .recurringSchedule .bottom-line {
    border-bottom: 2px solid #c5c3c6;
    padding-bottom: 25px; }
  .recurringSchedule .recurringDaysForm {
    height: 100px;
    margin-block: 25px;
    display: flex;
    flex-direction: column; }
    .recurringSchedule .recurringDaysForm .error {
      width: 100%;
      text-align: center;
      margin-block: 0.25rem;
      font-size: 80%;
      color: #f33; }
    .recurringSchedule .recurringDaysForm p {
      margin: 0; }
    .recurringSchedule .recurringDaysForm .days {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px; }
      @media (max-width: 768px) {
        .recurringSchedule .recurringDaysForm .days {
          flex-wrap: wrap; } }
      .recurringSchedule .recurringDaysForm .days input.day {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 100%;
        width: 60px;
        height: 60px;
        margin: 5px;
        outline: none;
        cursor: pointer;
        position: relative;
        border: 2px solid #55556d; }
        .recurringSchedule .recurringDaysForm .days input.day::before {
          content: attr(data-day);
          font-weight: bold;
          color: #55556d;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: white; }
        .recurringSchedule .recurringDaysForm .days input.day:checked::before {
          content: attr(data-day);
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #55556d;
          color: white;
          box-shadow: 0 0 0 0.2rem rgba(85, 85, 109, 0.25); }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.scheduleChoice {
  display: flex;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  min-width: 90%;
  margin: 0 auto;
  height: 100%; }
  .scheduleChoice header.header-choice {
    padding-top: 30px;
    width: 100%;
    text-align: center; }
    .scheduleChoice header.header-choice .screen-title {
      line-height: 50px;
      background-color: #fee02d;
      outline: 1px solid #fee02d; }
    @media (max-width: 768px) {
      .scheduleChoice header.header-choice .screen-title {
        line-height: 35px;
        font-size: 20px;
        padding-inline: 50px; } }
    @media (max-width: 768px) {
      .scheduleChoice header.header-choice {
        padding-top: 5px; } }
  .scheduleChoice main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    min-height: 40vh;
    align-content: center;
    width: 100%;
    min-width: 30vw;
    padding-inline: 0; }
    .scheduleChoice main .choice {
      display: flex;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 10px; }
    .scheduleChoice main button.type-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 220px;
      height: 100%;
      border: 1px solid #55556d;
      background-color: transparent;
      border-radius: 10px;
      padding: 5px 10px;
      font-weight: bold;
      font-size: 18px;
      margin-inline: 10px;
      margin-bottom: 10px; }
      .scheduleChoice main button.type-button:hover:not(.selected) {
        box-shadow: 0 2px 10px #55556d; }
      .scheduleChoice main button.type-button:focus {
        background-color: transparent;
        color: #55556d;
        outline: none; }
      .scheduleChoice main button.type-button .type {
        padding: 30px 10px;
        margin: 0;
        font-size: 20px;
        color: #65647f; }
      .scheduleChoice main button.type-button .type-tip {
        margin-inline: 0;
        margin-block: 15px 0;
        text-align: left;
        font-size: 16px;
        color: #55556d;
        font-weight: normal; }
      .scheduleChoice main button.type-button.selected {
        background-color: #55556d;
        box-shadow: 0 2px 10px #55556d; }
        .scheduleChoice main button.type-button.selected .type {
          color: white; }
        .scheduleChoice main button.type-button.selected .type-tip {
          color: white; }
    .scheduleChoice main .buttons {
      justify-content: center;
      flex-direction: row; }
    .scheduleChoice main p.text {
      font-size: 0.9em;
      text-align: start;
      margin: 0 5px 0 5px;
      color: #454e56; }

@media (max-width: 800px) {
  .scheduleChoice {
    min-width: 50%; }
  main {
    min-width: 50vw; } }

@media (max-width: 768px) {
  .scheduleChoice {
    padding-block: 40px 0;
    padding-inline: 10px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.billing-info {
  border-bottom: 2px solid #c5c3c6; }
  .billing-info .billing-text h6 {
    display: inline-block;
    color: #65647f;
    font-size: 16px;
    margin: 0; }
  .billing-info .billing-text .payment-source-text {
    color: #65647f;
    font-size: 15px;
    line-height: 20px; }
  .billing-info .card-panel {
    border: 1px solid black;
    margin-bottom: 20px;
    background-color: #eef3f5;
    padding: 15px 20px;
    box-shadow: rgba(50, 50, 93, 0.15) 0px 1px 3px, rgba(0, 0, 0, 0.02) 0px 1px 0px; }
    .billing-info .card-panel .plaid-link-button {
      margin: 0 auto;
      display: block;
      text-align: center;
      padding: 4px 16px;
      text-transform: uppercase;
      font-size: 14px;
      color: #fff;
      background-color: #55556d;
      border: 0;
      border-radius: 4px;
      margin-top: 10px; }
      .billing-info .card-panel .plaid-link-button:hover, .billing-info .card-panel .plaid-link-button:active, .billing-info .card-panel .plaid-link-button:focus, .billing-info .card-panel .plaid-link-button:active:focus {
        background-color: black;
        outline: none; }
    .billing-info .card-panel .hidden {
      display: none; }
    .billing-info .card-panel .card-panel-title h6 {
      margin: 5px 0 0 0;
      display: inline-block;
      color: #454e56; }
    .billing-info .card-panel .card-panel-title p {
      font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      display: block;
      color: #677379;
      font-size: 12px;
      margin: 0 0 5px 25px;
      line-height: 12px; }
  .billing-info .card-info {
    display: flex;
    flex-direction: column; }
    .billing-info .card-info .card-details p {
      color: #454e56;
      font-weight: bold;
      margin-bottom: 8px; }
    .billing-info .card-info .add-card-btn {
      text-transform: uppercase;
      font-size: 14px;
      color: #30827b;
      padding: 0 !important;
      text-align: start; }
      .billing-info .card-info .add-card-btn:focus {
        box-shadow: none;
        outline: none; }
  .billing-info .new-credit-card-v2 {
    display: flex;
    flex-direction: column; }
    .billing-info .new-credit-card-v2 .stripe-card {
      max-width: 496px;
      display: block;
      margin: 10px 0 20px 0;
      min-width: 200px;
      padding: 10px 14px;
      font-size: 1em;
      box-shadow: rgba(50, 50, 93, 0.15) 0px 1px 3px, rgba(0, 0, 0, 0.02) 0px 1px 0px;
      border: 0;
      outline: 0;
      border-radius: 4px; }
    .billing-info .new-credit-card-v2 .actions {
      margin: 0.5rem; }
    .billing-info .new-credit-card-v2 .v2-button {
      margin: 0 auto;
      display: block;
      text-align: center;
      padding: 4px 16px;
      text-transform: uppercase;
      font-size: 14px;
      color: #fff;
      background-color: #55556d;
      border: 0;
      border-radius: 4px;
      margin-top: 10px; }
      .billing-info .new-credit-card-v2 .v2-button:hover, .billing-info .new-credit-card-v2 .v2-button:active, .billing-info .new-credit-card-v2 .v2-button:focus, .billing-info .new-credit-card-v2 .v2-button:active:focus {
        background-color: black;
        outline: none; }
    .billing-info .new-credit-card-v2 .center {
      align-self: center; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.what-happens-next .card {
  border: none;
  border-bottom: 1px solid #979797; }
  .what-happens-next .card .card-header {
    padding: 10px;
    font-size: 16px;
    background: none;
    border: none;
    cursor: pointer;
    color: #55556d; }
    .what-happens-next .card .card-header .arrow {
      margin-left: 10px; }
  .what-happens-next .card .add-color {
    color: #006e6d; }
  .what-happens-next .card .card-body {
    padding-inline: 10px;
    padding-block: 0;
    padding-block-end: 10px;
    font-size: 15px; }
    .what-happens-next .card .card-body .text-title {
      font-weight: bold;
      color: #55556d; }
    .what-happens-next .card .card-body .text-content {
      color: #454e56; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.edit-shift-modal .modalTitle {
  display: flex;
  align-items: center; }
  .edit-shift-modal .modalTitle .screen-title {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-transform: unset;
    font-weight: 700;
    font-size: 1.5rem;
    padding-inline-end: 10px; }
  .edit-shift-modal .modalTitle .hrs {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px; }

.edit-shift-modal .lbr {
  text-align: center; }

.edit-shift-modal .fbr {
  text-align: center; }

.edit-shift-modal .remove-border-r {
  border-right: none; }

.edit-shift-modal .multi-day {
  transform: scale(0.8);
  -webkit-transform-origin-x: 12px; }

.edit-shift-modal input,
.edit-shift-modal select {
  border-color: #65647f;
  font-family: "nimbus-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .edit-shift-modal input:focus,
  .edit-shift-modal select:focus {
    box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
    outline: 0;
    border-color: #65647f; }

.edit-shift-modal .flatpickr-input.active {
  box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
  outline: 0;
  border-color: #65647f; }

.edit-shift-modal .input-group .input-group-append span,
.edit-shift-modal .input-group .input-group-prepend span {
  color: #65647f;
  border-color: #65647f; }

.edit-shift-modal label {
  font-family: Gotham-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.edit-shift-modal .note-text {
  margin-top: 18px;
  margin-bottom: 10px;
  color: #65647f;
  font-family: Gotham-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px; }
  .edit-shift-modal .note-text .note {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .edit-shift-modal .note-text > p {
    margin: 0; }

.edit-shift-modal .inputs {
  border-block: 1px solid #9d9dad;
  margin-block: 10px;
  padding-block-start: 5px; }
  .edit-shift-modal .inputs.bottom-padding {
    padding-block-end: 20px; }
  .edit-shift-modal .inputs .modal-input {
    text-align: center; }
  .edit-shift-modal .inputs label {
    color: #65647f;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.088px;
    margin-bottom: 2px;
    margin-top: 4px; }
  .edit-shift-modal .inputs input::-webkit-outer-spin-button,
  .edit-shift-modal .inputs input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .edit-shift-modal .inputs input[type="number"] {
    -moz-appearance: textfield;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.edit-shift-modal .modalActions {
  display: flex;
  justify-content: flex-end; }
  .edit-shift-modal .modalActions .actions {
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .edit-shift-modal .modalActions .actions button.btn-success {
      border-radius: 7px;
      width: 100px;
      align-self: center;
      background-color: #cfd3e0;
      border-color: #cfd3e0;
      opacity: 1; }
      .edit-shift-modal .modalActions .actions button.btn-success:not(:disabled) {
        background-color: #55556d;
        border-color: #55556d;
        opacity: 1; }
      .edit-shift-modal .modalActions .actions button.btn-success:not(:disabled):hover {
        background-color: black;
        border-color: black;
        opacity: 1; }
      .edit-shift-modal .modalActions .actions button.btn-success:focus {
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5); }
    .edit-shift-modal .modalActions .actions button:nth-child(2) {
      color: #9d9dad;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      padding-block: 2px;
      align-self: center;
      font-size: 14px; }
      .edit-shift-modal .modalActions .actions button:nth-child(2):hover {
        text-decoration: none; }
      .edit-shift-modal .modalActions .actions button:nth-child(2):focus {
        outline: none;
        box-shadow: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.add-shift-modal .modalTitle {
  display: flex;
  align-items: center; }
  .add-shift-modal .modalTitle .screen-title {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-transform: unset;
    font-weight: 700;
    font-size: 1.5rem;
    padding-inline-end: 10px; }
  .add-shift-modal .modalTitle .hrs {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px; }

.add-shift-modal .lbr {
  text-align: center; }

.add-shift-modal .fbr {
  text-align: center; }

.add-shift-modal .remove-border-r {
  border-right: none; }

.add-shift-modal .multi-day {
  transform: scale(0.8);
  -webkit-transform-origin-x: 12px; }

.add-shift-modal input,
.add-shift-modal select {
  border-color: #65647f;
  font-family: "nimbus-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .add-shift-modal input:focus,
  .add-shift-modal select:focus {
    box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
    outline: 0;
    border-color: #65647f; }

.add-shift-modal .flatpickr-input.active {
  box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
  outline: 0;
  border-color: #65647f; }

.add-shift-modal .input-group .input-group-append span,
.add-shift-modal .input-group .input-group-prepend span {
  color: #65647f;
  border-color: #65647f; }

.add-shift-modal label {
  font-family: Gotham-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.add-shift-modal .inputs {
  border-block: 1px solid #9d9dad;
  margin-block: 10px;
  padding-block-start: 5px;
  padding-block-end: 0; }
  .add-shift-modal .inputs .modal-input {
    text-align: center; }
  .add-shift-modal .inputs label {
    color: #65647f;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.088px;
    margin-bottom: 2px;
    margin-top: 4px; }
  .add-shift-modal .inputs input::-webkit-outer-spin-button,
  .add-shift-modal .inputs input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .add-shift-modal .inputs input[type="number"] {
    -moz-appearance: textfield;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.add-shift-modal .note-text {
  margin-top: 18px;
  margin-bottom: 10px;
  color: #65647f;
  font-family: Gotham-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px; }
  .add-shift-modal .note-text .note {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .add-shift-modal .note-text > p {
    margin: 0; }

.add-shift-modal .modalActions {
  display: flex;
  justify-content: flex-end; }
  .add-shift-modal .modalActions .actions {
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .add-shift-modal .modalActions .actions button.btn-success {
      border-radius: 7px;
      width: 100px;
      align-self: center;
      background-color: #cfd3e0;
      border-color: #cfd3e0;
      opacity: 1; }
      .add-shift-modal .modalActions .actions button.btn-success:not(:disabled) {
        background-color: #55556d;
        border-color: #55556d;
        opacity: 1; }
      .add-shift-modal .modalActions .actions button.btn-success:not(:disabled):hover {
        background-color: black;
        border-color: black;
        opacity: 1; }
      .add-shift-modal .modalActions .actions button.btn-success:focus {
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5); }
    .add-shift-modal .modalActions .actions button:nth-child(2) {
      color: #9d9dad;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      padding-block: 2px;
      align-self: center;
      font-size: 14px; }
      .add-shift-modal .modalActions .actions button:nth-child(2):hover {
        text-decoration: none; }
      .add-shift-modal .modalActions .actions button:nth-child(2):focus {
        outline: none;
        box-shadow: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.v2EditPostScene {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: white;
  min-height: calc(100vh - 78px); }
  .v2EditPostScene .sidebar-button {
    display: none; }
    @media (max-width: 768px) {
      .v2EditPostScene .sidebar-button {
        display: block;
        position: absolute;
        top: 84px;
        left: 20px;
        margin: 0;
        padding: 0px;
        z-index: 100;
        background: transparent;
        color: #30827b;
        outline: none;
        border: none;
        font-size: 24px; } }
    .v2EditPostScene .sidebar-button:hover {
      outline: none; }
  .v2EditPostScene main.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    z-index: 1; }
    .v2EditPostScene main.content .rounded-input {
      border-radius: 4px; }
    .v2EditPostScene main.content .react-tel-input .form-control {
      height: 38px;
      border-radius: 0; }
    .v2EditPostScene main.content header h4 {
      font-family: "Lato", Sans-serif;
      font-weight: 700;
      font-size: 1.5rem;
      color: #333333;
      padding-inline: 10px; }
    .v2EditPostScene main.content label:not([for^="file_label_type"]),
    .v2EditPostScene main.content small {
      color: #65647f;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.088px;
      margin-bottom: 2px;
      margin-top: 4px; }
  .v2EditPostScene .screen-title {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-transform: unset;
    font-weight: 700;
    font-size: 1.5rem;
    padding-inline: 10px; }
    @media (max-width: 768px) {
      .v2EditPostScene .screen-title {
        padding-inline: 50px;
        font-size: 24px; } }
    .v2EditPostScene .screen-title span {
      color: #9c9bac;
      font-size: 1.3rem; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.dashboardV2 {
  background-color: white;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  .dashboardV2 .header {
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid #65647f;
    height: 60px; }
    .dashboardV2 .header h1 {
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-transform: capitalize; }
  .dashboardV2 .widgets {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-block: 20px;
    flex-wrap: wrap; }
    .dashboardV2 .widgets .widget {
      display: flex;
      flex-direction: column;
      min-width: 170px;
      margin-block: 10px;
      height: 120px;
      border: 2px solid #65647f;
      border-radius: 10px;
      cursor: pointer;
      flex: 0 0 22%;
      max-width: 22%;
      margin-right: 10px; }
      .dashboardV2 .widgets .widget:last-child {
        margin-right: 0; }
      @media (max-width: 800px) {
        .dashboardV2 .widgets .widget {
          flex: 0 0 100%;
          max-width: 100%; } }
      .dashboardV2 .widgets .widget .widget-title {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        color: #65647f;
        font-size: 16px;
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        height: 50px; }
      .dashboardV2 .widgets .widget .widget-tooltip {
        display: flex;
        align-items: center;
        color: #65647f;
        font-size: 9px;
        height: 50px;
        font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
      .dashboardV2 .widgets .widget .widget-count {
        display: flex;
        color: #65647f;
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 3px;
        width: 90%;
        height: 40px; }
      .dashboardV2 .widgets .widget .widget-alert {
        position: absolute;
        left: -15px;
        top: 35%;
        background-color: #e03738;
        color: white;
        font-weight: bold;
        font-size: 20px;
        width: 30px;
        text-align: center;
        height: 30px;
        border-radius: 50%; }
    .dashboardV2 .widgets .active {
      border: 2px solid #33cccc;
      background-color: rgba(51, 204, 204, 0.1);
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; }
  .dashboardV2 .content-feed {
    border: 2px solid #65647f;
    height: 100%; }
    .dashboardV2 .content-feed .content-feed-header {
      background-color: #65647f;
      height: 50px;
      display: flex;
      align-items: center;
      padding-inline: 10px; }
      .dashboardV2 .content-feed .content-feed-header h6 {
        color: white;
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 18px;
        margin: 0; }
    .dashboardV2 .content-feed .content-feed-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      height: 250px; }
      .dashboardV2 .content-feed .content-feed-body .resource {
        flex: 1;
        overflow: hidden;
        padding: 15px;
        cursor: pointer; }
        .dashboardV2 .content-feed .content-feed-body .resource img {
          width: 100%;
          height: 80%;
          object-fit: cover; }
        .dashboardV2 .content-feed .content-feed-body .resource h6 {
          color: #65647f;
          font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-size: 16px;
          line-height: 1.2;
          margin: 5px 5px 0 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
    @media (max-width: 768px) {
      .dashboardV2 .content-feed .content-feed-body {
        flex-direction: column;
        height: 750px; }
        .dashboardV2 .content-feed .content-feed-body .resource {
          border-bottom: 2px solid #eef3f5; }
          .dashboardV2 .content-feed .content-feed-body .resource:last-child {
            border-bottom: none; } }

@media (max-width: 1100px) {
  .widgets {
    margin: 0 auto;
    justify-content: space-between; } }

@media (max-width: 768px) {
  .widget-tooltip {
    font-size: 12px !important;
    overflow-y: hidden; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.experienceModal .modal-button {
  color: #33cccc;
  text-decoration: underline;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  cursor: pointer; }

.experienceModal .feedback-button {
  color: #65647f;
  font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer; }
  .experienceModal .feedback-button:hover {
    text-decoration: underline; }

.experienceModal .upcoming-button {
  color: #33cccc;
  font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 500;
  cursor: pointer;
  flex-shrink: 0;
  display: flex;
  width: fit-content; }

.experience-modal-content {
  max-height: 90vh; }
  .experience-modal-content .modal-content {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch; }
  .experience-modal-content .modal-title {
    margin: 0;
    text-align: start; }
  .experience-modal-content .header {
    margin-inline: 10px;
    border-bottom: 2px solid #65647f;
    display: flex;
    padding: 1rem 0; }
    .experience-modal-content .header .at-profile-with-shift-details {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      .experience-modal-content .header .at-profile-with-shift-details .shift-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 1rem;
        gap: 0;
        font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 16px; }
        .experience-modal-content .header .at-profile-with-shift-details .shift-details .athletic-trainer {
          margin-bottom: 0;
          font-size: 1.5rem;
          font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          text-transform: unset; }
        .experience-modal-content .header .at-profile-with-shift-details .shift-details > * {
          margin-bottom: 0; }
        @media (max-width: 768px) {
          .experience-modal-content .header .at-profile-with-shift-details .shift-details {
            font-size: 15px; } }
      @media (max-width: 420px) {
        .experience-modal-content .header .at-profile-with-shift-details img {
          flex: 1; }
        .experience-modal-content .header .at-profile-with-shift-details .shift-details {
          flex: 2; } }
    .experience-modal-content .header img {
      height: 100px;
      width: 100px;
      object-fit: cover;
      border-radius: 100%;
      border: 2px solid #65647f;
      margin-block: 10px;
      margin-inline: 0.5rem; }
    .experience-modal-content .header .athletic-trainer-name {
      margin-inline: 5px;
      margin-bottom: 0;
      font-size: 1.5rem;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-transform: unset; }
  .experience-modal-content .modal-body {
    background-color: white;
    padding: 0 15px; }
    .experience-modal-content .modal-body .at-container .at-info {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      color: #65647f;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      margin-top: 5px;
      margin-bottom: 0; }
      .experience-modal-content .modal-body .at-container .at-info p {
        font-weight: bold;
        margin-block: 3px;
        display: flex;
        align-items: center; }
      .experience-modal-content .modal-body .at-container .at-info i {
        font-size: 18px; }
      .experience-modal-content .modal-body .at-container .at-info span {
        font-weight: normal;
        margin-left: 5px; }
    .experience-modal-content .modal-body .at-container .contact-information {
      display: flex;
      flex-direction: column;
      color: #65647f; }
      .experience-modal-content .modal-body .at-container .contact-information > h5 {
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 18px;
        color: #65647f;
        font-weight: bold;
        border-bottom: 1px solid #eef3f5;
        padding-bottom: 10px;
        text-transform: uppercase; }
      .experience-modal-content .modal-body .at-container .contact-information p {
        font-weight: bold;
        margin-block: 3px; }
      .experience-modal-content .modal-body .at-container .contact-information span {
        font-weight: normal; }
    .experience-modal-content .modal-body .at-container .credentials {
      display: flex;
      flex-direction: column;
      color: #65647f; }
      .experience-modal-content .modal-body .at-container .credentials > h5 {
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 18px;
        color: #65647f;
        font-weight: bold;
        border-bottom: 1px solid #eef3f5;
        padding-bottom: 10px;
        text-transform: uppercase; }
      .experience-modal-content .modal-body .at-container .credentials > ul {
        list-style: none;
        padding-inline: 0; }
        .experience-modal-content .modal-body .at-container .credentials > ul > li {
          border-bottom: 1px solid #eef3f5;
          margin-block-end: 3px; }
      .experience-modal-content .modal-body .at-container .credentials .download-documents-button {
        border-bottom: 1px solid #eef3f5;
        padding-bottom: 10px;
        width: fit-content;
        text-align: start;
        color: #33cccc;
        text-decoration: underline;
        font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px; }
    .experience-modal-content .modal-body .at-container .actions-container {
      display: flex;
      justify-content: space-between; }
      .experience-modal-content .modal-body .at-container .actions-container .at-button {
        font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .experience-modal-content .modal-body .experience {
      margin-block: 15px;
      border: 2px solid #65647f;
      border-radius: 4px;
      padding-bottom: 10px; }
      .experience-modal-content .modal-body .experience h5 {
        color: white;
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        background-color: #65647f;
        width: 100%;
        font-weight: bold;
        padding: 10px 0 2px 5px;
        font-size: 18px; }
      .experience-modal-content .modal-body .experience p {
        color: #65647f;
        margin: 0; }
      .experience-modal-content .modal-body .experience .experience-item {
        color: #65647f;
        font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-weight: bold;
        margin: 0;
        font-size: 1rem; }
  .experience-modal-content .actions {
    background-color: white;
    display: flex;
    border-top: 2px solid #65647f;
    margin-inline: 30px;
    justify-content: center; }
    .experience-modal-content .actions button {
      font-size: 15px;
      border-radius: 7px;
      font-weight: bold; }
    .experience-modal-content .actions .accept-button {
      background-color: #55556d;
      border-color: #55556d;
      width: 40%;
      color: white; }
      .experience-modal-content .actions .accept-button:not(:disabled):hover {
        background-color: black;
        border-color: black;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5); }
      .experience-modal-content .actions .accept-button:focus {
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5) !important;
        outline: 0;
        border-color: #55556d; }
      .experience-modal-content .actions .accept-button:active {
        border-color: black;
        background-color: black; }
    .experience-modal-content .actions .decline-button {
      color: #55556d;
      background: transparent;
      cursor: pointer;
      width: 40%;
      border: 1px solid #55556d; }
      å .experience-modal-content .actions .decline-button:hover {
        text-decoration: underline; }
      .experience-modal-content .actions .decline-button:focus {
        outline: none;
        box-shadow: none; }
  .experience-modal-content .credential-type {
    margin-block-end: 0px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .experience-modal-content .credential-type > span {
      font-weight: normal; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.rateBoostModal .modal-button {
  text-decoration: underline;
  text-transform: uppercase;
  color: #33cccc;
  font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.rateBoostModal .boost-shift {
  font-size: 14px;
  color: #65647f;
  font-weight: 600;
  font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin: 0;
  text-decoration: underline;
  background: none;
  border: none;
  outline: none;
  cursor: pointer; }
  .rateBoostModal .boost-shift:focus {
    box-shadow: none; }

.rate-boost-modal-content .modal-title {
  margin: 0; }

.rate-boost-modal-content .header {
  margin-inline: 30px;
  border-bottom: 2px solid #65647f;
  padding-block: 20px 0;
  padding-inline: 0; }
  .rate-boost-modal-content .header .title {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-transform: unset;
    font-size: 2rem;
    letter-spacing: 0.7px; }

.rate-boost-modal-content .modal-body {
  background-color: white;
  padding-inline: 30px;
  padding-block: 20px; }
  .rate-boost-modal-content .modal-body h6 {
    color: #65647f;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: bold;
    margin-bottom: 0; }
  .rate-boost-modal-content .modal-body p {
    color: #65647f;
    display: inline-block;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: normal;
    margin: 0;
    font-size: 16px; }
  .rate-boost-modal-content .modal-body h5 {
    color: #65647f;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: bold;
    margin-bottom: 0;
    display: inline-block; }
  .rate-boost-modal-content .modal-body .rate {
    border: 2px solid #33cccc;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    padding-block: 25px;
    margin-block: 15px; }
  .rate-boost-modal-content .modal-body .active {
    background-color: rgba(51, 204, 204, 0.1);
    border-width: 3px;
    box-shadow: rgba(50, 50, 93, 0.15) 0px 1px 3px, rgba(0, 0, 0, 0.02) 0px 1px 0px; }
  .rate-boost-modal-content .modal-body .rate-input {
    border-color: #65647f;
    color: #65647f; }

.rate-boost-modal-content .actions {
  background-color: white;
  display: flex;
  border-top: 2px solid #65647f;
  margin-inline: 30px;
  justify-content: center; }
  .rate-boost-modal-content .actions button {
    font-size: 15px;
    border-radius: 7px;
    width: 400px;
    font-weight: bold; }
    .rate-boost-modal-content .actions button.btn-success {
      background-color: #33cccc;
      border-color: #33cccc; }
      .rate-boost-modal-content .actions button.btn-success:hover {
        background-color: #006e6d;
        border-color: #006e6d; }
      .rate-boost-modal-content .actions button.btn-success:focus {
        box-shadow: 0 0 0 2px rgba(51, 204, 204, 0.5); }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.reviewModal .modal-button {
  color: #33cccc;
  text-decoration: underline;
  font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.review-modal-content {
  max-width: 600px; }
  .review-modal-content .modal-title {
    margin: 0;
    text-align: start; }
  .review-modal-content .header {
    margin-inline: 10px;
    border-bottom: 2px solid #65647f; }
    .review-modal-content .header img {
      height: 100px;
      width: 100px;
      object-fit: cover;
      border-radius: 100%;
      border: 2px solid #65647f;
      margin-block: 10px;
      align-self: start;
      display: block; }
    .review-modal-content .header .modal-title {
      margin-inline: 5px;
      margin-bottom: 0;
      display: inline-block;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: larger;
      word-wrap: break-word; }
      .review-modal-content .header .modal-title p,
      .review-modal-content .header .modal-title h6 {
        color: #65647f;
        display: inline-block; }
      .review-modal-content .header .modal-title h6 {
        font-weight: bold;
        margin-bottom: 0;
        text-transform: uppercase;
        margin-right: 5px;
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
      .review-modal-content .header .modal-title p {
        margin-block: 0;
        font-size: 13px;
        font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
        @media (max-width: 600px) {
          .review-modal-content .header .modal-title p {
            display: block;
            margin: 0; } }
  .review-modal-content .modal-body {
    background-color: white;
    padding-inline: 30px;
    padding-block: 20px; }
    .review-modal-content .modal-body .rate-choice {
      display: flex;
      color: white;
      font-weight: bold;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      background-color: #9d9dad;
      width: 90%;
      padding: 5px;
      border-radius: 15px;
      justify-content: center;
      margin-block: 10px;
      cursor: pointer; }
      .review-modal-content .modal-body .rate-choice .icon {
        font-size: 14px;
        background-color: #65647f;
        font-weight: bold;
        border-radius: 100%;
        color: white;
        padding: 4px;
        margin-right: 5px; }
    .review-modal-content .modal-body .selected {
      background-color: #65647f;
      box-shadow: 0px 4px 15px rgba(101, 100, 127, 0.8); }
      .review-modal-content .modal-body .selected .icon {
        background-color: #9d9dad; }
    .review-modal-content .modal-body label {
      color: #65647f;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-weight: bold;
      margin-top: 20px; }
    .review-modal-content .modal-body textarea {
      border-color: #65647f;
      border-radius: 4px;
      color: #65647f; }
    .review-modal-content .modal-body p {
      color: #65647f;
      font-style: italic;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      margin: 0;
      font-size: 13px; }
    .review-modal-content .modal-body a {
      color: #33cccc; }
    .review-modal-content .modal-body h6 {
      color: #65647f;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-weight: bold;
      margin: 0;
      font-size: 15px;
      display: flex; }
    .review-modal-content .modal-body i {
      font-size: 18px; }
  .review-modal-content .actions {
    background-color: white;
    display: flex;
    border-top: 2px solid #65647f;
    margin-inline: 10px;
    justify-content: center; }
    .review-modal-content .actions button {
      font-size: 15px;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
      .review-modal-content .actions button.btn-success {
        background-color: #33cccc;
        border-color: #33cccc;
        width: 60%; }
        .review-modal-content .actions button.btn-success:hover {
          background-color: #006e6d;
          border-color: #006e6d; }
        .review-modal-content .actions button.btn-success:focus {
          box-shadow: 0 0 0 2px rgba(51, 204, 204, 0.5); }
      .review-modal-content .actions button.skip-button {
        color: #9d9dad;
        text-decoration: underline;
        width: 30%; }
        .review-modal-content .actions button.skip-button:hover {
          text-decoration: none; }

.shiftModifications {
  background-color: white;
  display: flex;
  flex-direction: column; }
  .shiftModifications .eventListV2 .table .table-body .table-body-header > div {
    display: inline-flex;
    justify-content: flex-start;
    border-right: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.modification-list-item {
  display: flex;
  min-width: 900px;
  padding-block: 10px;
  border-bottom: 2px solid #eef3f5;
  z-index: 0;
  flex-wrap: wrap;
  padding-inline: 10px; }
  .modification-list-item .job-name {
    font-size: 14px !important;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .modification-list-item div {
    font-size: 13px;
    padding: 0;
    padding-right: 10px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .modification-list-item .modification-actions {
    display: flex;
    justify-content: space-between;
    padding-right: 5px; }
    .modification-list-item .modification-actions button,
    .modification-list-item .modification-actions span.plain-button {
      text-decoration: underline;
      text-transform: uppercase;
      color: #9d9dad;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .modification-list-item .modification-actions .accept {
      color: #33cccc; }

.shiftsWithNoApps {
  background-color: white;
  display: flex;
  flex-direction: column; }
  .shiftsWithNoApps .eventListV2 .table .table-body .table-body-header > div {
    display: inline-flex;
    justify-content: flex-start;
    border-right: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.noApps-list-item {
  display: flex;
  min-width: 900px;
  padding-block: 10px;
  border-bottom: 2px solid #eef3f5;
  z-index: 0;
  flex-wrap: wrap;
  padding-inline: 10px; }
  .noApps-list-item .job-name {
    font-size: 14px !important;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .noApps-list-item div {
    font-size: 13px;
    padding: 0;
    padding-right: 10px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .noApps-list-item .boostRate {
    display: flex;
    justify-content: space-between;
    padding-right: 5px; }
    .noApps-list-item .boostRate button,
    .noApps-list-item .boostRate span.plain-button {
      text-decoration: underline;
      text-transform: uppercase;
      color: #9d9dad;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .noApps-list-item .boostRate .accept {
      color: #33cccc; }

.feedback {
  background-color: white;
  display: flex;
  flex-direction: column; }
  .feedback .eventListV2 .table .table-body .table-body-header > div {
    display: inline-flex;
    justify-content: flex-start;
    border-right: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.feedback-list-item {
  display: flex;
  min-width: 900px;
  padding-block: 10px;
  border-bottom: 2px solid #eef3f5;
  z-index: 0;
  flex-wrap: wrap;
  padding-inline: 10px; }
  .feedback-list-item div {
    height: 20px;
    font-size: 14px;
    padding: 0;
    padding-right: 10px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .feedback-list-item div.actions {
      display: flex;
      gap: 1.5rem; }
      .feedback-list-item div.actions div {
        width: auto; }
      .feedback-list-item div.actions .skip {
        text-decoration: underline;
        color: #9d9dad; }

.applicants {
  background-color: white;
  display: flex;
  flex-direction: column; }
  .applicants .eventListV2 .table .table-body .table-body-header > div {
    display: inline-flex;
    justify-content: flex-start;
    border-right: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.applicants-list-item {
  display: flex;
  min-width: 900px;
  z-index: 0;
  padding: 10px;
  flex-wrap: wrap; }
  .applicants-list-item .job-name {
    font-size: 14px !important;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .applicants-list-item > div {
    font-size: 13px;
    padding: 0;
    padding-right: 10px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    align-items: center; }
  .applicants-list-item .view-applicants {
    display: flex;
    justify-content: flex-start; }
    .applicants-list-item .view-applicants i {
      font-size: 20px;
      color: #33cccc; }
    .applicants-list-item .view-applicants button.dropdown-button {
      color: #33cccc;
      font-size: 13px; }
    .applicants-list-item .view-applicants button.plain-button {
      text-decoration: underline;
      text-transform: uppercase;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.sideNavBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #eef3f5;
  min-width: 200px; }
  .sideNavBar .create-job {
    display: flex;
    border-bottom: 1px solid #65647f;
    width: 100%;
    padding-block: 20px;
    justify-content: center;
    height: 80px; }
    .sideNavBar .create-job .btn, .sideNavBar .create-job .custom-file-label {
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-transform: uppercase;
      font-style: normal; }
  .sideNavBar .logo {
    width: 45px; }
  .sideNavBar .links {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    height: 100%;
    padding: 0; }
    .sideNavBar .links li:last-of-type {
      padding-right: 0; }
      .sideNavBar .links li:last-of-type.simple {
        padding-left: 40px;
        margin-right: auto;
        font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-style: normal;
        white-space: nowrap; }
        .sideNavBar .links li:last-of-type.simple > a {
          text-transform: uppercase; }
  .sideNavBar .unauthed-links {
    flex-grow: 1; }
    .sideNavBar .unauthed-links:last-of-type {
      padding-right: 0; }
      .sideNavBar .unauthed-links:last-of-type.simple {
        padding-left: 40px;
        margin-right: auto;
        width: 110%;
        font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-style: normal;
        text-transform: uppercase; }

@media (max-width: 1100px) {
  .sideNavBar {
    display: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.signUpModal .gotham-regular-font {
  font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important; }

.signUpModal .gotham-medium-font {
  font-family: Gotham-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important; }

.signUpModal .gotham-bold-font {
  font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important; }

.signUpModal .nimbus-regular-font {
  font-family: "nimbus-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important; }

.signUpModal .modal-header {
  border: none;
  padding-bottom: 0;
  text-align: left; }
  .signUpModal .modal-header h5 {
    margin-inline: 10px;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .signUpModal .modal-header h5 .modal-title {
      margin-block: 0;
      padding-block: 0;
      font-size: 1.5rem;
      font-weight: 800; }
  .signUpModal .modal-header .close {
    outline: none; }

.signUpModal main .content {
  padding-top: 1rem; }
  .signUpModal main .content .rounded-select {
    border-radius: 5px; }
  .signUpModal main .content .react-tel-input .form-control {
    border-radius: 0; }
  .signUpModal main .content .italic-placeholder::placeholder {
    font-style: italic;
    color: #65647f; }
  .signUpModal main .content label:not([for^="terms"]) {
    font-family: Gotham-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .signUpModal main .content input,
  .signUpModal main .content select {
    border-color: #65647f; }
    .signUpModal main .content input:focus,
    .signUpModal main .content select:focus {
      box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
      outline: #65647f;
      border-color: #65647f; }
  .signUpModal main .content .react-tel-input input {
    border-color: #65647f; }
  .signUpModal main .content .react-tel-input .flag-dropdown {
    border-radius: 0;
    border-color: #65647f; }
  .signUpModal main .content .input-group .input-group-append span,
  .signUpModal main .content .input-group .input-group-prepend span {
    border-color: #65647f; }
  .signUpModal main .content .info-text {
    background-color: #fee02d;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    margin-block-end: 1rem; }
  .signUpModal main .content .password-disclaimer {
    line-height: 10px; }
  .signUpModal main .content .checkbox label a {
    color: #006e6d; }

.modal-content .modal-footer .actionBar__right .actionBar__continue {
  max-width: 200px; }

.modal-content .modal-footer .actionBar__right .login-link {
  color: #677379;
  font-size: 10px;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  outline: none;
  padding-block-start: 15px;
  padding-block-end: 5px; }
  .modal-content .modal-footer .actionBar__right .login-link:hover {
    text-decoration: underline; }

@media (max-width: 575px) {
  .modal-content .actionBar__left {
    display: none; }
  .modal-content .actionBar__right {
    width: 100%;
    margin: 0 auto; }
    .modal-content .actionBar__right .actionBar__continue {
      font-size: 16px; }
    .modal-content .actionBar__right button.login-link {
      font-size: 12px !important; } }

@media (max-width: 420px) {
  .modal-content .actionBar__right .actionBar__continue.save_and_continue {
    max-width: 100%;
    font-size: 20px;
    padding-inline: 20px;
    padding-block: 15px; }
  .modal-content .actionBar__right button.login-link.login-mobile {
    max-width: 100%;
    font-size: 18px !important;
    padding-block-start: 30px;
    padding-block-end: 50px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.credentialModal {
  display: flex;
  flex-direction: column;
  color: #65647f;
  margin-block-end: 3px; }
  .credentialModal .credential-view {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    text-decoration: underline;
    color: #33cccc;
    text-decoration-thickness: 1.5px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.navBarV2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 78px;
  padding-inline: 25px;
  background-color: white;
  border-bottom: 2px solid #65647f; }
  .navBarV2 i {
    font-size: 50px;
    color: black;
    cursor: pointer; }
  .navBarV2 .logo {
    width: 100px;
    border-right: 2px solid #65647f; }
  .navBarV2 .login-btn {
    color: #f33;
    align-self: flex-end;
    margin-bottom: 12px; }

.navBarV2CreateEvent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 15px;
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 78px;
  border-bottom: 2px solid #65647f; }
  .navBarV2CreateEvent i {
    font-size: 40px;
    color: black;
    cursor: pointer; }
  .navBarV2CreateEvent a {
    display: flex;
    width: 100%;
    justify-content: center; }
  .navBarV2CreateEvent .logo {
    width: 100px; }

.navBarV2CreateEventPlaceholder {
  height: 78px; }
  @media (max-width: 1100px) {
    .navBarV2CreateEventPlaceholder {
      height: 0px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.navLinkV2 {
  display: flex;
  color: #fff;
  flex-direction: column;
  margin-block: 10px; }
  .navLinkV2.active > a {
    color: black;
    z-index: 0;
    background-color: #fee02d;
    border-radius: 4px; }
  .navLinkV2 > a {
    display: flex;
    position: relative;
    justify-content: flex-start;
    color: #9d9dad;
    font-size: 17px;
    line-height: 19px;
    padding: 10px 15px;
    text-decoration: none;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-transform: uppercase;
    letter-spacing: normal; }
    .navLinkV2 > a:hover {
      color: black; }
    .navLinkV2 > a > .fa-angle-down {
      display: inline-block;
      padding-left: 6px; }
  .navLinkV2 .dropdown-items {
    width: min-content; }
    .navLinkV2 .dropdown-items a {
      display: flex;
      justify-content: flex-start;
      color: #9d9dad;
      font-size: 17px;
      line-height: 19px;
      padding-block: 10px;
      padding-inline: 30px 15px;
      margin-top: 10px;
      text-decoration: none;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-transform: uppercase;
      letter-spacing: normal; }
      .navLinkV2 .dropdown-items a:hover {
        color: black; }
      .navLinkV2 .dropdown-items a.active {
        color: black;
        background-color: #fee02d;
        border-radius: 4px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.mobileNavBarV2Placeholder {
  height: 0px; }
  @media (max-width: 1100px) {
    .mobileNavBarV2Placeholder {
      height: 78px; } }

.mobileNavBarV2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: 15px;
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 78px;
  border-bottom: 2px solid #65647f; }
  .mobileNavBarV2 i {
    font-size: 40px;
    color: black;
    cursor: pointer; }
  .mobileNavBarV2 a {
    display: flex;
    width: 100%;
    justify-content: center; }
  .mobileNavBarV2 .logo {
    width: 100px; }
  .mobileNavBarV2 .login-btn {
    position: absolute;
    right: 15px;
    color: #f33;
    align-self: flex-end;
    margin-bottom: 12px;
    width: fit-content; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.sideBarComponentV2 {
  background-color: #eef3f5;
  width: 300px;
  height: 100%;
  padding-top: 78px; }
  .sideBarComponentV2 .create-job {
    display: flex;
    border-bottom: 1px solid #65647f;
    width: 100%;
    padding-block: 20px;
    justify-content: center;
    height: 80px; }
    .sideBarComponentV2 .create-job .btn, .sideBarComponentV2 .create-job .custom-file-label {
      width: 200px;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-transform: uppercase;
      font-style: normal; }
  .sideBarComponentV2 .links {
    display: flex;
    flex-direction: column;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .sideBarComponentV2 .links li {
      display: flex;
      color: #fff;
      flex-direction: column;
      margin-block: 10px;
      width: 200px; }
      .sideBarComponentV2 .links li.active > a {
        color: black;
        z-index: 0; }
      .sideBarComponentV2 .links li a {
        display: flex;
        position: relative;
        justify-content: flex-start;
        color: #9d9dad;
        font-size: 17px;
        line-height: 19px;
        padding: 0px 15px;
        text-decoration: none;
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        letter-spacing: normal;
        text-transform: uppercase; }
        .sideBarComponentV2 .links li a:hover {
          color: black; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.sortComponent {
  padding: 0 !important;
  display: flex;
  flex-direction: column; }
  .sortComponent i {
    width: 6px;
    font-size: 14px;
    color: #9d9dad;
    line-height: 5px; }
  .sortComponent .active-arrow {
    color: #33cccc;
    text-shadow: 1px 1px 2px rgba(51, 204, 204, 0.5); }

.paginationV2 {
  align-self: center; }

.paginationV2 > li {
  display: inline-block;
  padding-left: 0; }

.paginationV2 > li {
  list-style: none;
  border: 0; }

.paginationV2 > li > a,
.paginationV2 > li > span {
  position: relative;
  float: left;
  padding: 3px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #65647f;
  background-color: #fff;
  font-size: 18px;
  border: 0;
  margin-inline: 2px; }

.paginationV2 > li.active > a {
  background-color: #eef3f5;
  color: #65647f;
  font-weight: bold;
  border-radius: 100%; }

/* Style the active class (and buttons on mouse-over) */
.paginationV2 > li > a:hover {
  background-color: transparent;
  color: #65647f;
  font-weight: bold; }

.paginationV2 > li:first-child > a,
.paginationV2 > li:first-child > span {
  margin-left: 0;
  padding: 1px 10px 0px 10px !important;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: 300; }

.paginationV2 > li:last-child > a,
.paginationV2 > li:last-child > span {
  margin-right: 0;
  padding: 1px 10px 0px 10px !important;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: 300; }

@media (max-width: 1100px) {
  .paginationV2 {
    justify-content: center; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.venue-map-v2 .venue-section {
  text-align: start;
  color: #55556d; }
  .venue-map-v2 .venue-section p.file-label {
    font-size: 17px; }
  .venue-map-v2 .venue-section p {
    margin: 0; }
  .venue-map-v2 .venue-section .venue-map-file {
    cursor: pointer;
    color: #30827b;
    text-decoration: underline; }
  @media (max-width: 768px) {
    .venue-map-v2 .venue-section {
      text-align: start; }
      .venue-map-v2 .venue-section p.file-label,
      .venue-map-v2 .venue-section p.file-label-copy,
      .venue-map-v2 .venue-section .venue-map-file {
        padding-left: 5px; } }

.venue-map-v2 .custom-file-label {
  top: inherit; }

.venue-map-v2 .upload-label {
  color: #55556d;
  font-size: 17px;
  color: #78868d;
  padding-bottom: 0;
  padding-left: 5px;
  margin-block-end: 8px; }

.venue-map-v2 .venue-action-section {
  width: 100%;
  justify-content: space-between; }

.venue-map-v2 .input-section {
  margin-block-end: 10px; }
  .venue-map-v2 .input-section p {
    color: #55556d; }
  .venue-map-v2 .input-section .custom-file-label {
    text-transform: uppercase;
    letter-spacing: 0;
    font-style: normal;
    width: 200px;
    margin-left: 20px;
    border-color: #65647f;
    background-color: #65647f;
    color: white;
    border-radius: 7px; }
    @media (max-width: 768px) {
      .venue-map-v2 .input-section .custom-file-label {
        margin-left: 0; } }
  .venue-map-v2 .input-section input:focus {
    box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
    outline: 0;
    border-color: #65647f; }
  .venue-map-v2 .input-section input.custom-file-input {
    max-width: 75%; }

@media (max-width: 768px) {
  .venue-map-v2 .vertical-rule {
    display: none; } }

@media (min-width: 768px) {
  .venue-map-v2 .horizontal-rule {
    display: none; } }

.venue-map-modal .modal-dialog {
  max-width: 80vw; }

.venue-map-modal .modal-body {
  text-align: center;
  padding: 20px; }
  .venue-map-modal .modal-body img {
    max-width: 100%; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventListV2 .teal {
  color: #33cccc; }

.eventListV2 .grey {
  color: #9d9dad; }

.eventListV2 .black {
  color: #65647f; }

.eventListV2 .bold {
  font-weight: bold !important; }

.eventListV2 .action-padding-left {
  padding-left: 2rem !important; }

.eventListV2 .collapse-border-bottom {
  border-bottom: 2px solid #eef3f5; }

.eventListV2 .ats {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding-inline: 0; }

.eventListV2 .job-title {
  margin-right: -0.25rem; }

.eventListV2 .ats-margin {
  margin-right: 0.25rem !important; }

.eventListV2 .long-table {
  min-width: 1200px !important; }

.eventListV2 .custom-width-dateTime {
  flex: 0 0 12.66667%;
  max-width: 12.66667%; }

.eventListV2 .custom-width-alert {
  flex: 0 0 20.66667%;
  max-width: 20.66667%; }

.eventListV2 .ats-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 19.5px;
  overflow: visible !important; }

.eventListV2 .ats-section {
  position: absolute;
  height: 43px;
  display: flex;
  align-items: center;
  width: 100%; }

.eventListV2 .expand {
  cursor: pointer;
  color: #33cccc; }

.eventListV2 .grey-expand {
  cursor: default;
  color: #9d9dad; }

.eventListV2 .open-collapse {
  background-color: #eef3f5;
  width: fit-content;
  padding-left: 0.25rem; }

.eventListV2 .has-sort {
  cursor: pointer; }

.eventListV2 .job-name {
  font-size: 16px;
  line-height: 18px;
  height: 20px; }

.eventListV2 .table {
  display: flex;
  align-self: center;
  flex-direction: column;
  border: 2px solid #65647f;
  margin-bottom: 0; }
  .eventListV2 .table .table-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-inline: 10px;
    flex-wrap: wrap;
    flex: 1; }
    .eventListV2 .table .table-header .table-header-title {
      color: #e03738;
      font-weight: bold;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 18px;
      text-transform: none;
      max-width: 60%; }
      .eventListV2 .table .table-header .table-header-title h6 {
        margin: 0;
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        text-transform: none; }
    .eventListV2 .table .table-header .left-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem; }
      .eventListV2 .table .table-header .left-actions i.filters {
        font-size: 45px;
        color: #33cccc;
        cursor: pointer; }
      .eventListV2 .table .table-header .left-actions .search-bar {
        border-bottom: 2px solid #65647f;
        color: #65647f;
        display: flex;
        align-items: center;
        height: 40px;
        width: 100%;
        min-width: 50px;
        max-width: 400px; }
        .eventListV2 .table .table-header .left-actions .search-bar i {
          font-size: 30px; }
        .eventListV2 .table .table-header .left-actions .search-bar input[type="text"] {
          border: none;
          background: transparent;
          height: 100%;
          width: 100%; }
          .eventListV2 .table .table-header .left-actions .search-bar input[type="text"]:focus {
            outline: none; }
        .eventListV2 .table .table-header .left-actions .search-bar button {
          border: none;
          background: transparent;
          padding: 0;
          margin-bottom: -10px; }
          .eventListV2 .table .table-header .left-actions .search-bar button i {
            font-size: 40px; }
          .eventListV2 .table .table-header .left-actions .search-bar button:focus {
            outline: none; }
  .eventListV2 .table .table-location-panel {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #65647f;
    margin-bottom: 5px;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .eventListV2 .table .table-location-panel .table-location {
      font-size: 13px;
      border-right: 2px solid white;
      color: white;
      padding-block: 5px;
      font-weight: bold; }
      .eventListV2 .table .table-location-panel .table-location > span {
        font-size: 0.9rem;
        font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .eventListV2 .table .table-location-panel :nth-child(1) {
      flex: 1;
      padding-inline: 10px; }
    .eventListV2 .table .table-location-panel :nth-child(2) {
      flex: 2;
      border-right: none;
      padding-inline: 10px; }
  .eventListV2 .table .table-body {
    width: 100%;
    overflow: auto;
    color: #65647f;
    text-align: center;
    height: 505px; }
    .eventListV2 .table .table-body .loading-spinner {
      margin-top: 5rem; }
    .eventListV2 .table .table-body .table-body-header {
      position: sticky;
      top: 0;
      z-index: 1;
      display: flex;
      min-width: 900px;
      background-color: #eef3f5;
      border-block: 1px solid #65647f;
      font-weight: bold;
      padding-inline: 10px; }
      .eventListV2 .table .table-body .table-body-header div.hover-effect {
        cursor: pointer; }
      .eventListV2 .table .table-body .table-body-header div {
        font-size: 9px;
        padding-block: 15px 3px;
        align-items: center;
        justify-content: flex-start;
        display: flex;
        padding-inline: 0; }
    .eventListV2 .table .table-body .table-row {
      display: flex;
      min-width: 900px;
      padding-block: 10px;
      border-bottom: 2px solid #eef3f5;
      z-index: 0; }
      .eventListV2 .table .table-body .table-row div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        align-items: center;
        justify-content: flex-start;
        font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
        .eventListV2 .table .table-body .table-row div .dropdown-button {
          color: #33cccc;
          display: flex;
          justify-content: space-evenly;
          width: 100%;
          padding-inline: 5px; }

@media (max-width: 1100px) {
  .eventListV2 .table .table-location-panel :nth-child(1) {
    flex: 1; }
  .eventListV2 .table .table-location-panel :nth-child(2) {
    flex: 1; } }

@media (max-width: 768px) {
  .eventListV2 .table .table-location-panel {
    flex-direction: column;
    text-align: center; }
    .eventListV2 .table .table-location-panel :nth-child(1) {
      border: none; } }

.upcoming {
  background-color: white;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  .upcoming .header {
    display: flex;
    align-items: flex-end;
    border-bottom: 2px solid #65647f;
    height: 60px;
    margin-bottom: 30px; }
    .upcoming .header > h1 {
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-transform: capitalize; }
    .upcoming .header span {
      font-size: 14px;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-weight: normal;
      color: #33cccc;
      line-height: normal; }
  .upcoming .eventListV2 .table .table-body .table-body-header > div {
    display: inline-flex;
    justify-content: flex-start;
    border-right: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.upcoming-list-item {
  display: flex;
  min-width: 900px;
  padding: 10px;
  border-bottom: 2px solid #eef3f5;
  z-index: 0;
  flex-wrap: wrap;
  padding-inline: 10px; }
  .upcoming-list-item .job-name {
    font-size: 16px !important;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    cursor: pointer;
    color: #33cccc;
    text-decoration: underline; }
  .upcoming-list-item div {
    font-size: 13px;
    padding: 0;
    padding-right: 10px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .upcoming-list-item div.ended {
      display: hidden; }
    .upcoming-list-item div.boost, .upcoming-list-item div.review {
      color: #f33;
      background-color: #fee02d;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-align: left;
      padding: 2px 5px;
      border-right: 30px solid white; }
    .upcoming-list-item div.new, .upcoming-list-item div.filled, .upcoming-list-item div.boosted, .upcoming-list-item div.confirmation {
      color: #979797;
      background-color: #eef3f5;
      font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-align: start;
      padding: 2px 5px;
      border-right: 30px solid white; }
  .upcoming-list-item .event-actions {
    display: flex;
    justify-content: space-between;
    padding-right: 10px; }
    .upcoming-list-item .event-actions button,
    .upcoming-list-item .event-actions span.plain-button {
      text-decoration: underline;
      text-transform: uppercase;
      color: #9d9dad;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.upcomingEvent {
  background-color: white;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  .upcomingEvent .header {
    display: flex;
    align-items: flex-end;
    border-bottom: 2px solid #65647f;
    min-height: 60px;
    height: auto; }
    .upcomingEvent .header h1 {
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-transform: capitalize; }
  .upcomingEvent .event-locations {
    display: flex;
    flex-direction: column;
    margin-block: 30px; }
    .upcomingEvent .event-locations .location {
      display: flex;
      width: 70%;
      flex-direction: column; }
      .upcomingEvent .event-locations .location .location-title h5 {
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        display: inline-block;
        font-size: 14px;
        color: #65647f; }
      .upcomingEvent .event-locations .location .location-title h6 {
        font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        display: inline-block;
        color: #65647f;
        font-size: 16px; }
      .upcomingEvent .event-locations .location .location-actions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap; }
        .upcomingEvent .event-locations .location .location-actions button {
          text-decoration: underline;
          font-weight: bold;
          margin-inline: 10px;
          font-size: 14px;
          display: inline-block;
          font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
        .upcomingEvent .event-locations .location .location-actions .view-button {
          color: #33cccc; }
        .upcomingEvent .event-locations .location .location-actions .delete-button {
          color: #9d9dad;
          text-decoration: underline; }
        .upcomingEvent .event-locations .location .location-actions .boost,
        .upcomingEvent .event-locations .location .location-actions .review,
        .upcomingEvent .event-locations .location .location-actions .new,
        .upcomingEvent .event-locations .location .location-actions .waitlist,
        .upcomingEvent .event-locations .location .location-actions .boosted,
        .upcomingEvent .event-locations .location .location-actions .confirmation,
        .upcomingEvent .event-locations .location .location-actions .filled {
          text-align: left;
          padding-inline: 5px;
          font-size: 14px;
          margin: 2px 10px; }
        .upcomingEvent .event-locations .location .location-actions .boost,
        .upcomingEvent .event-locations .location .location-actions .review {
          color: #f33;
          background-color: #fee02d;
          font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
        .upcomingEvent .event-locations .location .location-actions .new,
        .upcomingEvent .event-locations .location .location-actions .filled,
        .upcomingEvent .event-locations .location .location-actions .waitlist,
        .upcomingEvent .event-locations .location .location-actions .boosted,
        .upcomingEvent .event-locations .location .location-actions .confirmation {
          color: #979797;
          background-color: #eef3f5;
          font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .upcomingEvent .event-locations .add-button {
      color: #33cccc;
      align-self: flex-start;
      text-decoration: underline;
      font-weight: bold;
      margin-inline: 10px;
      font-size: 14px;
      display: inline-block;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.drafts {
  background-color: white;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  .drafts .header {
    display: flex;
    align-items: flex-end;
    border-bottom: 2px solid #65647f;
    margin-bottom: 30px;
    height: 60px; }
    .drafts .header > h1 {
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-transform: capitalize; }
    .drafts .header span {
      font-size: 14px;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-weight: normal;
      color: #33cccc;
      line-height: normal; }
  .drafts .eventListV2 .table .table-body .table-body-header > div {
    display: inline-flex;
    justify-content: flex-start;
    border-right: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.draft-list-item {
  display: flex;
  min-width: 900px;
  padding: 10px;
  border-bottom: 2px solid #eef3f5;
  z-index: 0;
  flex-wrap: wrap;
  padding-inline: 10px; }
  .draft-list-item .job-name {
    font-size: 16px !important;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    cursor: pointer;
    color: #33cccc;
    text-decoration: underline; }
  .draft-list-item div {
    font-size: 13px;
    padding: 0;
    padding-right: 10px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .draft-list-item div.complete {
      background-color: #009245;
      border-right: 30px solid white;
      color: white;
      text-transform: uppercase;
      font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      padding: 2px 5px; }
    .draft-list-item div.incomplete {
      background-color: #eef3f5;
      border-right: 30px solid white;
      color: #9d9dad;
      text-transform: uppercase;
      font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      padding: 2px 5px; }
  .draft-list-item .event-actions {
    display: flex;
    justify-content: space-between;
    padding-right: 10px; }
    .draft-list-item .event-actions button,
    .draft-list-item .event-actions span.plain-button {
      text-decoration: underline;
      text-transform: uppercase;
      color: #9d9dad;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .draft-list-item .event-actions .post-button {
      color: #33cccc;
      visibility: hidden; }
    .draft-list-item .event-actions .visible {
      visibility: visible; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.v2LoginScene {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;
  border: 1px solid #000;
  border-radius: 3px;
  margin-top: 40px; }
  .v2LoginScene .alert-margin {
    margin-block: 1rem 0;
    width: 80%; }

@media (max-width: 768px) {
  .v2LoginScene {
    width: 60%; } }

@media (max-width: 575px) {
  .v2LoginScene {
    width: 100%; } }

.v2-title {
  width: 100%;
  padding-block: 20px;
  text-transform: capitalize;
  align-self: self-start;
  letter-spacing: 0.7px;
  font-size: 2rem;
  color: black;
  border-bottom: 1px solid black; }
  .v2-title .v2-title-p {
    width: 80%;
    margin: 0 auto; }

a.atSignUpHyperlink {
  font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  color: #55556d;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-transform: uppercase;
  text-align: center;
  background-color: #eef3f5;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 50%;
  margin-block: 20px; }
  @media (max-width: 768px) {
    a.atSignUpHyperlink {
      width: 60%; } }
  @media (max-width: 575px) {
    a.atSignUpHyperlink {
      width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.v2loginForm {
  width: 80%;
  display: flex;
  flex-direction: column;
  color: #9c9bac;
  padding-block: 15px; }
  .v2loginForm label:not([for^="rememberMe"]) {
    margin: 0;
    color: #4d4d4d;
    font-family: Gotham-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .v2loginForm input {
    border-color: #65647f; }
    .v2loginForm input:focus {
      box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
      outline: #65647f;
      border-color: #65647f; }
  .v2loginForm button.login {
    color: white;
    padding-block: 3px;
    background-color: #55556d;
    width: 60%;
    margin: 0 auto;
    border-radius: 5px; }
    .v2loginForm button.login:hover {
      color: white; }
    .v2loginForm button.login:focus {
      box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
      color: white;
      background-color: #65647f; }
  .v2loginForm .remember-me {
    margin: 1rem auto;
    font-size: 15px;
    color: #4d4d4d; }
  .v2loginForm .show-password {
    position: absolute;
    justify-content: center;
    align-self: center;
    z-index: 100;
    right: 10px; }
  .v2loginForm .password-input {
    padding-right: 35px; }
  .v2loginForm .login-footer {
    display: flex;
    margin-block-end: 0;
    gap: 0.5rem; }
    .v2loginForm .login-footer a {
      text-decoration: underline;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      color: #30827b; }
  @media (max-width: 575px) {
    .v2loginForm .login-footer {
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 1.5rem; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.v2ForgotPasswordScene {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  margin: 0 auto;
  border-radius: 3px;
  color: #65647f;
  margin-top: 40px; }
  .v2ForgotPasswordScene .resetPasswordForm {
    padding-block-start: 15px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto; }
    .v2ForgotPasswordScene .resetPasswordForm input {
      border: 1px solid #65647f; }
      .v2ForgotPasswordScene .resetPasswordForm input:focus {
        box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
        outline: 0;
        border-color: #65647f; }
    .v2ForgotPasswordScene .resetPasswordForm button {
      width: 60%;
      margin: 0 auto;
      color: white;
      font-size: 15px;
      margin-block: 1rem;
      text-transform: uppercase;
      border-radius: 5px;
      padding-block: 3px;
      background-color: #cfd3e0;
      border-color: #cfd3e0;
      opacity: 1; }
      .v2ForgotPasswordScene .resetPasswordForm button:not(:disabled) {
        background-color: #55556d;
        border-color: #55556d;
        opacity: 1; }
      .v2ForgotPasswordScene .resetPasswordForm button:not(:disabled):hover {
        background-color: black;
        border-color: black;
        opacity: 1; }
      .v2ForgotPasswordScene .resetPasswordForm button:focus {
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5); }
    .v2ForgotPasswordScene .resetPasswordForm button.disabled {
      background-color: #cfd3e0;
      border-color: #cfd3e0;
      opacity: 1; }
      .v2ForgotPasswordScene .resetPasswordForm button.disabled:hover {
        background-color: #cfd3e0;
        border-color: #cfd3e0;
        opacity: 1; }
      .v2ForgotPasswordScene .resetPasswordForm button.disabled:focus {
        box-shadow: none; }
    .v2ForgotPasswordScene .resetPasswordForm .reset-failure-message {
      margin-block-start: 0.5rem;
      color: red; }
    .v2ForgotPasswordScene .resetPasswordForm .reset-success-message {
      margin-block: 1.25rem;
      color: #65647f;
      font-size: 14px; }
      .v2ForgotPasswordScene .resetPasswordForm .reset-success-message div:first-child {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px; }
      .v2ForgotPasswordScene .resetPasswordForm .reset-success-message div:last-child {
        padding-left: 28px; }

@media (max-width: 768px) {
  .v2ForgotPasswordScene {
    width: 60% !important; }
    .v2ForgotPasswordScene .resetPasswordForm .reset-success-message {
      margin-block: 0.5rem; } }

@media (max-width: 575px) {
  .v2ForgotPasswordScene {
    width: 100% !important; }
    .v2ForgotPasswordScene .resetPasswordForm button {
      width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.v2resetPasswordScene {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  margin: 0 auto;
  border-radius: 3px;
  color: #65647f;
  margin-top: 40px; }
  .v2resetPasswordScene .v2resetPasswordForm {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px; }
    .v2resetPasswordScene .v2resetPasswordForm input {
      border: 1px solid #65647f; }
      .v2resetPasswordScene .v2resetPasswordForm input:focus {
        box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
        outline: 0;
        border-color: #65647f; }
    .v2resetPasswordScene .v2resetPasswordForm .italic-placeholder::placeholder {
      font-style: italic;
      color: #65647f; }
    .v2resetPasswordScene .v2resetPasswordForm button {
      width: 60%;
      color: white;
      margin: 0 auto;
      margin-block: 1rem;
      text-transform: uppercase;
      border-radius: 5px;
      padding-block: 3px;
      font-style: normal;
      background-color: #cfd3e0;
      border-color: #cfd3e0;
      opacity: 1; }
      .v2resetPasswordScene .v2resetPasswordForm button:not(:disabled) {
        background-color: #55556d;
        border-color: #55556d;
        opacity: 1; }
      .v2resetPasswordScene .v2resetPasswordForm button:not(:disabled):hover {
        background-color: black;
        border-color: black;
        opacity: 1; }
      .v2resetPasswordScene .v2resetPasswordForm button:focus {
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5);
        outline: none; }
    .v2resetPasswordScene .v2resetPasswordForm .password-tip {
      font-size: 12px; }
  .v2resetPasswordScene .v2resetPasswordSuccess {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px; }
    .v2resetPasswordScene .v2resetPasswordSuccess .badges {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1rem; }
      .v2resetPasswordScene .v2resetPasswordSuccess .badges .badge {
        width: 40%;
        justify-content: center;
        align-items: center; }
        .v2resetPasswordScene .v2resetPasswordSuccess .badges .badge img {
          max-width: 100%; }
        .v2resetPasswordScene .v2resetPasswordSuccess .badges .badge.apple img {
          max-height: 70px;
          padding: 3px;
          height: auto !important; }
        .v2resetPasswordScene .v2resetPasswordSuccess .badges .badge.google img {
          max-height: 75px;
          height: auto !important; }

@media (max-width: 768px) {
  .v2resetPasswordScene {
    width: 60%; }
    .v2resetPasswordScene .v2resetPasswordForm button {
      width: 80%; }
  .v2resetPasswordSuccess {
    width: 60%; }
    .v2resetPasswordSuccess .badges {
      flex-direction: column;
      margin-bottom: 1rem; }
      .v2resetPasswordSuccess .badges .badge {
        width: 100%; } }

@media (max-width: 575px) {
  .v2resetPasswordScene {
    width: 100%; }
    .v2resetPasswordScene .v2resetPasswordForm button {
      width: 100%; }
  .v2resetPasswordSuccess {
    width: 100%; } }

.v2footer {
  position: relative;
  width: 100%; }
  .v2footer a {
    text-decoration: none;
    color: inherit; }
  .v2footer .v2lower {
    height: 30px;
    background-color: #65647f;
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    font-size: 0.8em;
    align-items: center;
    color: #9d9dad;
    margin-right: 0; }
    .v2footer .v2lower div {
      padding-inline: 30px;
      white-space: nowrap; }

@media (max-width: 1024px) {
  .v2footer .v2lower {
    min-height: 30px;
    height: auto; } }

@media (max-width: 420px) {
  .v2footer {
    align-items: center; }
    .v2footer .v2lower {
      justify-content: space-evenly;
      flex-direction: column;
      justify-content: center; }
      .v2footer .v2lower div {
        padding-block: 5px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.post-details-panel {
  display: flex;
  flex-direction: row; }
  .post-details-panel .event-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-inline: 5px; }
    .post-details-panel .event-details .event-title {
      color: #373636;
      font-size: 24px;
      font-weight: 900;
      line-height: 29px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.contact-list .remove-contact-btn {
  color: #65647f;
  padding-inline: 10px;
  font-size: 28px;
  cursor: pointer; }
  @media (max-width: 1100px) {
    .contact-list .remove-contact-btn {
      margin-top: 0px; } }

.contact-list label {
  font-family: Gotham-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.contact-list input,
.contact-list select {
  border-color: #65647f; }

.contact-list .italic-placeholder::placeholder {
  font-style: italic;
  color: #65647f; }

.contact-list .react-tel-input input {
  border-color: #65647f; }

.contact-list .react-tel-input .flag-dropdown {
  border-radius: 0;
  border-color: #65647f; }

.contact-list .invalid-feedback-displayed {
  color: #f33;
  font-size: 80%;
  padding-top: 4px; }

@media (min-width: 575px) {
  .contact-list .contact-mobile-row {
    display: none; } }

.contact-list .form-row {
  margin-inline: 0; }
  .contact-list .form-row > [class*="col-"] {
    padding-left: 5px; }

.contact-list .contact-row .form-group {
  margin-bottom: 5px; }
  .contact-list .contact-row .form-group > label {
    margin-top: 0 !important;
    line-height: 14px; }

.contact-list .contact-row .delete-button {
  display: flex;
  justify-content: center;
  text-align: end; }
  .contact-list .contact-row .delete-button button {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-inline: 5px;
    padding-bottom: 15px; }

@media (max-width: 575px) {
  .contact-list .contact-row {
    display: none; } }

.contact-list .actions {
  display: flex;
  flex-direction: row;
  gap: 10px; }
  .contact-list .actions button {
    font-size: 0.8rem;
    border: none;
    background: none;
    margin-bottom: 20px;
    text-decoration: underline;
    cursor: pointer;
    color: #33cccc;
    font-style: normal;
    text-transform: uppercase;
    font-weight: 800;
    padding: 0;
    margin-block: 0;
    margin-inline: 5px 0; }
  .contact-list .actions button.add {
    color: #30827b;
    margin-top: 10px; }

@media (max-width: 1100px) {
  .contact-list .add-emergency-contact-btn {
    margin-top: 15px; }
  .contact-list .merge-phone {
    flex: 5; }
  .contact-list .merge-close {
    flex: 1; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.emergency-equipment p.sub-text {
  font-size: 16px;
  color: #55556d;
  margin-inline: 0;
  padding-left: 5px; }

.emergency-equipment .remove-contact-btn {
  color: #65647f;
  font-size: 28px;
  margin-top: 6px;
  cursor: pointer; }

.emergency-equipment .eq-body-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .emergency-equipment .eq-body-mobile .location-input {
    margin-block: 0.5rem; }
  .emergency-equipment .eq-body-mobile input,
  .emergency-equipment .eq-body-mobile select {
    border-color: #65647f;
    width: 100%;
    padding: 0.375rem 0.75rem; }
  .emergency-equipment .eq-body-mobile .custom-select {
    background-image: none; }
  @media (min-width: 575px) {
    .emergency-equipment .eq-body-mobile {
      display: none !important; } }

.emergency-equipment .eq-body .eq-body-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-inline: 0; }
  .emergency-equipment .eq-body .eq-body-input .location-input {
    margin: 0; }
  .emergency-equipment .eq-body .eq-body-input .input-group,
  .emergency-equipment .eq-body .eq-body-input .from-group {
    padding-inline: 5px; }
  .emergency-equipment .eq-body .eq-body-input .custom-select {
    background-image: none; }
  .emergency-equipment .eq-body .eq-body-input .delete-button {
    display: flex;
    justify-content: center;
    text-align: end; }
    .emergency-equipment .eq-body .eq-body-input .delete-button button {
      padding-inline: 5px; }

@media (max-width: 575px) {
  .emergency-equipment .eq-body {
    display: none !important; }
    .emergency-equipment .eq-body .horizontal-rule {
      display: none !important; } }

.emergency-equipment .italic-placeholder::placeholder {
  font-style: italic;
  color: #65647f; }

.emergency-equipment .bold {
  font-weight: bold; }

.emergency-equipment .actions {
  display: flex;
  flex-direction: row;
  gap: 10px; }
  .emergency-equipment .actions button {
    font-size: 0.8rem;
    border: none;
    background: none;
    margin-bottom: 20px;
    text-decoration: underline;
    cursor: pointer;
    color: #33cccc;
    font-style: normal;
    text-transform: uppercase;
    font-weight: 800;
    padding: 0;
    margin-block: 0;
    margin-inline: 5px 0; }
  .emergency-equipment .actions .add {
    color: #30827b; }
  .emergency-equipment .actions .delete {
    color: #979797; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.v2details {
  display: flex;
  flex-grow: 1;
  flex-direction: row; }
  .v2details main {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    padding-inline: 40px;
    max-width: 60vw; }
    @media (max-width: 1024px) {
      .v2details main {
        padding-inline: 20px;
        max-width: 80vw; } }
    @media (max-width: 768px) {
      .v2details main {
        max-width: 100vw; } }
    .v2details main textarea {
      border-color: #4d4d4d; }
      .v2details main textarea:focus {
        box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(77, 77, 77, 0.25);
        outline: 0;
        border-color: #4d4d4d; }
      .v2details main textarea::placeholder {
        font-style: italic;
        color: #9d9cad;
        font-family: "nimbus-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .v2details main .section-sub-text {
      color: #55556d !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.088px;
      margin-bottom: 2px;
      margin-top: 4px;
      display: block; }
    .v2details main header {
      padding-top: 5px;
      width: 100%;
      border-bottom: 1px solid #677379; }
    .v2details main .details-container {
      min-height: 25vh;
      display: flex;
      flex-direction: column;
      padding-block: 20px; }
      .v2details main .details-container .job-details-section-title {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.088px;
        margin-block: 0;
        color: #4d4d4d; }
      .v2details main .details-container label.custom-control-label::after {
        cursor: pointer; }
      .v2details main .details-container .shift-documents-display {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-inline: 0;
        max-width: 100%; }
      .v2details main .details-container .shift-documents .upload-shifts-doc {
        background-color: transparent;
        border: none;
        outline: none;
        color: #33cccc;
        text-decoration: underline;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.088px;
        margin-bottom: 2px;
        margin-top: 4px;
        cursor: pointer; }
      .v2details main .details-container .shift-documents .doc-upload-list {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-inline: 0; }
      .v2details main .details-container .shift-documents .shift-copy {
        color: #55556d; }
      .v2details main .details-container .shift-documents .upload-documents-btn {
        font-size: 12px;
        text-underline-offset: 3px;
        font-weight: bold; }
      @media (max-width: 420px) {
        .v2details main .details-container .shiftDocuments label {
          height: auto; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.v2-confirmation-modal-content .modal-title {
  margin: 0;
  font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-transform: unset; }

.v2-confirmation-modal-content .header {
  margin-inline: 30px;
  border-bottom: 2px solid #65647f;
  padding-block: 20px 0;
  padding-inline: 0; }
  .v2-confirmation-modal-content .header .close {
    outline: none; }

.v2-confirmation-modal-content .modal-body {
  background-color: white;
  padding-inline: 30px;
  padding-block: 16px; }
  .v2-confirmation-modal-content .modal-body p {
    color: #65647f;
    display: inline-block;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: normal;
    margin: 0;
    font-size: 18px; }
    .v2-confirmation-modal-content .modal-body p.warning-text {
      color: #f33;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      margin-top: 1rem; }
    .v2-confirmation-modal-content .modal-body p.note-text {
      color: #65647f;
      font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
      .v2-confirmation-modal-content .modal-body p.note-text span.note {
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .v2-confirmation-modal-content .modal-body label {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #65647f;
    font-size: 18px; }
  .v2-confirmation-modal-content .modal-body select {
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #65647f;
    font-size: 18px; }

.v2-confirmation-modal-content .estimated-fee {
  margin-top: 1rem; }
  .v2-confirmation-modal-content .estimated-fee .estimated-jobs p {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    cursor: pointer; }
  .v2-confirmation-modal-content .estimated-fee .estimates-total-fees {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .v2-confirmation-modal-content .estimated-fee .confirmed-at {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.5rem; }
    .v2-confirmation-modal-content .estimated-fee .confirmed-at :nth-child(1) {
      margin-top: 0.5rem; }
    .v2-confirmation-modal-content .estimated-fee .confirmed-at :nth-last-child {
      margin-bottom: 0.5rem; }
    .v2-confirmation-modal-content .estimated-fee .confirmed-at .at-name {
      text-decoration: underline;
      font-weight: bold;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .v2-confirmation-modal-content .estimated-fee .confirmed-at .cancellation-reason {
      font-style: italic; }

.v2-confirmation-modal-content .actions {
  background-color: white;
  display: flex;
  border-top: 2px solid #65647f;
  margin-inline: 30px;
  justify-content: center;
  align-items: stretch; }
  .v2-confirmation-modal-content .actions button {
    font-size: 15px;
    border-radius: 7px;
    font-weight: bold; }
    .v2-confirmation-modal-content .actions button.btn-success {
      background-color: #55556d;
      border-color: white;
      width: 40%;
      outline: 0;
      border: 1px solid #55556d;
      font-style: normal; }
      .v2-confirmation-modal-content .actions button.btn-success:not(:disabled):hover {
        background-color: black;
        border-color: black;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5); }
      .v2-confirmation-modal-content .actions button.btn-success:focus {
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5) !important;
        outline: 0;
        border-color: #55556d; }
      .v2-confirmation-modal-content .actions button.btn-success:active {
        border-color: black;
        background-color: black; }
      @media (max-width: 420px) {
        .v2-confirmation-modal-content .actions button.btn-success {
          width: 45%; } }
    .v2-confirmation-modal-content .actions button.btn-success.disabled {
      background-color: #cfd3e0;
      border-color: #cfd3e0;
      opacity: 1; }
      .v2-confirmation-modal-content .actions button.btn-success.disabled:hover {
        background-color: #cfd3e0;
        border-color: #cfd3e0;
        opacity: 1; }
      .v2-confirmation-modal-content .actions button.btn-success.disabled:focus {
        box-shadow: none !important;
        border-color: #cfd3e0; }
    .v2-confirmation-modal-content .actions button.confirm-btn {
      color: #55556d;
      background: transparent;
      border: none;
      cursor: pointer;
      width: 40%;
      border: 1px solid #55556d; }
      .v2-confirmation-modal-content .actions button.confirm-btn:hover {
        text-decoration: underline; }
      .v2-confirmation-modal-content .actions button.confirm-btn:focus {
        outline: none;
        box-shadow: none; }
      @media (max-width: 420px) {
        .v2-confirmation-modal-content .actions button.confirm-btn {
          width: 45%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.finances-list-item {
  display: flex;
  min-width: 900px;
  padding: 10px;
  z-index: 0;
  align-items: center;
  flex-wrap: wrap; }
  .finances-list-item div {
    font-size: 13px;
    padding: 0;
    padding-right: 10px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .finances-list-item .bold {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    letter-spacing: 0; }
  .finances-list-item .duplicate {
    color: #33cccc;
    font-weight: 500;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin: 0;
    text-decoration: underline;
    background: none;
    border: none;
    outline: none;
    cursor: pointer; }
    .finances-list-item .duplicate:focus {
      box-shadow: none; }

.finances-jobs {
  display: flex;
  min-width: 900px;
  z-index: 0;
  align-items: center;
  padding-inline: 0;
  flex-wrap: wrap; }
  .finances-jobs .finances-jobs-headers {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    margin: 0;
    padding-inline: 0;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .finances-jobs .finances-jobs-headers > div {
      background-color: #eef3f5;
      border: 1px solid white;
      color: #9c9bac;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-weight: bold;
      font-size: 10px;
      padding-block: 7px;
      padding-inline: 10px;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    @media (max-width: 768px) {
      .finances-jobs .finances-jobs-headers {
        flex-wrap: nowrap; } }

.finances-job-item {
  display: flex;
  flex-direction: row;
  padding-inline: 0;
  align-items: stretch;
  width: 100%; }
  .finances-job-item > div {
    padding-block: 3px;
    padding-inline: 10px 3px;
    background-color: #eef3f5;
    border: 1px solid white;
    font-size: 13px;
    color: #65647f;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.finances {
  background-color: white;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  .finances .header {
    display: flex;
    align-items: flex-end;
    border-bottom: 2px solid #65647f;
    height: 60px;
    margin-bottom: 30px; }
    .finances .header h1 {
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-transform: capitalize; }
    .finances .header span {
      font-size: 13px;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-weight: normal;
      color: #33cccc;
      line-height: normal; }
  .finances .hidden {
    display: none; }
  @media (max-width: 768px) {
    .finances .finances-order {
      display: flex;
      flex-direction: column-reverse; } }
  .finances .finances-section {
    padding: 0; }
    @media (max-width: 768px) {
      .finances .finances-section {
        padding-block: 10px; } }
  .finances form.finances-filters {
    background-color: #eef3f5;
    padding: 0.5rem;
    max-width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column; }
    .finances form.finances-filters .row {
      justify-content: space-between;
      color: #65647f;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      border-bottom: 2px solid #65647f;
      margin-inline: 0;
      margin-bottom: 8px; }
      .finances form.finances-filters .row i {
        cursor: pointer;
        font-weight: bold; }
    .finances form.finances-filters .form-group {
      flex: 1;
      margin-bottom: 0.5rem; }
      .finances form.finances-filters .form-group label {
        color: #65647f;
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 14px;
        margin-bottom: 0.1rem; }
      .finances form.finances-filters .form-group .form-control {
        border-color: #65647f;
        font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 14px;
        line-height: 1rem; }
      .finances form.finances-filters .form-group .form-control::placeholder {
        color: #9d9dad; }
      .finances form.finances-filters .form-group .custom-select {
        color: #65647f;
        padding: 0.2rem 0.75rem; }
    .finances form.finances-filters .form-controls {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      height: 34px; }
      .finances form.finances-filters .form-controls .clear-filters {
        color: #33cccc;
        text-decoration: underline;
        font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
      .finances form.finances-filters .form-controls .apply-filters {
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        background-color: #33cccc;
        color: white;
        border-radius: 8px;
        padding-inline: 10px; }
        .finances form.finances-filters .form-controls .apply-filters:hover {
          background-color: #006e6d; }
    @media (max-width: 768px) {
      .finances form.finances-filters {
        max-width: 100%;
        width: 100%; } }
  .finances .finances-footer {
    color: #65647f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-block: 1rem; }
    .finances .finances-footer p {
      margin: 0; }
    .finances .finances-footer span {
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-weight: normal;
      color: #33cccc;
      line-height: normal;
      text-decoration: underline;
      cursor: pointer; }
    .finances .finances-footer .export {
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-transform: uppercase;
      font-style: normal; }
      .finances .finances-footer .export:focus {
        box-shadow: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.upcoming-shifts {
  background-color: white;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column; }
  .upcoming-shifts .header {
    display: flex;
    align-items: flex-end;
    border-bottom: 2px solid #65647f;
    margin-bottom: 30px;
    min-height: 60px; }
    .upcoming-shifts .header .header-scope {
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-transform: capitalize; }
      .upcoming-shifts .header .header-scope .view-locations {
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 1rem;
        text-decoration: underline;
        cursor: pointer;
        color: #33cccc;
        text-transform: uppercase;
        font-weight: 800;
        display: inline-block;
        letter-spacing: 0;
        white-space: nowrap; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.upcoming-shift-item {
  display: flex;
  min-width: 900px;
  padding: 10px;
  z-index: 0;
  align-items: center;
  flex-wrap: wrap; }
  .upcoming-shift-item .customTimeWidth {
    flex: 0 0 12.66667%;
    max-width: 12.66667%; }
  .upcoming-shift-item .customAlertWidth {
    flex: 0 0 20.66667%;
    max-width: 20.66667%; }
  .upcoming-shift-item .upcoming-shifts-row {
    font-size: 13px;
    padding: 0;
    padding-right: 10px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .upcoming-shift-item .upcoming-shifts-row.boost, .upcoming-shift-item .upcoming-shifts-row.review {
      color: #f33;
      background-color: #fee02d;
      font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-align: left;
      border-right: 15px solid white;
      padding: 2px 5px; }
    .upcoming-shift-item .upcoming-shifts-row.new, .upcoming-shift-item .upcoming-shifts-row.filled, .upcoming-shift-item .upcoming-shifts-row.boosted, .upcoming-shift-item .upcoming-shifts-row.confirmation {
      color: #979797;
      background-color: #eef3f5;
      text-align: start;
      border-right: 15px solid white;
      padding: 2px 5px; }
  .upcoming-shift-item .alerts {
    justify-content: space-between;
    padding: 0; }
    .upcoming-shift-item .alerts .alert {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
      margin: 0; }
      .upcoming-shift-item .alerts .alert .review-apps {
        font-size: 14px;
        color: #65647f;
        font-weight: 600;
        font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        margin: 0;
        text-decoration: underline;
        background: none;
        border: none;
        outline: none;
        cursor: pointer; }
        .upcoming-shift-item .alerts .alert .review-apps:focus {
          box-shadow: none; }
  .upcoming-shift-item .shift-actions {
    display: flex;
    padding-inline: 0 20px;
    justify-content: space-between; }
    .upcoming-shift-item .shift-actions button {
      text-decoration: underline;
      font-weight: bold; }
  .upcoming-shift-item .edit-button {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #33cccc;
    margin-left: 2rem;
    margin-right: 1rem; }
  .upcoming-shift-item .delete-button {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #9d9dad; }
    @media (max-width: 1400px) {
      .upcoming-shift-item .delete-button {
        flex-wrap: nowrap; } }
  .upcoming-shift-item .upcoming-shift {
    display: flex;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.upcoming-jobs {
  display: flex;
  min-width: 900px;
  z-index: 0;
  align-items: center;
  padding-inline: 0;
  flex-wrap: wrap; }
  .upcoming-jobs .upcoming-jobs-headers {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    margin: 0;
    padding-inline: 0;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .upcoming-jobs .upcoming-jobs-headers > div {
      background-color: #eef3f5;
      border: 1px solid white;
      font-size: 10px;
      color: #9c9bac;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-weight: bold;
      font-style: normal;
      text-transform: uppercase;
      display: flex;
      padding-block: 7px;
      padding-inline: 10px;
      align-items: center; }
    @media (max-width: 768px) {
      .upcoming-jobs .upcoming-jobs-headers {
        flex-wrap: nowrap; } }

.upcoming-job {
  display: flex;
  flex-direction: row;
  padding-inline: 0;
  align-items: stretch;
  width: 100%; }
  .upcoming-job > div {
    padding-block: 0.5rem;
    background-color: #eef3f5;
    border: 1px solid white;
    font-size: 14px;
    padding-inline: 10px;
    color: #65647f;
    display: flex;
    align-items: center; }
    .upcoming-job > div .at-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      align-items: center; }
  .upcoming-job .upcoming-job-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }

.long-table {
  min-width: 1200px; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.edit-job-modal .invalid-feedback-displayed {
  color: #f33 !important;
  font-size: 80%;
  padding-top: 4px; }

.edit-job-modal .modal-body {
  background-color: white; }

.edit-job-modal .action-button {
  text-decoration: underline;
  background-color: transparent;
  text-transform: uppercase;
  font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  .edit-job-modal .action-button:hover {
    cursor: pointer; }

.edit-job-modal .date.disabled {
  opacity: 0.5;
  cursor: not-allowed; }

.edit-job-modal .modalTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }
  .edit-job-modal .modalTitle .screen-title {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-transform: unset;
    font-weight: 700;
    font-size: 1.5rem;
    padding-inline-end: 10px; }
  .edit-job-modal .modalTitle .hrs {
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px; }
  .edit-job-modal .modalTitle .edit-job-tip {
    font-size: 13px;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    padding-inline-end: 10px;
    margin: 0; }

.edit-job-modal .inputs {
  margin-top: 10px;
  padding-block: 20px; }
  .edit-job-modal .inputs .modal-input {
    text-align: center; }
  .edit-job-modal .inputs label {
    color: #65647f;
    font-family: "Lato", Sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.088px;
    margin-bottom: 2px;
    margin-top: 4px; }
  .edit-job-modal .inputs label.disabled {
    color: #858b9a !important; }
  .edit-job-modal .inputs label.disabled::before {
    border-color: #858b9a !important; }
  .edit-job-modal .inputs input::-webkit-outer-spin-button,
  .edit-job-modal .inputs input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .edit-job-modal .inputs input[type="number"] {
    -moz-appearance: textfield; }

.edit-job-modal .actions {
  background-color: white;
  display: flex;
  border-top: 2px solid #65647f;
  margin-inline: 30px;
  justify-content: center; }
  .edit-job-modal .actions button {
    font-size: 15px;
    border-radius: 7px;
    font-weight: bold; }
    .edit-job-modal .actions button.btn-success {
      background-color: #55556d;
      border-color: #55556d;
      width: 40%; }
      .edit-job-modal .actions button.btn-success:not(:disabled):hover {
        background-color: black;
        border-color: black;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5); }
      .edit-job-modal .actions button.btn-success:focus {
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5) !important;
        outline: 0;
        border-color: #55556d; }
      .edit-job-modal .actions button.btn-success:active {
        border-color: black;
        background-color: black; }
    .edit-job-modal .actions button.confirm-btn {
      color: #55556d;
      background: transparent;
      border: none;
      cursor: pointer;
      width: 40%;
      border: 1px solid #55556d; }
      .edit-job-modal .actions button.confirm-btn:hover {
        text-decoration: underline; }
      .edit-job-modal .actions button.confirm-btn:focus {
        outline: none;
        box-shadow: none; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.add-button {
  text-transform: uppercase;
  border: none;
  background: none;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  color: #30827b;
  font-style: normal;
  padding: 0;
  margin: 0;
  outline: none; }
  .add-button:hover {
    color: #006e6d; }

.delete-button {
  width: fit-content;
  text-wrap: nowrap;
  text-transform: uppercase;
  border: none;
  background: none;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  color: #979797;
  font-style: normal;
  padding: 0;
  margin: 0;
  outline: none; }
  .delete-button:hover {
    color: #677379; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.applicants-list {
  display: flex;
  min-width: 900px;
  z-index: 0;
  align-items: center;
  padding-inline: 0;
  flex-wrap: wrap; }
  .applicants-list > div {
    width: 100%; }
  .applicants-list .shift-header {
    background-color: #9c9bac;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-transform: uppercase;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 15px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .applicants-list .shift-header > div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 10px; }
    .applicants-list .shift-header > div > span {
      text-transform: none;
      font-weight: normal; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.button-cell .action-button {
  text-decoration: underline;
  background-color: transparent;
  text-transform: uppercase;
  font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.button-cell .disabled {
  color: #aab8be;
  text-decoration: none; }

.login-modal .modal-header {
  border: none;
  padding-bottom: 0;
  text-align: left; }
  .login-modal .modal-header h5 {
    margin-inline: 10px;
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
    .login-modal .modal-header h5 .modal-title {
      margin-block: 0;
      padding-block: 0;
      font-size: 1.5rem;
      font-weight: 800; }
  .login-modal .modal-header .close {
    outline: none; }

.login-modal .container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-block: 20px; }

.login-modal .alert-margin {
  margin-block: 1rem 0;
  width: 80%; }

.shift-jobs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 0;
  align-items: stretch;
  min-width: 900px; }
  .shift-jobs .shift-jobs-headers {
    display: flex;
    flex-direction: row;
    margin-inline: 0; }
    .shift-jobs .shift-jobs-headers > div {
      background-color: #eef3f5;
      border: 1px solid white;
      font-size: 10px;
      color: #9c9bac;
      font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-weight: bold;
      font-style: normal;
      text-transform: uppercase;
      display: flex;
      padding-block: 7px;
      padding-inline: 10px;
      align-items: center; }
    .shift-jobs .shift-jobs-headers .accept-btn {
      color: #33cccc;
      text-decoration: underline; }
    .shift-jobs .shift-jobs-headers .decline-btn {
      color: #9d9dad;
      text-decoration: underline; }
    @media (max-width: 768px) {
      .shift-jobs .shift-jobs-headers {
        flex-wrap: nowrap; } }
  .shift-jobs .shift-job {
    display: flex;
    flex-direction: row;
    padding-inline: 0;
    align-items: stretch;
    width: 100%; }
    .shift-jobs .shift-job .add-padding {
      padding-block: 0.5rem;
      padding-inline: 10px; }
    .shift-jobs .shift-job > div {
      background-color: #eef3f5;
      border: 1px solid white;
      font-size: 14px;
      color: #65647f;
      display: flex;
      align-items: center; }
      .shift-jobs .shift-job > div .at-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        align-items: center; }
      .shift-jobs .shift-job > div .all-applied-icon {
        margin-left: 5px;
        width: 14px; }
      .shift-jobs .shift-job > div.expire-text {
        font-size: 13px;
        color: #f33;
        font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        min-width: 0; }
        .shift-jobs .shift-job > div.expire-text .expiration-date {
          margin: 0;
          padding: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
      .shift-jobs .shift-job > div .new-updated-job {
        font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
        color: #65647f; }
        .shift-jobs .shift-job > div .new-updated-job span {
          color: #f33;
          font-weight: bold; }
        .shift-jobs .shift-job > div .new-updated-job p {
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
        .shift-jobs .shift-job > div .new-updated-job .hidden {
          display: none; }
  .shift-jobs .shift-job-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-inline-end: 5px;
    padding-inline-start: 0; }
    .shift-jobs .shift-job-actions .button-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      border-right: 2px solid white;
      padding-block: 0.5rem;
      min-width: calc((100% - 44px) / 4);
      padding-inline: 10px; }
      .shift-jobs .shift-job-actions .button-cell:first-child {
        min-width: calc((100% / 4) + 40px); }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.tooltip-icon {
  position: relative;
  color: #30827b !important;
  cursor: pointer;
  font-family: "Material Icons" !important;
  font-weight: normal !important;
  font-style: normal !important;
  letter-spacing: normal !important; }

.flatpickr-time,
.flatpickr-weekdays,
span.flatpickr-weekday {
  color: white;
  background-color: #55556d;
  outline-color: #55556d;
  border-color: #55556d; }

.flatpickr-current-month select.flatpickr-monthDropdown-months {
  color: white;
  background-color: #55556d; }

.flatpickr-months div.flatpickr-month,
.dayContainer .flatpickr-day.selected,
.dayContainer .flatpickr-day.selected:hover {
  color: white;
  background: #55556d;
  border-color: #55556d; }

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  color: white;
  fill: white; }

.flatpickr-current-month div.numInputWrapper span.arrowUp:after,
.flatpickr-current-month div.numInputWrapper span.arrowDown:after,
.flatpickr-time div.numInputWrapper span.arrowUp:after,
.flatpickr-time div.numInputWrapper span.arrowDown:after {
  border-bottom-color: white;
  border-top-color: white; }

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #65647f; }

.flatpickr-time input,
.flatpickr-time span.flatpickr-time-separator,
.flatpickr-time span.flatpickr-am-pm {
  color: white; }

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  color: white;
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  border: 1px solid #55556d; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.vertical-rule {
  width: 1px;
  background: linear-gradient(to bottom, #78868d, #78868d); }
  .vertical-rule.dashed {
    border: none;
    background-image: repeating-linear-gradient(to bottom, #78868d, #78868d 8px, transparent 8px, transparent 16px); }
  .vertical-rule.white {
    background: linear-gradient(to bottom, white, white); }
    .vertical-rule.white.dashed {
      border: none;
      background-image: repeating-linear-gradient(to bottom, white, white 8px, transparent 8px, transparent 16px); }

@media (max-width: 1100px) {
  .hidden-responsive {
    display: none; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.eventIntro {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px; }
  .eventIntro header {
    font-size: 2vw;
    padding: 1vw;
    width: 100%;
    background-color: #fee02d;
    padding: 10px 0; }
    .eventIntro header .screen-title {
      font-size: 24px;
      font-weight: bold; }
  .eventIntro main.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    max-width: 80vw;
    width: 100%; }
    .eventIntro main.content .content-wrapper {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 20px;
      width: 100%;
      padding-left: 10px; }
      .eventIntro main.content .content-wrapper .list-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        flex: 1; }
        .eventIntro main.content .content-wrapper .list-container ul {
          list-style-type: none;
          padding-left: 0; }
          .eventIntro main.content .content-wrapper .list-container ul li {
            font-size: 22px;
            padding: 10px 0;
            font-family: "nimbus-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            color: #65647f; }
        .eventIntro main.content .content-wrapper .list-container .not-ready-link,
        .eventIntro main.content .content-wrapper .list-container .ready-button {
          width: 100%;
          max-width: 300px;
          text-align: center; }
        .eventIntro main.content .content-wrapper .list-container .not-ready-link {
          color: #30827b;
          font-size: 85%;
          margin-top: 10px;
          background: none;
          border: none;
          outline: none;
          box-shadow: none;
          text-decoration: underline; }
        .eventIntro main.content .content-wrapper .list-container .ready-button {
          background-color: #55556d;
          color: white;
          padding: 10px 20px;
          font-size: 16px;
          cursor: pointer;
          border-radius: 5px; }
          .eventIntro main.content .content-wrapper .list-container .ready-button:hover {
            background-color: #3f3f50; }
      .eventIntro main.content .content-wrapper .intro-video {
        flex: 9;
        width: 100%; }
        .eventIntro main.content .content-wrapper .intro-video iframe {
          float: inline-end;
          width: 100%;
          height: 75vw;
          max-width: 860px;
          max-height: 484px; }

@media (max-width: 768px) {
  .eventIntro main.content .content-wrapper {
    flex-direction: column; }
    .eventIntro main.content .content-wrapper .intro-video iframe {
      float: unset;
      width: 100%;
      height: 75vw;
      max-width: 650px;
      max-height: 384px; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.learn-more-modal .modal-header {
  border-color: white;
  padding-block: 4px; }

.learn-more-modal .header {
  background-color: white;
  font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: bold; }

.learn-more-modal h5.modal-title {
  font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin-top: 20px; }

.learn-more-modal .modal-body {
  background-color: white;
  font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #65647f;
  font-size: 14px;
  padding-block: 4px; }
  .learn-more-modal .modal-body p {
    font-size: 1.2em;
    margin-bottom: 1.3rem; }
  .learn-more-modal .modal-body .form-group {
    margin-bottom: 1.5rem; }
    .learn-more-modal .modal-body .form-group label {
      color: #65647f;
      font-size: 1.2em;
      font-weight: 500;
      margin-bottom: 0; }
    .learn-more-modal .modal-body .form-group .form-control {
      font-family: "nimbus-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 14px;
      border: 1px solid #65647f;
      border-radius: 0.25rem; }
      .learn-more-modal .modal-body .form-group .form-control.is-invalid {
        border-color: #f33; }
      .learn-more-modal .modal-body .form-group .form-control::placeholder {
        color: #adb5bd; }
    .learn-more-modal .modal-body .form-group .text-danger, .learn-more-modal .modal-body .form-group .newCreditCard .errorMessage, .newCreditCard .learn-more-modal .modal-body .form-group .errorMessage, .learn-more-modal .modal-body .form-group .billing-info .new-credit-card-v2 .error-message, .billing-info .new-credit-card-v2 .learn-more-modal .modal-body .form-group .error-message {
      font-size: 80%;
      padding-top: 0.25rem;
      color: #f33; }

.learn-more-modal .modal-footer {
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 22px;
  border-top: none; }
  .learn-more-modal .modal-footer .submit-learn {
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    padding: 0.375rem 0.75rem;
    width: 200px;
    border-radius: 0.25rem;
    font-weight: bold; }
    .learn-more-modal .modal-footer .submit-learn.btn-secondary {
      font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      background-color: transparent;
      border: 1px solid #55556d;
      color: #55556d;
      text-transform: uppercase; }
      .learn-more-modal .modal-footer .submit-learn.btn-secondary:hover {
        text-decoration: underline; }
    .learn-more-modal .modal-footer .submit-learn.btn-primary, .learn-more-modal .modal-footer .submit-learn.custom-file-label {
      font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      background-color: #55556d;
      border: 1px solid #55556d;
      color: white;
      text-transform: uppercase; }
      .learn-more-modal .modal-footer .submit-learn.btn-primary:hover, .learn-more-modal .modal-footer .submit-learn.custom-file-label:hover, .learn-more-modal .modal-footer .custom-file-input:hover + .submit-learn.custom-file-label {
        background-color: #3f3f50;
        border-color: #3f3f50;
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5); }
      .learn-more-modal .modal-footer .submit-learn.btn-primary:focus, .learn-more-modal .modal-footer .submit-learn.custom-file-label:focus {
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5);
        outline: 0; }
      .learn-more-modal .modal-footer .submit-learn.btn-primary:disabled, .learn-more-modal .modal-footer .submit-learn.custom-file-label:disabled {
        background-color: #c0c0ce;
        border-color: #c0c0ce; }

.learn-more-modal .horizontal-rule {
  width: 85%;
  margin: 1rem auto;
  border-top: 1px solid #65647f; }

@media (max-width: 768px) {
  .learn-more-modal .header {
    font-size: 1.2rem; }
  .learn-more-modal .modal-body p {
    font-size: 0.9rem; }
  .learn-more-modal .modal-body .form-group label {
    font-size: 12px; }
  .learn-more-modal .modal-body .form-group .form-control {
    font-family: "nimbus-sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 12px; }
  .learn-more-modal .modal-body .form-group .text-danger, .learn-more-modal .modal-body .form-group .newCreditCard .errorMessage, .newCreditCard .learn-more-modal .modal-body .form-group .errorMessage, .learn-more-modal .modal-body .form-group .billing-info .new-credit-card-v2 .error-message, .billing-info .new-credit-card-v2 .learn-more-modal .modal-body .form-group .error-message {
    font-size: 70%; }
  .learn-more-modal .modal-footer .btn, .learn-more-modal .modal-footer .custom-file-label {
    font-size: 0.875rem;
    padding: 0.375rem 0.75rem;
    width: 120px; } }

.customCheckbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(48, 130, 123, 0.25);
  border-color: #30827b; }

.customCheckbox .custom-checkbox .custom-control-label::before {
  top: 0.14rem;
  border-radius: 0.3rem;
  color: #30827b; }

.customCheckbox .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #30827b;
  background: #30827b linear-gradient(180deg, #458f88, #30827b) repeat-x; }

.customCheckbox .custom-checkbox .custom-control-label::after {
  top: 0.14rem;
  cursor: pointer; }

.center-text {
  margin-inline: auto; }

.customRadioCheckBox .custom-radio .custom-control-input ~ .custom-control-label::before {
  background-color: transparent;
  border: 1px solid #373636; }

.customRadioCheckBox .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%23006e6d'/%3e%3c/svg%3e"); }

.customRadioCheckBox .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: transparent;
  border: 1px solid #373636; }

.customRadioCheckBox .custom-radio.adjust-modal-vertical-align .custom-control-input:checked ~ .custom-control-label::after {
  top: 0.122rem;
  left: -1.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='-4 -4 8 8'%3e%3ccircle r='4' fill='%23006e6d'/%3e%3c/svg%3e"); }

.customRadioCheckBox .custom-radio.adjust-modal-vertical-align .custom-control-label::before {
  top: 0.122rem; }

.customRadioCheckBox .form-control:disabled {
  opacity: 0; }

.customRadioCheckBox label.disabled {
  color: #858b9a !important; }

.customRadioCheckBox label.disabled::before {
  border-color: #858b9a !important; }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.v2ResendConfirmationScene {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  margin: 0 auto;
  border-radius: 3px;
  color: #65647f;
  margin-top: 40px; }
  .v2ResendConfirmationScene .resendConfirmationForm {
    padding-block-start: 15px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto; }
    .v2ResendConfirmationScene .resendConfirmationForm input {
      border: 1px solid #65647f; }
      .v2ResendConfirmationScene .resendConfirmationForm input:focus {
        box-shadow: 0 0 1px white, 0 0 0 0.2rem rgba(101, 100, 127, 0.25);
        outline: 0;
        border-color: #65647f; }
    .v2ResendConfirmationScene .resendConfirmationForm button {
      width: 60%;
      margin: 0 auto;
      color: white;
      font-size: 15px;
      margin-block: 1rem;
      text-transform: uppercase;
      border-radius: 5px;
      padding-block: 3px;
      background-color: #cfd3e0;
      border-color: #cfd3e0;
      opacity: 1; }
      .v2ResendConfirmationScene .resendConfirmationForm button:not(:disabled) {
        background-color: #55556d;
        border-color: #55556d;
        opacity: 1; }
      .v2ResendConfirmationScene .resendConfirmationForm button:not(:disabled):hover {
        background-color: black;
        border-color: black;
        opacity: 1; }
      .v2ResendConfirmationScene .resendConfirmationForm button:focus {
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5); }
    .v2ResendConfirmationScene .resendConfirmationForm button.disabled {
      background-color: #cfd3e0;
      border-color: #cfd3e0;
      opacity: 1; }
      .v2ResendConfirmationScene .resendConfirmationForm button.disabled:hover {
        background-color: #cfd3e0;
        border-color: #cfd3e0;
        opacity: 1; }
      .v2ResendConfirmationScene .resendConfirmationForm button.disabled:focus {
        box-shadow: none; }
    .v2ResendConfirmationScene .resendConfirmationForm .resend-failure-message {
      margin-block-start: 0.5rem;
      color: red; }
    .v2ResendConfirmationScene .resendConfirmationForm .resend-success-message {
      margin-block: 1.25rem;
      color: #65647f;
      font-size: 14px; }
      .v2ResendConfirmationScene .resendConfirmationForm .resend-success-message div:first-child {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px; }
      .v2ResendConfirmationScene .resendConfirmationForm .resend-success-message div:last-child {
        padding-left: 28px; }
    .v2ResendConfirmationScene .resendConfirmationForm .confirmation-footer {
      display: flex;
      justify-content: center;
      margin-block: 1rem; }
      .v2ResendConfirmationScene .resendConfirmationForm .confirmation-footer a {
        text-decoration: underline;
        color: #30827b; }

@media (max-width: 768px) {
  .v2ResendConfirmationScene {
    width: 60% !important; }
    .v2ResendConfirmationScene .resendConfirmationForm .resend-success-message {
      margin-block: 0.5rem; } }

@media (max-width: 575px) {
  .v2ResendConfirmationScene {
    width: 100% !important; }
    .v2ResendConfirmationScene .resendConfirmationForm button {
      width: 100%; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.paid-job-modal .screen-title {
  font-weight: bold;
  border-bottom: 2px solid #65647f;
  font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #454e56;
  font-size: 22px; }

.paid-job-modal .modal-title {
  margin: 0;
  font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-transform: unset;
  font-style: bold;
  font-size: 24px;
  font-weight: bold;
  color: #55556d; }

.paid-job-modal .modal-body {
  background-color: white;
  padding-inline: 30px;
  padding-block: 20px; }
  .paid-job-modal .modal-body p {
    color: #65647f;
    display: inline-block;
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: normal;
    margin: 0;
    font-size: 18px;
    padding-top: 20px; }
  .paid-job-modal .modal-body .modal-message {
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    color: #65647f;
    margin-bottom: 20px; }
  .paid-job-modal .modal-body label {
    font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #65647f;
    font-size: 18px; }
  .paid-job-modal .modal-body select {
    font-family: Gotham-Book, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #65647f;
    font-size: 18px; }

.paid-job-modal .actions {
  background-color: white;
  display: flex;
  border-top: 2px solid #65647f;
  margin-inline: 30px;
  justify-content: center; }
  .paid-job-modal .actions button {
    font-size: 15px;
    border-radius: 7px;
    font-weight: bold; }
    .paid-job-modal .actions button.btn-success {
      background-color: #55556d;
      border-color: white;
      width: 40%;
      outline: 0;
      border: 1px solid #55556d;
      font-style: normal; }
      .paid-job-modal .actions button.btn-success:not(:disabled):hover {
        background-color: black;
        border-color: black;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5); }
      .paid-job-modal .actions button.btn-success:focus {
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5) !important;
        outline: 0;
        border-color: #55556d; }
      .paid-job-modal .actions button.btn-success:active {
        border-color: black;
        background-color: black; }
    .paid-job-modal .actions button.confirm-btn {
      color: #55556d;
      background: transparent;
      border: none;
      cursor: pointer;
      width: 40%;
      border: 1px solid #55556d; }
      .paid-job-modal .actions button.confirm-btn:hover {
        text-decoration: underline; }
      .paid-job-modal .actions button.confirm-btn:focus {
        outline: none;
        box-shadow: none; }

.termsAndConditionsModal {
  height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
  margin-block: 20px; }
  .termsAndConditionsModal .modal-content {
    height: 100%; }
  .termsAndConditionsModal .modal-header {
    text-align: left;
    height: min-content; }
    .termsAndConditionsModal .modal-header h5.modal-title {
      margin-inline: 10px;
      margin-block: 0;
      font-size: 1.5rem;
      font-weight: 800; }
  .termsAndConditionsModal main .bold {
    font-weight: bold; }
  .termsAndConditionsModal main .headers span {
    font-weight: bold; }
  .termsAndConditionsModal .modal-body {
    height: auto;
    overflow-y: scroll; }
  .termsAndConditionsModal .actions {
    background-color: white;
    display: flex;
    border-top: 2px solid #65647f;
    margin-inline: 25px;
    justify-content: center;
    height: min-content; }
    .termsAndConditionsModal .actions button {
      font-size: 15px;
      border-radius: 7px;
      font-weight: bold; }
    .termsAndConditionsModal .actions .accept-button {
      background-color: #55556d;
      border-color: #55556d;
      width: 40%;
      color: white; }
      .termsAndConditionsModal .actions .accept-button:not(:disabled):hover {
        background-color: black;
        border-color: black;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5); }
      .termsAndConditionsModal .actions .accept-button:focus {
        box-shadow: 0 0 0 2px rgba(85, 85, 109, 0.5) !important;
        outline: 0;
        border-color: #55556d; }
      .termsAndConditionsModal .actions .accept-button:active {
        border-color: black;
        background-color: black; }
    .termsAndConditionsModal .actions .decline-button {
      color: #55556d;
      background: transparent;
      cursor: pointer;
      width: 40%;
      border: 1px solid #55556d; }
      å .termsAndConditionsModal .actions .decline-button:hover {
        text-decoration: underline; }
      .termsAndConditionsModal .actions .decline-button:focus {
        outline: none;
        box-shadow: none; }

@media (max-width: 1100px) {
  .termsAndConditionsModal {
    height: 80vh;
    max-height: 80vh;
    margin-block: 4vh 16vh; } }

@font-face {
  font-family: Evogria;
  src: url("Evogria.otf"); }

@font-face {
  font-family: Gotham;
  src: url("Gotham-Bold.otf"); }

@font-face {
  font-family: Gotham-Book;
  src: url("Gotham Book.otf"); }

@font-face {
  font-family: Evogria;
  src: url("Evogria Italic.otf");
  font-style: italic; }

@font-face {
  font-family: Gotham-Medium;
  src: url("Gotham Medium.otf"); }

.custom-file-label {
  border-radius: 3px; }
  .custom-file-label::after {
    display: none; }

.custom-file-input:hover {
  cursor: pointer; }

.btn-primary, .custom-file-label {
  color: white; }

.btn-gray {
  background: #d9d9d9; }

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .custom-file-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 44px;
  font-size: 14px; }

.btn-link.secondary {
  color: #78868d; }

.btn-link:disabled {
  text-decoration: none; }

.btn.bold-button, .bold-button.custom-file-label {
  padding: 16px 32px;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 20px;
  line-height: 25px;
  align-items: center;
  justify-content: center;
  font-style: italic;
  font-family: Gotham, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: 0.7;
  text-transform: "lowercase"; }
  .btn.bold-button:active, .bold-button.custom-file-label:active {
    box-shadow: none !important; }
  .btn.bold-button.disabled, .bold-button.disabled.custom-file-label, .btn.bold-button[disabled], .bold-button.custom-file-label[disabled] {
    background-color: #b9b6ba;
    border-color: #b9b6ba; }
  .btn.bold-button:hover:not(.disabled), .bold-button.custom-file-label:hover:not(.disabled) {
    text-decoration: none;
    box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.5); }

.link-button {
  color: #00b3ef;
  cursor: pointer; }
  .link-button:hover {
    color: #007aa3; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .was-validated .custom-control-input:checked:valid ~ .custom-control-label {
  color: #373636; }
  .was-validated .custom-control-input:valid ~ .custom-control-label:before, .was-validated .custom-control-input:checked:valid ~ .custom-control-label:before {
    background-color: transparent; }

.modal-header {
  border-color: #979797; }

.modal-footer {
  justify-content: space-between;
  border-top: 0; }

.modal-body {
  padding: 25px 40px; }

.modal-body, .modal-footer {
  background-color: rgba(51, 204, 204, 0.1); }

.cancel-modal {
  font-size: 18px;
  font-weight: 500; }

.modal-content {
  border-radius: 0;
  font-size: 15px;
  color: #373636; }

h5.modal-title {
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 22px;
  text-align: center;
  color: #454e56;
  margin-left: auto; }

* {
  box-sizing: border-box; }

a[disabled] {
  pointer-events: none; }

body,
html {
  min-height: 100vh;
  height: 100%; }

h1,
h2,
h3 {
  letter-spacing: 0.7px;
  text-transform: uppercase; }

ul.list-table {
  padding: 0;
  border-top: 1px solid #979797;
  margin-top: 15px; }
  ul.list-table li {
    list-style: none;
    border-bottom: 1px solid #c4c5c7;
    border-left: 1px solid #979797;
    border-right: 1px solid #979797;
    background-color: #fff; }
    ul.list-table li.head {
      font-size: 12px;
      color: #6d6b6b;
      display: flex;
      line-height: 15px;
      text-transform: uppercase;
      background-color: #f2f4f3;
      height: 48px;
      align-items: center; }
      ul.list-table li.head > div.stacked-text {
        word-spacing: 9999px; }

#attribution {
  background-repeat: no-repeat;
  height: 20px;
  margin-top: 10px; }

#root {
  min-height: 100vh;
  height: 100%; }

.plain-button {
  color: #00b3ef;
  border: none;
  background: transparent;
  display: inline-block;
  font-weight: normal;
  padding: 0; }
  .plain-button:focus {
    outline: none; }

.is-invalid + .flatpickr-input {
  border-color: #f33; }

.flatpickr-day.selected,
.flatpickr-day.selected:hover {
  background: #f33;
  border-color: #f33; }

.flatpickr-input[readonly] {
  background-color: white; }

@media (max-width: 1100px) {
  h1 {
    font-size: 30px; } }
